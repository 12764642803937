import Profile from "../form/Profile";
import FormatPopup from "../sections/format/FormatPopup";
import HeaderInfo from "./HeaderInfo";
import HeaderLogo from "./HeaderLogo";
import { SwitchToMenu } from "./SwitchToMenu";
import { logout } from "./api/api";
import { findOptionByKey, getSectionExists } from "../class/utils";
import { ALL_WIDGETS, CLIENTS, CLIENT_ID_STORAGE, EDIT_SCOPE, CHAT_BOT } from "../class/constants";
import { readCookie } from "../class/jqueries";
import { getLocalStorageValueByParameter } from "../class/common";
import { useMemo } from "react";
import ScopeProfile from "../form/ScopeProfile";
import { ProfitAssist } from "./ProfitAssist";
/**
 * @author Sandra Abdelkhalek
 * @description this components renders client logo, bento menu icon, settings icon, info icon and avatar avatar
 * @param {*} props 
 * @returns {@link SwitchToMenu} , {@link HeaderLogo}, {@link FormatPopup}, {@link Profile}
 */
const NavigationHeader = (props) => {
  let isScopeFilterAllowed = getSectionExists(props.allowedMenuLinks, EDIT_SCOPE);
  // remove info icon if user don't have access to vector defintions and psl defintions
  let showInfoIcon = !props.hideInfo;

  let clients = getLocalStorageValueByParameter(CLIENTS) ? JSON.parse(getLocalStorageValueByParameter(CLIENTS)) : [];

  const formatData = (data) => {
    const clientIdFromLocalStorage = getLocalStorageValueByParameter(CLIENT_ID_STORAGE)? Number(getLocalStorageValueByParameter(CLIENT_ID_STORAGE)):"";
    data.map(e=>{
        e.value = e.client_id;
        e.label = e.client_name;
    })
    return findOptionByKey(data, clientIdFromLocalStorage).label
  }

  const formattedData = useMemo(() => formatData(clients), [clients]);
  const allowedMenuLinks = useMemo(()=>props.allowedMenuLinks,[props.allowedMenuLinks]);
  const goToReport = useMemo(()=>props.goToReport,[props.goToReport]);
  const goToLandingPage = useMemo(()=>props.goToLandingPage,[props.goToLandingPage]);
  const clientUrl = useMemo(()=>props.userSettings?.clientUrl,[props.userSettings?.clientUrl]);
  const clientLogo = useMemo(() => props.clientLogo, [props.clientLogo]);
  const scenario = useMemo(()=>props?.scenario,[props?.scenario]);
  const hideSettings = useMemo(()=>props?.hideSettings,[props?.hideSettings]);
  const historyPushAndReload = useMemo(()=>props?.historyPushAndReload,[props?.historyPushAndReload]);
  const format_max_decimal = useMemo(()=>props.userSettings?.format_max_decimal,[props.userSettings?.format_max_decimal]);
  const isSwitchDisabled = useMemo(()=>props?.isSwitchDisabled,[props?.isSwitchDisabled]);
  const user = useMemo(()=>props.userSettings?.user,[props.userSettings?.user]);
  const showPersonalSettings = useMemo(()=>props?.showPersonalSettings,[props?.showPersonalSettings]);
  const signIn = useMemo(()=>props?.signIn,[props?.signIn]);
  const setProfitFormat = useMemo(()=>props?.setProfitFormat,[props?.setProfitFormat]);
  const addScope = useMemo(()=>props.userSettings?.addScope,[props.userSettings?.addScope]);
  const useProfitAssist = useMemo(()=>props.userSettings?.useProfitAssist,[props.userSettings?.useProfitAssist]);
  const userSettings = useMemo(()=>props?.userSettings,[props?.userSettings]);


  return (
    <div  key={"navigation-header"} className="navigation-header-component  noprint">
      <div key={"navigation-header-logo"} className="elements-container">
        {!props.hideSwitchToMenu && (
          <SwitchToMenu userAllowedMenuLinks={allowedMenuLinks} goToReport={goToReport} goToLandingPage={goToLandingPage} />
        )}
          <HeaderLogo clientUrl={clientUrl} clientLogo={clientLogo}/>
      </div>
      <div className="elements-container">
      {showInfoIcon && <HeaderInfo userAllowedMenuLinks={allowedMenuLinks} setProfitSegmentationOpen={props.setProfitSegmentationOpen} scenario={scenario} />}
        {!hideSettings && (
          <FormatPopup
            isNewHeader={true}
            userAllowedMenuLinks={allowedMenuLinks}
            historyPushAndReload={historyPushAndReload}
            format_max_decimal={format_max_decimal}
          />
        )}
          {addScope ?
              <ScopeProfile
                  isSwitchDisabled={isSwitchDisabled}
                  logout={logout}
                  user={user}
                  goToMultiClients={showPersonalSettings}
                  signIn={signIn}
                  setProfitFormat={setProfitFormat}
                  vectors = {props.vectors}
                  scenario = {props.scenario}
                  isScopeFilterAllowed = {isScopeFilterAllowed}
              />
              :
              <Profile
                  isSwitchDisabled={isSwitchDisabled}
                  logout={logout}
                  user={user}
                  goToMultiClients={showPersonalSettings}
                  signIn={signIn}
                  setProfitFormat={setProfitFormat}
                  // checkForUnsavedChanges={this.props.checkForUnsavedChanges}
              />
          }
          {useProfitAssist && getSectionExists(allowedMenuLinks, CHAT_BOT) && props.showChatbot?
              <ProfitAssist userSettings={userSettings}/> : ""}
      </div>

      {/* These are for DevOps to get the email and client selected */}
      <div className="hidden">
        <span id={"email_profile"}>{(user && user.email) || readCookie("user_email")}</span>
        <span id={"selected_client"}>{formattedData}</span>
      </div>
      {/* ************************************************************ */}
    </div>
  );
};

export default NavigationHeader;
