// CustomChatBot.js
import React, { useEffect, useState, useRef } from 'react';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils";
import {Span} from "../../Tracing/Span";
import { useChatInteract, useChatMessages, useChatSession, sessionState } from '@chainlit/react-client';
import { useRecoilState } from 'recoil';
import Button from '../../newComponents/Button';
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from '../../class/constants';
import ChatBubble from "./ChatBubble";
import { VectorSuggestions } from './VectorSuggestions';
import { RecentChats } from './RecentChats';
import { chatMessagesState } from './recoilState';
import { ClickAwayListener } from '@mui/material';
import SoundFlicker from './SoundFlicker';
import {ReactComponent as Microphone} from '../../styles/images/chatbot/microphone.svg'
import {ReactComponent as SendBtn} from '../../styles/images/chatbot/send.svg'

const  recent_chats = [{title:"show my top 5 Customers for 2034Q1", history:{}},
    {title:"show my top 5 Products for 2034Q1", history:{}},
    {title:"show my top 5 Orders for 2034Q1", history:{}},
    {title:"See more ...", history:{}},

];
function CustomProfitAssist(props) {
    const userEnv = {};
    const [inputValue, setInputValue] = useState("");
    const { sendMessage, clear } = useChatInteract();
    const {messages} = useChatMessages(chatMessagesState); // Use Recoil for global chat state
    const [isListening, setIsListening] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [recentChats] = useState(recent_chats);
    const recognitionRef = useRef(null);
    const { connect } = useChatSession();
    const session = useRecoilState(sessionState);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const chainlitUrl = process.env.REACT_APP_CHATBOT_APP_BASE_URL;

    
    useEffect(() => {
      if (session?.socket?.connected) {
        return;
      }
       if (Object.keys(props.userSettings).length > 0) {
        getAccessToken(baseUrl, chainlitUrl)
      }
    }, [connect, props.userSettings, session?.socket?.connected]);


    useEffect(() => {
        const SpeechRecognition =
            window.SpeechRecognition || window.webkitSpeechRecognition;

        if (!SpeechRecognition) {
            console.error("Speech recognition is not supported in your browser.");
            return;
        }

        const recognition = new SpeechRecognition();
        recognition.continuous = false;
        recognition.interimResults = false;
        recognition.lang = "en-US";

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setInputValue(transcript); // Update input with the recognized text
        };

        recognition.onend = () => {
            console.log("Speech recognition ended.");
            recognitionRef.current.stop();
            recognitionRef.current.abort();
            setIsListening(false);
        };

        recognition.onspeechend = () => {
            console.log("Speech recognition ended.");
            recognitionRef.current.stop();
            recognitionRef.current.abort();
            setIsListening(false);
        };

        recognition.onerror = (event) => {
            console.error("Speech recognition error:", event.error);
            setIsListening(false);
            alert("Could not process speech. Please try again.");
        };

        recognitionRef.current = recognition;

        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.abort();
                recognitionRef.current = null;
            }
        };
    }, []);


    // Function to get sessionId
    const getSessionId = () => {
        return new Promise((resolve, reject) => {
            let query = { action: "getSessionId" };
            let onThenCallback = (data) => {
                if (data?.sessionId) {
                    resolve(data.sessionId); // Resolve the promise with the sessionId
                } else {
                    reject(new Error('Failed to get sessionId'));
                }
            };
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getSessionId",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: false,
                [FETCHAPI_PARAMS.path]: "/get-session-id",
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            };
            fetchAPI(fetchOptions);
        });
    };

    // Function to get chatbotVectorsInfo
    const getCloudRunToken = () => {
        return new Promise((resolve, reject) => {
            let query = { action: "getChatbotAccessToken" };
            let onThenCallback = (data) => {
                if (data?.accessToken) {
                    resolve(data.accessToken); // Resolve the promise
                } else {
                    reject(new Error('Failed to get chatbotAccessToken'));
                }
            };
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getChatbotAccessToken",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: false,
                [FETCHAPI_PARAMS.path]: "/get-chat-bot-access-token",
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            };
            fetchAPI(fetchOptions);
        });
    };

    // Function to get access token from the /custom-auth endpoint
    const getAccessToken = async (baseUrl, chainlitUrl) => {
        try {
            // Fetch the access token from the new endpoint
            const cloudRunToken = await getCloudRunToken();
            // Now use the access token to authenticate the existing API call
            const sessionId = await getSessionId();
            const requestBody = {
                sessionId: sessionId,
                url: `${baseUrl}/chat-bot-get-vector`,
                authority: baseUrl,
                origin: window.location.origin,
                firstName: props.userSettings.user.first_name,
                userId: props.userSettings.user.userId,
                clientId: props.userSettings.clientId,
                clientName: props.userSettings.clientName,
            };
            let span = new Span( "POST"+ "/custom-auth");
            // Make the POST request to your custom-auth endpoint
            const authResponse = await fetch(`${chainlitUrl}/custom-auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${cloudRunToken}`, // Use the access token here
                    'X-Trace-Id': span.getTraceId(),
                    'X-Span-Id': span.getSpanId(),
                },
                credentials: 'include',
                body: JSON.stringify(requestBody),
            });

            const data = await authResponse.json();
            if (authResponse.ok) {
                connect({
                    userEnv,
                    accessToken: `Bearer: ${data.token}`,
                  });
                }
        } catch (error) {
            return null;
        }
    };

    const handleSendMessage = async() => {
        const content = inputValue.trim();
        if (content) {
            const message = {
                name: "user",
                type: "user_message" ,
                output: content,
            };
            setInputValue("");
            sendMessage(message, []);
        }
    };

    const renderMessage = (message) => {
        const dateOptions = {
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
            month: "long", 
            day: "numeric"
        };
        const date = new Date(message.createdAt).toLocaleTimeString( undefined, dateOptions);
        return (
            (message.output.replace(/ /g,'') !== "" && <ChatBubble id={message.id} name={message.name} content={message.output} date={date}/>)
        );
    };


    const handleMicClick = () => {
        if (!recognitionRef.current) return;
        recognitionRef?.current?.start();
        setIsListening(true);
    };

    const saveChatandExit = () => {
        // setSessions(...sessions,...[session[0].socket.id]);
        // setRecentChats(recentChats.push({title:"Latest Chat", messages:messages}));
        clear();
        session[0].socket.disconnect();
        getAccessToken(baseUrl, chainlitUrl)
    }

    const addSuggestions = (suggestions) => {
        setSuggestions(suggestions);
        setIsDropdownVisible(true);
    }

    const handleClickAway = (e)=>{
        if (e?.target?.className === "category") return
        setSuggestions([]);
        setIsDropdownVisible(false);
    }

    const resumeChat = (index) => {
        // session[0].socket.disconnect();
        // connect({ idToResume: sessions[index] });
        // setMessages(recentChats[index].messages);
    }

    return (
        <div className = {"main-container-chatbot" + (messages.length !==0 ? " conversation" : "")}>
            {/*<Button */}
            {/*    label={"save & exit"}*/}
            {/*    variant={BUTTON_VARIANT.PRIMARY}*/}
            {/*    size={SIZES.DEFAULT}*/}
            {/*    type={BUTTON_TYPE.DEFAULT}*/}
            {/*    onBtnClick={() => saveChatandExit()}*/}
            {/*/>*/}
            {isListening &&  <SoundFlicker /> }
            <div className="chatbot_input_container">
                <div className={messages?.length !== 0 ? 'scrollable-chat' : ''}>
                    <div className="chat-container">
                    {messages?.map((message) => renderMessage(message))}
                    {messages && messages[messages?.length-1]?.output?.replace(/ /g,'') === "" && (
                        <div className="typing-indicator">
                            <img src='/images/3dots.gif' className="height-20 uk-margin-small-left"/><p>Just a moment</p>
                        </div>
                    )}
                    </div>
                </div>
                <div class="input-container" style={{width:'100%'}}>
                    <div className="input" style={{width:'100%'}}>
                        <input
                            id="message-input"
                            placeholder="Ask me (almost) anything..."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    handleSendMessage();
                                }
                            }}
                        />
                    </div>
                    <div className="micro-send-container">
                        <div>
                            <Microphone onClick={handleMicClick} />
                        </div>
                        <div>
                            <SendBtn onClick={handleSendMessage}/>
                        </div>
                    </div>
                </div>
                {isDropdownVisible && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div className="autocomplete-dropdown">
                            {suggestions.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        className="autocomplete-item"
                                        // onClick={() => handleSuggestionClick(suggestion)}
                                    >
                                        {suggestion.text}
                                    </div>
                                ))}
                        </div>
                    </ClickAwayListener>
                )}
            </div>
            {messages?.length === 0  &&
                <>
                    {/*<VectorSuggestions addSuggestions={addSuggestions}/>*/}
                    {/*<RecentChats recentChats={recentChats} resumeChat={resumeChat}/>*/}
                </>
            }
            <div className="profit_assist_footer_text" style={{marginTop:"auto", alignSelf:"center"}}>Profit Assist adjusts responses based on your access level, ensuring restricted data remains secure.</div>
        </div>
    )
}

export default CustomProfitAssist;
