import { Category } from "./Category";
import { ReactComponent as CustomerSVG } from '../../styles/images/chatbot/custom_category.svg';
import { ReactComponent as ProductSVG } from '../../styles/images/chatbot/product_category.svg';
import { ReactComponent as OperationSVG } from '../../styles/images/chatbot/operation_category.svg';

export const VectorSuggestions = (props) => {
    const categories = [
        {label:"Customer", questions:[{text:"What are my top 5 Customers"},{text:"What are my top 5 Customers"},{text:"What are my top 5 Customers"}], svg:CustomerSVG},
        {label:"Product", questions:[{text:"What are my top 5 Product"},{text:"What are my top 5 CusProducttomers"},{text:"What are my top 5 Product"}], svg:ProductSVG},
        {label:"Operations", svg: OperationSVG, questions:[{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"},{text:"What are my top 5 Operations"}]}];
    
    const handleClick = (category)=>{
        let suggestions = categories.filter(e=>e.label === category)[0].questions;
        props.addSuggestions(suggestions)
    }
    return(
        <div className="vector-suggestions-container">
            <span className="vector-suggestions-text">How Can I Help ?</span>
            <div className="category-container">
                {categories?.map((category)=>{
                    return <Category label={category.label} svg={category.svg} handleClick={handleClick}/>
                })
                }
            </div>
        </div>
    );
}