import TableFromString from "./TableFromString";
import {ReactComponent as CopySVG} from "../../styles/images/chatbot/copy.svg";
import {ReactComponent as BotSVG} from "../../styles/images/chatbot/bot.svg"

const ChatBubble = ({
    id,
    name,
    content,
    date
}) =>{
    const senderIsMe = name.slice(0, 2).toUpperCase() === "US";

    const handleCopy = () => {
        // navigator.permissions.query({ name: "write-on-clipboard" }).then((result) => {
        //     if (result.state == "granted" || result.state == "prompt") {
        //       alert("Write access granted!");
        //     }
        //   });
        // navigator.clipboard.writeText(JSON.stringify(content)).then(() => {
        //   alert('Message copied to clipboard!');
        // }).catch(err => {
        //   console.error('Failed to copy text: ', err);
        // });

        const tableElement = document.getElementById(`table`); // Ensure `id` is unique for each table
        if (tableElement) {
          navigator.clipboard.write([
            new ClipboardItem({
              "text/html": new Blob([tableElement.outerHTML], { type: "text/html" }), // Copy as HTML
              "text/plain": new Blob([tableElement.innerText], { type: "text/plain" }), // Copy as plain text fallback
            }),
          ])
            .then(() => {
              alert("Table copied to clipboard!");
            })
            .catch((err) => {
              console.error("Failed to copy table: ", err);
            });
        } else {
          console.error("Table element not found!");
        }
    
        // Use clipboard API to copy the table as HTML
        navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([tableContainer.innerHTML], { type: "text/html" }),
            "text/plain": new Blob([tableContainer.innerText], { type: "text/plain" }), // Fallback
          }),
        ])
          .then(() => {
            alert("Table copied to clipboard!");
          })
          .catch((err) => {
            console.error("Failed to copy text: ", err);
          });
      // } else {
      //   // Copy plain text for non-table content
      //   navigator.clipboard.writeText(content).then(() => {
      //     alert("Message copied to clipboard!");
      //   }).catch((err) => {
      //     console.error("Failed to copy text: ", err);
      //   });
      // }
      };

    return (
        <div key ={id} className={"bubble-main-container" +  (senderIsMe ? " right" : " left")}>
            <div className={"timestamp-container" + (senderIsMe ? " right" : " left")}>{date}</div>
            <div className="bubble-container">
              {!senderIsMe && <BotSVG/>}
              <div className="bubble" style={{backgroundColor: senderIsMe ? 'white' : '#F5F5F5'}}>
                  {content.startsWith("|") ? <TableFromString inputString={content}/> : <p className="content">{content}</p>}
              </div>
              {senderIsMe && <div className="avatar">{name.slice(0, 2).toUpperCase()}</div>}
            </div>
            {!senderIsMe && 
              <div>
                  <CopySVG onClick={()=>{handleCopy()}} style={{ padding: '5px 10px', cursor: 'pointer', height: '25px', width: '40px' }}/>
              </div>
            }
            
      </div>
    );
}
export default ChatBubble;