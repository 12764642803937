// ChatBotWrapper.js
import React from 'react';
import CustomChatBot from "./customChatBot";
import CustomProfitAssist from "./CustomProfitAssist";
import "./ChatBotWrapper.css";
import "./category.css";
import "./conversation.css";
import "./recentChats.css";

function ChatBotWrapper(props) {
    return (
        <div className="chat-wrapper">
            {props.newBot ? <CustomProfitAssist userSettings={props.userSettings} showChatbot={props.showChatbot}/> :<CustomChatBot userSettings={props.userSettings} showChatbot={props.showChatbot}/>}
        </div>
    );
}

export default ChatBotWrapper;
