// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-container {
    display: flex;
    align-items: center;
    column-gap: 15px;
    align-self: stretch;
  }

  .category{
    display: flex;
    padding: 17px 20px 20px 20px;
    align-items: center;
    gap: 15px;
    flex: 1 0;
    border-radius: 6px;
    border: 1px solid #E9E9FF;
    background: #FFF;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/chatBot/category.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,4BAA4B;IAC5B,mBAAmB;IACnB,SAAS;IACT,SAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":[".category-container {\n    display: flex;\n    align-items: center;\n    column-gap: 15px;\n    align-self: stretch;\n  }\n\n  .category{\n    display: flex;\n    padding: 17px 20px 20px 20px;\n    align-items: center;\n    gap: 15px;\n    flex: 1 0 0;\n    border-radius: 6px;\n    border: 1px solid #E9E9FF;\n    background: #FFF;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
