import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { EntitiesSkeletonLoader } from "./EntitiesSkeletonLoader";
import { QuerySearchableList } from "../querySearchableList/QuerySearchableList";
import Button from "../../newComponents/Button";
import { BUTTON_TYPE, BUTTON_VARIANT, RECENT_ENTITIES, SIZES } from "../../class/constants";
import { lang } from "../../language/messages_en";
import { getLocalStorageValueByParameter, setLocalStorageValueByParameter } from "../../class/common";
import { addRecentEntities, removeEntityFromRecentStorage } from "../../templateLayout/functions/filterFunctions";
import { ReactComponent as ExpandArrow } from "../../styles/images/solid-arrow-up.svg";

const EntitiesList = forwardRef(function EntitiesList(
  {
    entities,
    onBackClick,
    isFetchingData,
    vector,
    handleCheckboxChange,
    onClearAllClick,
    selectionLimit,
    getFilterEntities,
    recentSelectedEntitiesLimit,
    setLocalStorageVectorEntities,
    localStorageVectorEntities,
    removeEntityFromRecentSelected,
  },
  ref
) {
  const [isRecentSectionExpanded, setIsRecentSectionExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const querySearchableListRef = useRef();

  useImperativeHandle(ref, () => ({
    setIsDataFiltering: (val) => {
      querySearchableListRef.current?.setIsDataFiltering(val);
    },
  }));

  const toggleRecentSectionExpandCollapse = () => {
    setIsRecentSectionExpanded((prev) => !prev); // Toggle the state
  };

  const handleCheckboxClick = (entity) => {
    let selectedEntity = structuredClone(entity);
    selectedEntity.checked = !entity.checked;
    if (selectedEntity.checked) {
      addRecentEntities(selectedEntity, vector, recentSelectedEntitiesLimit);
    }
    handleCheckboxChange(selectedEntity);
  };

  /**
   * Returns filtered items based on search term and search criterias
   * @param {*} items
   * @param {*} searchTerm
   * @returns
   */
  const filterRecentSelectedEntitiesBasedOnSearch = (entities, searchTerm, searchCriterias) => {
    const matchesSearchTerm = (entity) => {
      return searchCriterias.some((criteria) => entity[criteria].toLowerCase().includes(searchTerm.toLowerCase()));
    };

    return entities?.filter(matchesSearchTerm);
  };

  const formatEntitiesData = (data) => {
    if (!data) {
      return [];
    }

    let sectionsArray = [];
    if (getLocalStorageValueByParameter(RECENT_ENTITIES) !== null) {
      let entitiesLocalStorage = JSON.parse(getLocalStorageValueByParameter(RECENT_ENTITIES));
      if (entitiesLocalStorage[vector.label]) {
        entitiesLocalStorage[vector.label].map((entity) => {
          let entityObj = localStorageVectorEntities.find((f) => f.name === entity.name && f.number === entity.number);
          entity.checked = entityObj?.checked;

          return entity;
        });
        setLocalStorageValueByParameter(RECENT_ENTITIES, JSON.stringify(entitiesLocalStorage));
        let recentEntitiesChecked = entitiesLocalStorage[vector?.label].filter((f) => f.checked).length;

        let filteredRecentSelectedEntities = filterRecentSelectedEntitiesBasedOnSearch(entitiesLocalStorage[vector?.label], searchTerm, [
          "name",
          "number",
        ]);
        let recentEntitesSection = {
          titleComponent: (
            <button
              className={"recent_entities_text_container " + (isRecentSectionExpanded ? "expand_section" : "collapse_section")}
              onClick={toggleRecentSectionExpandCollapse}
              type="button"
            >
              <span className="recent_entities_title">Recent selected entities</span>
              <span className="recent_entities_selection">Selected {recentEntitiesChecked} of 50</span>
              <div className="recent_entities_arrow">
                <ExpandArrow className={isRecentSectionExpanded ? "expand-arrow" : "collapse-arrow"} />
              </div>
            </button>
          ),
          data: filteredRecentSelectedEntities.map((m) => {
            m.label = m.name || m.number;
            m.isRemovable = true;
            m.strikeThrough = m.entityNotFoundInScenario;
            return m;
          }),
          containerClassName: "recent-selected-entities-container",
        };
        if (filteredRecentSelectedEntities?.length > 0) {
          sectionsArray.push(recentEntitesSection);
        }
      }
    }

    let entitiesSection = {
      data: data.map((m) => {
        m.label = m.name || m.number;
        return m;
      }),
    };
    sectionsArray.push(entitiesSection);

    return sectionsArray;
  };

  const fetchFilteredEntities = (searchTerm) => {
    getFilterEntities(vector, true, searchTerm);
  };

  const removeEntityFromRecent = (event, item) => {
    removeEntityFromRecentStorage(event, item, vector, setLocalStorageVectorEntities);
    removeEntityFromRecentSelected(item);
  };

  const isSelectionLimitReached = entities?.filter((f) => f.checked).length >= selectionLimit;
  return (
    <>
      {isFetchingData && <EntitiesSkeletonLoader backClick={onBackClick} />}
      {!isFetchingData && (
        <QuerySearchableList
          ref={querySearchableListRef}
          sectionsData={formatEntitiesData(entities)}
          extraInfo={"number"}
          handleCheckboxChange={handleCheckboxClick}
          removeEntityFromRecent={removeEntityFromRecent}
          selectionLimitReached={isSelectionLimitReached}
          fetchQuery={fetchFilteredEntities}
          setSearchTerm={setSearchTerm}
        >
          <div className="entities-container-title">
            <div className="entities-container-label">
              <Button
                id="quick-filter-back-btn"
                variant={BUTTON_VARIANT.TERTIARY}
                size={SIZES.ICON}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={onBackClick}
                leftIcon={<i className={"far fa-chevron-left"} />}
              />
              <label>
                Showing {entities?.length} of {vector?.entityCount}
              </label>
            </div>
            <Button
              id={"entities-clear-all"}
              label={lang.modal.buttons.clear_all}
              variant={BUTTON_VARIANT.TERTIARY}
              size={SIZES.SMALL}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={onClearAllClick}
              disabled={localStorageVectorEntities?.filter((f) => f.checked).length === 0}
            />
          </div>
        </QuerySearchableList>
      )}
    </>
  );
});

export { EntitiesList };
