import { ClickAwayListener } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { setLocalStorageValueByParameter } from "../../class/common";
import { BUTTON_TYPE, BUTTON_VARIANT, RECENT_ENTITIES, SIZES } from "../../class/constants";
import { lang } from "../../language/messages_en";
import Button from "../../newComponents/Button";
import { fetchFilterEntities } from "../../templateLayout/api/api";
import { getLocalStorageEntities, uncheckVectorEntitiesInStorage } from "../../templateLayout/functions/filterFunctions";
import { EntitiesList } from "./EntitiesList";
import { VectorsList } from "./VectorsList";
import "./quickFilters.css";
import PropTypes from 'prop-types';

const DROPDOWN_VIEWS = { VECTOR_VIEW: "vectorView", ENTITIES_VIEW: "entitiesView" };

function AddFilterComponent({
  btnId,
  vectorOptions,
  userSettings,
  FilterDropdownButton,
  setSelectedFilters,
  filter,
  selectedFilters,
  onAdvancedBtnClick,
  scenarioState,
  profitFormat,
  onVectorRemove,
}) {
  const [open, setOpen] = useState(false);
  const [isFetchingEntities, setIsFetchingEntities] = useState(false);
  const [vectorEntities, setVectorEntities] = useState();
  const [dropdownView, setDropdownView] = useState(DROPDOWN_VIEWS.VECTOR_VIEW);
  const [vector, setVector] = useState();
  const [localStorageVectorEntities, setLocalStorageVectorEntities] = useState(); // this is used when we need to rerender after changing the local storage

  const controllerRef = useRef();
  const entitiesListRef = useRef();

  /**
   * This will remove the entities skeleton loader when the request is done.
   */
  useEffect(() => {
    if (vectorEntities) {
      setIsFetchingEntities(false);
    }
  }, [vectorEntities]);

  /**
   * In case a dropdown already has a vector and entities selected (applied).
   * This useEffect will display the vector label and selected entities of the applied filters.
   */
  useEffect(() => {
    if (filter) {
      setVector(filter.vector);
      setLocalStorageVectorEntities(filter.entities);
      setDropdownView(DROPDOWN_VIEWS.ENTITIES_VIEW);

      getFilterEntities(filter.vector, false, "", filter.entities);
    }
  }, [filter, open]);

  /**
   * Opens/closes the dropdown
   */
  const handleAddFilterClick = () => {
    $(`#${btnId}`)?.toggleClass("active-dropdown"); // for the open effect (change background color)
    setOpen((prev) => !prev);
    if (!filter) {
      setDropdownView(DROPDOWN_VIEWS.VECTOR_VIEW);
    }
  };

  const closeDropdown = () => {
    setOpen(false);
    $(`#${btnId}`)?.removeClass("active-dropdown");
  };

  /**
   * In case we click outside of the component, close it
   */
  const handleClickAway = () => {
    if (!filter) {
      setDropdownView(DROPDOWN_VIEWS.VECTOR_VIEW);
    }
    closeDropdown();
  };

  /**
   * This will be triggered when selecting a vector from the dropdown.
   * It will switch the dropdown view to entities and fetches the corresponding entities of the selected vector.
   * When clicking on a vector, we uncheck all previously selected entitie for this vector
   */
  const onVectorClick = (vector) => {
    setDropdownView(DROPDOWN_VIEWS.ENTITIES_VIEW);
    setIsFetchingEntities(true);
    setVector(vector);
    getFilterEntities(vector, false);

    uncheckVectorEntitiesInStorage(vector?.label);
    setLocalStorageVectorEntities(getLocalStorageEntities()?.[vector.label]);
  };

  /**
   * Sends a request to fetch the the selected vector entities.
   * - This function is triggered when we search for an entity in the search bar to fetch filtered data. We check the storage to check/uncheck entities if found in the displayed entities.
   * - It is also triggered when we open a vector dropdown to fetch its entities to be displayed and we check the ones found in filter.entities sent from <QuickFilters />
   * @param {Object} vectorObj selected vector object
   * @param {Boolean} checkStorage if true, we check the storage to see the checked items and we update the fetched data
   * @param {Object} abortSignal used to abort the unused requests
   * @param {String} searchTerm the value entered in the search bar
   * @param {Array} filterEntities the entities for a selected filter (saved or inherited filter)
   */
  const getFilterEntities = (vectorObj, checkStorage, searchTerm = "", filterEntities = []) => {
    if (entitiesListRef.current) {
      // used to display skeleton loader for the entities
      entitiesListRef.current.setIsDataFiltering(true);
    }

    const onThenCallback = (result) => {
      let data = result?.tierFilters?.length ? result?.tierFilters : [];
      let foundEntitiesInScenario = result?.foundEntities;

      if (checkStorage) {
        data = updateCheckedItemsFromStorage(data, vectorObj);
      }
      if (filterEntities.length > 0) {
        data = updateCheckedItems(data, filterEntities);
      }

      if (entitiesListRef.current) {
        // used to display skeleton loader for the entities
        entitiesListRef.current.setIsDataFiltering(false);
      }
      
      let allEntitiesFromStorage = getLocalStorageEntities();
      let vectorEntitiesFromStorage = allEntitiesFromStorage?.[vectorObj?.label];
      // checking if the entities in history are found in selected scenario or not
      if(foundEntitiesInScenario && vectorEntitiesFromStorage.length > 0) {
        vectorEntitiesFromStorage = vectorEntitiesFromStorage.map(m => {
          m.entityNotFoundInScenario = foundEntitiesInScenario.filter(f => f.name === m.name && f.number === m.number).length === 0;
          m.checked = filterEntities?.find(f => f.name === m.name && f.number === m.number)?.checked || false;
          return m;
        });
        setLocalStorageVectorEntities(vectorEntitiesFromStorage);
        setLocalStorageValueByParameter(RECENT_ENTITIES, JSON.stringify(allEntitiesFromStorage));
      }

      setVectorEntities(data);
    };
    ////// end of onThenCallback

    if (controllerRef.current) {
      controllerRef.current.abort(); // abort any filter entity request still running
    }

    controllerRef.current = new AbortController();
    const { signal } = controllerRef.current;

    let allEntitiesFromStorage = getLocalStorageEntities();
    let vectorEntitiesFromStorage = allEntitiesFromStorage?.[vectorObj?.label];

    let scenarioId = typeof scenarioState?.scenario === "number" ? scenarioState?.scenario : scenarioState?.scenario?.value;
    fetchFilterEntities({
      vectorObj: vectorObj,
      vectorOptions: vectorOptions,
      scenarioId: scenarioId,
      userSettings: userSettings,
      onThenCallback: onThenCallback,
      profitFormat: profitFormat,
      abortSignal: signal,
      searchTerm: searchTerm,
      filterEntities: vectorEntitiesFromStorage,
    });
  };

  const updateCheckedItemsFromStorage = (data, vectorObj) => {
    let allEntitiesFromStorage = getLocalStorageEntities();
    let vectorEntitiesFromStorage = allEntitiesFromStorage?.[vectorObj?.label];

    if (vectorEntitiesFromStorage) {
      data = updateCheckedItems(data, vectorEntitiesFromStorage);
    }

    return data;
  };

  const updateCheckedItems = (data, selectedEntities) => {
    return data.map((entity) => {
      let selectedEntity = selectedEntities.find((f) => f.name === entity.name && f.number === entity.number);
      if (selectedEntity) {
        entity.checked = selectedEntity.checked;
      }
      return entity;
    });
  };

  /**
   * When clicking on back in entities dropdown, we switch to vector view
   * and we discard any selection made in enttiies view.
   */
  const onBackClick = () => {
    setDropdownView(DROPDOWN_VIEWS.VECTOR_VIEW);
  };

  /**
   * When we select an entity, we check if this entity is found in the displayed entities.
   * If it is not found, we update it in the lcal storage. If found we also update it in vectorEntities state, which holds all displayed entities in the dropdown.
   * @param {*} entity
   */
  const handleEntitySelection = (entity) => {
    // if entity selected is found in the displayed entities, then update vectorEntities state
    if (vectorEntities?.find((f) => f.name === entity.name && f.number === entity.number)) {
      setVectorEntities((prevEntities) => {
        let updatedEntities = structuredClone(prevEntities);
        let selectedEntity = updatedEntities?.find((f) => f.name === entity.name && f.number === entity.number);
        selectedEntity.checked = entity.checked;
        return updatedEntities;
      });
    }

    updateSelectedEntityInLocalStorageAndItsState(entity);
  };

  const updateSelectedEntityInLocalStorageAndItsState = (entity) => {
    let allEntitiesFromStorage = getLocalStorageEntities();
    let vectorEntitiesFromStorage = allEntitiesFromStorage?.[vector?.label];
    let selectedEntityInStorage = vectorEntitiesFromStorage?.find((f) => f.name === entity.name && f.number === entity.number);
    selectedEntityInStorage.checked = entity.checked;

    setLocalStorageValueByParameter(RECENT_ENTITIES, JSON.stringify(allEntitiesFromStorage));
    setLocalStorageVectorEntities(vectorEntitiesFromStorage);
  };

  /**
   * When applying the vector filter, we update the selectedFilters state.
   * This state holds the selected filters ()
   */
  const handleApplyClick = () => {
    closeDropdown();
    setSelectedFilters((prevFilters) => {
      let updatedFilters = structuredClone(prevFilters) || [];
      let alreadySelectedVector = updatedFilters.find((f) => f.vector.label === vector?.label);

      if (alreadySelectedVector) {
        // if we change the entities of an already selected vector, we update its entities
        alreadySelectedVector.entities = localStorageVectorEntities;
        alreadySelectedVector.isEdited = true;
        alreadySelectedVector.isFilterBroken = false; // the user cannot apply if he still has unavailable entities.
      } else if (filter && filter.vector?.label !== vector.label) {
        // if we are changing vector of an already displayed vector filter, we need to update the dropdown name and entities
        let currentDropdownFilter = updatedFilters.find((f) => f.vector.label === filter.vector?.label);
        currentDropdownFilter.vector = vector;
        currentDropdownFilter.entities = localStorageVectorEntities;
        currentDropdownFilter.isEdited = true;
        currentDropdownFilter.isFilterBroken = false; // the user cannot apply if he still has unavailable entities.
      } else {
        // if the vector is not already selected, add it to the selectedFilters array
        updatedFilters.push({ vector: vector, entities: localStorageVectorEntities, isEdited: true });
      }
      return updatedFilters;
    });
  };

  const handleAdvancedFiltersClick = () => {
    onAdvancedBtnClick();
    closeDropdown();
  };

  const handleClearAllClick = () => {
    setVectorEntities((prevEntities) => {
      let updatedEntities = structuredClone(prevEntities);
      updatedEntities = updatedEntities.map((entity) => ({ ...entity, checked: false }));
      return updatedEntities;
    });

    uncheckVectorEntitiesInStorage(vector?.label);
    setLocalStorageVectorEntities(getLocalStorageEntities()?.[vector.label]);
  };

  const removeEntityFromRecentSelected = (entity) => {
    setVectorEntities((prevEntities) => {
      let updatedEntities = structuredClone(prevEntities) || [];
      let selectedEntity = updatedEntities.find((f) => f.name === entity.name && f.number === entity.number);
      if (selectedEntity) {
        selectedEntity.checked = false;
      }
      return updatedEntities;
    });
  };

  /**
   * getSelectedEntitiesNumber is a function that counts checked entities.
   * Without useCallback, this function would be recreated on every render, creating a new reference.
   * Since this function is used as a dependency in isApplyDisabled's useMemo, memoizing it prevents unnecessary recalculations of isApplyDisabled.
   */
  const getSelectedEntitiesNumber = useCallback((entities) => {
    return entities?.filter((f) => f.checked).length || 0;
  }, []);

    /**
   * getSelectedEntityNotFoundInScenarioNumb is a function that returns the number of selected entities that are not found in selected scenario.
   * Without useCallback, this function would be recreated on every render, creating a new reference.
   * Since this function is used as a dependency in isApplyDisabled's useMemo, memoizing it prevents unnecessary recalculations of isApplyDisabled.
   */
  const getSelectedEntityNotFoundInScenarioNumb = useCallback((entities) => {
    return entities?.filter((f) => f.entityNotFoundInScenario && f.checked).length || 0;
  }, []);

  /**
   * The calculation of isApplyDisabled should only happen when its dependencies change:
   * - dropdownView changes (switching between views)
   * - vectorEntities changes (when entities are fetched or updated)
   * - selectedVector changes (when user selects a different vector)
   */
  const isApplyDisabled = useMemo(() => {
    return (
      dropdownView === DROPDOWN_VIEWS.VECTOR_VIEW ||
      isFetchingEntities ||
      getSelectedEntitiesNumber(localStorageVectorEntities) === 0 ||
      getSelectedEntityNotFoundInScenarioNumb(localStorageVectorEntities) > 0
    );
  }, [dropdownView, localStorageVectorEntities, vector, isFetchingEntities]);

  /** Removing already selected vectors from displayed options. */
  const alreadySelectedVectors = selectedFilters?.filter((f) => f.vector.label !== filter?.vector?.label)?.map((m) => m.vector.label);
  const filteredVectors =
    alreadySelectedVectors?.length > 0 ? vectorOptions?.filter((f) => !alreadySelectedVectors.includes(f.label)) : vectorOptions;

  const dropdownContent =
    dropdownView === DROPDOWN_VIEWS.VECTOR_VIEW ? (
      <VectorsList vectors={filteredVectors} onVectorClick={onVectorClick} />
    ) : (
      <EntitiesList
        ref={entitiesListRef}
        onBackClick={onBackClick}
        entities={vectorEntities}
        isFetchingData={isFetchingEntities}
        vector={vector}
        handleCheckboxChange={handleEntitySelection}
        selectionLimit={userSettings?.filterEntitySelectionLimit}
        recentSelectedEntitiesLimit={userSettings?.recentSelectedEntitiesLimit}
        onClearAllClick={handleClearAllClick}
        getFilterEntities={getFilterEntities}
        setLocalStorageVectorEntities={setLocalStorageVectorEntities}
        localStorageVectorEntities={localStorageVectorEntities}
        removeEntityFromRecentSelected={removeEntityFromRecentSelected}
      />
    );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FilterDropdownButton
          onBtnClick={handleAddFilterClick}
          vectorName={vector?.label || filter?.vectorLabel}
          dropdownOpen={open}
          numberOfSelectedEntities={isFetchingEntities ? undefined : localStorageVectorEntities?.filter((f) => f.checked).length}
          onVectorRemove={onVectorRemove}
        />
        {open ? (
          <div className="filter-by-container">
            {dropdownContent}
            <div className="filter-by-buttons">
              <Button
                id={"add-filter-apply-btn"}
                label={lang.modal.buttons.apply}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.LARGE}
                type={BUTTON_TYPE.DEFAULT}
                className={"max_width"}
                disabled={isApplyDisabled}
                onBtnClick={handleApplyClick}
              />
              <div className="advanced-filters-label-container">
                {lang.header.placeholders.switch_to}
                <Button
                  id={"quick-filters-advanced-filter-btn-link"}
                  label={lang.modal.buttons.advanced_filter}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.SMALL}
                  type={BUTTON_TYPE.DEFAULT}
                  className={"max_width"}
                  onBtnClick={handleAdvancedFiltersClick}
                  uk-toggle={"target: #filterModal"}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}

AddFilterComponent.propTypes = {
  btnId: PropTypes.string.isRequired,
  vectorOptions: PropTypes.array,
  userSettings: PropTypes.object,
  FilterDropdownButton: PropTypes.elementType.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  filter: PropTypes.object,
  selectedFilters: PropTypes.array,
  onAdvancedBtnClick: PropTypes.func.isRequired,
  scenarioState: PropTypes.object,
  profitFormat: PropTypes.string,
  onVectorRemove: PropTypes.func
};

export { AddFilterComponent };
