import {default as React, Suspense, useEffect, useMemo, useRef, useState} from "react";
import ErrorBoundary from "../../ErrorBoundary";
import {
    ALL_FORMATS,
    ALL_WIDGETS,
    BUTTON_TYPE,
    BUTTON_VARIANT,
    costtype,
    CURRENT_FILTER_COOKIE,
    DIALOG_SIZE,
    DROPDOWN_TYPE,
    FILTER,
    FormatTypes,
    HEADER_ELEMENT,
    LISTENER,
    MENU_ITEM,
    period_calendar_type,
    PROFILE_COLUMN,
    PSS,
    SAVE_COMPANY_FILTERS,
    SAVED_FILTER_ID_COOKIE,
    SCENARIOS,
    SIZES,
} from "../../class/constants";
import {lang} from "../../language/messages_en";
import Dropdown from "../../newComponents/DropDown";
import {useAuthenticated, useCalendarToggle, useExportScopeFilter} from "../CustomHooks";
import {ReportHeader} from "../ReportHeader";
import {renderButton, renderCalendar, renderTitle} from "../functions/componentFunctions";

import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom/cjs/react-router-dom";
import {
    formatAdvancedFilter,
    formatBasicFilter,
    getLocalStorageValueByParameter,
    logout,
    setLocalStorageValueByParameter,
    toggleLoader,
    updateWindowLoading
} from "../../class/common";
import {generatePeriods, getPeriodDifference, getPeriodFromDate, getQuarterFromDate, monthDiff} from "../../class/date";
import {saveCookie} from "../../class/jqueries";
import {checkIfMonthsValid, copyObjectValues, findOptionByKey, getSectionExists, tryParse} from "../../class/utils";
import AppliedFilters from "../../components/AppliedFilters";
import FilterByDropdown from "../../components/FilterByDropdown";
import ManageColumns from "../../components/manageColumns/ManageColumns";
import Button from "../../newComponents/Button";
import Modal from "../../newComponents/Modal";
import FilterDialog from "../../sections/filter/FilterDialog";
import {getNewEntityFilter} from "../../sections/filter/FilterHelperFunctions";
import CompareScenarios from "../../sections/manageScenarios/CompareScenarios";
import {LoaderSkeleton} from "../LoaderSkeleton";
import {getFilterEntities, getPeriodsStatus, getVectors} from "../api/api";
import {getClientPeriods, getEngineScenarios} from "../api/apiDataModeling";
import {addCorrespondingParenthesisToFilter} from "../functions/filterFunctions";
import { QuickFilters } from "../../components/quickFilters/QuickFilters";
import { useListener } from "../../actions/CustomEventHandler";

const CompareScenariosWrapper = (props) => {
    const reportRef = useRef();
    const manageColsRef = useRef();

    const {userAllowedMenuLinks} = props;

    const profitFormat = ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS;
    const history = useHistory();
    const params = useParams();
    const userSettings = props.userSettings;
    const scenarioState = props.scenarioState;
    const dispatch = useDispatch();

    /**Store*/
    const psLinesOptions = useSelector((state) => state.psLinesOptions);
    const quadrantTierOptions = useSelector((state) => state.quadrantTierOptions);

    /**State */
    const [scenariosState, setScenariosState] = useState({});
    const [vectorState, setVectorState] = useState();
    const [referenceScenario, setReferenceScenario] = useState();
    const [comparisonScenario, setComparisonScenario] = useState();
    const [periodsStatusState, setPeriodsStatusState] = useState({});
    const [vectorOptions, setVectorOptions] = useState();
    const [periodOptions, setPeriodOptions] = useState();
    const [vectorSelected, setVectorSelected] = useState([]);
    const [callGo, setCallGo] = useState(false);
    const [manageColumnsProfile, setManageColumnsProfile] = useState();
    const [basicFilterItem, setBasicFilterItem] = useState();
    const [displayFilter, setDisplayFilter] = useState();
    const [datasetState, setDatasetState] = useState({});
    const [tempFilter, setTempFilter] = useState();
    const [filterFinalBasic, setFilterFinalBasic] = useState(
        props.history.location.state
            ? props.history.location.state.mainFilterFinalBasic
                ? tryParse(props.history.location.state.mainFilterFinalBasic) || []
                : []
            : []
    );
    const [filterFinal, setFilterFinal] = useState(
        props.history.location.state
            ? props.history.location.state.outsideFilter
                ? JSON.stringify(tryParse(props.history.location.state.outsideFilter).filter)
                || JSON.stringify(tryParse(props.history.location.state.outsideFilter)) || []
                : []
            : []
    );
    const [basicFilterEntities, setBasicFilterEntities] = useState([]);
    const [basicFilterEntitiesState, setBasicFilterEntitiesState] = useState([]);
    const [count, setCount] = useState(0);
    const [exportQueryFilter, setExportQueryFilter] = useState(undefined);
    const [openFilterDialog, setOpenFilterDialog] = useState();

    /**Header Related States */
    const [headerElements, setHeaderElements] = useState([]);
    const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.DATA_MODELING.COMPARE_SCENARIOS);
    const [showGreyOverLay, setShowGreyOverLay] = useState(true);
    const initialPeriodRangeState = { startPeriod: "", endPeriod: "", inputValue: "Choose Period" };
    const [selectedRange, setSelectedRange] = useState(initialPeriodRangeState)
    const [clientPeriodsState, setClientPeriodsState] = useState([]);

    /** Dialogs States */
    const [isInfoDialogOpen, setInfoDialogOpen] = useState(false);
    const [infoDialogMsg, setInfoDialogMsg] = useState("");

    const profileColumns = useSelector(state=>state.profileColumns);
    const columnProfiles = useSelector(state=>state.columnProfiles);
    const profiles = useSelector(state=>state.profiles);
    const stateProfiles = useSelector(state=>state.stateProfiles);
    const customGroups = useSelector(state=>state.customGroups);

    const manageColsOverlay = useRef();
    const filterByRef = useRef();
    const appliedFiltersRef = useRef();
    const countRef = useRef(0);
    const filterDialRef = useRef();
    const filterSet = useRef(false);
        
    /**Custom Hooks */
    const { toggleCalendar } = useCalendarToggle();
    const exportScopeFilter = useExportScopeFilter(userSettings, datasetState?.datasetOptions, vectorOptions, filterDialRef?.current?.state?.psLinesOptions);

    const manageColsAccess = {
        [ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS), [userAllowedMenuLinks]),
        [ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS), [userAllowedMenuLinks]),
        [ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS), [userAllowedMenuLinks]),
        [ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS), [userAllowedMenuLinks])
    };

    const manageColumnsProps = {
        manageColumnsUpdateSections: [ALL_WIDGETS.FIELDS.LIST, MENU_ITEM.FIELDS.HEATMAP, MENU_ITEM.FIELDS.EROSION_AND_GROWTH, ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS]
    };


    const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

    let showSkeleton = !(
        Object.keys(scenariosState).length > 0 &&
        headerElements.length > 0
    );

    /**
     * @function useEffect()
     * on screen initialization if isAuthenticated is false user gets logged out 
     * isAuthenticated is a boolean read from a custom hook named useAuthenticated
     **/
    useEffect(() => {
        if(!isAuthenticated) {
          logout();
        }
      }, [isAuthenticated]);

    useEffect(() => {
        updateWindowLoading(showSkeleton, "skeleton-loader");
    }, [showSkeleton]);

    useEffect(() => {
        if (
            // Object.keys(periodsStatusState).length > 0 &&
            Object.keys(scenariosState).length > 0 &&
            Object.keys(clientPeriodsState).length > 0
        ) {
            let headerElements = getHeaderElements();
            setHeaderElements(headerElements);
        }
    }, [scenariosState, clientPeriodsState, referenceScenario, comparisonScenario, vectorOptions,
        vectorSelected, vectorState, periodOptions, selectedRange, showGreyOverLay, filterFinal,count,
        basicFilterEntities,
        filterFinalBasic]);

    useEffect(() => {
        if (userAllowedMenuLinks) {
            getEngineScenarios(userAllowedMenuLinks, profitFormat, scenariosState, undefined, false, setScenariosState, true, history);
        }
    }, [userAllowedMenuLinks]);

    useListener(LISTENER.APPLY, () => {
        if (!showGreyOverLay) {
            apply();
            setCallGo(false);
        }
    });

    useEffect(() => {
        if (Object.keys(scenariosState).length > 0) {
            setVectorState({});
            setPeriodsStatusState({});
            setClientPeriodsState({});
            getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
            getVectors(scenariosState, undefined, undefined, setVectorState, reportTitle, profitFormat, props, false, undefined, userSettings, vectorState);
            getPeriodsStatus(scenariosState, undefined, undefined, setPeriodsStatusState, props, profitFormat, userSettings, 12);
        }
    }, [scenariosState]);

    const getBuiltPeriodsObjectForScenarios = (periodsArray, keysArray) => {
        let periodsObject = {}
        let firstKeyArray = periodsArray[keysArray[0]].filter(f => f.is_built);
        let secondKeyArray = periodsArray[keysArray[1]].filter(f => f.is_built);
        periodsObject[keysArray[0]] = firstKeyArray;
        periodsObject[keysArray[1]] = secondKeyArray;
        return periodsObject;
    }

    const getCommonPeriodsBuilt = (keysArray) => {
        let periodsArray = getSelectedScenariosValues(periodsStatusState, keysArray);
        let finalPeriodsArray = getCommonValuesForScenarios(getBuiltPeriodsObjectForScenarios(periodsArray, keysArray), keysArray, "period_name");
        let formattedArr = formatPeriodsArray(finalPeriodsArray);
        setPeriodOptions(formattedArr);
    }

    const getCommonVectors = () => {
        setVectorOptions(getScenarioValues(vectorState.allScenariosVectors, "label"));
    }

    useEffect(() => {
        if (comparisonScenario && referenceScenario) {
            let keysArray = [String(referenceScenario.value), String(comparisonScenario.value)]
            getCommonVectors(keysArray);
            getCommonPeriodsBuilt(keysArray);
        }
    }, [comparisonScenario, referenceScenario])

    useEffect(() => {
      if (callGo && !showGreyOverLay) {
        apply();
        setCallGo(false);
      }
    }, [callGo, manageColumnsProfile, filterFinal, filterFinalBasic]);

    const apply = () => {
        if (!runApplyValidations()) {
            return;
        }

        reportRef?.current.go();
        setShowGreyOverLay(false);
    };

    const openDialog = (message) => {
        setInfoDialogMsg(message);
        setInfoDialogOpen(true);
        return false;
    }

    const runApplyValidations = () => {
        let emptyHeaderElements = vectorSelected.length > 0 && selectedRange.startPeriod
        if (getPeriodDifference(selectedRange?.startPeriod, selectedRange?.endPeriod) > 12) {
            return openDialog(lang.not_allowed_periods_selection);
        }
        if (!emptyHeaderElements) {
            return openDialog(lang.fields_cannot_be_empty);
        }
        return true;
    };

    const formatPeriodsArray = (array) => {
        array?.map((item, index) => {
            item.value = item.period_name;
            item.label = item.period_name;
            return item;
        })
        return array;

    }

    const handleScenarioChange = (scenarioType, e) => {
        if (scenarioType === HEADER_ELEMENT.SCENARIO) {
            setReferenceScenario(e);
        } else {
            setComparisonScenario(e)
        }
        setVectorSelected([]);
        setSelectedRange(initialPeriodRangeState);
        setShowGreyOverLay(true)
    }

    const handleVectorChange = (e) => {
        setVectorSelected([e]);
        setShowGreyOverLay(true)
    }

    const handlePeriodRangeChange = (name, e) => {
        let selectedYears = e.filter((year) => year.isSelected);
        let tempState = copyObjectValues(selectedRange);

        if (selectedYears.length > 0) {
            let periods = getLocalStorageValueByParameter("periods") ? JSON.parse(getLocalStorageValueByParameter("periods")) : [];
            let sortedSelectedYears = selectedYears.map((p) => p.year + p.value).sort();
            let startPeriod = sortedSelectedYears[0];
            let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
            let startDate = new Date(periods.filter((e) => e.value === startPeriod)[0].start_date);
            let endDate = new Date(periods.filter((e) => e.value === endPeriod)[0].end_date);

            if (getPeriodDifference(startPeriod, endPeriod) > 12) {
                setInfoDialogMsg(lang.not_allowed_periods_selection);
                setInfoDialogOpen(true);
                return;
            }

            tempState.startPeriod = startPeriod;
            tempState.startPeriod = endPeriod;
            tempState.customStartDate = startDate;
            tempState.customEndDate = endDate;

            let inputValue = startPeriod;
            if (startPeriod !== endPeriod) {
                inputValue += " " + lang.COMMON.TO.toLowerCase() + " " + endPeriod;
            }
            tempState.inputValue = inputValue;

            saveCookie("nextCustomStartDate", startDate);
            saveCookie("nextCustomEndDate", endDate);
            setSelectedRange(tempState);
            setShowGreyOverLay(true)
        }
    };

    const getVectorValue = () => {
        if (vectorSelected.length > 0) {
            return findOptionByKey(vectorOptions, vectorSelected[0])
        }else {
            return [];
        }
    }

    const tablesToExcelWrapper = () => {
      toggleLoader(true, "tablesToExcel");

      let options = {
        report: reportTitle,
        vector: vectorSelected[0]?.label,
        user: userSettings.user.first_name + " " + userSettings.user.last_name,
        scenarioNumber: referenceScenario[SCENARIOS.F.NUMBER],
        comparisonScenarioNumber: comparisonScenario ? comparisonScenario[SCENARIOS.F.NUMBER] : "",
      };

      setTimeout(function () {
        reportRef.current?.exportTableToExcel(options);
      }, 10);
    }

    /**Filter Related Functions */
    const getExportQueryFilter = (additionalFilter) => {
      if (additionalFilter?.filter && typeof additionalFilter?.filter != "function") {
        additionalFilter = additionalFilter.filter;
      }
      return additionalFilter ? formatQueryFilter(additionalFilter) : exportQueryFilter;
    };

    const formatQueryFilter = (filter) => {
      return filter.find((f) => !f.isBasicFilter)
        ? formatAdvancedFilter(
            filter,
            userSettings.user?.user_allowed_vectors,
            // datasetState.datasetOptions,
            vectorState.vectorOptions,
            filterDialRef?.current?.state?.psLinesOptions
          )
        : formatBasicFilter(filter, userSettings?.user?.user_allowed_vectors);
    };

    const getRemainingScenarios = (valueToRemove) => {
        return scenariosState.scenarioList.filter(f => f.value !== valueToRemove);
    }

    const getReferenceScenarioValue = () => { // TODO create 1 function that combines ( getReferenceScenarioValue and getComparisonScenarioValue)that getScenario based on paramater if reference or comparison 
        if (referenceScenario) {
            return findOptionByKey(scenariosState.scenarioList, referenceScenario);
        }
    }
    const getComparisonScenarioValue = () => {
        if (comparisonScenario) {
            return findOptionByKey(scenariosState.scenarioList, comparisonScenario);
        }
    }

    const getSelectedScenariosValues = (options, keysArray) => {
        let extractedObject = Object.fromEntries(
            Object.entries(options)?.filter(([key]) => keysArray.includes(key))
        );
        return extractedObject
    }

    const getScenarioValues = (options = [], filterOption) => {
        let keysArray = [String(referenceScenario.value), String(comparisonScenario.value)];
        return getCommonValuesForScenarios(getSelectedScenariosValues(options, keysArray), keysArray, filterOption);
    }

    const getCommonValuesForScenarios = (extractedObject, keysArray, filterOption) => { // TODO move to array.js an rename to getCommonValuesForArrays
        let commonValues = extractedObject[keysArray[0]]?.filter(obj1 => extractedObject[keysArray[1]]?.some(obj2 => obj2[filterOption] === obj1[filterOption]));
        return commonValues
    }

    const approveBulkChange = (key, element) => {
        if(key === PROFILE_COLUMN.IS_CHECKED) {
            let elementCosttype = element[PSS.DUPLICATE_KEYS.COSTTYPE];
            if(elementCosttype === costtype.calculated) {
                //if calculated, allow only if not percentage
                return ALL_FORMATS[element[PSS.FORMAT_TYPE_ID]] !== FormatTypes.PERCENTAGE;
            } else {
                return ![costtype.attribute, costtype.medians, costtype.ratios, costtype.totalratios, costtype.count].includes(elementCosttype);
            }
        }
    }

    const updateManageColumnsProfile = (profile, callback) => {
        let manageColumnsProfile = copyObjectValues(profile);
        manageColumnsProfile.is_modified = profile.is_modified;
        manageColumnsProfile.is_applied = profile.is_applied;
        manageColumnsProfile.name = profile.name;
        manageColumnsProfile.label = profile.name;
        manageColumnsProfile.simplified_columns = profile.simplified_columns || profile.columns;
        setManageColumnsProfile(manageColumnsProfile);
        if (callback) callback();
    }

    /**
     * @function onSelectDefaultProfile()
     * @param {Object} profile profile selected by default in manageColumns
     * @param {*} callback function to be executed then updating profile
     * called from {@link ManageColumns} when the  profile is selected by default on initialization
     */
    const onSelectDefaultProfile = (profile, callback) => {
        let isModified = false;
        profile.is_modified = isModified;
        updateManageColumnsProfile(profile, callback);
    }

    /**
     * @function onChangeProfileData()
     * @param {Object} profile profile selected by user or clicking on apply in managecolumna
     * @param {*} callback function to be executed then updating profile
     * called from {@link ManageColumns} when any profile is selected or apply is clicked
     */
    const onChangeProfileData = (profile, callback) => {
        updateManageColumnsProfile(profile, callback);
        setCallGo(true);
    }

    /**ManageColumns Related functiontions */
    const onToggleManageColsBoard = () => {
        manageColsOverlay.current.classList.toggle("uk-hidden");
    }

    /**Limit Access Related Functions */
    const checkForLimitAccessMessage = (data, showModal)=> {
        let noAccessMessage = data.no_access_message;
        if(!!noAccessMessage && showModal && !props.checkedPsLines) {
            setLimitAccessDialogOpen(true, noAccessMessage);
        }
        return !!noAccessMessage;
    }

    const infoDialogActions = () => {
        return (
            <Button
                label={lang.modal.buttons.ok}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => setInfoDialogOpen(false)}
            />
        );
    };

    /**
    * Get the periods selected in Profit stack mapping screen
    * @returns
    */
    const getSelectedPeriods = () => {
      if (selectedRange.customStartDate) {
        let periodsCount = monthDiff(selectedRange.customStartDate, selectedRange.customEndDate) + 1;
        return generatePeriods(selectedRange.customStartDate, periodsCount);
      }
      return [];
    };

    const setManageColumnsWarnings = (warningReturnNames, globalWarningMsg, individualWarningMsg) => {
      manageColsRef.current?.setWarnings(warningReturnNames, globalWarningMsg, individualWarningMsg);
    }

    const setManageColumnsAdditionalColumns = (columns) => {
        manageColsRef.current?.setAdditionalColumns(columns);
    }

    const getFilterBySectionsData = () => {
        let isClearBtnDisabled = false;
        let filterFinalBasicState = filterFinalBasic || [];
        let vectors = vectorOptions?.filter(
            (f) => f.entityCount <= userSettings.filterVectorCountThreshold || f.isGroupTitle
        ); // remove vectors with entityCount <= threshold and that are not group title;
        if (vectors && filterFinalBasicState) {
            filterFinalBasicState =
                typeof filterFinalBasicState === "object" ? filterFinalBasicState : JSON.parse(filterFinalBasicState);
            filterFinalBasicState = Array.isArray(filterFinalBasicState) ? filterFinalBasicState : filterFinalBasicState.filter;
            isClearBtnDisabled = filterFinalBasicState.length === 0;
            vectors = vectors.map((item) => ({
                ...item,
                checked: !item.isGroupTitle && filterFinalBasicState.some((e) => e.vector === item.value), // .some() checks if any of the element.vector === item.value
            }));
        }

        // Add suggested section if we have nextVectors and nextVectors' entity count < threshold
        let sectionsJson = [];
        vectors = vectors; // add isDisabled to vectors that are not generated

        let suggestedVectors = vectors?.filter((f) =>  vectorSelected[0]?.value === f.value && !f.isGroupTitle && !f.isDisabled); // get nextVectors as jsonObject
        suggestedVectors = suggestedVectors?.filter((f) => f.entityCount <= userSettings.filterVectorCountThreshold); // checks if header vectors' entity count <= thereshold
        let hasSuggested = suggestedVectors?.length > 0;
        if (hasSuggested) {
            sectionsJson.push({
                sectionTitle: lang.suggested,
                itemsList: suggestedVectors,
            });
        }

        sectionsJson.push({
            sectionTitle: lang.all,
            itemsList: vectors,
        });
        return { data: sectionsJson, isClearBtnDisabled: isClearBtnDisabled };
    };

    const getCountRef = () => {
        return countRef.current;
    };

    const setCountRef = (count) => {
        countRef.current = count;
    };

    const handleBasicFilterCheckBoxChange = (item, clear, entity, allEntities) => {
        let tempState = {};
        let vectorStateOptions = {}
        vectorStateOptions.vectorOptions = vectorOptions;
        let filter = typeof filterFinalBasic === "string" ? JSON.parse(filterFinalBasic) : filterFinalBasic;
        let initialFilter = copyObjectValues(filter);

        filter = filter || [];
        let removedLine = [];
        let isClear = false;
        let isUnCheck = false;
        if (entity && item) {
            for (let e in filter) {
                if (filter[e].vector === item.vector) {
                    if (entity.checked === true) {
                        filter[e].entities.push(entity);
                    } else {
                        filter[e].entities = filter[e].entities.filter((f) => f.value !== entity.value);
                    }
                }
            }
        } else if (item && allEntities) {
            for (let e in filter) {
                if (filter[e].vector === item.vector) {
                    for (let j in allEntities) {
                        filter[e].entities.push(allEntities[j]);
                    }
                }
            }
        } else if (item) {
            if (filter?.find((e) => e.vector === item.value)) {
                removedLine = filter.find((e) => e.vector === item.value);
                filter = filter.filter((e) => e.vector !== item.value);
                delete basicFilterEntities[item.value];
                isUnCheck = true;
            } else {
                let selectedEmptyFilter = getNewEntityFilter(
                    item.value,
                    FILTER.VALUES.FIELD.NUMBER,
                    [],
                    FILTER.VALUES.FILTER_ROW_TYPE.FILTER,
                    lang.ui_filter.dropdowns.and
                );
                selectedEmptyFilter.vectorLabel = item.label;
                filter?.push(selectedEmptyFilter);
            }
        } else if (clear) {
            filter = [];
            tempState.filterFinal = [];
            isClear = true;
            // if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            tempState.exportQueryFilter = formatBasicFilter(filter, userSettings.user?.user_allowed_vectors);
            setExportQueryFilter(tempState.exportQueryFilter);
            // }
        }
        filter = addCorrespondingParenthesisToFilter(filter);
        if (isUnCheck && removedLine.entities.length > 0) {
            tempState.filterFinalBasic = filter;
            let copyFilter = copyObjectValues(filter);
            copyFilter.forEach((e) => {
                if (e.entities) {
                    e.isBasicFilter = true;
                    e.entities = e.entities.map((ent) => {
                        ent.value = ent.number;
                        ent.label = ent.number;
                        return ent;
                    });
                }
            });
            let filtersByName = copyObjectValues(copyFilter);
            filtersByName.forEach((e) => {
                e.field = "name";
                e.entities = e.entities.map((ent) => {
                    ent.value = ent.name;
                    ent.label = ent.name;
                    return ent;
                });
                copyFilter.push(e);
            });
            tempState.filterFinal = JSON.stringify(copyFilter);
            tempState.exportQueryFilter = formatBasicFilter(copyFilter, userSettings.user?.user_allowed_vectors);
        }
        filter.map((item) => {
            item.filter_display_name = undefined;
        });
        tempState.filterFinalBasic = isUnCheck ? JSON.stringify(filter) : filter;
        setFilterFinal(tempState.filterFinal);
        setFilterFinalBasic(tempState.filterFinalBasic);

        if (item && Object.keys(basicFilterEntities).length && basicFilterEntities[item.value]) {
            basicFilterEntities[item.value].map(entity => entity.checked = false);
        }

        let countState = countRef.current;
        setCount(countState + 1);
        countRef.current = countState + 1;
        if (filterDialRef?.current) {
            filterDialRef?.current?.setState({
                savedFilterData: {},
            });
        }

        if (filterDialRef?.current?.savedFilterNameInput) {
            filterDialRef.current.savedFilterNameInput.value = "";
        }

        if (isUnCheck && removedLine.entities.length > 0 && !showGreyOverLay) {
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(tempState.filterFinal));
            let countstate = countRef.current;
            setCount(countstate + 1);
            countRef.current = countState + 1;
            // apply();
            setCallGo(true);
        } else if (!isClear) {
            getFilterEntities(item, vectorStateOptions, setBasicFilterEntities, basicFilterEntitiesState, profitFormat, userSettings, scenariosState, tempState.filterFinal, getCountRef, setCount, setCountRef);
        }
    };

    const clearClick = () => {
        handleBasicFilterCheckBoxChange(undefined, true);
        if (!showGreyOverLay) {
            setCallGo(true);
            // apply();
        }
    };

    const filterOnBasicFilter = (basicAppliedFilter) => {
        let filters = copyObjectValues(basicAppliedFilter.filter((e) => e.entities.length));
        filters?.forEach((e) => {
            e.isBasicFilter = true;
            e.filter_display_name = undefined;
            e.entities = e.entities.map((ent) => {
                ent.value = ent.number;
                ent.label = ent.number;
                return ent;
            });
        });
        let filtersByName = copyObjectValues(filters);
        filtersByName?.forEach((e) => {
            e.field = "name";
            e.entities = e.entities.map((ent) => {
                ent.value = ent.name;
                ent.label = ent.name;
                return ent;
            });
            filters.push(e);
        });

        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
        let filterFinalBasic = copyObjectValues(basicAppliedFilter); //typeof _this.state.filterFinalBasic === "string" ? JSON.parse(_this.state.filterFinalBasic) : _this.state.filterFinalBasic;
        if (filterDialRef?.current) {
            filterDialRef?.current?.setState({
                savedFilterData: {},
            });
        }

        if (filterDialRef?.current?.savedFilterNameInput) {
            filterDialRef.current.savedFilterNameInput.value = "";
        }
        filterFinalBasic?.forEach((e) => {
            e.filter_display_name = undefined;
        });

        filters = addCorrespondingParenthesisToFilter(filters);
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
        setFilterFinal(JSON.stringify(filters));
        setFilterFinalBasic(filterFinalBasic);
        setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
        if (!showGreyOverLay) {
            // apply();
            setCallGo(true);
        }
    };

    const openFilterModal = () => {
        setOpenFilterDialog(true);
    };

    const closeFilterModal = () => {
        setOpenFilterDialog(false);
    };

    const handleBasicFilterAndClose = () => {
        closeFilterModal();
        clearAdvancedFilter(basicFilterItem);
    };

    const clearAdvancedFilter = (basicFilterItem) => {
        filterDialRef?.current?.clearFilter();
        setFilterFinal([]);
        handleBasicFilterCheckBoxChange(basicFilterItem);
    };

    const filterModalBody = () => {
        return (
            <h4>
                {lang.clear_advanced_filter}
                <br />
                {lang.switching_to_quick_filter}
            </h4>
        );
    };

    const saveDialogActions = () => {
        return (
            <>
                <Button
                    label={lang.modal.buttons.clear_filter}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={handleBasicFilterAndClose}
                />
                <Button
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={closeFilterModal}
                />
            </>
        );
    };

    const handleCheckBoxChange = (item) => {
        let filterFinalArr = filterFinal !== "[]" && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal; // when filterFinal = ""[]"", remove the extra ""
        let isAdvancedFilterApplied =
            filterFinalArr &&
            filterFinalArr !== "[]" &&
            (typeof filterFinalArr === "object" ? filterFinalArr.find((e) => !e.isBasicFilter) : JSON.parse(filterFinalArr).find((e) => !e.isBasicFilter));
        if (isAdvancedFilterApplied) {
            setBasicFilterItem(item);
            openFilterModal();
        } else {
            handleBasicFilterCheckBoxChange(item);
        }
    };

    const setFilterTo = () => {
        let filter = filterFinal;
        //before saving the filter, save the old value if not previously set
        if(filter && typeof filter !== 'string') {
            filter = JSON.stringify(filter);        //onChangeSavedFilter expects a string and not an array
        }
        let filterSetRef = filterSet.current;
        if (!filterSetRef && filter?.length > 0) {
            filterSet.current = true;
            filterDialRef?.current?.onChangeSavedFilter(filter, false);
        }
    }

    const onChangeFilter = (filterStr, afterDrilling, applyFilter = false, isClear, isSwitchBasic, filterDisplayName) => {
        let filter = typeof filterStr === "string" ? JSON.parse(filterStr) : filterStr;
        // on changing advanced saved filter its name should be removed if cookie has no saved filter since we remove the saved filter from cookie when we do a change on it
        if (
            !filterDisplayName &&
            ((getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === null ||
                !getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) ||
                getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === "undefined"))
        ) {
            filter.map((item) => {
                item.filter_display_name = undefined;
            });
        }

        if (isSwitchBasic) {
            filter.map(function (item) {
                item.isBasicFilter = false;
            });
        }
        let tempState = {};

        tempState.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;

        if (isClear) {
            filter = "[]";
            tempState.filterFinalBasic = [];
        } else if (!filter.find((f) => !f.isBasicFilter)) {
            tempState.filterFinalBasic = copyObjectValues(filter).filter((e) => e.field === "number"); //from selecting a saved basic filter
            let filterObjs = copyObjectValues(filter);
            const unique = [...new Set(filterObjs.map((item) => item.vector))]; // [ 'A', 'B']
            for (var e in unique) {
                getFilterEntities(
                    { value: unique[e] },
                    vectorState,
                    setBasicFilterEntities,
                    basicFilterEntitiesState,
                    profitFormat,
                    userSettings,
                    scenarioState,
                    tempState.filterFinal,
                    getCountRef,
                    setCount,
                    setCountRef
                );
            }
        } else if (applyFilter) {
            // from apply in advanced
            tempState.filterFinalBasic = [];
        }
        tempState.tempFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
        let currState = history.state;
        if (!applyFilter) {
            let tempState2 = { tempFilter: filterStr }; //this state represents the filter displayed in the header but not necessarily applied on the data (pending apply())
            if (!isSwitchBasic) {
                tempState2.filterFinalBasic = tempState.filterFinalBasic;
                setFilterFinalBasic(tempState2.filterFinalBasic);
            }
            setTempFilter(filterStr);
            return;
        }

        //check if filter contains quadrant or quadrant/tier filter
        tempState.isAdvancedFilterApplied = isClear ? false : true;
        tempState.exportQueryFilter =
            filterStr === "[]"
                ? undefined
                : formatAdvancedFilter(
                    JSON.parse(filterStr),
                    userSettings.user?.user_allowed_vectors,
                    undefined,
                    vectorOptions,
                    filterDialRef?.current?.state?.psLinesOptions
                );

        setFilterFinal(tempState.filterFinal);
        setFilterFinalBasic(tempState.filterFinalBasic);
        setExportQueryFilter(tempState.exportQueryFilter);
        // setIsAdvancedFilterApplied(tempState.isAdvancedFilterApplied);
        setTempFilter(tempState.tempFilter);

        if (isClear) {
            filterStr = "[]";
        }
        if(history && history.location && history.location.state){
            let state = history.location?.state;
            state.outsideFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
            state.mainFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
            state.filter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
            state.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;
            state.originalMainFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
            state.scenarioState = history?.location?.state?.scenarioState,
                state.callOnMount = history?.location?.state?.callOnMount
            history.push({
                state: state
            })
            setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filter));
        }
        if (!showGreyOverLay) {
            // apply();
            setCallGo(true);
        }
    };

    const switchBasicToAdvanced = (filters) => {
        if (filterByRef?.current) {
            filterByRef.current.handleClose();
        }

        if(!filterFinal) {
          return;
        }
        
        let filterStr = filterFinal;
        let filter =
            typeof filterStr === "string"
                ? JSON.parse(filterStr)
                : filterStr.filter && typeof filterStr.filter !== "function"
                    ? filterStr.filter
                    : filterStr;

        if(filters && userSettings?.useNewQuickFilters) {
            filter = filters
        }

        filter = addCorrespondingParenthesisToFilter(filter);
        if (filter.find((e) => e.isBasicFilter)) {
            filterDialRef?.current?.onChangeSavedFilter(filter, false, true);
        }
    };

    const setQuickFilters = (filters) => {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
        
        
        setFilterFinal(JSON.stringify(filters));
        setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
        if (!showGreyOverLay) {
            setCallGo(true);
        }
    }
    
    const clearAdvancedFilters = (isClearAll) => {
        if(isClearAll) {
            filterDialRef?.current?.clearFilter();
            setFilterFinal([]);
        }

        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        let exportQueryFilter = formatBasicFilter([], userSettings.user?.user_allowed_vectors);
        setExportQueryFilter(exportQueryFilter);
    }

    /**HeaderElements Related Functions */
    const getHeaderElements = () => {
        let sectionsData;
        if(comparisonScenario && referenceScenario) {
            sectionsData = getFilterBySectionsData();
        }
        let headerElements = [];
        let startPeriodMnageCol = getPeriodFromDate(new Date(selectedRange.startPeriod));
        let endPeriodManageCol = getPeriodFromDate(new Date(selectedRange.endPeriod));
        let canSaveCompanyFilters = getSectionExists(userAllowedMenuLinks, SAVE_COMPANY_FILTERS);

        headerElements.push(
            <div style={{display: "flex", width: "100%", alignItems: "center"}}
                 className="first_header_row gap_between_buttons">
                {renderTitle(reportTitle)}
                <Dropdown
                    id="select-set"
                    className="width-200 input__dropdown"
                    // classNamePrefix="scenario-dropdown"
                    name={HEADER_ELEMENT.SCENARIO}
                    value={getReferenceScenarioValue()}
                    onChange={(e) => handleScenarioChange(HEADER_ELEMENT.SCENARIO, e)}
                    options={getRemainingScenarios(comparisonScenario?.value)}
                    type={DROPDOWN_TYPE.INPUT}
                />
                <Dropdown
                    id="select-set"
                    className="width-200 input__dropdown"
                    name={HEADER_ELEMENT.SCENARIO}
                    value={getComparisonScenarioValue()}
                    onChange={(e) => handleScenarioChange(HEADER_ELEMENT.COMPARISON_SCENARIO, e)}
                    options={getRemainingScenarios(referenceScenario?.value)}
                    // isDisabled={this.props.disabledElements.includes(HEADER_ELEMENT.SCENARIO)}
                    type={DROPDOWN_TYPE.INPUT}
                />
                <div className="select_vector_compare_scenario_container">
                    <Dropdown
                        id={"select_vector_compare_scenario"}
                        className="width-200 input__dropdown"
                        value={getVectorValue()}
                        options={vectorOptions}
                        onChange={(e) => handleVectorChange(e)}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                </div>
                {renderCalendar(
                    periodOptions || clientPeriodsState.allPeriods,
                    selectedRange.startPeriod,
                    selectedRange.endPeriod,
                    selectedRange.inputValue,
                    handlePeriodRangeChange,
                    undefined,
                    clientPeriodsState.allPeriods,
                    toggleCalendar,
                    false,
                    referenceScenario?.value + "_" + comparisonScenario?.value + "_" + periodOptions?.length, // concat 2 scenarios
                    false,
                    undefined,
                    2010,
                    2030,
                    period_calendar_type.BUILT)
                }
                <div>
                    {renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), "", "", apply, !showGreyOverLay)}
                </div>
                <ManageColumns
                    ref={manageColsRef}
                    key={HEADER_ELEMENT.ADD_COLUMNS}
                    scenarioId={referenceScenario?.value || ""}
                    profitFormat={profitFormat}
                    approveBulkChange={approveBulkChange}
                    user={userSettings.user}
                    onSelectProfile={updateManageColumnsProfile}
                    onSelectDefaultProfile={onSelectDefaultProfile}
                    onChangeProfileData={onChangeProfileData}
                    onToggleBoard={onToggleManageColsBoard}
                    manageColsAccess={manageColsAccess}
                    comparisonScenarioId={comparisonScenario?.value || ""}
                    vectorObjects={vectorSelected || {}}
                    sectionsProfilesAreValidFor={manageColumnsProps.manageColumnsUpdateSections}
                    hideDropdown={false/**isDrilling */}
                    vectorOptions={vectorOptions || []}
                    checkForLimitAccessMessage={checkForLimitAccessMessage}
                    vector={vectorSelected[0]}
                    outsideColumnProfileId={props?.history?.location?.state ? props?.history?.location?.state?.columnProfileId : undefined}
                    report={reportTitle}
                    hideArrow={false}
                    hideManage={false}
                    updateManageColumnsProfile={updateManageColumnsProfile}
                    isCompareScenarios={true}
                    dataSet={userSettings.dataset}
                    dispatch={dispatch}
                    manageColumnsSelectionLimit={userSettings.manageColumnsSelectionLimit}
                    selectedRange={startPeriodMnageCol + (startPeriodMnageCol !== endPeriodManageCol ? " to " + endPeriodManageCol : "")}
                    selectedFilter={[]/*filterFinal*/}
                    profileColumns={profileColumns}
                    customGroups={customGroups}
                    profiles={profiles}
                    columnProfiles={columnProfiles}
                    stateProfiles={stateProfiles}
                    constraint={"10"}
                    characterSizeLimit={userSettings.characterSizeLimit}
                    scenarioState={scenarioState}
                    useNewAddColumn={userSettings.useNewAddColumn}
                />
            </div>
        );
        headerElements.push(
            <div className="second_header_row gap_between_buttons" style={{display: "flex", width: "100%", padding: "0.41667vw 0", justifyContent: "space-between"}}>
                <div
                    className="filter-list-filled uk-display-flex gap_between_buttons"
                    uk-tooltip={"title:" + ("") + ";pos: top-left"}
                >
                    {userSettings?.useNewQuickFilters ? (
                        <QuickFilters
                            vectorOptions={vectorOptions}
                            profitFormat={profitFormat}
                            userSettings={userSettings}
                            scenarioState={scenarioState}
                            filterFinal={filterFinal}
                            setQuickFilters={setQuickFilters}
                            switchBasicToAdvanced={switchBasicToAdvanced}
                            clearAdvancedFilters={clearAdvancedFilters}
                            filterDialRef={filterDialRef}
                        />) 
                        : (
                        <FilterByDropdown
                            ref={filterByRef}
                            sectionsData={sectionsData?.data? copyObjectValues(sectionsData.data) : []}
                            clearClick={clearClick}
                            isClearBtnDisabled={sectionsData ? sectionsData.isClearBtnDisabled : false}
                            handleCheckboxChange={handleCheckBoxChange}
                            basicFilterCountLimit={userSettings.basicFilterCountLimit}
                            switchBasicToAdvanced={switchBasicToAdvanced}
                        />)
                }
                <FilterDialog
                    id={"secondary_header_compare_scenario"}
                    ref={filterDialRef}
                    section_id={params.sectionId}
                    vectorOptions={vectorOptions || []}
                    profitFormat={profitFormat}
                    mustHideFilter={false}
                    categoryToolkit={false}
                    onChangeFilter={onChangeFilter}
                    quadTierIsAllowed={true}
                    isTempScenario={false}
                    scenario_id={referenceScenario?.value}
                    mustFetchDatasets={false}
                    user={userSettings.user}
                    isDrilling={false}
                    // fromOptions={datasetState.datasetOptions}
                    // datasetOptions_id={datasetState.datasetOptions_id}
                    // dataSet={datasetState.dataset}
                    useCookies={true}
                    parentMessage={""}
                    psFilterDisabled={false}
                    quadrantsDisabled={false}
                    quadrantDefault={false}
                    checkIfMonthsValid={checkIfMonthsValid}
                    comparisonScenarioId={comparisonScenario?.value}
                    filterDisplayEditable={true}
                    psLinesOptions={psLinesOptions}
                    quadrantTierOptions={quadrantTierOptions}
                    dispatch={dispatch}
                    savedFilterDataState={history?.location?.state?.savedFilterDataState || filterDialRef?.current?.state?.savedFilterData}
                    isRedirectionFromStacks={false}
                    savedFilter={true}
                    fromSecondaryHeader={true}
                    savedFilterDisabled={false}
                    setFilterTo={setFilterTo}
                    useNewQuickFilters={userSettings.useNewQuickFilters}
                    filterFinal={filterFinal}
                    canSaveCompanyFilters = {canSaveCompanyFilters}
                />
                </div>
                <div style={{display: "flex", flexDirection: "row", columnGap: "0.42vw"}}>
                    <Button
                        id="Export"
                        label={lang.header.titles.xls}
                        title={lang.header.tooltips.xls}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        disabled={showGreyOverLay}
                        onBtnClick={tablesToExcelWrapper}
                        leftIcon={<i className="fal fa-file-excel fa-lg"/>}
                    />
                </div>
            </div>
        );

        if(!userSettings.useNewQuickFilters) {
            headerElements.push(
                <div className="third_row_header" style={{ display: "flex", width: "100%" }}>
                    <div
                        key={Math.random()}
                        className={
                            "noprint " +
                            (displayFilter && ((filterFinalBasic?.length > 0 && filterFinalBasic !== "[]") || (filterFinal?.length > 0 && filterFinal !== "[]"))
                                ? "border-third-header "
                                : "") +
                            "uk-display-block"
                        }
                    >
                        <AppliedFilters
                            filterProps={filterFinalBasic}
                            filterFinal={filterFinal}
                            ref={appliedFiltersRef}
                            userAllowedVectors={userSettings.user?.user_allowed_vectors}
                            basicFilterCountLimit={userSettings.basicFilterCountLimit}
                            handleCheckboxChange={handleBasicFilterCheckBoxChange}
                            sectionsData={basicFilterEntities}
                            fetchData={filterOnBasicFilter}
                            clearFilter={filterDialRef?.current?.clearFilter}
                            vectors={vectorOptions}
                            quadrantsDisabled={false}
                            psFilterDisabled={false}
                            parentMessage={"" /*props.filterWarningMessage*/}
                            saveFilter={filterDialRef?.current?.startSaveFilter}
                            clearBasicFilter={clearClick}
                            clearFilterFromDialog={filterDialRef?.current?.clearFilter}
                            forgetFilter={filterDialRef?.current?.handleOpenForgetFilterDialog}
                            className={""}
                            tooltip={"title:" + ("") + ";pos: top-left"}
                            filterIsEditable={filterDialRef?.current?.state?.savedFilterData?.editable}
                        />
                    </div>
                </div>
            );
        }
        return headerElements;
    };
    return (
        <>
            {showSkeleton && <LoaderSkeleton/>}
            <ErrorBoundary>
                <Suspense fallback={<p id="loading">Loading...</p>}>
                  <div ref={manageColsOverlay} id="overlay-manage-columns" className="uk-hidden"/>
                  <div id={"main-component-container"} className={"main-component-container " + (showSkeleton ? "hidden" : "")} style={{"--banner-height": 0}}>
                    <div className={"header-banner-div-hidden"}/>
                    <div className="main-report-header">
                        <ReportHeader headerElements={headerElements}/>
                    </div>
                    <div className={"main_report"}>
                      {headerElements.length > 0 && showGreyOverLay && <div className="overlay-div">{lang.overlay_go_msg}</div>}
                      {Object.keys(scenariosState).length > 0 && Object.keys(clientPeriodsState).length > 0 && referenceScenario && comparisonScenario
                        && selectedRange?.customStartDate && vectorSelected?.length > 0 && (
                          <div id="main_report_container" className={"main_report_container"}>
                            <CompareScenarios
                              ref={reportRef}
                              costCenter={userSettings.costCenter}
                              limit={userSettings.recordsLimit}
                              compareVectorsLimit={userSettings.compareVectorsLimit}
                              user={userSettings.user}
                              vector={vectorSelected[0]}
                              scenarios={[referenceScenario?.value, comparisonScenario?.value]}
                              scenarioObjects={[referenceScenario, comparisonScenario]}
                              periods={clientPeriodsState.periods}
                              selectedPeriods={getSelectedPeriods()}
                              profitFormat={profitFormat}
                              quarter={getQuarterFromDate(selectedRange.customEndDate)}
                              manageColumnsProfile={manageColumnsProfile}
                              setManageColumnsWarnings={setManageColumnsWarnings}
                              setManageColumnsAdditionalColumns={setManageColumnsAdditionalColumns}
                              filter={filterFinal}
                              exportQueryFilter={getExportQueryFilter}
                              exportScopeFilter={exportScopeFilter}
                            />
                          </div>
                        )}
                    </div>
                    <Modal
                      id={"compare-scenarios-validation-dialog"}
                      openDialog={isInfoDialogOpen}
                      bodyContent={() => <h4>{infoDialogMsg}</h4>}
                      dialogActions={infoDialogActions}
                      closeClick={() => setInfoDialogOpen(false)}
                      size={DIALOG_SIZE.MEDIUM}
                    />
                    <Modal
                      id={"switch-basic-to-advanced-filter"}
                      openDialog={openFilterDialog}
                      bodyContent={filterModalBody}
                      dialogActions={saveDialogActions}
                      size={DIALOG_SIZE.MEDIUM}
                      closeClick={closeFilterModal}
                    />
                  </div>
                </Suspense>
            </ErrorBoundary>
        </>
    );
};
export { CompareScenariosWrapper };

