import { SEGMENTS, SEGMENTS_TIERS } from "../class/constants";

const SEGMENT = "Profit Segments";
const lang = {
    observability:{
        manage_scenarios: {
            screen_name:"Manage Scenarios",
            key:"manage_scenarios",
            requests_description:{
                rename_scenario:"This request renames your scenario after inputting a new name for it.",
                create_scenario_from_scratch:"This request creates a new scenario from scratch.",
                get_scenarios_data:"This request gets all of client's scenarios with thier statuses.",
                update_scenario_status:"This request updates your scenario's status.",
                clone_scenario:"This request clones a scenario.",
                fetch_build_details:"This request fetches your build details upon clicking on variance icon beside scenario name (if found).",
            },
            budgeting_configuration: {
                screen_name:"Manage Scenarios - Budgeting Configuration",
                key:"manage_scenarios.budgeting_configuration",
                requests_description: {
                    get_budget_configurations:"This request gets your budget configuration.It is accessed in manage scenarios through your budget scenario.",
                    get_used_metrics:"In the budgeting scenario configuration, the users must be informed of the list of metrics that are using a GL data source whether actual or budget.",
                    save_budget_configurations:"This request saves your budget configurations.",
                    get_budget_query:"Generate Budgeting Data (TRX).",
                    generate_budget_files:"This request generates your budget files (master_data,currency,etc)."
                }   
            }
        },
        upload_data:{
            screen_name:"Upload Data",
            key:"upload_data",
            requests_description:{
                fetch_crosswalk_links:"This request fetches all of your crosswalk links.",
                save_crosswalk_links:"This request saves your crosswalk links.",
                get_raw_file_list:"This request gets you all passed uploaded files of all types.",
                run_validation:"This request is sent to ensure validity on crosswalk links.",
                run_matching_entity_query:"This request is sent to check how common entities exist between the source and target column."
            }
        },
        modeling_settings: {
            screen_name:"Modeling Settings",
            key:"modeling_settings",
            periods:{
                screen_name:"Modeling Settings - Periods",
                key:"modeling_settings.periods",
                requests_description:{
                    save_fiscal_periods:"This request saves your updates in periods tab."
                }
            },
            datasources: {
                screen_name:"Modeling Settings - Datasources",
                key:"modeling_settings.datasources",
                requests_description: {
                    get_subtype_definition:"This request gets the subtype definition for a particular subtype.",
                    check_if_file_is_uploading_or_redefining:"This request check if a particular file is upload or redefining  under a specific subtype to prevent you from changing the definition.",
                    validate_datasource_header:"Once you set up your column header this request validates the datasource header to check if is valid or not.",
                    validate_defined_file:"This request is called after defining your file to check if the defined file is valid or not.",
                    save_subtype_data_definition:"This request saves your subtype defintion once you enter save in define dailog.",
                    initiate_ftp_upload:"This request uploads your files.",
                    initiate_query_upload:"This request uploads your queries results.",
                    run_manual_automation:"This request uploads your automated files manually without the need of uploading a dependency file to trigger it.",
                    get_file_census_sections_data:"This request gets your datasources data once you enter your datasources screen.",
                    get_all_file_type_columns:"This request is called after redefine is done to re-fetch all file type columns.",
                    get_distinct_currency_values:"This request is called when you configure a numeric column to fetch distinct currency values.",
                    run_matching_keys_audit:"This request is sent to check how many common currency entities is matching between your currency file and your selected file.",
                    save_file_census_sections:"This request is sent when we save anything in modeling settings."
                }
            }
        },
        monthly_build: {
            screen_name:"Monthly Build",
            key:"monthly_build",
            requests_description: {
               get_steps_state:"This request gets your monthly build step states.",
               set_step_checked:"This request marks your step as completed.",
               cancel_revert:"This request cancels your revert on monthly after reverting, as if you are publishing again.",
            },
            introduction: {
                screen_name: "Monthly Build - Introduction",
                key:"monthly_build.introduction",
                requests_description:{
                    get_monthly_build_period:"This request gets your monthly build period you are working on.",
                    get_prerequisites_data:"This request is always called after entering the monthly build to get all info regarding your monthly build and if it is applicable to continue.",
                    get_last_monthly_build_status:"This request is executed once you are in the build step and is not completed.",
                    save_fiscal_periods:"This request saves your newly period in monthly build.",
                    proceed_from_intro:"This request is called once you proceed from introduction step.",
                    revert_monthly_build:"This request reverts your monthly build. This is done in the introduction step after publishing your monthly build period.",
                }
            },
            upload_files: {
                screen_name: "Monthly Build - Upload Files",
                key:"monthly_build.upload_files",
                requests_description:{
                    initiate_ftp_upload:"This request uploads your files in upload step.",
                    get_data_sources_board_data:"This request gets all your uploaded data for the current period you are working on in upload step.",
                    ignore_crosswalk_file:"This request ignores your crosswalk file, once you click on ignore.",
                }
            },
            tie_out_pl_and_gl: {
                screen_name: "Monthly Build - Tie out P&L & GL",
                key:"monthly_build.tie_out_pl_and_gl",
                requests_description:{
                    fetch_gl_account_info:"This request is called in Tie out P&L & GL step. It fetches gl accounts amount, profit name, tied out value & tieout thresholds. Also checks if there are undefined gl accounts.",
                    fetch_detailed_tie_out_export_data:"This request give a detailed export in case your tied out value was not matching upon clicking on export.",
                    save_tiedout_value:"This request saves youre tied out value upon proceeding from Tie out P&L & GL step."
                }
            },
            define_cost_centers: {
                screen_name: "Monthly Build - Define cost centers",
                key:"monthly_build.define_cost_centers",
                requests_description:{
                    save_cost_center_state:"This request saves your newly created cost center state.",
                    generate_cost_center_states: "This request generates your cost center states upon stp upload.",
                    get_cost_centers_data:"This request gets all your cost center data."
                }
            },
            assign_gl_strings: {
                screen_name: "Monthly Build - Assign GL strings",
                key:"monthly_build.assign_gl_strings",
                requests_description:{
                    update_combinations:"This request is saves your gl strings in assign gl strings step.",
                    get_gl_strings_data:"Gets all your gl strings and their configurations."
                }
            },
            build: {
                screen_name: "Monthly Build - Build",
                key:"monthly_build.build",
                requests_description:{
                    track_build_progress:"This request tracks your build once a build is executed in monthly build.",
                    stop_full_build:"This request stops your build.",
                    run_monthly_build:"This request is responsible to execute your monthly build.",
                    get_monthly_build_affected_periods:"This request is responsible to get the list of periods that will be included in this monthly build.",
                }
            },
            publish_monthly_build:{
                screen_name: "Monthly Build - Approve",
                key:"monthly_build.publish_monthly_build",
                requests_description:{
                    publish_monthly_build:"This request approves your monthly build.",
                    get_last_approve_status:"This request is responsible to get your approve status.We need this to know if approval failed to show the failure banner in case you enter the screen.",
                }
            }
        },
        configure_build: {
            screen_name:"Configure & Build",
            key:"configure_build",
            requests_description: {
                track_build_progress:"This request tracks your build once a build is executed in Configure & Build.",
                run_full_build:"This request executes your build in Data Modeling.",
                generate_segments:"This request generates your segments manually.",
                fetch_build_details:"This request fetches your build details upon clicking on details in manage build details.",
                stop_full_build:"This request stops your build.",
                get_scenarios:"This request gets your engine scenarios."
            },
            configure_cost_function: {
                screen_name:"Configure & Build - Configure Cost Function",
                key:"configure_build.configure_cost_function",
                requests_description: {
                    translate_filter:"This request is called when we click on apply rule/filter after editing it.",
                    get_metric_fields:"This request gets all metric fields.",
                    get_vectors_status:"This request gets all vectors statuses fields.",
                    get_fields:"This request gets transacton fields & calculated columns fields.",
                    get_cost_function:"This request gets your configured cost functions.",
                    get_periods_status:"This request gets your staged periods.",
                    save_cost_functions:"This request saves your cost functions changes.",
                    get_configure_columns_values:"This request gets your column values.",
                    get_scenario_vector_metrics:"This request gets your vector's metrics."
                }
            },
            configure_vectors: {
                screen_name:"Configure & Build - Configure Vectors",
                key:"configure_build.configure_vectors",
                requests_description: {
                    get_vector_list:"This request gets your saved vectors.",
                    update_vectors:"This request saves your changes in configure vectors."
                }
            },
            configure_quadrants: {
                screen_name:"Configure & Build - Configure Qudarants",
                key:"configure_build.configure_quadrants",
                requests_description: {
                    get_quadrant_configuration:"This request gets your saved quadrant configuration.",
                    save_quadrant_configuration:"This request saves your quadrant configuration.",
                    get_profit_stack_fields:"This request gets your scenario profit stack lines.",
                    
                }
            }
        },
        manage_cost_center: {
            screen_name:"Manage Cost Center",
            key:"manage_cost_center",
            requests_description: {
                generate_cost_center_states: "This request generates your cost center states upon stp upload.",
                get_uploaded_files_status:"This request basically is called to check the status of your file being generated.",
                fetch_cost_center_periods:"This request fetches all of the data in manage cost center it is called when you enter manage cost center.",
                get_generated_budget_scenarios:"This gets all budgeting scenarios, they are shown under scenarios drop down.",
                save_cost_center_state:"This request saves your newly created cost center state.",
                get_scenarios:"This request gets your engine scenarios."
            }
        },
        stage: {
            screen_name:"Stage",
            key:"stage",
            requests_description:{
                stage_calculated_columns:"This request stages your calculated columns in stage screen.",
                stage_metrics:"This request stages your metrics in stage screen.",
                stage_exclusions:"This request stages your exclusions in stage screen.",
                stage_vectors:"This request stages your vectors in stage screen.",
                stage_profit_stack:"This request stages your profit stack in stage screen.",
                get_scenarios:"This request gets your engine scenarios.",
                get_all_file_type_columns:"This request gets the default and non default raw file fields for all of your subtypes.",
                get_client_cost_terms:"This request gets your client cost terms.",
                fetch_used_attributes:"This request gets your created profit stack mapping attributes.",
                get_staging_data:"This request gets your staging data.",
                get_vector_status:"This request gets your vector status in all periods.",
                get_metric_status:"This reuqest gets your metric status in all periods."
            },
            exclusions_mapping: {
                screen_name:"Exclusion Mapping",
                key:"stage.exclusions_mapping",
                requests_description:{
                    get_exclusion_data:"This request gets your exclusions data.",
                    get_attribute_values:"This request gets your chosen attribute values in the filter.",
                    get_exclusions:"This request gets your saved exclusions.",
                    save_exclusions:"This request saves your exclusions.",
                    get_calculated_fields:"This request gets your calculated fields when entering the exclusions mapping."
                }
            },
            calculated_columns_mapping:{
                screen_name:"Calculated Column Mapping",
                key:"stage.calculated_columns_mapping",
                requests_description:{
                   get_calculated_columns:"This request fetches your scenario's calculated columns.",
                   load_calculated_columns_total_amounts:"This request is called upon clicking load total amounts in calculated columns mapping to compute calculted columns' total amount for a specific period.",
                   save_calculated_columns:"This request saves your calculated columns.",
                   check_calculated_column:"This request checks if a calculated column is used in a cost function rule/filter."
                }
            },
            vector_mapping:{
                screen_name:"Vector Mapping",
                key:"stage.vector_mapping",
                requests_description:{
                    get_vectors_entity_count:"This request is called upon clicking load entity count in vector mapping to compute vectors' entity counts for specific period.",
                    get_vector_detail:"This request is sent upon clicking on more on a specific vector to get you the vector details.",
                    get_vectors:"This request gets your scenario's vectors.",
                    get_translated_identifier:"This request is called after submitting a change in a vector mapping configuration. It translates the key, name, number to human readable.",
                    save_vectors:"This request saves your vector mapping changes.",
                    create_new_scenario:"This request creates a new scenario with new configuration modified in a published or review scenario."
                }
            },
            metric_mapping:{
                screen_name:"Metric Mapping",
                key:"stage.metric_mapping",
                requests_description:{
                   get_calculated_columns:"This request fetches your scenario's calculated columns.",
                   get_metrics_data:"This request gets your metric values and it gets your metric details when clicking on more for specific period.",
                   get_vector_list:"This request gets your vectors (For each vector you have key, name, number).",
                   save_metrics:"This request saves your metrics.",
                   get_metric_data_validation:" This request validates that the data in the primary key for the chosen anc file is present in the vector data for this period.",
                }
            },
            profit_stack_mapping:{
                screen_name:"Profit Stack Mapping",
                key:"stage.profit_stack_mapping",
                requests_description:{
                    get_combinations_data:"This request get everything related to combinations. It basically gets all your combinations types with their values.",
                    fetch_data_for_mapping:"This gets everything needed to setup your profit stack lines. It fetches all of your ancillary files, metrics & calculated columns.",
                    get_profit_stack_fields:"This request gets your scenario profit stack lines.",
                    get_mapped_lines:"This request gets your scenario's mapped lines.",
                    parse_amount:"This request parses amount (Small loader in psl table).",
                    get_raw_file_field_names:"This request gets your raw file field names.",
                    get_column_values:"This request fetches your values based on the column in a filter.",
                    parse_map_exception_query:"This request parses your amount when adding a filter to a psl line set to ancillary.",
                    parse_metric_amount:"This request parses your amount when adding a filter to a psl line set to metric.",
                    update_scenario:"This request saves your profit stack mapping changes.",
                    create_new_scenario:"This request creates a new scenario with new configuration modified in a published or review scenario."
                }
            },
        },
        configure_tie_out: {
            screen_name:"Configure Tie Out",
            key:"configure_tie_out",
            requests_description: {
                fetch_gl_configuration:"This request gets your gl account configuration.",
                save_gl_configuration:"This request saves your gl account configuration.",
                all_gl_account_numbers_are_defined:"This request checks if all gl account numbers are defined else it will show a banner.",
                get_undefined_gl_account_numbers:"This reuqest gets all of your undefined gl account numbers. ",
                get_configure_columns_values:"This request gets your column values.",
                
            }
        },
        manage_accruals: {
            screen_name:"Manage Accruals",
            key:"manage_accruals",
            requests_description: {
               get_scenarios:"This request gets your engine scenarios",
               get_fiscal_years:"This request gets all of the client's fiscal years.",
               get_profit_stack_fields:"This request gets your scenario profit stack lines.",
               get_prediction_config:"This request gets your prediction configuration.",
               get_fy_cost:"This request gets your fiscal year cost.",
               save_accruals:"This request saves your accrual changes."
            }
        },
        output:{
            landing_page:{
                screen_name:"Landing Page"
            },
            configurations: {
                get_client_periods: "This request fetches client Periods",
                fetch_vectors: "This request fetches vectors",
                fetch_datasets:"This request fetches datasets",
                fetch_filter_entities: "This request fetches filter entities",
                fetch_saved_broken_filters: "This request fetches saved broken filters to be displayd in saved filters dropdown",
                fetch_custom_view_data: "This request fetches the stacks",
                update_login: "This request updates Last login date",
                get_bridge_options: "This request fetches bridge configuration",
                update_show_again: "This request update the show again of the release notes",
                get_images: "This request fetches the images signed urls",
                get_cost_hierarchy: "This request fetches the hierarchy of the stack chosen",
                get_model_coherence_data: "This request fetches the data of the model coherence screen",
                get_map_coordinates: "This request fetches the coordinates files needed to draw the map for Geography screen",
                get_user_settings: "This request fetches the user settings",
                get_menu_items:"This request fetches menu items",
                get_scenarios:"This request fetches scenarios",
                get_periods: "This request fetches periods with their statuses",
                save_filter: "This request saves the filter",
                delete_filter: "This request deletes the filter",
                get_saved_filters: "This request fetches saved filters",
                get_qts: "This request fetches quadrant tiers",
                save_profile: "This request saves profile",
                save_profile_fav: "This request saves profile as favorite",
                save_profile_def: "This request saves profile as default",
                save_created_column: "This request saves created column",
                delete_column: "This request deletes created column",
                fetch_groups: "This request fetches custom groups",
                save_created_column: "This request saves created column",
                fetch_appearance_values: "This request fetches column appearance values",
                fetch_columns: "This request fetches columns",
                fetch_profiles: "This request fetches profiles",
                update_order: "This request updates columns order",
                delete_profile: "This request deletes a profile",
                save_dashboard: "This request saves a dashboard",
                clone_dashboard: "This request clones a dashboard",
                delete_dashboard: "This request delets a dashboard",
                make_default: "This request makes a dashboard default",
                fetch_widgets: "This request fetches widgets",
                fetch_dashboards: "This request fetches dashboards",
                fetch_configuration: "This request fetches configuration of supply chain",
                get_profit_stack_lines: "This request fetches profit stack lines",
                set_scope_filter: "This request saves a scope filter"
            },
            list:{
                screen_name:"List",
                requests_description:{
                    apply:"This request fetches data for list screen",
                    drill:"This request fetches the drill vector entities focused on the selected rows",
                    export: "This request exports the list table into a CSV",
                    export_drill: "This request exports the drill list into a CSV",
                    with_all_psls:" with all profit stack lines"
                }
            },
            total_stacks:{
                screen_name:"Total Stacks",
                requests_description:{
                    apply:"This request fetches data for total stacks screen",
                    chart:"This request fetches data for the chart of the total stacks screen",
                    costClassification: "This request fetches cost classification data for total stacks screen",
                }
            },
            entity_stacks:{
                screen_name:"Entity Stacks",
                requests_description:{
                    apply:"This request fetches data for entity stacks screen",
                    chart:"This request fetches data for the chart of the entity stacks screen",
                    costClassification: "This request fetches cost classification data for entity stacks screen",
                }
            },
            mom_stacks:{
                screen_name:"MoM Stacks",
                requests_description:{
                    apply:"This request fetches data for month over month stacks screen and chart",
                    chart:"This request fetches data for the chart of the MoM stacks screen",
                    costClassification: "This request fetches cost classification data for MoM screen",
                }
            },
            yoy_stacks:{
                screen_name:"YOY Stacks",
                requests_description:{
                    apply:"This request fetches data for Year over year stacks screen and the chart",
                    chart:"This request fetches data for the chart of the year over year stacks screen",
                    costClassification: "This request fetches cost classification data for Year over Year screen",
                }
            },
            erosion_and_growth:{
                screen_name:"Erosion & Growth",
                requests_description:{
                    apply:"This request fetches data for Erosion and Growth screen",
                }
            },
            bridge_report:{
                screen_name:"Bridge Report",
                requests_description:{
                    chart:"This request fetches data for bridge report chart",
                    data:"This request fetches data for bridge report table",
                    drill:"This request fetches data for drilled lever in bridge report",
                    drill_from_drill:"This request fetches data for drill under drilled lever in bridge report",
                    higher_level: "This requests fetches higher levels vectors"
                }
            },
            vector_definition:{
                screen_name : "Vectors Definition",
                requests_description:{
                    vectors_definition: "This request fetches data for Vector Definitions"
                }
            },
            heatmap:{
                heatmap_list:{
                    screen_name : "HeatMap List",
                    requests_description:{
                        apply: "This request fetches data for the list under HeatMap screen",
                        drill: "This request fetches data for the list drill under HeatMap screen",
                        export: "This request exports the list table into a CSV under HeatMap screen",
                        export_drill: "This request exports the drill list into a CSV under HeatMap screen"
                    }
                },
                screen_name : "HeatMap",
                requests_description:{
                    apply: "This request fetches data for HeatMap"
                }
            },
            geography:{
                geography_list:{
                    screen_name : "Geography List",
                    requests_description:{
                        apply: "This request fetches data for the list under Geography screen",
                        export: "This request exports the list table into a CSV under Geography screen"
                    }
                },
                screen_name : "Geography",
                requests_description:{
                    apply: "This request fetches data for Geography"
                }
            },
            landscape:{
                landscape_list:{
                    screen_name : "Profit Landscape List",
                    requests_description:{
                        apply: "This request fetches data for the list under Profit Landscape screen",
                        drill: "This request fetches data for the list drill under Profit Landscape screen",
                        export: "This request exports the list table into a CSV under Profit Landscape screen",
                        export_drill: "This request exports the drill list into a CSV under Profit Landscape screen"
                    }
                },
                screen_name : "Profit Landscape",
                requests_description:{
                    apply: "This request fetches data for Profit Landscape"
                }
            },
            tlc:{
                screen_name:"TLC Lanscape",
                requests_description:{
                    apply:"This request fetches data for TLC Lanscape",
                    get_entities: "This request fetches entities for channel and department tabs under TLC Lanscape",
                    trend_chart: " (Trend Chart)"
                }
            },
            dashboards:{
                screen_name:"Dashboards",
                requests_description:{
                    metrics:"This request fetches data for Metrics widgets"
                },
                widget: " (Widget) "
            },
            manage_stacks:{
                screen_name:"Manage Stacks",
                request_description: {
                    compatibility:"This request fetches the compatibility of each stack with the selected scenario",
                    published_stack:"This requests fetched the stack of the published scenario",
                    stacks:"This request fetches all stacks",
                    stack_info:"This request fetches the edited stack info",
                    save:"This request saves the stack",
                    clone:"This request clones a stack",
                    delete:"This request deletes a stack",
                    total_stacks_apply:"This request fetches data for total stacks screen accessed from Manage Stacks screen",
                    total_stacks_chart:"This request fetches data for the chart of the total stacks screen accessed from Manage Stacks screen",
                    total_stacks_costClassification: "This request fetches cost classification data for total stacks screen accessed from Manage Stacks screen",

                }
            },
            configure_bridge_report:{
                screen_name:"Configure Bridge Report",
                request_description: {
                    views:"This request fetches the stacks saved",
                    profit_stack_fields:"This requests fetches the profit stack lines",
                    fields:"This request fetches the calculated lines",
                    bridge_configurations:"This request fetches the bridge configurations",
                    save:"This request saves the bridge configurations",
                    delete:"This request deletes configuration"
                }
            },
            manage_custom_reports:{
                screen_name:"Manage Custom Reports",
                request_description: {
                    client_reports:"This request fetches the client reports",
                    access_groups:"This requests fetches the report's access groups",
                    save:"This request saves the report",
                    delete:"This request deletes report",
                }
            },
            manage_access:{
                screen_name:"Manage Access",
                request_description: {
                    data:"This request fetches the users, roles and access groups",
                    save_role:"This request saves the role",
                    save_access_group:"This request saves the access group",
                    save_user:"This request saves the user",
                    deactivate:"This request",
                    reset_mfa:"This request resets MFA",
                    set_mfa:"This request sets MFA",
                    reset_password:"This request send an email to reset password",
                    failed_attempts: "This request sets failed attempts",
                    save_user:"This request saves user",
                    get_allowed_domains:"This request fetches the allowed domains",
                    check_email_exists: "This request checks if email exists"
                }
            },
            psl_definition:{
                screen_name: "Profit Stack Line Definitions",
                request_description: {
                    data:"This request fetches the table data",
                    cost_terms: "This request fetches the client cost terms"
                }
            },
            supply_chain: {
                screen_name:"Suply Chain Setup",
                save_exclusions: "This request saves exclusions",
                save_buckets: "This request saves buckets",
                save_vectors: "This request saves vectors configuration"
            }

        },
        platform:{
            login:{
                screen_name: "Login",
                check_if_password_expired: "This request checks if password expired or not",
                check_user_acces: "This request checks the user access",
                get_session_id: "This reques retreives the session id",
                set_failed_attempts: "This request increments the failed attempts or resets it to 0",
                get_user_settings: "This request fetches the user settings",
                forgot_password: "This request sends email to reset password",
                set_user_default_client: "This request sets the default client for user",
                preferences: "Preferences",
                get_user_details: "This request fetches user details",
                update_access: "This request updates user access",
                update_user: "This request updates user",
                
            }
        }
    },
    toggle_label_entity: "Group remaining entities" ,
    no_entities_to_group: "No entities to group" ,
    entity_limit_reached: "Entity limit reached" ,
    tootltip_select_entity:"Number of entities less than 20" ,
    new_scenario_message: "A new scenario will be created and your sandbox will be overwritten (if available). Do you wish to clone it with the new changes made?",
    overwrite_scenario_message: "The current scenario will be overwritten. Do you wish to continue?",
    N_A: "N/A",
    N_A_full: "Not Available",
    undefined_value: "Undefined",
    define_pss_formula: "Define PSL Formula",
    define_pss_formula_acr:"Define Denominator",
    pss_formula_title:"This formula is used to calculate the assigned cost over the amount defined here. Bubble color follows a color scale based on percentage calculated by entity.",
    add_tier:"Add tier",
    add_filter:"Add Filter",
    edit_filter:"Edit Filter",
    input_unique_filter_name: "Input a unique filter name",
    rule:"Rule: ",
    filter:" Filter: ",
    save_filter:"Save Filter",
    define_psl_formula:"Define Profit Stack Lines Formula",
    formula_title:"Formula",
    profit_stack_line: "Profit Stack Lines",
    profit_stack_line_single: "Profit Stack Line",
    configure_attribute: "Select Attribute",
    configue_psl_empty: "% PSL is empty",
    choose_a_client: "Choose a client",
    disableMedTooltip: "Only applicable on the Default Profit Stack",
    filter_setup:"Filter Setup",
    limit_access:"Limit access",
    threshold_configure_icon:"The threshold is filtered based on standard deviation. The threshold is applied on the chart and the bubble are colored based on the Assigned % PSL standard deviation ranges green, yellow, orange and red from low to high respectively.",
    ten_thousand_statement:"10,000 vector entities are shown based on the selected order preference.",
    configure_quadrant_profit:"What is the Calculated Profit Stack line that defines profit for "+ SEGMENT +" generation?",
    configure_quadrant_revenue:"What is the Standard Profit Stack line that defines revenue for "+ SEGMENT +" generation?",
    configure_quadrant_revenue_validation: SEGMENT +" Revenue must be setup",
    configure_vectors_group_validation:"At least one vector does not belong to a group.",
    configure_vectors_checkout_validation:"This action requires the scenario to be checked out.",
    preview_title:"You should define the PSL formula in order to preview cost assignments.",
    preview_validate_title:"You cannot preview your cost assignment unless the selected period has Profit Stack, Invoice Line, Vectors, and Metrics staged.",
    input_name_for_filter:"Please input a name for your filter",
    same_password_error:"The new password cannot be the same as the old one.",
    password_length:"The password must be at least 8 characters long",
    password_start_symbol:"The password must not start with special characters",
    passowrd_contain_digits_symbols:"The password must contain digits, characters and special characters",
    drilled_to_text:"Drilled To ",
    order: [
        {label: "Top Assigned Cost", value: "top_assigned_cost", orderBy: "ac", orderer:"desc"},
        {label: "Bottom Assigned Cost", value: "bottom_assigned_cost", orderBy:"ac", orderer:"asc"},
        {label: "Top Assigned % PSL", value: "top_assigned_perc_psl", orderBy: "psl_perc", orderer:"desc"},
        {label: "Bottom Assigned % PSL", value: "bottom_assigned_perc_psl", orderBy:"psl_perc", orderer:"asc"},
    ],
    order_title:"Prioritize By",
    assigned_cost:"Assigned Cost",
    assigned_perc_total:" Assigned % Total",
    assigned_psl:"Assgined % PSL",
    count_lines:"Lines",
    order_icon:"Up to 10,000 vector entities will be shown based on the selected order preference.",
    rule_overlay_text:"Press Preview button to get your data.",
    cost_assignment_by_entity_title:"Cost Assignment By Entity",
    rule_definition:"Rule Definition",
    cost_assignment_by_entity_icon:"This section provides insight into how cost will be assigned across the selected vector entities according to the defined rule and filter in the selected period.",
    rule_footer_titles:{
        total_assigned:"Total Assigned",
        total_variance:"Total Variance",
        maximum:"Maximum",
        minimum:"Minimum (non-zero)",
        averageac:"Average",
        median:"Median",
        total_entities_count:"Total Entities Count",
        entities_assigned_zero:"Entities Assigned Zero",
        perc_entities_assigned_zero:"% Entities Assigned Zero"
    },
    rule_footer_types:{
        total_assigned:"amount",
        total_variance:"amount",
        maximum:"amount",
        minimum:"amount",
        averageac:"amount",
        median:"amount",
        total_entities_count:"numeric",
        entities_assigned_zero:"numeric",
        perc_entities_assigned_zero:"percentage"
    },
    basic_filter:{
        applied_filters_text: "Filters applied:",
        filter_by:" Filter by",
        add_filter:"Add Filter",
    },
    not_defined_value: "Not defined",
    build_model_id: "Each profit stack line has a unique ID.",
    build_model_name: "This shows the name of the profit stack lines from staging profit stack",
    build_model_period_name: "These columns represent the periods. <br> If a period is already built, then it will show a done icon on the relevant profit stack line.<br> If a period is not built yet, then it will show a pending icon on the relevant profit stack line.<br> If a period was built and a change was made on the ID's Rule or Filter, then a failure icon will be shown on the relevant profit stack line.",
    build_model_hide: "Hiding columns will make the periods no longer visible in the table below. <br>Hidden periods will not be taken into consideration when building a model.",
    build_model_confirm: "Some builds still have variances.<br/>Are you sure you want to continue to generate quadrants?",
    build_model_no_built_periods: "You cannot access \"Generate Quadrants\" screen unless you have 3 periods built that lead to a quarter.",
    build_cannot_be_initiated: "Build cannot be initiated. The rule is not yet defined for the following Profit Stack lines:",
    build_generate_quadrant_vector_name: "Vectors that are chosen as visible on the application in Configure Vectors are available here.",
    build_generate_quadrant_quarter_column : "These columns represent the quarters.<br>If quadrants are generated on a vector based on a quarter, then it will show a done icon on the relevant vector.<br>If quadrants are not generated on a vector based on a quarter, then it will show a pending icon on the relevant vector.<br>If quadrants were generated on a vector based on a quarter and a change was made on the vectors, then a failure icon will be shown on the relevant vector.<br>Quarters with missing periods are not clickable.",
    build_generate_quadrant_hide: "Hiding columns will make the quarters no longer visible in the table below.<br>Hidden columns will not be taken into consideration when generating quadrants.",
    no_new_periods_to_be_generated: "You have no new periods to be generated in the fact table.",
    model_sent_for_review:"Model Sent For Review.",
    configure_vectors_handle_row: "Drag and drop the vectors to change their order within the same group.",
    configure_vectors_visibility: "Choose whether vectors will be visible or not.",
    configure_vectors_vector_group: "Select the group of the vector&#58; <br>Customer<br>Product<br>Operations.",
    configure_vectors_max_entity_count:"Total distinct vector entity count.",
    configure_vectors_generate_by:"Choose based on what " + SEGMENT + " will be generated by.",
    configure_vectors_generate_quads:"Choose whether " + SEGMENT + " will be generated or not. You can generate " + SEGMENT + " for up to [Y] vectors.",
    configure_vectors_generate_quads_limit:"You are not allowed to generate " + SEGMENT + " for more than [Y] vectors.",
   configure_cf_costKey: "Each profit stack line has a unique ID.",
    configure_cf_name: "This shows the name of the profit stack lines from staging profit sta ck.",
    configure_cf_costCenter: "Shows whether the Profit Stack line is Cost Center Based or not.",
    configure_cf_filter: "Edit the Profit Stack line to define the rule & filter",
    //"A filter is defined in the lowest level profit stack line.<br>Filter is optional.",
    configure_cf_rule: "Edit the Profit Stack line to define the rule & filter", 
    //"A rule is defined in the lowest level profit stack line.<br>The rule for the parent is the Sum of IDs of its children.<br>The rule should be defined.",
    configure_cf_inProfitMap: "Choose whether to show children under each Profit Stack line or not.",
    configure_cf_amount: "The amounts will be based on the selected period in the period dropdown as per the Profit Stack mapping.",
    configure_cf_is_valid: "This column is used to validate the amount calculated based on the rule and filter defined on each profit stack line against the staged amount.<br>If there were variances, a warning icon will be shown.<br>You can troubleshoot the variances.<br>If there were no variances, then the line will show that it passed.",
    configure_cf_no_more_vectors: "There is not enough distinct vectors for new rows.",
    configure_cf_configured_periods : "Choose the periods on which the defined rule & filter will be applied for the split Profit Stack lines",
    configure_cf_children_access : "Decide which access group can see the children under each Profit Stack line.",
    configure_cf_default : "Choose the default rule & filter for the split Profit Stack lines. The default rule and filter will be automatically used for newly added periods.",
    add_filter_rule :"Add rule & filter",
    select_periods:"Select periods",
    selectPeriods:"Select Periods",
    view_periods:"View periods",
    all: "All",
    multiple: "Multiple",
    find_period : "Find a period",
    find_column:"Find a column",
    show_all: "Show all",
    hide_all: "Hide all",
    select_all:"Select All",
    select_all_basic_filter:"Select all",
    unselect_all :"Deselect All",
    add_column: "Add column",
    settings: "Settings",
    information: "Information",
    your_configuration_model: "Your Configured Model",
    profit_isle_methodology: "Profit Isle Methodology",
    choose_a_scenario: "Choose a Scenario",
    default_rule_filter_deletion:"You cannot delete the default rule & filter.",
    empty_rows:"The rows you added under the following Profit Stack lines are still missing period configuration. ",
    discard_unconfigured_rows:"Saving will discard unconfigured rows. Do you wish to proceed?",
    warning_periods:"Below periods were automatically selected for the default rule and filter {x}",
    default_message:"Deselecting any period will automatically become selected in the default rule & filter.",
    profit_stack_period_name: "Periods with uploaded files are enabled and can be mapped.<br>Periods with no uploaded files are disabled.",
    profit_stack_profit_stack_staging_status: "There are three statuses that a period goes through&#58; <br>Not Staged&#58; this is the first status for a new period.<br>Stage&#58; the period becomes staged after clicking to stage.<br>Invalid&#58; the period becomes invalid when it is staged and some changes occur on mapping.",
    profit_stack_assigned_combination: "This column shows the percentage of assigned combinations compared to the total number of combinations.",
    profit_stack_assigned_amount: "This column shows the percentage of assigned amounts compared to the total amount.",
    profit_stack_excluded_combination: "This column shows the percentage of excluded combinations compared to the total number of combinations.",
    profit_stack_excluded_amount: "This column shows the percentage of excluded amounts compared to the total amount.",
    profit_stack_unassigned_combination: "This column shows the percentage of unassigned combinations compared to the total number of combinations.",
    profit_stack_mapping_dd_title: "The amounts in the table reflect the period chosen.",
    profit_stack_mapping_attribute_title: "Map Attribute Line",
    profit_stack_mapping_attribute_info: "Please define the selected Attribute Profit Stack line. Select either a Vector or a Calculated Column that you created.",
    profit_stack_mapping_attribute_select_title: "Select The Attribute Column",
    profit_stack_mapping_attribute_show_hide: "Click to show or hide the Attribute line in Profit Stack.",
    profit_stack_chart_title: "Here you can see a summary of combinations and amounts statuses displayed as Charts.",
    profit_stack_manage_exclusions_title: "This section is used to select Unassigned Combinations and Exclude them.", 
    profit_stack_manage_exclusions_dd_title: "The amounts in the table reflect the period chosen.",
    profit_stack_map_title: "The table below has a list of&#58;  <br>Combinations already assigned to the chosen profit stack line.<br>All the unassigned combinations.<br>Check the combination(s) to be mapped to the chosen profit stack line.",
    profit_stack_map_selectd_period: "Amounts shown are for the selected period: {x}",
    profit_stack_map_exp_title: " Choose to map using the below options of exception&#58;<br> None (no exceptions)<br>Transaction<br>Ancillary<br>If Transaction is chosen then the Driver query should be defined, a period should be selected and then the query should be parsed to result in a Parsed Amount.<br>If Ancillary is chosen then one of Ancillary file subcategories should be chosen from the list and then the Driver query should be defined, a period should be selected and then the query should be parsed to result in a Parsed Amount.",
    profit_stack_plan_term_title: " Choose to add a plan term using the below options of plan terms ",
    profit_stack_map_cc_title: " This toggle indicates the cost source hierarchy used for the assignment. When the toggle is on,<br> [Cost Center] based costs will be assigned within the corresponding [Cost Center] only.<br> When the toggle is off, the cost source will be interpreted as a corporate or enterprise-wide expense.<br> Note: Make sure all combinations mapped to [Cost Center] based Profit Stack lines are linked to selling cost centers.",
    profit_stack_map_mapped_title: "T stands for Mapped as Transaction<br>A stands for Mapped as Ancillary",
    profit_stack_split_perc: "Split % will split only the matched PS Line percentage based on 100% value and not the percentage of the matched parsed amount.",
    profit_stack_map_filter_title: "The items available under Field drop-down are the columns uploaded under General Ledger.<br>Select one of the items available under Field drop-down list, select an operator, define the value and Apply the filter.",
    profit_stack_map_variance_warning: "This line was mapped as transaction and requires to define the transaction column in InvoiceLine - Mapping & Tie Off",
    profit_stack_map_variance_unmatched: "The difference between the Assigned amount and the Parsed amount {x} will be set as unmatched and the current line will have the parsed amount",
    profit_stack_map_variance_var: "The difference between the Assigned amount and the Parsed amount {x} will be added to the VAR Line and the current line will have the parsed amount",
    profit_stack_map_empty_name: "Profit stack lines names can't be empty.\n",
    profit_stack_map_red_dot: "All Calculated Lines and Attributes indicated by a red dot must be setup.",
    profit_stack_no_file_cost_center: "The selected Ancillary/Metric does not have a [Branch] defined.",
    transaction_no_cost_center: "This line is mapped to Transaction.",
    transaction_mixed_with_psl_by_branch: "One of the lines is mapped to Transaction.",
    profit_stack_link_filter_tooltip: "Filter/Clear linked Profit Stack Lines",
    profit_stack_link_clear_filter_tooltip: "Clear Profit Stack Lines filter",
    profit_stack_link_moved_filter_tooltip: "Filter/Clear moved Profit Stack Line",
    combinationSignageFlipWarning:"This action being taken, results in combinations moving in or out of the Net Revenue parent. All values in the Net Revenue parent are in terms of revenue while all others represent values in terms of expense. Combination signage can be flipped automatically when the selected combinations are moved.",
    automatically_flip:"Automatically flip/revert combination signage",
    select_range: "Select a range to proceed.",
    stage_no_period_selected: "Please select a period.",
    ps_mapping_noassigned_combination: "At least one of the profit stack lines under this parent has no assigned combinations.",
    ps_mapping_not_setup: "Line not fully setup",
    manage_stacks_not_configured: "Line not fully configured",
    manage_stacks_line_not_found: "Line no longer in the scenario",
    ps_mapping_change_period: "You have unsaved changes on this line. Make sure to submit before changing the period. Do you want to continue and lose your changes?",
    ps_mapping_split_type_one:"Lines with the following indicators share a common set of GL combinations and the costs are split across 2 or more Profit Stack lines.",
    ps_mapping_split_type_two:["S%: Line split using a manually defined percentage",
                                "T: Line split using transaction/invoice line data",
                                "A: Line split using values from an ancillary file",
                                "M: Line split using metric values",
                                "A%: Line split using both ancillary and percentage splits",
                                "M%: Line split using both metric and percentage splits",
                                "V: Line sharing the remaining variance value"],
    profit_stack_cost_center_status:"TBD",
    profit_stack_assigned_count:"This column shows the count of assigned combinations.",
    profit_stack_unassigned_count:"This column shows the count of unassigned combinations.",
    profit_stack_excluded_count:"This column shows the count of excluded combinations.",
    profit_stack_total_combination:"This column shows the count of total combinations.",
    used_ps_no_delete_1_attr: " is being used in the following Attribute Profit Stack lines:",
    used_ps_no_delete_1_calc: " is being used in the following Calculated Profit Stack lines:",
    used_ps_no_delete_2: "Kindly edit them to proceed.",
    profit_stack_refresh_amounts: "Please refresh amounts to see details.",
    no_vector_description: "No Description",
    quadrants_not_generated:"Quadrants not generated",
    segments_not_generated:"Segments not generated",
    period_not_generated:"This period isn't generated yet",
    vector_not_exist_for_scenario: "This vector does not exist in Scenario ",
    period_not_built: "This period isn't built yet",
    period_not_activated: "This period is deactivated",

    quarter_not_generated: "This quarter isn't generated yet",
    quarter_not_exist_for_scenario: "This quarter does not exist in Scenario ",
    quarter_not_exist_for_any_scenario: "This quarter does not exist in any scenario.",
    manage_cost_centers:{
        tooltip_title: "This screen allows you to define the state for each available cost center on each period. Cost center definition is an essential step of your building process.",
        status_tooltip:"This column is here to help you spot which periods are missing state definitions and which are defined.",
        cost_center_status_tooltip: "This column is here to help you spot which cost centers are missing state definitions on some periods and which are defined.",
        missing_gl:"Missing General Ledger File",
        missing_tr:"Missing Transaction Files",
        add_state: "Add new state",
        pull_previous_states: "Pull Previous States",
        define_tooltip:"This section allows you to define the state of all the cost centers belonging to [period]",
        cc_define_tooltip:"This section allows you to define state of [cost_center] on all periods.",
        description:"Describe your cost center state",
        empty_type:"Type can't be empty",
        empty_name:"Name can't be empty",
        new:"New",
        new_label: "This cost center appears for the first time on this period",
        warning_state: "This Cost Center has a [x] State in previous Period",
        state_saved_successfully: "New Cost Center State is saved successfully",
        state_saved_error:"Something went wrong while saving new cost center state",
        duplicate: "A Cost Center State already exists with the same name",
        revenue_generating:"Revenue Generating",
        non_revenue_generating:"Non Revenue Generating",
        not_defined_cost_centers:"Some Cost Center States are not defined yet.",
        not_defined_periods:"Some Periods States are not defined yet.",
        select_cost_center_state:"Select cost center state",
        select_state:"Select State",
        what_select_state:"What is the cost center state ?",
        state:"State",
        select_state_message: "Select a cost center state before applying",
        bulk_define_tooltip: "This section allows you to define the states of all the cost centers simultaneously for all periods.",
        bulk_define_title: "all periods",
        defined_periods_title: "Defined Periods:",
        undefined_periods_title: "Undefined Periods:",
        defined_cost_centers_title: "Defined Cost Centers:",
        undefined_cost_centers_title: "Undefined Cost Centers:",
        warning: "Periods [X] are partially defined, and they are still missing some cost centers definition. In order to be able to save, all cost centers within a period must be defined.",
        show_amounts_using: "Show amounts using",
        actual_data:"Actual data",
        budget_data:"Budget data from scenario",
        select_budget_scenario:"Select Budget Scenario",
        no_budget_scenario:"No budgeting scenario available"
    
    },
    psl_config: {
        title: "Profit Stack Line Configuration"
    },
    ps_mapping: {
        sign_tooltip:"",
        flipSign: "Flip Sign",
        left_section: {
            title: "Profit Stack Lines"
        },
        map_section_title: {
            calculated: "Map Calculated Line - "
        },
        title_tooltip: {
            calculated: "Please define the selected Calculated Profit Stack line. You can drag Profit Stack lines and drop them into the formula box."
        },
        select_vector_or_calculated_line:"Select a vector or a calculated column.",
        attrtibute:{
            hide_attribute:"Hide Attribute",
            show_attribute: "Show Attribute",
        },
        enter_name:"Enter Name",
    },
    psl_placeholders: {
        standard_line_name:"Name your Profit Stack Line",
        calculated_line_name:"Name your Calculated line",
        calculated_line_description:"Describe your Calculated line",
        attribute_line_name:"Name your Attribute line",
        attribute_line_description:"Describe your Attribute line",
    },
    psl_tooltip:{
        fy_cost:"FY Cost",
        by_branch:"By Branch",
        percentage:"Percentage",
        amount:"Amount",
        profit_stack_line:"Profit Stack Line: ",
        gl_cost_source:"GL Cost Source",
        cost_function:"Cost Function",
        rule:"Rule: ",
        filter:"Filter: ",
        none_value: "None",
        plan_term:"Plan Term",
        more_details: "More Details",
        description: "Description",
        assignment: "Assignment ",
        split: "Split",
        variance: "Variance %"
    },
    stage_tables_alerts:{
        define_expected: "Make sure all expected files are uploaded and defined",
        map_lines: "You should be mapping all your lines before staging",
        stage_invoiceline: "Please make sure that InvoiceLine is staged.",
        define_cc_state: "Please define your cost center state and re-check mapping."
    },
    search_vectors_placeholder:"Search Vector",
    pss_mapping_search_placeholder:"Search Profit Stack Lines",
    pss_mapping_title: "Every lowest level child under the profit stack should be mapped to one or more combinations. <br>Use the map icon available next to each child to do the mapping.<br>After mapping each profit stack line to a combination(s) you can find its relevant Amount, Filter and Cost Center.<br>The order icon can be used to change the order of profit stack lines.",
    pss_map_exception: {
        titles: {
            exception_driver: "Split Type",
            file: "Filename",
            column: "Column",
            psl_name: "PSL Name",
            entity: "Entity",
            percentage: "Percentage",
            parsed_amount: "Parsed Amount",
            plan_term: "Plan Term",
            calculated_column: "Calculated Column"
        },
        button: {
            add_ancillary: "Add Ancillary/Metric",
            add_transaction: "Add Calculated Column",
            split_perc: "Split",
            add_filter: "Add Filter",
            edit_filter: "Edit Filter",
            add_more: "Add more",
        },
        suffixes: {
            accrual: "- Accrual",
            matched: "",
            unmatched: "(unmatched)",
            variance: " Variance"
        },
        text: {
            will_receive: "Will receive",
            will_receive_remaining: "Will receive ",
            will_receive_unmatched: "Will receive "
        },
        totals:{
            total_count:"Total Count",
            total_amount:"Total Amount",
            selected_count:"Selected Count",
            selected_amount:"Selected Amount",
        },
        labels:{
            none:"None",
            other:"Ancillary/Metric/Transaction"
        }
    },
    assigned_to_pss_filter_label :"Assigned To",
    split_type_label:"Split Type",
    filter_combinations:"Filter Combinations",
    not_staged_metric: "Used metric in this profit stack line for the selected Period is not Staged. Go stage the metrics for this period in order to show amounts.",
    calc_col_file_missing_in_period: "Missing Transaction file for selected period ",
    vector_table_title: "Only vectors with status 'Not Staged' and 'Invalid' available under the selected period will be staged.",
    vector_map_edit_used_vector_err: " is used in cost function configuration, any change will require rebuilding all affected profit stack lines.",
    vector_map_delete_used_vector_err: " is used in cost function configuration. Update the configuration to delete this vector.",

    invoiceLine_table_title: "\u2022 All defined mandatory and optional transaction columns under the selected periods will be staged.<br>\u2022 All vectors under the selected periods will be staged.",

    configure_intro_faqs: [{ title: "How to configure a vector?", content: "Duis aute irure dolor in reprehenderit in vol" },
    { title: "What is a Cost Function?", content: "Duis aute irure dolor in reprehenderit in vol" },
    { title: "What is a Rule?", content: "Duis aute irure dolor in reprehenderit in vol" },
    { title: "How to use Rule and Filter to configure cost function?", content: "Duis aute irure dolor in reprehenderit in vol" }],

    build_intro_faqs: [{title: "How to build a model?", content: "Duis aute irure dolor in reprehenderit in vol"},
        { title: "How to generate quadrants?", content: "Duis aute irure dolor in reprehenderit in vol" }],
    
    metrics_table_title: "This section is for mapping Metrics. <br>Stage will be disabled until all expected Ancillary Files are uploaded.",
    metrics_period_name: "Periods with uploaded files are enabled and can be mapped.<br>Periods with no uploaded files are disabled.",
    metrics_metric_staging_status: "There are three statuses that a period goes through&#58;<br>Not Staged&#58; this is the first status for a new period.<br>Stage&#58; the period become staged after clicking to stage.<br>Invalid&#58; the period becomes invalid when it is staged and some changes occur on mapping",
    metrics_completed_metrics: "This column shows the percentage of assigned metrics from total metrics.",
    metrics_mapping_title: "This page is used for creating Metrics and mapping them.",
    metrics_map_title:"This page is used to define the metric name, the ancillary file its related to, vector and cost center.",
    metrics_map_dd_title: "The period selected reflects the amounts available under the table below.",
    metrics_map_cc: "Please choose if the cost is split by Cost Center or not (equally).",
    metric_mapping_primary_key_not_in_vector: "{y} entities in file {x} match.",
    metric_mapping_sample_data: "Sample data (1000 records)",
    metric_mapping_description_placeholder:"Describe your metric",
    calculated_mapping_description_placeholder:"Describe your Calculated Line",
    standard_mapping_description_placeholder:"Describe your Profit Stack Line",
    vector_mapping_description_placeholder:"vector description",
    load_entity_count:"Load entity count",
    load_total_amount:"Load total amount",
    load_metric_value:"Load metric value",
    load_total_values:"Load total values",
    metrics_mapping_header_period_description: "Values shown are for the selected period: ",
    metrics_mapping_configuration_title: "Metric Configuration",
    metrics_mapping_value_column_title: "Value Columns",
    metrics_mapping_file_name_title: "File Name",
    metrics_mapping_value_columns_title: "Value Columns",
    metrics_mapping_metric_configuration_delete_used_row: "The following filter, and/or case condition, and/or formula are associated with the file name row you are attempting to delete:",
    metrics_mapping_join_column_match: "Which column matches the metric vector in ",
    metrics_mapping_md_td: "{x} vector will be {y} similarly to the metric vector.",
    metrics_metric_value_formula_title: "Metric Value Formula",
    metrics_delete_condition_button: "Delete Condition",
    performing_match_analysis: "Performing match analysis",
    build_model_no_period_selected_alert: "Make sure to select periods.",
    build_model_consecutive_periods_alert: "Please select consecutive periods.",
    build_model_not_staged_metric_vector: "You have unstaged metrics/vectors",
    build_model_unselected_costkeys_1: "All the Ids of ",
    build_model_unselected_costkeys_2: " should be checked.",
    build_model_leaves_without_rule: "You have costkeys with missing rules. Build cannot be completed.",
    build_model_no_quadrant_selected_alert: "Make sure to select quarters.",
    stage_published_scenario: "You can not stage a published or a scenario under review.",
    stage_unsaved_changes:"This action will discard your unsaved changes. Do you want to save first?",
    build_periods_costKeys: "Make sure to have all costkeys ready to be built.",
    stage_save_proceed:"Save & Proceed",
    name_starting_number: "The name cannot start with a number.",
    name_used:"This name is already used.",
    name_reserved:"This name is already used.",
    rename_line:"There is already a PS line with the same name. Do you want to rename this line to [EXISTING_NAME] ?",
    discard_changes_confirmation: "Your changes will be discarded, are you sure you want to continue?",
    discard_unsaved_changes:"This action will discard your unsaved changes.",
    build_is_running: "You can't switch clients while a build is running",
    no_uploaded_files: "You haven't uploaded any files yet, press on the upload button on the top right of this table to intitiate the upload process",
    drag_and_drop_files: "Drag and drop one file here or press Upload File button.",
    uploaded_file_gpg_extension: "Your encrypted file should have .gpg extension",
    mapped_metric_cant_be_edited:"You can’t edit a metric’s name that has been mapped to a Cost Function Rule. Metric {x} is mapped to costkeys {y}.",
    mapped_metric_cant_be_edited_2:"You can't edit a metric's name that is being used in a Profit Stack Line. Metric {x} is used in lines {y}.",
    fill_empty_fields: "Please make sure to fill all fields.",
    fill_empty_field: "Please make sure to fill scenario name",
    mapped_metric_cant_be_deleted:"You can’t delete a metric’s name that has been mapped to a Cost Function Rule. Metric {x} is mapped to costkeys {y}.",   
    mapped_metric_pss_cant_be_deleted:"{x} is being used in the following Profit Stack lines:{y}",   
    confirm_boolean_selection:"All values will be converted to true and false. This will result the loss of all the values within this column and they can no longer be reverted back. Are you sure you want to continue?",
    fill_all_period_fields: "Make sure to fill all fields before proceeding.",
    start_data_less_than_end_date: "Make sure that start date is less than end date",
    period_inactive_message: "period {x} will be inactive. Are you sure you want to continue?",
    period_inactive_message_undo: "you can undo this action.",
    reset_warning: "This action will discard your unsaved changes.",
    active_periods: "Active Periods: ",
    first_period_name:"What is the first period of your data?",
    enter_valid_dates:"Make sure to enter valid and not overlapping dates.",
    enter_non_existing_period:"Make sure to enter a non existing period.",
    current_period_less_than_previous:"Make sure to enter a period greater than the first period created.",
    no_values_are_true: "No values are true",
    no_values_are_credit: "No values are 'credit'",
    invalid_periods_range:"Selected Period Range is invalid.",
    upload_file:"Make sure to upload a file.",
    toggle_off:"The selected Ancillary File does not have a cost center column defined. The By [Branch] switch button will be turned off and disabled. Do you wish to continue?",
    toggle_off_branch:"The selected metric vector is the [Branch] vector. Therefore, the By [Branch] switch button is turned off and disabled.",
    toggle_off_tooltip:"The selected Ancillary File does not have a cost center column defined. Therefore, the By [Branch] switch button is turned off and disabled.",
    toggle_rule_tooltip:"Metric is being used in a rule. Therefore, the By [Branch] switch button is disabled.",
    configure_vectors_loader_msg :"Vector information is being analyzed to figure out whether " + SEGMENT + " should be generated or not. This may take up to a minute.",
    ui_filter: {
        quadrant_timeline_warning: "Data points on the graph are filtered for {x} full year quadrant.",
        col_names: {
            typeManage:"Type",
            type: "File Name",
            vector: "Vector",
            field: "Column",
            calcField:"InvoiceLine Column",
            function: "Function",
            value: "Value",
            from: "From",
            to: "To",
            total_lines: "Total Lines",
            total_invoices: "Total Invoices",
            total_revenue: "Total Revenue",
            total_cogs: "Total COGS",
            fieldVector: "Field"
        },
        dropdowns: {
            type: {
                vector: "Vector",
                profit_stack: "Profit Stack"
            },
            buttons: {
                AND: "AND",
                OR: "OR",
                ADD: "ADD"
            },
            months: {
                mo3: { label: "3 Mo", value: "OFF" },
                mo12: { label: "12 Mo", value: "ON" }
            },                
            functions: {
                equals: {value:"EQ", label:"Equals", value_type: "string", quadrant_option: "true"},
                not_equals: {value:"NEQ", label:"Does Not Equal", value_type: "string", quadrant_option: "true"},
                contains: {value:"CT", label:"Contains", value_type: "string", quadrant_option: "false"},
                not_contains: {value:"NCT", label:"Does Not Contain", value_type: "string", quadrant_option: "false"},
                empty: {value:"EMP", label:"is Empty", value_type: "string", quadrant_option: "false"},
                not_empty: {value:"NEMP", label:"is Not Empty", value_type: "string", quadrant_option: "false"},
                equalsNUM: {value:"EQ", label:"Equals", value_type: "numeric", quadrant_option: "true"},
                not_equalsNUM: {value:"NEQ", label:"Does Not Equal", value_type: "numeric", quadrant_option: "true"},
                greater: {value:"GT", label:"is Greater Than", value_type: "numeric", quadrant_option: "false"},
                less: { value: "LT", label: "is Less Than", value_type: "numeric", quadrant_option: "false" },
                greater_or_equalsNUM: {value:"GOE", label:"is Greater Than Or Equal", value_type: "numeric", quadrant_option: "false"},
                less_or_equalsNUM: {value:"LOE", label:"is Less Than Or Equal", value_type: "numeric", quadrant_option: "false"},
                betweenNUM: {value:"BTWN", label:"is Between", value_type: "numeric", quadrant_option: "false"},
                start_with: {value:"SW", label:"Starts With", value_type: "string", quadrant_option: "false"},
                end_with: {value:"EW", label:"Ends With", value_type: "string", quadrant_option: "false"},
            },
            engine_filter_functions: {
                equals: {value:"EQ", label:"Equals", value_type: "string", quadrant_option: "true"},
                not_equals: {value:"NEQ", label:"Does Not Equal", value_type: "string", quadrant_option: "true"},
                contains: {value:"CT", label:"Contains", value_type: "string", quadrant_option: "false"},
                not_contains: {value:"NCT", label:"Does Not Contain", value_type: "string", quadrant_option: "false"},
                greater: {value:"GT", label:"is Greater Than", value_type: "numeric", quadrant_option: "false"},
                less: { value: "LT", label: "is Less Than", value_type: "numeric", quadrant_option: "false" },
                empty: {value:"EMP", label:"is Empty", value_type: "string", quadrant_option: "false"},
                not_empty: {value:"NEMP", label:"is Not Empty", value_type: "string", quadrant_option: "false"},
                starts_with: {value:"SW", label:"Starts With", value_type: "string", quadrant_option: "false"},
                not_starts_with: {value:"NSW", label:"Does Not Start With", value_type: "string", quadrant_option: "false"},
                ends_with: {value:"EW", label:"Ends With", value_type: "string", quadrant_option: "false"},
                not_ends_with: {value:"NEW", label:"Not Ends With", value_type: "string", quadrant_option: "false"},
                equalsDT: {value:"EQ", label:"Equals", value_type: "datetime", quadrant_option: "true"},
                not_equalsDT: {value:"NEQ", label:"Does Not Equal", value_type: "datetime", quadrant_option: "true"},
                equalsNUM: {value:"EQ", label:"Equals", value_type: "numeric", quadrant_option: "true"},
                not_equalsNUM: {value:"NEQ", label:"Does Not Equal", value_type: "numeric", quadrant_option: "true"},
                equalsBool: {value:"EQ", label:"Equals", value_type: "boolean", quadrant_option: "true"},
                not_equalsBool: {value:"NEQ", label:"Does Not Equal", value_type: "boolean", quadrant_option: "true"},
            },
            engine_filter_functions_psl_definitions: {
                equals: {value:"EQ", label:"is", value_type: "string", quadrant_option: "true"},
                not_equals: {value:"NEQ", label:"is not", value_type: "string", quadrant_option: "true"},
                contains: {value:"CT", label:"contains", value_type: "string", quadrant_option: "false"},
                not_contains: {value:"NCT", label:"does not contain", value_type: "string", quadrant_option: "false"},
                greater: {value:"GT", label:"is greater than", value_type: "numeric", quadrant_option: "false"},
                less: { value: "LT", label: "is less than", value_type: "numeric", quadrant_option: "false" },
                empty: {value:"EMP", label:"is empty", value_type: "string", quadrant_option: "false"},
                not_empty: {value:"NEMP", label:"is not empty", value_type: "string", quadrant_option: "false"},
                starts_with: {value:"SW", label:"starts with", value_type: "string", quadrant_option: "false"},
                not_starts_with: {value:"NSW", label:"does not start with", value_type: "string", quadrant_option: "false"},
                ends_with: {value:"EW", label:"ends with", value_type: "string", quadrant_option: "false"},
                not_ends_with: {value:"NEW", label:"does not end with", value_type: "string", quadrant_option: "false"},
                equalsDT: {value:"EQ", label:"is", value_type: "datetime", quadrant_option: "true"},
                not_equalsDT: {value:"NEQ", label:"is not", value_type: "datetime", quadrant_option: "true"},
                equalsNUM: {value:"EQ", label:"is", value_type: "numeric", quadrant_option: "true"},
                not_equalsNUM: {value:"NEQ", label:"is not", value_type: "numeric", quadrant_option: "true"},
                equalsBool: {value:"EQ", label:"is", value_type: "boolean", quadrant_option: "true"},
                not_equalsBool: {value:"NEQ", label:"is not", value_type: "boolean", quadrant_option: "true"},
            },
            fields_attributes: {
                name: {label: "Name", value: "name"},
                number: {label: "Number", value: "number"},
                quadrant: {label: "Quadrant", value: "quadrant"},
                quadrant_tier: {label: "Quadrant Tier", value: "quadrant/tier"},
            },
            and:"and",
        },
        clear_all: "Clear",
        titles: {
            invalid_rows: "Invalid Rows",
            discard_changes: "Discard Changes",
            saved_filter_used_in_custom_views: "Filter being used in Custom Views",
            empty_filter: "Empty Filter",
            forget_filter: "Forget Filter",
            clear_filter: "Clear Filter",
        },
        messages: {
            invalid_rows: "You have some invalid rows in your filter, proceeding will discard any incomplete rows, are you sure?",
            discard_changes: "Are you sure you want to cancel?<br/>You have unsaved changes.",
            saved_filter_used_in_custom_views: "This filter is currently being used in one or more Custom Views, changing it will alter the behavior of those views, are you sure you want to proceed ? ",
            empty_filter: "You have no valid rows in your filter, nothing to save.",
            forget_filter: "Are you sure you want to completely remove filter ",
            clear_filter: "Are you sure you want to clear this filter? <br/>You cannot undo this action.",
            saved_filter_used_in_custom_views_disable_after: "This Filter is being used by a Custom view",
            invalid_filter:"You can not use the OR operator between two different file names."
        }
    },
    filter_text: {
        WHEN:"When",
        THEN:"Then",
        ELSE:"Else",
        WHERE_THE: "Where the ",
        WHERE:"Where ",
        AND_THE: "the ",
        OPERATOR: {
            EQUALS: "is equal",
            NOT_EQUALS: "is not equal",
            CONTAINS: "contains",
            NOT_CONTAINS: "does not contain",
        },
        no_results_found: "No results found.",
        fetching_results: "Fetching results...",
        main_quarter_used: "Default period taken for the quadrant filters is the main period selected when the filter was applied.",
        filter_for: "Filter for ",
        filter:"Filter"
    },
    rule_text: {
        TO_THE: "To the ",
        THEN_TO_THE: " then to the ",
        BY: " by "
    },
    BUILD:{
        ERROR:{
            MERGE_ERROR: "There was an error while saving your data.",
        },
        BUILD_COMPLETED: "Build completed.",
        QUADRANT_UPDATE_COMPLETED: "Quadrant update completed.",
        SOMETHING_WENT_WRONG_WHILE_MERGING: "Something went wrong while merging data. Please contact your System Administrator."
    },

    VECTOR_MAPPING: {
        VECTOR_NAME_QUEST: "What is the vector name?",
        VECTOR_NAME_DESCRIPTION: "What is the vector description?",
        CHOOSE_KEY_FIELD: "What is the column name in the chosen file?",
        CHOOSE_NUMBER_FILE: "What is the file used to map the vector number?",
        CHOOSE_KEY_FILE: "What is the file used to map the vector ID?",
        CHOOSE_NUMBER_FIELD: "What is the column name in the chosen file?",
        CHOOSE_NAME_FIELD: "What is the column name in the chosen file?",
        CHOOSE_NAME_FILE: "What is the file used to map the vector name?",
        SAME_AS_KEY: "Same as ID",
        VECTOR_NAME_ALREADY_USED:" Name already used.",
        fetch_missing_entity: "Do you want the missing entity name/number to be fetched from previous periods?"
    },
    mapping_tie_off: "Mapping & Tie Off",
    mapping_tie_off_filter_formula: "Totals by exclusions are grouped based on brackets in advanced filter. Make sure to group your filter by brackets the way you need to preview the totals.",
    calculated_columns: "Calculated Columns",
    cost_center_state: "Define Cost Center States",
    vectors: "Vectors",
    loader_first_message: "This might take several minutes",
    loader_second_message: "Please wait...",
    empty_calculated_fields: "Please make sure to fill column name and column formula",
    CostKeyNoFilter: "The Cost Function you have configured is attempting to assign cost to a Cost Center that is not present in the transaction dataset.<br><br> The cost you are trying to assign will be excluded from the scenario as there are no corresponding transactions to assign the cost to.<br><br> There are 3 options&#58 <br>1- Remove the line for this cost center in the ancillary file and re-upload the file <br>2- Exclude this cost center from the metric configuration or <br>3- Accept this variance and continue.",
    CostKeyFilter: "The Cost Function you have configured is attempting to assign cost to a Cost Center that is present in the transaction dataset, but there are no lines that meet the filter requirements.<br><br> The expense you are trying to assign will be excluded from the scenario as there are no corresponding transactions to assign the cost to.<br><br> There are 4 options&#58<br> 1- Remove the line for this cost center in the ancillary file and re-upload the file 2- Exclude this cost center from the metric configuration <br>3- Change the filter requirements for the Cost Function or <br>4- Accept this variance and continue.",
    countCostKeyFilter: "The Cost Function you have configured is attempting to assign cost, but there are no lines that meet the filter requirements.<br><br> The expense you are trying to assign will be excluded from the scenario as there are no corresponding transactions to assign the cost to.<br><br> There are 2 options&#58 <br>1- Change the filter requirements for the Cost Function or <br>2- Accept this variance and continue. ",
    CostKeyMetric: "The Cost Function you have configured is attempting to assign cost to a Cost Center that is present in the transaction dataset, but has no non-zero metric values.<br> The expense you are trying to assign will be excluded from the scenario as there are no corresponding non-zero metrics associated with this Cost Center.<br><br>There are 4 options&#58<br> 1- Review and correct the metrics defined <br>2- Upload a new ancillary file with new values that result in non-zero metrics for this Cost Center or <br>3- Accept this variance and continue.",
    ProfitStack : "Profit Stack",
    non_profit_stack_assigned_amount:"Please make sure to assign combinations.",
    empty_mapped_values:"Please make sure to fill all inputs and dropdowns.",
    mapped_line_not_present:"Please make sure that the line being edited is selected among the dropdowns.",
    percentages_do_not_sum:"Your percentages, excluding unmatched row, must sum up to 100.",
    inputted_amount_limit:"Inputted amount should not be higher than parsed amount.",
    percentages_0_perc:"Every row must have a percentage greater than 0.",
    intersection_in_filters:"You have selected the same ancillary/metric file multiple times. Please make sure to add a filter for each with no intersections",
    mapped_line_deleted:"The line you are mapping must be selected in of the PSL name dropdowns.",
    scenarios: "Scenarios",
    range:"Range",
    under_review_scenario_banner: "You are currently viewing a scenario under review",
    conditions_not_met_banner : "All the combinations must be either assigned or excluded and all the Profit Stack lines indicated by a red dot must be setup.",
    eligible_period_banner: "Some active periods are still not eligible to be built.",
    SCENARIOS:{
        LABEL: "Scenario",
        STATUS:{
            PUBLISHED: 'PUBLISHED',
            SANDBOX: 'SANDBOX',
            REVIEW: 'REVIEW'
        }
    },
    DIMENSIONS: {
        FIRST: {
            LABEL: "One Vector",
            VALUE: "oneDimension",
            DROP_DOWN_LABEL: "Vector",
            FORMAT_OPTION: "One Dimension"
        },
        SECOND: {
            LABEL: "Two Vectors",
            VALUE: "twoDimensions",
            DROP_DOWN_LABEL: "Second Vector",
            FORMAT_OPTION: "Two Dimensions"
        },
        THIRD: {
            LABEL: "Three Vectors",
            VALUE: "threeDimensions",
            DROP_DOWN_LABEL: "Third Vector"
        },
        HEATMAP: {
            FIRST: {
                DROP_DOWN_LABEL: "Row Vector"
            },
            SECOND: {
                DROP_DOWN_LABEL: "Column Vector"
            },
        },
        CONTOURMAP: {
            FIRST: {
                DROP_DOWN_LABEL: "X-axis Vector"
            },
            SECOND: {
                DROP_DOWN_LABEL: "Y-axis Vector"
            },    
        },
        LABEL: "Dimensions",
        GENERATE: "Generate"
    },
    LIST_ORDER: {
        TOP: {label: "Top", value: "DESC"},
        BOTTOM: {label: "Bottom", value: "ASC"}
    },
    TLC_SELECTION:{
        VIEWS:[
            {
                value:"margin_view",
                label:"Margin View"
            },
            {
                value:"cost_view",
                label:"Cost View"
            },
        ],
        LENSES:[
            {
                value:"total",
                label:"Total"
            },
            {
                value:"profit_segment",
                label:"Profit Segment",
                abv:"Segment"
            },
            {
              value:"department",
              label:"Department"
            },
            {
                value:"channel",
                label:"Channel"
            },
        ]
    },
    COST_CENTER_VIEW :{
        PERIODS:{label: "Periods", value: "periods"},
        COST_CENTER:{label: "Cost Centers", value: "cost_centers"}
    },
    count: {
        entity_count: {label:"Entity Count", value:"entity_count"},
        count_per_total: {label:"Count Per Total", value:"count_per_total", tooltip:"Percent of total entities, filters not considered"},
    },
    sum: {
        entity_count: {label:"Value", value:"entity_count"},
        count_per_total: {label:"Per Total", value:"count_per_total", tooltip:"Percent, filters not considered."},
    },
    total: {
        entity_count: {label:"Value", value:"value"},
        count_per_total: {label:"Per Total", value:"per_total", tooltip:"Percent, filters not considered."},
    },
    other_per_vector : {
        average: {label:"AVG",value:"average"},
        median:  {label:"MED",value:"median"},
        range: {label:"RNG",value:"range"},
        minimum: {label:"MIN",value:"minimum"},
        maximum: {label:"MAX",value:"maximum"}
    },
    other_per_line_attribute: {
        average: {label:"AVG",value:"average"}
    },
    arrow: {
        green_up: {label:"", value:"green_up",iconClass:"arrow-green-up-red-down",tooltip:"Standard Logic"},
        red_up: {label:"", value:"red_up",iconClass:"arrow-red-up-green-down",tooltip:"Reversed Logic"}
    },
    heatmap_configure: {
        configure: "Configure",
        select_ps_line: "Select Profit Stack Line",
        threshold: "Threshold",
        thresholds: "Thresholds",
        row_vector_view: "Row Vector View Settings",
        column_vector_view: "Column Vector View Settings",
        x_axis_vector_view: "X-axis Vector View Settings",
        y_axis_vector_view: "Y-axis Vector View Settings",
        x_axis_entities: "X-axis Entities",
        y_axis_entities: "Y-axis Entities",

        view_settings: [
            {label: "Default", value: "_default"},      //using "_" cz default is a keyword on API and cant be used in an enum
            {label: "Entities", value: "entities"},
            {label: "Quadrant", value: "quadrant"},
            {label: "Quadrant Tier", value: "quadrant_tier"},
        ],
        entities: {
            select_entities: "Select Entities",
            search_entities: "Search Entities",
            grouped_entities: "Grouped Entities",
            grouped_entities_desc: " (Grouping of the remaining selected entities)"
        },
        view_groups: {
            default: {label: "Default", value: "_default"},     //"_" bcz "default" is a keyword on API
            entity: {label: "Entity", value: "entity"},
            quadrant: {label: "Quadrant", value: "quadrant"},
            quadrant_tier: {label: "QuadrantTier", value: "quadrant_tier"}
        }
    },
    UPLOAD: {
        file_upload_success: "File has been uploaded!",
        file_upload_success_p_and_l: "Uploaded File Information",
        file_define_success: "File has been defined!",
        file_upload_fail: "File failed upload.",
        file_define_fail: "File definition failed.",
        file_being_validated: "File is being validated.",
        please_wait: "Please wait, this may take few minutes...",
        define_successful: "Your file has been defined successfully.",
        deleting: "Deleting",
        selected_files_will_be_deleted: "will affect all the scenarios using this crosswalk links. Are you sure you want to continue?",
        all_selected_files_will_be_deleted:" will result the loss of this subcategory definition. Are you sure you want to proceed?",
        file_exists_under_period: "A file already exists under the selected Period. Are you sure you want to replace it?",
        file_upload_encryption_warning: "You decided to upload an unencrypted file. We strongly recommend encrypting all data prior to uploading",
        master_join_data_column: "Transaction Data Column",
        master_join_data_file_name: "Transaction Filename",
        ancillary_join_data_column: "Transaction/Master Data Column",
        ancillary_join_data_file_name: "Transaction/Master Data Filename",
        file_replaced:"The previously chosen file will be replaced. Are you sure you want to continue?",
        unique_entities:"- At least one column must have unique entities.",
        encrypted_file_extension: "- Your encrypted file must have .gpg extension.",
        not_defined_crosswalk:"You did not define your Crosswalk file yet.",
        add_crosswalk: "Start by adding a link.",
        define_crosswalk: "Define Crosswalk",
        add_link: "Add link",
        start_period: "Start Period",
        end_period: "End Period",
        define_message_crosswalk:"Define your crosswalk file and set its time-frame.",
        source_message:"This is the grouping in which you select the subcategory column that needs a crosswalk.",
        source_subcategory:"Select the source subcategory.",
        source_column:"Select the source subcategory column.",
        source_crosswalk:"Select the crosswalk column that matches the source subcategory column. Only columns with unique entities will be shown in this drop-down list.",
        source_entity_matching:"Indicates how many distinct Source Subcategory column entities match the Source Crosswalk column entities. Select the time-frame periods, Source Subcategory Column and Crosswalk Column to see the result.",
        target_message:"This is the grouping in which you select the subcategory column you want to match the source column to.",
        target_subcategory:"Select the target subcategory.",
        target_column:"Select the target subcategory column.",
        target_crosswalk:"Select the crosswalk column that matches the target subcategory column.",
        target_entity_matching: "Indicates how many distinct Target Subcategory column entities match the Target Crosswalk column entities. Select the time-frame periods, Target Subcategory Column and Crosswalk Column to see the result.",
        undefined_period:"Please make sure to fill the period dropdown.",
        correct_periods:"Please make sure start period is less than end period.",
        empty_drop_down:"Please make sure to fill all empty dropdowns.",
        same_source_target_file:"You are not allowed to select the same column as source and target subcategory column.",
        same_source_target_subtype:"You are not allowed to select the same subcategory as source and target.",
        same_source_target_crosswalk:"You are not allowed to select the same column as source and target crosswalk column.",
        same_source_multi_times:"You are not allowed to select the same column as source in more than one link.",
        not_unique_target_column:"You are not allowed to select a target column that is not unique when choosing a primary key source column.",
        delete_link_confirmation: "Deleting this link will affect all the scenarios using it. ",
        matchingEntity_title: "In the below table, you can see a comparison between the entities of the subcategory column and the crosswalk column.",
        same_source: "[xcolumnsx]  columns are already linked more than once for the same range of periods. Those links exist in [xfilesx] crosswalk files",
        crosswalk_column:" is a crosswalk column.",
        no_links_defined: "You don't have any links defined.",
        links_saved_success: "Your links have been saved successfully.",
        define_period_or_date: "You must define only one of the following mandatory fields: Period or Date.",
        configure:"Configure",
        configure_modal_title: "Configure Currency Conversion - ",
        currency_subcategory: "Currency Subcategory",
        transaction_currency: " Currency",
        date_currency: " Date",
        date_currency_tooltip: "This dropdown will only show the columns defined as DATETIME data type.",
        conversion_currency :"Conversion Currency",
        entity_matching_summary_tooltip_1: "In the below table, you can see a comparison between the entities of the Currency Subcategory column and the selected ",
        entity_matching_summary_tooltip_2: " Currency column. Make sure all the currencies have a match.",
        static_currency: "Static Currency",
        currency_column: "Currency Column",
        entity_matching: "Entity Matching Summary",
        missing_configuration:"At least one of the columns using currency conversion is not yet configured. The configure button is highlighted in red; make sure to configure it.",
        changed_type: "The {x} Currency column cannot be of type {y} because it is used as the {x} Currency column in one of the currency conversion configurations and it must be of string type.",
        changed_type_datetime: "The data type of the DATETIME column in at least one of the currency conversion configurations has changed. You must reconfigure it to proceed.",
        fill_mandatoryFields: "Make sure to fill all drop downs",
        read_matching_entity_new_arch: "Read matching entity from new arch "
    },
    define_crosswalk_table: {
        source: "Source",
        target: "Target",
        subcategory: "Subcategory",
        subcategory_column: "Subcategory Column",
        crosswalk_column: "Crosswalk Column",
        entity_matching: "Entity Matching",
        action: "Action",
        is_old_arch: "Is Old Arch"
    },
    name_validation: {
        already_used: "Name already used.",
        invalid_name: "Name is not valid.",
    },
    formula: {
        title: "Formula",
        tooltip: "TBD",
        text_standard: "Drag and drop columns here",
        text_ps_mapping: "Drag and drop lines here from the \"Profit Stack Lines\" table on the left",
        text_ps_mapping_section: "Drag and drop lines here from the \"Profit Stack Lines\" section on the left",
        // text_cpsl:"Drag and drop lines here from the above \"Profit Stack Lines\" section",
        text_cpsl:"Click on the numbers in the sections above to populate the formula.",
        invalid_formula: "Your formula is not well formed.",
        formula_drop: {
            formula: "Formula value",
            formula_true: "Formula value if true",
            formula_false: "Formula value if false",
            wrong_formula: "Formula is wrong"
        }
    },    
    control_btns: {
        title: "Control Buttons",
        title_nmpd: "Keypad",
    },
    unused_columns_will_be_deleted_single: " will be discarded because it is not used in any formula. Do you wish to continue?",
    // metrics_duplicate_value_columns: " will be discarded because it is inserted multiple times. Do you wish to continue?",
    metrics_duplicate_value_columns: "You cannot use this column because it was used before.",
    unused_columns_will_be_deleted_many: " will be discarded because they are not used in any formula. Do you wish to continue?",
    calc_cols: {
        fill_condition_correctly_err: "Your condition is not well formed.",
        column_formula_mismatch: "The columns used in the formulas do not match the created ones. Kindly reset your formulas and refill them with the new columns.",
        calculated_column_title: "Create Calculated Column",
        calculated_column_available: "Available Columns",
        placeholder_name: "Name your calculated column",
        placeholder_description: "Describe your calculated column",
        missing_name: "Please add a name to the created column.",
        empty_columns: "Please fill all added column dropdowns.",
        used_column: "Deleting this column will result in resetting the formula value that contains it. Are you sure you want to continue?",
        delete_confirmation: "Are you sure you want to delete ",
        col_used_mapping_tieoff: " is used in Mapping & Tie Off section. Update the configuration to delete this column.",
        col_used_by_another_col: " cannot be deleted because it is used in the creation of another column.",
        col_used_in_mapping: "This column is used in profit stack mapping.",
        condition: "Condition",
        mapping: {
            mapping_type: {
                basic: "Basic",
                advanced: "Advanced"
            },
            columns_container: {
                title: "Columns",
                tooltip: "TBD",
                add_column: "Add column",
                file_name: "File name",
                file_name_tooltip: "TBD",
                column_name: "Column name",
                column_name_tooltip: "TBD"
            }
        }
    },

    COMMON: {
        LIST: "List",
        CREATE_FILTER: "Filter",
        OF: " of ",
        FROM: "From",
        TO: "To",
        BY: " by ",
        sure_continue: "Are you sure you want to continue?",
        sure_proceed:"Are you sure you want to proceed?",
        you: "you",
        click_to_drill: "Click to Drill Down",
        drill_not_supported: "Drill button does not support multiple vectors",
        drill_not_supported_main: "Drill button does not support lists not having at least name, number, T or S toggled on",
        drill_not_supported_list_q: "Drill button does not support vectors having non generated segments for lists not having name and number toggled on",
        BRIDGE_REPORT_DRILL: "Bridge Report Drill",
        DRILL:"Drill",
        profit_stack_not_supported:"Action is not supported for multiple vector selections.",
        profit_stack_not_supported_when_drilling:"Action is not supported when drilling.",
        profit_stack_not_supported_three_vectors:"Action is not supported for more than 3 vectors selected.",
    },
    execution_error: "There was an execution error, please rest we're already on it!",
    something_went_wrong: "Something went wrong, please try again.\nIf the problem persists, contact our support team.",
    something_went_wrong_crash:"Looks like something went wrong!",
    retry:"Please reload your screen and try again",
    execution_error_two:"An error has occured.",
    invalid_custom_view: "This Custom View contains Profit Stack Lines not present in the selected scenario. In order to use this Custom View in this scenario you must update the Custom View definition or create a clone of the Custom View compatible with this scenario.",
    disabled_custom_view: "This Custom View contains Profit Stack Lines not present in the scenario you are viewing. In order to use this Custom View in this scenario you must update the Custom View definition or create a clone of the Custom View compatible with this scenario.",
    custom_view_invalid_for: "This Custom View will be invalid for the following scenarios:",
    invalid_file:"Make sure to choose a file with valid mapping",
    combinations_unchecked: "Your checked combinations will get unchecked if you add a filter",
    unsaved_changes: "You have unsaved combinations on this psl which will be lost. Make sure to submit first before changing period. Do you want to continue and lose your changes? ",
    missing_rule: "Rule is missing",
    line_emherited:"The new line inherited PSL_NAME's mapping and setup.",
    not_staged_for_build: "Vectors/Metrics used for this costKey are not staged",
    heatmap_12month_abbrv: " [12 Mo]",
    flush_message_confirmation: `will be flushed.`,
    are_you_sure_to_continue: "Are you sure you want to continue?",
    flushed_message_confirmation: "have been flushed successfully.",
    manage_scenario_delete: "Delete",
    manage_scenario_edit: "Edit",
    manage_scenario_rename: "Rename",
    manage_scenario_clone: "Clone",
    manage_scenario_clone_new_arch:"Clone",
    manage_scenario_clone_as_budgeting:"Clone as Budgeting Scenario",
    manage_scenario_configure_budgeting:"Configure",
    manage_scenario_publish: "Publish",
    manage_scenario_checkout: "Checkout",
    manage_scenario_review: "Send for Review",
    manage_scenario_delete_long: "Delete Scenario",
    manage_scenario_edit_long: "Edit Scenario",
    manage_scenario_rename_long: "Rename Scenario",
    manage_scenario_clone_long: "Clone Scenario",
    manage_scenario_publish_long: "Publish Scenario",
    manage_scenario_checkout_long: "Checkout Scenario",
    manage_scenario_review_long: "Send Scenario for Review",
    confirm_scenario_delete: "Are you sure you want to delete scenario {x}?",
    confirm_scenario_rename: "This will edit scenario {x}, do you want to continue?",
    confirm_scenario_clone: "This will delete your scenario and clone scenario {x} - {y}. Please confirm to continue",
    confirm_scenario_publish: "Are you sure you want to publish scenario {x}?",
    confirm_scenario_review: "Are you sure you want to send scenario {x} for review?",
    confirm_scenario_checkout: "If you have a sandbox it will be replaced by the under review scenario and all your existing configuration will be lost. Are you sure you want to revert scenario {x} - {y} to become your current sandbox?",
    success_scenario_checkout: "Scenario {x} was checked out successfully",
    success_scenario_delete: "Scenario {x} was deleted successfully.",
    success_scenario_clone: "Scenario {x} was cloned successfully.",
    success_scenario_publish: "Scenario {x} was published successfully.",
    success_scenario_review: "Scenario {x} was sent for review.",
    success_scenario_rename: "Scenario {x}'s was renamed.",
    manage_scenarios: "Manage Scenarios",
    clone_with_staged_data: "Clone with staged data",
    clone_with_built_data: "Clone with built data",
    scenarios_limit_exceeded: "You have reached the maximum limit of scenarios. Please delete a scenario to be able to create a new one.",
    empty_vectors: "Please fill all added vector dropdowns.",
    empty_filter_dropdown: "Please fill all filter dropdowns and inputs.",
    mandatory_vectors:"Please fill all mandatory fields.",
    invalid_vector_name: "Vector name is not valid",
    invalid_metric_name: "Metric name is not valid",
    FORMATTING: {
        RATIO:{
            NAME: "Ratio",
            MACHINE_NAME: "ratio",
            SAMPLE: 123.456
        },
        AMOUNT:{
            NAME: "Amount",
            MACHINE_NAME: "amount",
            SAMPLE: 1234567890.1234
        },
        PERCENTAGE:{
            NAME: "Percentage",
            MACHINE_NAME: "percentage",
            SAMPLE: 12.345
        },
        NUMERIC:{
            NAME: "Numeric",
            MACHINE_NAME: "numeric",
            SAMPLE: 1234567890.1234
        },
        decimals: "Decimals",
        multiplier: "Multiplier"
    },
    manage_scenario_confirm_create_scenario: "Your sandbox scenario will be replaced by a new scenario. Your current sandbox scenario Staging and Configuration will be discarded. This cannot be undone.",
    configure_cost_function_rule_message: "&#8226; By default, the first row has Invoice Line selected and cannot be changed. Count or list of Calculated Columns are shown in the condition dropdown.<br>&#8226; After adding a row, the user can select the desired vector. Count or list of metrics of the selected vector are shown in the condition dropdown.",
    configure_cost_function_filter_message: "&#8226; The items available under 'Columns' drop-down list are all the columns from staging invoice line and stage vectors.<br>&#8226; The functions available are based on the type of the column selected&#58;<br>&emsp;&#8226; NUMERIC&#58; Greater Than, Less Than<br>&emsp;&#8226; STRING, BOOLEAN&#58; Equal, Not Equal, Contain, Not Contain<br>&#8226; The items available under 'Value' are based on the column selected&#58;<br>&emsp;&#8226; Vector&#58; List of entities for the vector<br>&emsp;&#8226; Any other numeric item&#58; The Value is an input field and will only accept numbers.",
    advanced_filter_text: "Filling out the above inputs and adding a filter condition by clicking on (AND) or (OR) buttons will display the filter condition formula that you created here.",
    advanced_filter_draggable_text: "You can drag and drop parenthesis into the box on the right ",
    advanced_filter_validation_message: "Your parenthesis distribution is not correct, please fill them correctly",
    custom_views_edit_calculated_line: "Please define a valid name to the group",
    custom_views_delete_calculated_line: "Are you sure you want to delete this calculated line ?",
    engine_filter_set_value_error: "One value should be entered in this field.",
    engine_filter_set_value_condition: "Values should be comma separated.",
    no_data_available: "No Data Available",
    gl_placeholder: "General Ledger Combinations are loading",
    psl_placeholder: "Profit Stack Lines are loading",
    invalid_scenario_name: "{x} already exists. Try another name.",
    default_label: "default",
    invalid_mo_selection: "There are insufficient periods available to view the Months selection made. Please check the available data sets and choose an available Months selection.",
    
    modal: {
        buttons: {
            cancel: "Cancel",
            confirm: "Confirm",
            submit: "Submit",
            continue: "Continue",
            apply: "Apply",
            save_and_apply: "Save & Apply",
            clear: "Clear",
            clear_all: "Clear All",
            clear_filter:"Clear filter",
            advanced_filter: "Advanced Filter",
            delete: "Delete",
            delete_dashboard: "Delete Dashboard",
            delete_column: "Delete Column",
            delete_column_list: "Delete Column List",
            delete_widget: "Delete Widget",
            deactivate_period:"Deactivate Period",
            discard: "Discard",
            edit:"Edit",
            save: "Save",
            save_as: "Save As",
            save_as_new: "Save As New",
            overwrite: "Save",
            forget: "Forget",
            ok: "Ok",
            rename: "Rename",
            no: "No",
            close: "Close",
            yes: "Yes",
            reset: "Reset",
            reset_to_default: "Reset to Default",
            send: "Send",
            activate:"Activate",
            unblock:"Unblock",
            deactivate:"Deactivate",
            proceed:"Proceed",
            change_column: "Change Tab",
            build: "Build",
            generate_segments: "Generate " + SEGMENT,
            get_affected_items:"Get Affected Items",
            delete_stack: "Delete Stack",
            dismiss: "Dismiss",
            learn_more: "Learn more",
            got_it: "Got it",
            provide_feedback:"Provide feedback"
        },
        variable_place_holder: "$VARIABLES$",
    },
    navigation: {
        btns: {
            next: "Next",
            previous:"Previous",
            back: "Back",
            cancel: "Cancel",
            save: "Save",
            reset: "Reset",
            discard: "Discard",
            config: "Configure",
            save_goto_built: "Save & Continue to Build",
            exit_drill : "Exit Drill",
            generate:"generate",
            header_manage_build: "Manage Build",
            stop:"Stop"
        }
    },
    drill_ticket:"Reset & drill again starting from this level.",
    drill_limit_exceeded:"To drill, please remove one/some of the drill levels.",
    undrill_ticket:"Reset & drill back to this level.",
    remove_ticket:"Remove this drill level.",
    empty_exclusion_fields: "Please make sure to fill all the required data.",
    define_cost_center_title: "TBD",
    edit_cost_center_branch: "TBD",
    edit_cost_center_name: "TBD",
    edit_cost_center_gl_amount: "TBD",
    edit_cost_center_td_count: "TBD",
    edit_cost_center_td_revenue: "TBD",
    edit_cost_center_predicted_state: "TBD",
    on_edit_add_cost_center_title: "What is the new costcenter state?",
    define_cost_center_status: "TBD",
    stage_cost_center_not_defined: "costcenter State not defined yet.",
    unsaved_changes:"You have unsaved changes. Make sure to save your changes before exiting this page.",
    manage_reports:{
        manage_reports_title: "Manage Custom Reports",
        manage_reports_table_title:"List of Reports",
        report_name: "A unique valid name for the report.",
        report_link: "This link will redirect the user to the report created.",
        report_description: "An optional description about the created report.",
        report_access_groups:"The list of all users access groups.",
        report_name_placeholder:"Name your report",
        report_url_placeholder:"Paste your report url here",
        select_a_destination_group:"Select a destination group",
        report_description_placeholder:"Describe your report",
        fill_all_inputs:"Please make sure to fill all inputs.",
        empty_name: "Name is empty",
        invalid_name: "Name is invalid",
        name_used:"Name already exists",
        invalid_url:"URL is empty",
        long_description:"Description must not surpasses %X% characters",
        tableau_report_label: "Tableau Report",
        allow_period_selection: "Allow period selection",
        url_not_tableau: "The allow period selection should not be turned on because the URL you entered is not a tableau report URL",
        select_destination_group: "Select destination group.",
    },
    vector_definitions:{
        vector_definitions_title:"Vector Definitions",
    },
    build_details:{
        build_details_title:"Build Details",
    },
    profit_stack_line_definitions:{
        profit_stack_line_definitions_title:"Profit Stack Line Definitions",
    },
    model_coherence:{
        model_coherence_title:"Model Coherence",
    },
    profit_segment_definition:{
        profit_segment_definition_title:"Profit Segmentation Definition",
    },
    // best_practice_definition:{
    //     best_practice_definition_title:"Best Practice Definitions",
    // },
    manage_access:{
        title: {
            Actions: "Actions",
            Role: "Role",
        },
        edit_access_group: "Edit Access Group",
        manage_access: "Manage Access",
        tab_labels: ["Users", "Roles", "Access Groups"],
        add_user: "Add User",
        Active: "Active",
        active: "active",
        show_inactive: "Show Inactive",
        users_visibility: "Users Visibility",
        inactive_users: "Inactive Users",
        blocked_users: "Blocked Users",
        system_users: "System Users",
        add_role: "Add Role",
        select_role_placeholder: "You can select multiple roles",
        hide_inactive: "Hide Inactive",
        add_access_group: "Add Access Group",
        access_group_tab_labels: ["Feature", "Profit Stack", "Vector"],
        failed_sign_in: "Some active users are not able to sign in.",
        profit_stack_access: "Select Profit Stack Lines Access",
        select_all :"Select All",
        deselect_all: "Deselect All",
        allow_all_lines: "Always allow all lines",
        vectors_label:"Vectors",
        always_allow_label:"Always allow",
        always_allow_info:"User will always be granted access to all Profit Stack lines",
        always_allow_info_vector:"User will always be granted access to all Vectors by Vector Group",
        access_group_name: "Access Group Name",
        access_group_description: "Access Group Description",
        FIRST_NAME: "First Name",
        EMAIL: "Email",
        PASSWORD: "Password",
        LAST_NAME: "Last Name",
        DOMAIN: "Domain",
        MOBILE_NUMBER: "Mobile Number",
        ROLE:"Role",
        NAME:"Name",
        DESCRIPTION:"Description",
        ROLE_NAME:"Role Name",
        ROLE_DESCRIPTION:"Role Description",
        SELECT_ACCESS_GROUP:"Select Access Group",
        SEARCH_ACCESS_GROUP:"Search Access Groups",
        SELECT_ROLE:"Select Role",
        access_group_edited:"Access group has been edited succesfully.",
        invalid_name:"Name is not valid",
        name_empty:"Name is empty",
        group_empty:"Group is empty",
        option_empty:"Option is empty",
        vector_empty:"Vector is empty",
        line_attribute_empty:"Line Attribute is empty",
        access_group_created: "Access group has been created succesfully.",
        role_created: "Role has been created succesfully.",
        role_edited: "Role has been edited succesfully.",
        FIRST_NAME_ID: "FirstName",
        EMAIL_ID: "Email",
        PASSWORD_ID: "Password",
        LAST_NAME_ID: "LastName",
        DOMAIN_ID: "Domain",
        MOBILE_NUMBER_ID: "MobileNumber",
        ROLE_ID:"Role",
        NAME_ID:"Name",
        DESCRIPTION_ID:"Description",
        SELECT_ACCESS_GROUP_ID:"SelectAccessGroup",
        NOTIFY_USER:"notifyUser",
        pss_save:"only the primary highest level parents are shown",
        limit_access:"Limit Access",
        limit_access_tooltip:"Limit access by vector entities and/or Profit Stack lines values.",
        define:"Define",
        name_already_used:"Name already used",
        notify_by_email:"Notify User By Email",
        MAX_LIMIT_EXCEEDED:"You exceeded the maximum allowed number of active users",
        password_expired: "Your password has expired. Please set a new one.",
        user:{
            user_validations:{
                no_missing_fields_are_allowed: "No missing fields are allowed.",
                email_bad_format: "The email address is badly formatted.",
                mobile_invalid:"The mobile number is invalid.",
                password_validation:"Password must be at least six characters long.",
                existing_email:"Cannot create a new user with an already existing email.",
                firstname_validation:"The FirstName is invalid",
                lastname_validation:"The LastName is invalid"
            },
            user_created:"User successfully created.",
            user_edited:"User successfully  edited.",
            error_occured:"An error has occured, please try again later",
            user_cannot_login_stmt:"Your login credentials may be incorrect. Make sure you typed them correctly. Otherwise, contact your admin.",
            user_blocked_stmt:"For your security, we have blocked your account due to several failed login attempts. To unblock your account please contact your local administrator.",
            user_unblocked:"User has been unblocked successfully",
            sso_not_associated:"Your username is not associated with an SSO provider.",
            sso_problem:"There were some problems connecting to the SSO provider.",
            sso_match_username:"Your email address did not match your authenticated credentials. Please log in with the same email.",
            user_credentials_wrong_stmt:"Your credentials may be incorrect. Make sure you typed them correctly. Otherwise, contact your admin."
        },
        reset_password: "Reset Password",
        reset_mfa: "Reset MFA",
        deactivate_user: "Deactivate User",
        deactivate_role: "Deactivate Role",
        activate_role: "Activate Role",
        deactivate_access_group: "Deactivate Access Group",
        activate_access_group:"Activate Access Group",
        send_password_reset_email: "Send a password reset Email.",
        send_password_reset_email2:"An e-mail will be sent to you to reset your password.",
        send_mfa_reset_email: "Send an MFA reset Email.",
        deactivate_user_warning: "Inactive user will not be able to sign in.",
        activate_user_warning: "Active user will be able to sign in.",
        unblocked_user_warning: "Unblocked user will be able to sign in.",
        deactivate_role_warning: "Inactive role will block access for users using it",
        activate_role_warning: "Active role will grant access for users using it.",
        deactivate_access_group_warning: "Inactive access groups will block access for users using it. ",
        activate_access_group_warning:"Active access groups will grant access for users using it. ",
        activate_user:"Activate User",
        unblock_user:"Unblock User",
        define_scope:"Define Scope"
    },
    no_description:"None",
    filter_not_define:" Filter not defined",
    rule_not_define: "Rule not defined",
    define_rule:"Define Rule",
    default_rule:"To the Line by Count",
    define_filter:"Define Filter",
    configure_no_rule: "All attributes, Invoice Line Types and calculated lines do not have a rule",
    configure_no_filter: "All attributes, Invoice Line Types and calculated lines do not have a filter",
    configure_map_to_add_rule: "Map this line in stage profit stack screen to be able to add a rule",
    configure_map_to_add_filter: "Map this line in stage profit stack screen to be able to add a filter",
    configure_validate:"Click to validate all Profit Stack lines before running the build",
    no_scenarios_available: "There are no scenarios available.",
    confirm_clear: "Are you sure you want to clear this filter? You cannot undo this action.",
    overlay_message:"Press on the Go button to get your new data.",
    overlay_message_empty_inputs: "Please fill all inputs and press on the Go button to get your data.",
    overlay_message_request:"Or you can ",
    overlay_message_request_reset:"Reset your selection",
    non_editable_scenario:"You can't edit a published scenario or a scenario under review",
    scenario_under_build:"This scenario is currently being built in a different session",
    threshold_message:"The percentages in the threshold are in absolute value.",
    psl_message:"The selected profit stack line will be used for the cross column percentage calculation.",
    contour_map_configure_message:"The calculation is based on",
    contour_map_configure_message_bold:"%L% using %T%% threshold.",
    header: {
        titles: {
            scenario: "Scenario",
            reference_scenario: "Reference Scenario",
            comparison_scenario: "Comparison Scenario",
            dataset: "Data Set",
            fy: "Months",
            format: "Format",
            go: "GO",
            display: "Display",
            xls_short: "Export",
            xls: "Export Excel",
            csv: "Export CSV",
            export_to_csv: "CSV",
            export_selected_rows_csv: "Columns displayed on screen, up to 1 million rows",
            export_to_excel: "Excel",
            export_selected_visible_rows: "Columns displayed on screen, up to 100 rows",
            export_visible_rows: "Export",
            export_all_csv: "Columns displayed, plus all Profit Stack columns not shown, up to 1 million rows",
            export_visible_rows_description: "Exports all visible rows.",
            export_all_rows:"Export (All Rows)",
            export_all_rows_description:"Exports all rows, including hidden rows.",
            csv_all_psl: "CSV (full Profit Stack)",
            period: "Period",
            start_date: "Start Date",
            end_date: "End Date",
            fiscal_year: "Fiscal Year",
            comparison: "Comparison",
            comparison_type: "Comparison Type",
            selection_range: "Selection Range",
            range: "Range",
            bridge: "Bridge",
            mix_vector: "Mix Vector",
            profit_stack: "Profit Stack",
            profit_stack_type: "Profit Stack Type",
            preview: "Preview",
            quarter: "Quarter",
            last_generated_token:"Last Generated Token"
        },
        tooltips: {
            export_all_excel: "Export all selected columns for all visible data.",
            xls: "Export visible data",
            csv: "Securely export to csv all selected columns (up to 1,000,000 records)",
            csv_all: "Securely export to csv all columns (up to 1,000,000 records)",
            export_format: "Export this report in the format of your choice.",
            segments_not_applicable: "Segmentation filters are unapplicable",
        },
        placeholders: {
            profit_stack: "Select Profit Stack",
            profit_stack_type: "Select Profit Stack Type",
            view_stack: "View Stack",
            select_list: "Select List",
            saved_filters: "Saved Filters",
            my_filters: "My Filters",
            switch_to: "Switch to",
        },
        custom_text: {
            reauthenticate_modal_message: "Please reauthenticate your credentials before downloading the file:",
            download_start: "Your download will start soon. Please wait.",
            error: "An error has occurred."
        },
        options: {
            comparison: [
                {label: "Time", value: "", isDisabled: true,isGroupTitle: true},
                {label: "Year-over-Year (YoY)", value: "year_over_year",  type:"Time", description:"title: Compares the selected periods to the same periods in the previous year.; pos: right"},
                {label: "Previous Adjacent", value: "previous_adjacent", type:"Time", description:"title: Compares the selected periods to the periods immediately prior, with equivalent range.; pos: right"},
                {label: "Best Practice", value: "", isDisabled: true},
                {label: "BP Profit Tier", value: "bp_quadrant_tier", type:"BestPractice", description:"title: Compares the selected vector entities to the entities that grew at the same rate as their relative best practice (e.g. [Peak 4] entity’s relative best practice is [Peak 1]).; pos: right"},
                {label: "Top Segment", value: "top_quadrant", type:"BestPractice", description:"title: Compares the selected vector to Peak Segment over the same period range.; pos: right"},
                {label: "Top Profit Tier", value: "top_quadrant_tier", type:"BestPractice", description:"title: Compares the selected vector to Peak 1 Profit tier over the same period range.; pos: right"},
            ],
            comparisonOld: [
                {label: "Time", value: "", isDisabled: true,isGroupTitle: true},
                {label: "Year-over-Year (YoY)", value: "year_over_year",  type:"Time", description:"title: Compares the selected periods to the same periods in the previous year.; pos: right"},
                {label: "Previous Adjacent", value: "previous_adjacent", type:"Time", description:"title: Compares the selected periods to the periods immediately prior, with equivalent range.; pos: right"},
                {label: "Best Practice", value: "", isDisabled: true},
                {label: "BP Quadrant Tier", value: "bp_quadrant_tier", type:"BestPractice", description:"title: Compares the selected vector entities to the entities that grew at the same rate as their relative best practice (e.g. [MN:4] entity’s relative best practice is [MN:1]).; pos: right"},
                {label: "Top Quadrant", value: "top_quadrant", type:"BestPractice", description:"title: Compares the selected vector to IOP quadrant over the same quarter range.; pos: right"},
                {label: "Top Quadrant Tier", value: "top_quadrant_tier", type:"BestPractice", description:"title: Compares the selected vector to IOP:1 quadrant tier over the same quarter range.; pos: right"},
            ],
            period_range: [
                {label: "Year-over-Year (YoY)", value: "", isDisabled: true},
                {label: "Period YTD", value: "period_ytd", description: "Comparison between the Year-to-Date (YTD) periods and the same periods in the previous year."},
                {label: "Period QTD", value: "period_qtd", description: "Comparison between the Quarter-to-Date (QTD) periods and the same periods in the previous year."},
                {label: "Year-to-Date (YTD)", value: "", isDisabled: true},
                {label: "Periods", value: "periods", description: "Selection of all the Year-to-Date (YTD) periods."},
            ]
        },
        messages:{
            include_entities_with_no: "Include entities with no ",
        }
    },
    proceed_message: "Kindly remove them to proceed.",
    accruals:{
        tooltip_title:"Please define the Profit Stack lines that you wish to accrue. The FY Cost % and the Likeliness to be Accrued values are shown in green when both thresholds are met. In this case the Profit Stack line is suggested to be accrued.",
        build_fiscal_year:"Please build all the periods of the selected fiscal year.",
        accrual_chart_overlay_message:"The Reference Fiscal Year $not_built_years$ is not yet built.",
        accrual_overlay_message_1:"'s prediction is using $not_built_years$ ",
        accrual_overlay_message_2_single: "as reference fiscal year that is not yet built.",
        accrual_overlay_message_2_multiple: "as reference fiscal years that are not yet built.",
        accrual_overlay_action:"Go to ",
        titles: {
            main_table: {
                evaluate: "Evaluate Accrual",
                predict: "Predict Accrual",
            },
            chart: "Line Chart",
        },
        info: {
            chart: "This line chart visualizes the Period Build and FY Build cost amounts over the periods of the selected fiscal year.",
            main_table: {
                evaluate: "The Evaluate Accruals section allows you to evaluate and assess the selected Profit Stack line’s accrual calculation.",
            }
        },
        actions: {
            change_status: "Change Status",
            simulate: "Simulate",
            evaluate: "Evaluate",
            evaluate_long: "Evaluate",
            predict: "Predict",
            predict_long: "Predict",
            exclude: "Exclude",
            unassign: "Unassign",
            exclude_long: "Exclude",
            include: "Include",
            include_long: "Include",
            accrue: "Accrue",
            accrue_long: "Accrue",
            remove_accrual: "Remove Accrual",
            remove_accrual_long: "Remove Accrual",
        },
        prediction_config: {
            titles: {
                type: "Prediction type",
                ref_fiscal_year: "Reference Fiscal Year",
                ratio: "Ratio %",
                is_by_year: "is_by_year"
            },
            options: {
                type: {
                    historical: { title: "Historical", value: "historical" },
                    manual: { title: "Manual", value: "manual" },
                    metric: { title: "Metric", value: "metric" },
                },
                ref_fiscal_year: {
                    previous: { title: "Previous", value: "previous_year" }
                },
                is_by_year: {
                    by_year: { title: "By Year", value: "by_year"},
                    by_period: { title: "By Period", value: "by_period"},
                }
            }
        }
    },
    bridge_report:{
        title:{
            price:"Price",
            returns:"Returns",
            cogs:"COGS",
            volume:"Volume",
            mix:"Mix",
            new_sales:"New sales",
            no_sales:"No sales"
        },
        description:{
            price_cogs_volume_mix:"click here to view the formula",
            price_desc:"Effect of Price when Volume and Mix are held constant.",
            returns_desc:"Returns effect.",
            cogs_desc:"Effect of COGS when Volume and Mix are held constant.",
            volume_desc:"Effect of Volume when Selling Margin (Price - COGS) and Mix are held constant.",
            mix_desc:"Effect of Mix when Selling Margin (Price - COGS) and Volume are held constant.",
            new_sales_desc:"Profit effect of [mix vector] entities sold in the selected (β) range and not sold in the comparison (α) range or best practice.",
            no_sales_desc:"Profit effect of [mix vector] entities not sold in the selected (β) range but sold in the comparison (α) range or best practice.",
        },
        view:"View",
        grouped_by: "grouped by",
    },
    select_entities_to_view_history:"Select up to 1 record to view the report.",
    select_entities_to_view:"Select up to 10 records to view the report.",
    cannot_choose_bp:"This option cannot be picked when more than one record is selected.",
    select_entity_to_view:"Select an entity to view the report.",
    select_entities_to_view_PS:"Select up to 3 records to view the Profit Stack.",
    insufficient_periods_selection:"There are insufficient periods available for the comparison of the selected range.",
    not_allowed_quarters_selection:"Range selection cannot exceed 4 consecutive quarters. Please enter a valid selection.",
    not_allowed_periods_selection:"Range selection cannot exceed 12 consecutive periods. Please enter a valid selection.",
    fields_cannot_be_empty:"Fields cannot be empty. Please complete your selections to proceed.",
    no_iop_selected_vector:"The selected vector has no Peak segment for the selected range.",
    manage_accruals_main_table_title: "Define Accruals",
    manage_accruals_chart_title: "Accruals Status Chart",
    manage_accruals_main_table_info: "The Manage Accruals screen lets you define and evaluate your Profit Stack lines in order to accrue them.",
    manage_accruals_chart_info: "This chart shows the number of Profit Stack lines by status.",
    manage_accruals_warning_message:"The FY Cost % and/or the Likeliness to be Accrued changed and you are recommended to evaluate if the accrual is still valid.",
    manage_accruals_disabled_line: "This Profit Stack Line is mapped to Transaction and cannot be evaluated or accrued.",
    manage_accruals_disabled_line_two: "One of the Profit Stack Lines is mapped to Transaction and cannot be evaluated or accrued.",
    vectors_mapping_concatenated_column_connector: "What is the connector used?",
    vectors_mapping_concatenated_column_file: "What is the file used to concatenate this column?",
    vectors_mapping_concatenated_column_column: "What is the column used to concatenate this vector [key]?",
    vectors_mapping_concatenated_column_title: "Concatenate",
    vectors_mapping_concatenated_column_title_long: "Concatenate column",
    vectors_mapping_concatenated_column_connector_placeholder: "Choose a connector",
    vectors_mapping_concatenated_column_file_placeholder: "Choose a file",
    vectors_mapping_concatenated_column_column_placeholder: "Choose a column",
    profit_list:{
        disable_checkbox:"You are not allowed to select more than 3 records."
    },
    vector_analysis:{
        disable_checkbox: "You are not allowed to select more than 10 records.",
        disable_checkbox_bp: "You are not allowed to select more than one record for the BP Profit Tier."
    },
    bridge_selected_entities: "Selected entities",
    save_cancel_to_proceed:"Save or cancel your changes to proceed with the configuration.",
    discarded_changes:"All your changes will be discarded.",
    are_you_sure:"Are you sure you want to continue?",
    saved: "Changes saved successfully.",
    filter_saved: "Filter saved successfully.",
    redefining: "Your files are being re-uploaded and redefined",
    scenario_added_to_sandbox: "A new scenario has been added to your sandbox!",
    not_saved: " Saving changes failed.",
    automation_started:"Automation has started",
    something_went_wrong_while_save: "Something went wrong. Please try to save again or contact your admin." ,
    compare_scenarios_manage_columns_warning: "At least one Profit Stack line has different amounts between the two selected scenarios.",
    compare_scenarios_manage_columns_warning_by_psl: "This Profit Stack Line has different amounts between the two selected scenarios.",
    total_stacks_configure:{
        info:{
            configure_tab_from_list:"You can choose to view the amount column as follows:<br>Value - monetary worth <br> % per selection - ratio of the amount at hand out of the combined selected amount. Filters applied affect both the dividend and the devisor.<br>% per total - ratio of the amount at hand out of the total amount of the company. Filters applied only affect the dividend.",
            configure_tab:"You can choose to view the amount column as follows: <br> Value - monetary worth <br> % per total - ratio of the amount at hand out of the total amount of the company. Filters applied only affect the dividend.",
        }
    },
    mom_stacks_configure:{
        info:{
            configure_tab:"You can choose to view the amount column as follows: <br> Value - amount in applicable currency (e.g. $) <br> Percentage - the percent of the company total based on the filters applied for the selected range",
        }
    },
    manage_columns: {
        titles: {
            manage_columns: "Columns",
            unsaved_columns: "Unsaved Columns",
            select_all: "Select All",
            deselect_all: "Deselect All",
            save_column: "Save Column List",
            edit_preset: "Edit Preset",
            name: "Name",
            visibility: "Visibility",
            create_column: "Create Column",
            add_column: "Add Column",
            edit_column :"Edit Column",
            description: "Description",
            custom_group: "Custom Group",
            type: "Type",
            group: "Group",
            delete_column: "Delete Column",
            created_by: "Created by",
            edit:"Edit",
            modified:"Modified",
            activate:"Activate",
            deactivate:"Deactivate",
        },
        add_column_subtitles:{
            add:"Add a new column to your existing list"
        },
        text: {
            invalid_formula_warning: "Make sure your formula is correct. Otherwise, contact your admin.",
            footer_text: "For saved lists, the order of columns in the table will be saved automatically when rearranged.",
            search_placeholder: "Search",
            search_lists_placeholder: "Search Lists",
            search_dashboards_placeholder:"Search Dashboards",
            blue_dot_tooltip: "At least one of the profit stack lines under this parent is switched on.",
            name_your_profile: "Name your column",
            make_default: "Make Default",
            default: "Default",
            default_client_selection:"Always log me in to this selection",
            profile_name_is_not_unique: "Your column list name must be unique",
            saved_successfully: "Column list has been saved successfully.",
            edited_successfully: "Column has been edited successfully.",
            column_saved_successfully: "Column has been saved successfully.",
            calculated_line_saved_successfully: "Calculated line has been saved successfully.",
            missing_cols_will_be_unselected: "The missing columns listed above will be unselected.",
            made_default_successfully: "[List Name] was set as default.",
            made_favorite_successfully: "[List Name] was added to Favorites.",
            made_unfavorite_successfully: "[List Name] was removed from Favorites.",
            delete_confirmation: "Are you sure you want to completely delete saved column list configuration ",
            deleted_successfully: "Column list has been deleted successfully.",
            column_deleted_successfully: "Column has been deleted successfully.",
            changes_not_saved: "Changes not saved",
            no_access_to_edit_company_profiles: "You don't have access to overwrite company configurations.",
            profile_and_scenario_do_not_match_1: "The following columns are not compatible with the scenario you are viewing:",
            profile_and_scenario_do_not_match_2: "Only the columns that exist in this scenario will be displayed.\nTo fix this saved list in this scenario, you must edit it with valid columns.",
            visibility_not_match:"You cannot save a company column list having a user column selected.\n The following user columns are selected in [x]",
            column_in_saved_list: "After you delete a column, it is permanently deleted.\n This action cannot be undone.",
            column_saved_in: "This column is used in the following saved column lists: \n",
            column_removed: "This column will be automatically removed from the column lists mentioned above.",
            check_visibility:"This company list is being used in a company dashboard and cannot be saved as a user list:",
            modified_stmt:"The currently selected column list is in a Modified state. In order not to lose your changes, go to Manage Columns and save.",
            conf_build_info:"This screen allows you to to manipulate & shape your data & vectors the way you want to see them on the output screens. After completion, building will be the last step to check out your data on any report desired.",
            editting_preset: "You’re editing the [X] preset. Please hit Save once you’ve finished editing.",
            name_surpasses_limit: "Name must not surpasses [X] characters",
            manage_reorder_create: "Manage, reorder or create new columns...",
            discard_applied_changes: "This action will discard your unapplied changes. Do you want to apply first?",
            start_typing_formula: "Start typing your formula...",
            wrong_formula:"The formula is wrong",
            incomplete_formula:"The formula is incomplete",
            complex_formula:"This formula is too complex"
        },
        visibility_options: {
            user: "User",
            company: "Company",
        },
        column_types: {
            psl: {label: "Profit Stack Lines", value: "psl"},
            line_attribute: {label: "Line Attributes", value: "line_attribute"},
            custom: {label: "Custom Group", value: "custom"},
            vector: {label: "Vector", value: "vector"},
            selected_vector: {label: "Selected Vector", value: "selected_vector"}
        },
        custom_column: "Custom Column",
        column_name: "Column Name",
        column_description: "Column Description",
        column_custom_group: "Column Custom Group",
        format: "Format",
        unit: "Unit",
        labels: ["Calculated Profit Stack Line", "Per Vector", "Per Line Attribute"],
        TYPE_DEFAULT_VALUE:"calculated_ps_line",
        type_tooltips: ["Create columns using formulas and Profit Stack values.", "Create columns using combinations of Profit Stack lines and Vectors.", "Create columns based on calculated or raw values in the invoice line data."],
        type_empty:"Type is empty",
        custom_empty:"Custom group is empty",
        format_empty:"Format is empty",
        formula_empty:"Formula is empty",
        per_vector:"Per Vector",
        per_line_attribute: "Per Line Attribute",
        group_labels: ["Profit Stack Lines", "Line Attributes", "Vectors"],
        line_attribute:"line_attribute",
        all_filters_considered:"Percent, all filters considered.",
        filters_not_considered:"Percent, filters not considered.",
        NO_DESCRIPTION:"No description.",
        select_vector:"Select Vector",
        select_group:"Select Group",
        select_format:"Select Format",
        select_custom_group: "Select Custom Group",
        type_unit:"Type a Unit",
        cant_edit_title:"Can't Edit",
        cant_edit_warning :"You cannot save a user column selected in a company column list.",
        cant_edit_text:"The following company column lists are using the column",
        calibrate_adjust_manage: "Calibrate, adjust & manage your columns",
        tabs: ["Manage", "Reorder", "Appearance"],
        select_how_view_columns: "Select how you you’d like to view your columns",
        drag_rearrange_columns: "Drag the columns to rearrange their positions",
    },
    print_chart: "Print Chart",
    print: "Print",
    password:"Contains at least 6 characters",
    select_domain:"Select domain",
    failedToSave:"A role should have access to all three access group types",
    dashboards:{
        add_widget: "Add widget",
        add_a_widget: "Add a widget",
        cant_create_dashboards:"You don't have access to create a dashboard.",
        make_default: "Make Default",
        edit_dashboard: "Edit Dashboard",
        delete_dashboard: "Delete Dashboard",
        create_dashboard: "Create Dashboard",
        clone_dashboard: "Clone Dashboard",
        titles: {
            name: "Name",
            dashboard_name: "Dashboard Name",
            description: "Description",
            dashboard_description: "Dashboard Description",
            list_type:"List Type",
            line_attribute: "Line Attribute",
            vector:"Vector",
            profit_stack_type:"Profit Stack Type",
            profit_stack: "Profit Stack",
            order_by:"Order By",
            size:"Size",
            filter:"Filter",
            profit_stack_line:"Profit Stack Line",
            change_color_logic:"Change Color Logic",
            column:"Column",
            mix_vector:"Mix Vector",
            chart_name:"Chart Name",
            chart_description:"Chart Description"
        },
        configure_title: "Configure ",
        configure_title_table: "Configure Table",
        messages: {
            confirm_delete_widget: "After you delete a widget, it is permanently deleted. This action cannot be undone.",
            confirm_deactivate_period: "After you deactivate a period, its data will be removed from all the scenarios. Uploaded data will not be removed. You can always reactivate the period, but you will have to rebuild it in each scenario.",
            confirm_delete_dashboard: "After you delete a dashboard, it is permanently deleted. This action cannot be undone",
            confirm_delete_list: "After you delete a column list, it is permanently deleted. This action cannot be undone",
            dashboard_created_successfully : "The dashboard has been created successfully.",
            dashboard_deleted_successfully : "The dashboard has been deleted successfully.",
            dashboard_edited_successfully : "The dashboard has been edited successfully.",
            dashboard_cloned_successfully : "The dashboard has been cloned successfully.",
            dashboard_make_default_successfully : "Dashboard has been successfully set as default.",
            widget_deleted_successfully : "The widget has been deleted successfully",
            widget_order_updated_successfully : "The widgets order has been updated successfully.",
            widget_order_update_confirmation : "Widget order has changed. Do you want to save changes?",
            name_already_used: "This dashboard name is already used",
            name_is_empty: "Name is empty",
            list_type_is_empty: "List Type is empty",
            vector_is_empty: "Vector is empty",
            order_by_is_empty: "Order By is empty",
            profit_stack_type_is_empty: "Profit Stack Type is empty",
            profit_stack_line_is_empty:"Profit stack line is empty",
            column_is_empty:"Column is empty",
            line_attribute_is_empty: "Line Attribute is empty",
            comparison_type_is_empty: "Comparison type is empty",
            widget_saved: "Your widget has been created successfully",
            widget_edited: "Your widget has been edited successfully",
            edit_widget:"Configure",
            delete_widget:"Delete",
            no_permission:"The configuration of this widget is not compatible with your selected scenario or you do not have sufficient permissions to view it.",
            not_compatible_with_filter:"The configuration of this widget is not compatible with the filter applied.",
            visibility_check:"This dashboard is using a user column or list in one of the widget configurations and cannot be saved as a company dashboard",
            visibility_check_two:"This company column is being used in a company dashboard and cannot be saved as a user column:",
            ps_line_limit:"You can only select up to 10 profit stack lines",
            want_to_save_first:"This action will discard your unsaved changes. Do you want to save first?",
            save_before:"This action will discard your unsaved changes. To be able to save, the stack must be fully configured. Are you sure you want to continue?",
            select_one_period:"To be able to build, at least one period must be selected.",
            bridge_is_empty: "Bridge is empty",
            no_suff_permission: "You do not have sufficient permissions to view this widget."
        },
        select_list_type:"Select List Type",
        select_vector:"Select Vector",
        select_psl:"Select Column",
        select_line_attribute: "Select Line Attribute",
        select_comparison_type : "Select Comparison Type",
        select_bridge:"Select Bridge"
    },
    CATEGORIES:{
        categories_label:"CATEGORIES",
        titles : ['Charts','Metrics','Tables'],
        values :['charts','metrics','tables'],
    },
    widget:{
        options: {
            comparison: [
                {label: "None", value: "none"},
                {label: "Year-over-Year (YoY)", value: "year_over_year",  type:"Time", description:"title: Compares the selected periods value to the same periods in the previous year.; pos: right"},
                {label: "Previous Adjacent (PA)", value: "previous_adjacent", type:"Time", description:"title: Compares the selected periods value to the periods immediately prior.; pos: right"}
            ],
            period_range: [
                {label: "Year-over-Year (YoY)", value: "",description:"Compares the selected periods value to the same periods in the previous year", isDisabled: true},
                {label: "Period YTD", value: "period_ytd", description: "Comparison between the Year-to-Date (YTD) periods and the same periods in the previous year."},
                {label: "Period QTD", value: "period_qtd", description: "Comparison between the Quarter-to-Date (QTD) periods and the same periods in the previous year."},
                {label: "Year-to-Date (YTD)", value: "", isDisabled: true},
                {label: "Periods", value: "periods", description: "Selection of all the Year-to-Date (YTD) periods."},
            ],
            mom_period_range: [
              {label: "Year-over-Year (YoY)", value: "", isDisabled: true},
              {label: "Period YTD", value: "period_ytd", description: "Comparison between the Year-to-Date (YTD) periods and the same periods in the previous year."},
              {label: "Period QTD", value: "period_qtd", description: "Comparison between the Quarter-to-Date (QTD) periods and the same periods in the previous year."},
              {label: "Month-over-Month (MoM)", value: "", isDisabled: true},
              {label: "Periods", value: "periods", description: "A preview of all selected periods"},
          ]
        }
    },
    bridge_configurations:{
        save_success:"Configurations saved successfully",
        delete_success:"Configurations deleted successfully",
        save_changes_text:"Saving changes will update the configuration of all Calculated Lines at once",
        bridge_field:"bridge_field",
        groups:"groups",
        field_quantity_shipped:"field_quantity_shipped",
        field_revenue:"field_revenue",
        field_cogs:"field_cogs",
        custom_view_id:"custom_view_id",
        report_id:"report_id",
        report_name:"report_name",
        custom_view_groups:"custom_view_groups",
        validation_text:"All fields must be filled",
        price_revenue:"Transactional Revenue",
        lever:"Lever",
        description:"Description",
        group:"Group",
        actions:"Actions",
        custom_views:"Custom Views",
        stacks:"Stacks",
        report_name:"Report Name",
        add_lever:"Add Lever",
        quantity_shipped:"Units",
        info_message: "Only the Calculated Profit Stack Lines configured for Bridge Report are displayed.",
        calculated_line: "Calculated Line",
        save_changes:"Save Changes",
        delete:"Delete",
        confirm_delete_configuration:"After you delete a configuration, it is permanently deleted. This action cannot be undone.",
        delete_configuration:"Delete Configuration",
        no_bridge_configurations:"At least one Calculated PS Line needs to be configured for this report.",
        no_bridge_configurations_prefix:" needs to be configured for this report.",
        transactional_cogs:"Transactional COGS"
    },
    totals:"Totals",
    gl_combinations:"General Ledger Combinations",
    record_limit_exceeded:"Record limit exceeded. Column sorting still applies to all records. Column filter is disabled.",

    manage_stacks:{
        stacks:"Stacks",
        add_stack:"Add Stack",
        clone_stack:"Clone",
        delete_stack:"Delete",
        remove_stack:"Remove", 
        edit_stack:"Setup",
        stack_name: "Name",
        stack_name_placeholder: "Stack Name",
        stack_description: "Description",
        stack_description_placeholder: "Stack Description",
        published_profit_stack_title:"Published Profit Stack",
        tooltip_title:"Manage Stacks is used to create alternate Stack views. These views are accessible wherever you can view a Profit Stack.",
        new_profit_stack_title:"New Stack",
        no_permission: "No sufficient permissions",
        bluedot_tooltip:"Unused child Profit Stack Line",
        attribute_bluedot_tooltip:"Unused Attribute Line",
        is_used:"[PSL] is being used in the following Calculated Profit Stack Line&#58 [CAUCULATED_PSL]. Kindly edit them to proceed.",
        not_configured:"Your Stack is not fully configured. Incomplete lines are indicated with red dots.",
        limit_reached:"Stacks limit reached",
        confirm_delete_stack: "After you delete a stack, it is permanently deleted. This action cannot be undone",
        titles:{
            name:"Name",
            visibility:"Visibility",
            created_by:"Created by"
        },
        delete_to_proceed:"Stacks limit reached, delete a stack to proceed.",
        stack_saved_successfully:"Changes saved successfully.",
        stack_cloned_successfully:"Stack has been cloned successfully.",
        stack_deleted_successfully:"Stack has been deleted successfully.",
        invalid_scenarios:"This Stack will be incompatible with the following scenarios:",
        user_limit_reached:"Due to insufficient permissions or stack limits, cloning this stack will result in the visibility to be automatically set to company.",
        company_limit_reached:"Due to insufficient permissions or stack limits, cloning this stack will result in the visibility to be automatically set to user.",
        discard:"This action will discard your unsaved changes.",
        save_first:"Do you want to save first?",
        save_before_discard:"This action will discard your unsaved changes. Do you want to save first?",
        must_be_fully_configured:"This action will discard your unsaved changes. To be able to save, the stack must be fully configured. Are you sure you want to continue?",

    },

    modeling_settings:{
        delete_title:"Delete",
        pull_sftp_data: "Pull SFTP Data",
        pull_query_data: "Pull QUERY Data",
        user_notifications_title:"User Notifications",
        user_notifications_placeholder:"Select User(s)",
        at_least_one_file_is_uploading: "At least one file is still being uploaded under this Data Source.",
        define: {
            define_title: "Define - ",
        },
        info_icons:{
            delimiter:"A field delimiter is a character that separates cells in a CSV file.",
            encryption_extension:"Specify whether the file will be encrypted or not. Enter the encrypted file extension in case of encryption.",
            header_rows_to_skip:"Enter the number of rows to skip from the top of the CSV file.",
            user_notifications:"Selected users will be notified when files are uploaded. A summary report email will be sent with details about the files.",
            column_headers_info: "This section allows you to introduce the expected column headers of the data source that you are about to upload files onto. This action however is only required once upon data source creation prior to uploading files onto it.",
        },
        validations:{
            data_source: {
                alpha_numeric:"Only alphanumeric characters are allowed in subsection name",
                unique:"Name must be unique",
                empty:"Name is empty"
            },
            fileNamingTemplate:{
                empty:"Filename is empty",
                not_valid:"The naming template is not valid",
                unique:"The naming template should be unique"
            }
        },
        user: {
            company_users:"Company Users",
            system_users:"System Users"
        },
        data_source: {
            titles: {
                periods: "Periods",
                data_sources: "Data Sources",
                set_column_headers_dialog: "Set Column Headers - ",
                set_column_headers_btn: "Set Column Headers",
                define_columns_btn: "Define Columns",
                column_definition_reset_warning: "This data source's column definition will be reset and you'll have to redefine it. Do you wish to proceed?",
                space_replace_by_underscore_warning: "Spaces will be automatically replaced by underscores.",
                run_automation: "Run Automation"
            },
            fields: {
                period: "periods",
                data_sources: "data_sources",
                column_header_placeholder: "Input Column Headers",
                automation_range: "automation_range",
            },
            tooltips: {
                define_columns_tooltip: "Save your changes to proceed.",
                has_defined_files_tooltip: "At least one file is already uploaded.",
                has_header_columns_tooltip: "Column headers must be set."
            },
            statements: {
                automation:"Select the periods you would like to manually run the automation for: "
            }

        }
    },

    fiscal_calendar:{
        buttons:{
            add_period:"Add Period",
            add_12_period:"Add 12 Periods"
        },
        messages:{
            activate_limit_reached: "Active periods limit reached. Deactivate a period to proceed.",
            cant_add_12 :"Adding 12 periods will exceed the allowed number of active periods. Deactivate required periods to proceed.",
            changes_saved:"Changes already saved",
        }
    },
    edit_rule_or_filter:"Edit Rule/Filter",
    split_rule_or_filter:"Split Rule/Filter",
    set_as_default: "Set as Default",
    delete: "Delete",
    stop_build : "No build in progress",
    use_new_arch: "Use New Architecture",
    switch_assign_cost:"Switching on force assign cost will allow you to build all eligible periods including the ones that are already built.",
    build_button_disabled:{
        already_built:"All active periods are already built.",
        file_prerequiste_validation:"You need to have at least one period generated for your transaction and general ledger.",
        no_group:"At least one vector in Configure Vectors does not belong to a group.",
        scenario_not_sandbod:"This action requires the scenario to be checked out.",
        reason_not_selected:"You need to choose your reason for build.",
        no_quadrants: SEGMENT + " configuration is missing in " + SEGMENT + " Tab"
    },
    manage_build: {
        cost_assignment_label: "Cost Assignment",
        quadrants_label: SEGMENT,
        cost_assignment_text: "[x] active period[s] [are] ready for cost assignment",
        quadrants_text: "[x] period[s] [are] ready for " + SEGMENT + " generation",
        reason_for_build_options:[{value:"option_one",label:"Change in data"},{value:"option_two",label:"Change in configuration"},{value:"option_three",label:"Adding a new period"},{value:"option_four",label:"Rebuilding because of a bug"}]
    },
    build_status: {
        failed: " An error has occurred while executing the build. Please try again or contact your admin.",
        passed: " Build completed."
    },
    mfa: {
        set_password:{
            stmt_one:"Set your password",
            stmt_two:"Passwords must be at least 8 characters long and contain at least one (1) of each of the following: letters (A-Z, not case sensitive), numbers (0-9), and special characters (e.g. !@#$%^&*).",
            password_title:"Password",
            confirm_password_title:"Confirm Password"
        },
        phone_number: {
            title: "Insert your phone number for MFA",
            sub_title: "Secure your account by adding phone verification to your password.",
            button: "Next",
            cancel: "Cancel",
            back:"Back"
        },
        verify_code: {
            title: "Verify your MFA identity",
            sub_title: "A code was sent to your phone number (",
            sub_title_2: "). Please insert the code to verify it's you."
        },
        link_expired:"The link you followed has expired.",
        reset_password_link_expired:"Try resetting your password again",
        reset_password_success_stmt:"Password changed successfully",
        validation:"Phone number must be at least 8 digits long",
        code_not_sent:"Something went wrong and code wasn't sent, try again in 1 minute.",
        code_error:"Insert a valid verification code",
        resend:"Resend Verification Code",
        verify:"Verify",
        back:"Go back",
        verification_code_label:"Verification Code"
        },
    new_dialog:{
        turn_on_mfa:"Multi-factor authentication (MFA) provides an extra security layer for user accounts. By turning MFA ON, all existing users must go through 2-step verification upon signing in. Do you wish to turn it on?",
        turn_off_mfa:"Multi-factor authentication (MFA) provides an extra security layer for user accounts. By turning MFA OFF, the account security for all users will be compromised. The users will have to go through 2-step verification one last time once the toggle is switched off. Do you wish to turn it off?"
    },
    stack_incompatible: "No sufficient permissions or stack incompatible with the Published scenario",
    preview: "Preview",
    incompatible_or_restricted:"The bridge report you are viewing is incompatible or restricted on the scenario chosen.",
    incompatible_vectors:"The report you are viewing is incompatible with the selected scenario.",
    session:{
        session_expired: "Your session is about to expire. You will be logged out in : X seconds",
        logout:"Logout",
        continue: "Continue Session"
    },
    exit_drill_to_proceed:"Exit drill to proceed.",
    filter_not_supported_drill_mode: "Filtering not supported in drill mode.",
    reset_and_drill_again:"Reset & drill again.",
    data_still_loading:"Data still loading",
    loading_res: "Loading results",
    budgeting:{
        configure_budgeting:{
            report_title:"Configure Budget",
            sections:{
                configure_data:"Data Configuration",
                transaction_simulation_data:"Transaction Simulation Configuration"
            },
            titles:{
                trx_datasource: "Trx Datasource",
                budget_range:"Budget Range",
                reference_year:"Reference Year",
                lines_to_be_added_based_on:"What drives the additional transaction lines ?",
                lines_to_be_sorted_based_on:"What drives the order of predicted entities for missing vectors?",
                map_trx_datasource:"Map Trx Datasource",
                budgeting_gl:"Budgeting GL"
            },
            mapping:{
                columns:"Columns",
                mapped:"Mapped to"
            },
            message:"Configuration saved sucessfully",
            metrics_using_a_gl:"Metrics using a GL",
            metrics_using_a_gl_title:"The metrics below are defined using GL data sources",
            no_metrics_using_a_gl_title:"No metrics defined using a GL data source",
            metric_name:"Metric Name"

        }
    },
    no_psl_columns_selected: "Columns could not be found. Please contact your administrator.",
    select_login_destination:"Select your login destination.",
    choose_default_destination:"Choose your default destination.",
    you_can_change_your_selection:"You can change your selection later in preferences.",
    switch_to_client:"Switch to",
    change_client:"Change client",
    preferences:"Preferences",
    log_out:"Log out",
    OR:"OR",
    LOGIN_WITH_SSO: "Log in with SSO",
    LOGIN_USING_DIFF_METHOD:"Log in using different method",
    LOGIN_WITH_YOUR_SSO:"Log in with your single sign-on credentials",
    EMAIL_WILL_BE_SENT:"An email will be sent if your credentials are valid.",
    you_can_select_upto: "You can select up to [limit] records",
    at_least_select_one:"At least one entity must be selected.",
    not_supported_for_na:"Feature not supported for N/A entities",
    column_selection_exceeded:"Column selection exceeds the limit.",
    compare_stacks:"Compare Stacks",
    profit_landscape:{
        tooltips:{
            select:"Click to select",
            unselect: "Click to unselect",
            show_entities:"Click to show entities",
            not_supported:"Not supported for N/A"
        },
        select_segments:"Select 2 segments to compare",
    },
    geography_concentration:{
        compare_button_state : "Compare States",
        compare_button_zipcode : "Compare Zip Codes ",
        compare_state_text:"Select 2 States to compare",
        compare_zipcodes_text:"Select 2 ZIP Codes to compare",
        see_list: "See List",
        hide_list: "Hide List",
        stop_comparison: "Stop comparison"
    },
    filters_selected: "[X] filters selected",
    filters_not_selected: "Select up to [X] quick filters",
    select_filters: "Select filters",
    suggested: "Suggested",
    all: "All",
    advanced_filters:"Advanced filters",
    show_details:"Show details",
    clear_filters:"Clear filters",
    Q_QT_not_applied:"Note that Quadrant and Quadrant/Tier filters are not applied in this report.",
    Q_and_QT:"Quadrant and Quadrant/Tier filters",
    note_that_ps_filter:"Note that the profit stack filter",
    are_not:"aren't",
    is_not:"isn't",
    applied_in_this_report:"applied in this report.",
    clear_advanced_filter:"Clear current advanced filters?",
    switching_to_quick_filter:"Switching to quick filter will clear up all filters applied.",
    clear_filters:"Clear filters",
    allNotGenerated: 'ALL_NOT_GENERATED',
    monthly_build: {
        steps: {
            name:{
                introduction: "Introduction",
                upload_files: "Upload files",
                tie_out_pl_gl: "Tie out P&L & GL",
                tie_out_gl_trx:  "Tie out GL & TRX",
                define_cost_centers: "Define cost centers",
                setup_profit_stack: "Assign GL strings",
                build_scenario:  "Build",
            },
            value:{
                introduction: "INTRODUCTION",
                upload_files: "UPLOAD_FILES",
                tie_out_pl_gl: "TIE_OUT_PL_GL",
                tie_out_gl_trx: "TIE_OUT_GL_TRX",
                define_cost_centers: "DEFINE_COST_CENTERS",
                setup_profit_stack: "SETUP_PROFIT_STACK",
                build_scenario: "BUILD_SCENARIO",

            },
            status:{
                COMPLETED:"COMPLETED",
                NOT_COMPLETED:"NOT_COMPLETED",
                REBUILD:"REBUILD"
            }
        },
        intro: {
            update_model_with_period: "Update your model with a new period",
            monthly_build_description: "The Monthly Build path will guide you to complete all the necessary steps to deliver a fully configured and built model for a new period in a smooth and time-efficient manner.",
            before_you_start: "Before you start",
            make_sure_suggested_periods_are_ready: "Have all [period] relevant files ready as agreed upon with Profit Isle.",
            ensure_pl_available: "Ensure that the P&L statement is available, since you will need to extract some values from it for the tie out section.",
            period_setup: "Period setup for [period]",
            last_built_period: "Last published period is [period]",
            start_date: "Start date: ",
            end_date: "End date: ",
            why_cant_change_enddate: "Why can't I change the end date?",
            why_cant_change_this: "Why can’t I change this?",
            why_cant_change_enddate_description: "This period has already been defined; you are unable to change the ending date.",
            understand_checkbox_label: "I understand I will no longer be able to change the end date after clicking on start",
            rollback_checkbox_label: "I understand I will no longer be able to rollback to the previous period after clicking on start",
            proceed_error_msg: "Resolve the concern above to proceed.",
            checkbox_unchecked_error_msg: "Acknowledge the message[S] above to proceed",
            changes_were_not_saved: "Changes of the end date were not saved",
            do_you_want_save_changes: "Do you want to save changes you made to the end date before you proceed? The end date was changed to [start_date] from [end_date]",
            save_proceed_btn: "Save and proceed",
            discard_changes: "Discard changes",

        },
        upload_files: {
            step_2: "Step 2",
            upload_title: "Upload files",
            period_banner: "For period [period]. Start date: [start_date] End date: [end_date]",
            to_proceed_text: "To proceed, upload & process required files.",
            required_file: "Required file",
            automated_file: "Automatically generated file",
            upload_sftp: "Upload to SFTP",
            process_sftp: "Process from SFTP",
            status: {
                not_uploaded: {label: "Not Uploaded", value: "not_uploaded"},
                pending: {label: "Pending", value: "pending"},
                processed: {label: "Processed", value: "processed"},
            },
            file_status: {
                failed_upload: "failed_upload",
                passed: "passed",
                failed_decrypt:"failed_decrypt"
            },
            errors_in_file: "Errors in the file",
            errors_in_automated_file: "Pending other files to be uploaded & processed.",
            see_erros: "See errors",
            ignore:"Ignore",
            confirm:"Confirm",
            cancel:"Cancel",
            ignore_message_info: "This data source had a crosswalk configuration covering the previous period. By confirming this message, you won’t be required to upload a crosswalk file for the current period anymore.",
            ignore_crosswalk_dialog_title : "Ignore crosswalk for current period?",
            crosswalk_ignored_toast_message: "Current crosswalk ignored",
            succeded_text: "Successfully processed",
            succeded_with_errors: "Successfully processed with errors",
            pending_process_sftp: "Pending process from SFTP",
            upload_url: "https://sftp.profitisle.com/workspace#/",
            refresh_btn: "Refresh",
            filter_by_category: "Filter by category",
            proceed_error_msg: "Some files are still not processed. See information above",
            pending_upload: "Pending upload to SFTP",
            pending_process: "Pending process from SFTP",
            files_did_not_match_any_file_template: "The files below did not match any data source file naming template",
            see_errors_dialog_title:" contains errors:",
            see_errors_dialog_ending:"Fix the file and upload it again.",
            see_errors_dialog_with_failed_crosswalk:"Check the files and re-upload. Otherwise, contact your admin or Profit Isle representative.",
            automated_data_see_errors_dialog:"Please contact your admin or Profit Isle representative",
            proceed_dialog:{
                title:"Some required Data Sources are not yet uploaded.",
                action_proceed:"Proceed anyway",
                action_cancel:"Cancel",
                body:"Do you still want to proceed anyway?"
            }
        },
        tie_out_pl_gl: {
            step_3: "Step 3",
            tie_out_pl_gl_title: "Tie Out P&L & GL",
            period_banner: "For period [period]. Start date: [start_date] End date: [end_date]",
            banner_error: "Undefined accounts detected! Please contact your admin or Profit Isle representative for assistance.",
            tie_out_pl_gl_description: "To ensure accuracy, input your P&L [EBITDA] value and click the tie out button to check if it matches the GL:",
            input_question: "What is the P&L [EBITDA] value for [period]?",
            no_value_error_message: "Please fill in the value above and tie out to proceed.",
            no_tie_out_error_message: "You must tie out to proceed.",
            resolve_concern_error_message: "Resolve the concern above to proceed.",
            banner_messages:{
                TIED_OUT: "Tied out! Your P&L <EBITDA> equals the GL <EBITDA>.\nYou can now proceed to the next step.",
                NEARLY_TIED_OUT: "Your P&L <EBITDA> nearly tied out with the GL <EBITDA> <GL EBITDA>\n(Tie out Δ: <Tie out Δ value> | Tie out %: <Tie out %>%)\nYou can proceed to the next step. However, you can rectify the P&L <EBITDA>  in the field above or perform a detailed tie out at a more specific level.",
                NOT_TIED_OUT: "Your P&L <EBITDA> did not tie out with the GL <EBITDA> <GL EBITDA>\n(Tie out Δ <Tie out Δ value> | Tie out %: <Tie out %>%)\nYou can rectify the P&L <EBITDA> in the field above 👆🏿 or perform a detailed tie out at a more specific level."
            },
            modal:{
                input_label: "What is P&L [CATEGORY] value for [PERIOD]?",
                error: "Value entered not accepted",
                banner_messages:{
                    TIED_OUT: "Tied out! Your P&L <EBITDA> equals the GL <EBITDA>.\nYou can now proceed to the next step.",
                    NEARLY_TIED_OUT: "Your P&L <EBITDA> nearly tied out with the GL <EBITDA> <GL EBITDA>\n(Tie out Δ: <Tie out Δ value> | Tie out %: <Tie out %>%)\nYou can proceed to the next step. However, you can rectify the P&L <EBITDA>  in the field above or make sure your GL data is accurate.",
                    NOT_TIED_OUT: "Your P&L <EBITDA> did not tie out with the GL <EBITDA> <GL EBITDA>\n(Tie out Δ: <Tie out Δ value> | Tie out %: <Tie out %>%)\nChange the P&L <EBITDA> in the field above 👆🏿 or make sure your GL data is accurate"
                },
            },
            skip_button:"Skip this step"

        },
        cost_centers: {
            step_5: "Step 4",
            cost_centers_title: "Define Cost Centers State",
            period_banner: "For period [period]. Start date: [start_date] End date: [end_date]",
            intro_text:"Cost centers are separate units or divisions in a company that don't directly generate profit but still require funds to operate.\nCost center states group or classify cost centers based on their current function or purpose within a fiscal period. They can either be revenue generating or non-revenue generating.\n",
            no_new_text: "Since there are no newly detected cost centers, you can only view or edit the states of cost centers previously seen in past periods which are by default inheriting their states from the previous fiscal period.\nWould you like to edit them?",
            existing_title:"Existing cost centers for period [period]",
            new_title:"[count] new cost centers detected:",
            new_text:"In this step, you are required to define the states of all newly detected cost centers. You can also view or edit the states of cost centers previously seen in past periods which are by default inheriting their states from the previous fiscal period.",
            view_cost_centers:"View cost centers seen in past periods",
            hide_cost_centers:"Hide cost centers seen in past periods",
            empty_states_error_message: "Define all new cost centers to proceed.",
            generate_cost_centers_error_message: "Something went wrong! Please contact your admin or Profit Isle representative for assistance.",
            modal:{
                title: "Add new cost center state",
                name: "Name",
                description: "Description"
            }
        },
        setup_profit_stacks: {
            step: "Step [s]",
            title:"Assign GL strings",
            intro:"Assign new GL strings to their respective profit stack line(s).",
            tabs:{
                strings:"Assign GL strings",
                review:"Review assignments"
            },
            modal:{
                title:"Would you like to save your progress?",
                progress_lost:"If you leave without saving, all your progress will be lost.",
                save:"Save progress",
                leave:"Leave without saving"
            },
            intro_no_unassigned_gl_strings:"There are no new GL strings. Proceed to the next step.",
            cards:{
                gl_string:"GL string",
                psl:"Assign to PSL:"
            },
            distributed_between_stmt:"Distributed between:",
            banner_psl_linkage_stmt:"These Profit Stack Lines are linked together. The GL string will be assigned to all of them.",
            validation:"The GL string is still not assigned.",
            no_suggestions_header:"No suggestions",
            no_suggestions_message:"There is not enough data to suggest.\nPlease assign manually",
            cc_base:"Cost center based profit stack lines",
            flipped:"Flipped sign",
            excluded_btn_name:"Exclude GL String",
            excluded_tag_name:"Excluded",
            ai_accept_btn_name:"Accept",
            manually_assign_btn_name:"Manually assign",
            discard_to_suggested_btn_name:"Discard to suggested",
            submitted_tag_stmt:"Submitted",
            suggested_tag_stmt:"Suggested",
            unassigned_review_cell_stmt:"Not assigned",
            existing_assignments_btn_name:"Existing assignments",
            reassign_btn_name:"Re-assign",
            discard_btn_name:"Discard",
            excluded_stmt:"This GL string is excluded.\nIt is not assigned to any PSL.",
            btns:{
                flip: "Flip sign"
            },
            error_message: "Some GL strings are still not mapped.",
            error_message_bad_request: "Something went wrong, please contact Profit Isle Team.",
            progress_saved_successfully: "Your progress is now saved successfully!",
        },
        build_scenario:{
            step: "Step [s]",
            title:"Build",
            period_list_title:"Periods affected by this build:",
            build_progress:{
                start_build:"Start Your Build",
                pre_build_message:"Upon starting, your period build will be generated.",
                build_percentage_message:["Your build is currently ","% complete"],
                feel_free_footer:"Feel free to navigate away from this screen. We’ll notify you once it's finished.",
                changes_display_footer:"Changes will not display in the output reports until you review the build summary & publish your build.",
                build_success_header:"Your build has been completed successfully",
                build_failed:"Build Failed",
                build_fail_header:"Our team has been notified, you may also contact your admin or Profit Isle representative.",
                build_fail_footer:[
                    "Please consult with your admin or Profit Isle representative before ",
                    "restarting the build",
                    "."
                ],
                build_success_footer:[
                    "Please consult with your admin or Profit Isle representative, if you need to ",
                    "restart the build",
                    ".",
                ],
                restart_build_modal:{
                    header:"Are you sure you want to restart the build?",
                    body:"Reinitiating the build will overwrite the previous one.",
                    restart_btn:"Restart Build",
                    dismiss_btn:"Dismiss"
                },
                cancel_build_modal:{
                    header:"Are you sure you want to cancel the build?",
                    body_cancel:"You are about to cancel the build. If you do, you'll have to start the build over.",
                    body_moving:"By leaving this screen, the build in progress will be canceled.",
                    cancel_btn:"Cancel Build",
                    dismiss_btn:"Dismiss"
                },
                failed_build_statement:"Contact your admin or Profit Isle Representative to fix the issue",
                failed_build:"Failed to build",
                succeeded_build_statement:"Your build has been completed successfully",
                succeeded_build:"Build Complete",
            },
            publish_period:{
                toast:{
                    publish_start:"Publishing Monthly Build. We will notify you when complete.",
                    publish_done:"Your Monthly Build period has been published!",
                    publish_fail: "Publishing failed. Please try again or contact support if the issue persists."
                },
                republish_modal:{
                    title:"Are you sure you want to re-publish your period?",
                    body:"Prior to republishing your period, it's recommended to consult with your admin or PI representative.",
                    action:"Re-publish period",
                    cancel:"Dismiss"
    
                },
                publish_status:{
                    initial:"INITIAL",
                    ongoing:"PUBLISHING",
                    success:"PASSED",
                    fail:"FAILED"
                },
                publish_period_not_confirmed_error:"Acknowledge the message above to proceed.",
                publish_period_success:"Period published.",
                publish_period_success_statement:"The monthly build process has been completed.",
                
                get_started:"Get Started",
                initial:{
                    title:"Publish period",
                    sub_title:"The build process has been completed successfully, it’s time to publish your changes.",
                    body:"Since you’ve completed the build process and taken a moment to review the build summary, you can\nnow proceed to publish your period build.\nThis action will smoothly integrate the new period into the output reports, and it's important to know\nthat once done, you cannot make any additional changes unless you rollback to this period later.",
                    footer:"By checking this box, I confirm my understanding and acceptance of the points mentioned above."
                },
                ongoing:{
                    title:"Publishing",
                    sub_title:"Your build is currently being published, it will take a few moments",
                    footer:"Feel free to navigate away from this screen. We’ll notify you once it's finished."
                },
                failed:{
                    title:"Publish Failed",
                    banner_failed_1:"Period failed to publish.",
                    banner_failed_2:{
                        1:"Please consult with your admin or Profit Isle representative before ",
                        2:"re-publishing",
                        3:".",
                    },
                }
                
            }
        },
        failed_upload_messages:{
            1001: "Decryption failed",
            1002: "Duplicate name in header",
            1003: "Invalid column names",
            1004: "Header row not available",
            1005: "Reserved keyword in header",
            1006: "Invalid delimiter",
            1007: "File is not in a machine readable format",
            1008: "Unescaped special characters used in data",
            1009: "Some mandatory columns are not defined",
            1010: "Primary key is not unique",
            1011: "Some periods do not belong to the start and end period range",
            1012: "Data does not match defined formatting",
            1013: "Data does not match defined data types",
            1014: "Some columns are not defined",
            1016: "Some dates are not associated with any transaction",
            1017: "No Column has unique entities",
            1018: "Some source/target crosswalk columns are missing",
            1019: "Source crosswalk columns are not unique",
            1020: "Target crosswalk columns used for primary key are not unique",
            1021: "Currency is not unique",
            1022: "Conversion currency value does not exist in the data",
            1023: "Some periods/dates do not belong to the start and end period range",
            1024: "Some dates do not belong to the start and end period range",
            1025: "Some currencies do not exist in the currency conversion file",
            1026: "Some currency conversion columns are not defined",
            1027: "File is empty",
            1028: "Null values in data",
            1029: "Period in the filename does not match the current period",
            497: "Decryption failed.",
            495: "Incorrect encryption key."
        },
        rejected_upload_messages:{
            data_source_is_not_defined:"Data Source is not defined",
            period_bad_format:"Filename contains an uncreated or bad format period",
            periods_are_not_within_defined_range_in_data_source:"Periods are not within defined range in data source",
            encryption_key_is_not_correct:"Encryption Key is not correct",
            reply_data_source_is_not_defined:"Data source columns is not defined. Please contact your admin or Profit Isle representative.",
            reply_periods_are_not_within_defined_range_in_data_source:"Filename contains a period that is not within the range currently set as: ",
            reply_encryption_key_is_not_correct:"Incorrect encryption key.",
            automation_error:"Data generation failed because of an incorrect or missing configuration.",
            crosswalk_not_configured:"Crosswalk is not configured.",
            crosswalk_period_not_matching:"Period in the filename does not match the current period.",
        },
        force_rebuild_message: "Some changes were made, you will need to re-build your period.",
        cancel_revert_message:"You are reviewing the %P% period, if no changes are made, you can republish directly without having to rebuild.",
        cancel_revert_btn:"Re-publish this period",
        rollback:{
            confirm_checkbox:"I understand I will no longer be able to rollback to the previous period after clicking on start",
            title:"Are you sure you want to rollback to %P%?",
            body:"Rolling back to [previousPeriod] will revert Profit Isle model back to its previously published data, allowing you to re-do your latest build.",
            rollback_btn:"Rollback to previous period",
            rollback_btn_introduction:"Rollback to previous period [previousPeriod]",
            dismiss_btn:"Dismiss"
        },
       cancel_rollback: {
        title:"Are you sure you want to re-publish %P%?",
        body:"By re-publishing this period, the output will get updated, re-showing  the data from the lastly built period.",
        cancel_rollback_btn:"Re-publish",
        dismiss_btn:"Dismiss"
       } 
    },
    menu:{
        switch_to: "Switch To",
        profit_isle_start: "ProfitIsle Start",
        years_ago: "years ago",
        last_year: "last year",
        months_ago: "months ago",
        last_month: "last month",
        weeks_ago: "weeks ago",
        last_week: "last week",
        days_ago: "days ago",
        last_day: "Last day",
        yesterday: "yesterday",
        today: "today",
        welcome: "Welcome",
        last_login: "Last login",
        powered_by_profit_isle: "Powered by Profit Isle",
        welcome_message_subtitle:"What do you want to work on?",
        hi_text:"Hi",
        add_module_subtitle:"Explore all of our tools & add them to your system",
        add_module_title:"Add a Module",
    },
    no_menu_links_found_msg: "You have insufficient access. Please contact your administrator.",
    scenario: "Scenario:",
    reports_label: "Reports:",
    overlay_go_msg: "Press on the Apply button to get your new data.",
    configure_account_ranges:{
        wrong_input:"Make sure inputted values are acceptable.",
        wrong_configuration:"Some accounts exist in your data but are not included in the configuration above.",
        export:"Export accounts"
    },
    tags:{
        required:" (Required)",
        optional:" (Optional)"
    },
    vectors_saved: "Vectors saved",
    build_progress_jobs: {
        assigning_costs:['Stage Vectors','Stage Exclusions','Stage Calculated','Stage Metrics','Stage Pss','Build','Generate Quads','Generate Fact'],
        generating_segments:"Generate Segments",
        build_already_running_on_scenario: "A build is already running for this scenario",
    },
    build_progress_jobs_new: {
        preparing_data:['Stage Exclusions','Stage Calculated','Stage Vectors','Stage Metrics','Stage Pss'],
        assigning_amounts:['Prepare Assign Cost Temp Tables', 'Run Fy Projection', 'Assign Cost Save Queries', 'Finalize Assign Cost'],
        generating_data:['Generate Build Data Table','Generate Quads','Update Fact Data'],
        generating_segments:["Generate Segments"],
        build_already_running_on_scenario: "A build is already running for this scenario",
    },
    build_progress_currently_running_job: {
        preparing_data:['Stage Vectors','Stage Exclusions','Stage Calculated','Stage Metrics'],
        assigning_amounts:['Stage Pss', 'Prepare Assign Cost Temp Tables','Run Fy Projection', 'Assign Cost Save Queries'],
        generating_data:['Finalize Assign Cost', 'Generate Build Data Table','Generate Quads'],
        generating_segments:'Update Fact Data',
        build_done:'Generate Segments',
        build_already_running_on_scenario: "A build is already running for this scenario",
    },
    switching_client:"Switching",
    switch_client_text:"One moment while we switch, this won’t take long",
    no_results:"No results",
    lamp_emoji: "💡",
    apply_filter_tip: "Tip:<b> Apply a filter.",
    you_are_viewing_amounts_percentage: "You are viewing<i> amount percentage",
    you_are_viewing_percentage: "You are viewing<i> percentage of [PSL]",
    you_are_viewing_amounts_selected_vector: "You are viewing<i> [average] amount per [selected vector]",
    you_are_viewing_amounts_selected_attribute: "You are viewing<i> [average] amount per [selected attribute]",
    twelve_months_rolling: "(12 months rolling)",
    viewing_pecentage_net_revenue: "Viewing Percentage of %REVENUE%",
    viewing_pecentage_cogs: "Viewing Percentage of COGS",

    footer_text:{
        login_text:"Profit Isle Inc. All rights reserved.",
        landing_text:", all rights reserved",
        powered_by:"Powered by"

    },
    supply_chain_set_up:{
        exclusions:{
            subtitle: "Select what to exclude from this module",
            input_labels: {
                select_filter: "Select:",
                select_vector: "Select Vector:",
                select_field: "Name / Number:",
                select_function: "Pick a Function:",
                select_value: "Set a Value:"
            },
        },
        vectors:{
            subtitle: "Set up the vectors"
        },
        buckets:{
            subtitle: "Aggregate your total landed costs",
            title_first_section:"Bucket Name:",
            title_second_section:"Choose Profit Stack Line(s):"
        }
    },
    trend_types:[
        {label:"% of %REVENUE%", value:"percentage_per_revenue"},
        {label:"% of COGS", value:"percentage_per_cogs"},
        {label:"Amount (%CURRENCY%)", value:"value"},
    ],
    long_name: "Name must not surpass %X% characters.",
    tlc_landscape: {
      select_entities_title: "Select Entities",
      select_entities_description: "Choose which entities you’d like to view in the chart",
      maximum_entities: "(3 maximum)"
    },
    select_up_to_3_items: "Select up to 3 items:",

    sort_titles:{
        sort_a_z:"Sort A-Z",
        sort_z_a:"Sort Z-A"
    },
    drill:{
        start_drilling: "Start Drilling...",
        start_drilling_message:"How would you like to drill into your selected items?",
        by_segment: "By Segment",
        by_tier: "By Tier",
        by_entity: "By Entity"
    },
    select_entities_list: "You can select ${remaining} more ${rowText}",
    make_this_my_default: "Make this my default",
    add_scope: "Add Scope",
    edit_scope: "Edit Scope",
    remove_scope: "Remove Scope",

    profit_assist:{
        ask:"ASK",
    }

};


//please make sure to use the same naming convention(lower case with underscores), please do not create a new naming convention each time you add variables
//NB: please try to rename your old variables if they do not match the naming convention mentioned above. Thanks
export { lang };
