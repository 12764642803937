import React, {Component} from "react";
import {
    buildNewObject,
    getTranslationFile,
    copyObjectValues,
    findOptionByKey,
    findOptionByKeyValue,
    deepCompareObjects,
    tryParse,
    updateData,
    updateAllData,
    capitaliseFirstLetterAfterChar
} from '../../class/utils.js';
import  {components} from 'react-select';
import { ToggleTab } from '../../form/elements.js';
import AsyncSelect from 'react-select/async';
import {
    FILTER,
    API_URL,
    QUADRANTS,
    UI_ACTIONS,
    SAVED_FILTER_ID_COOKIE,
    CURRENT_FILTER_COOKIE,
    VECTOR_STAGING_ATTRIBUTES,
    IDENTIFIERS,
    CURRENT_FILTER_VISIBILITY_COOKIE,
    COST_FUNCTIONS_TITLES,
    BUTTON_VARIANT,
    SIZES,
    BUTTON_TYPE,
    DROPDOWN_TYPE,
    DIALOG_SIZE,
    SEGMENTS,
    SEGMENTS_TITLES,
    ALL_WIDGETS,
    SIDE_PANEL_BUILD,
    IS_BUILD_RUNNING,
    BUILD_STATUS,
    BUILD_SCENARIO_ID,
    RAW_ITEMS,
    UPLOAD_SECTIONS,
    Formats,
    SCENARIO_TYPE, SECTION,
    SUPPLY_CHAIN_SETUP,
    PSS,
    costtype,
    SCOPE_FILTERS
} from "../../class/constants.js";
import { getObjectAsArray } from "../../class/jqueries";
import { toggleLoader, shouldCompUpdate, setLocalStorageValueByParameter, getLocalStorageValueByParameter, getDefaultLocalStorageValueByParameter, formatAdvancedFilter, getOutputScreenName } from "../../class/common.js";
import { fetchDatasets } from "../../class/commonRequests.js";
import { getNumericValue } from "../../class/number.js";
import { getFilterDisplayClass, validateFilterRow, getFilterQuadrantColorStyle, returnLastDatasets, getFilterBackgroundColor } from "./FilterHelperFunctions.js";

import '../../styles/common.css';
import "../../styles/filter.css";
import 'jqueryui'
import { fetchAPI, FETCH_METHOD, FETCHAPI_PARAMS, deleteItemFromSession, getDataFromSession, logUIError } from "../../class/networkUtils";
import {findIndexOfValue, linearizeHierarchy, removeDuplicateKeyValues} from "../../class/array";
import { getVectorAllowedAttributes } from "../manageAccess/ManageAccessUtils";
import { lang } from "../../language/messages_en";
import { convertPxToViewport, convertViewportToPx } from "../../class/formatting";
import {  updateQTs } from '../../actions/filterActions';
import { Icon } from "@mui/material";
import Dropdown from "../../newComponents/DropDown.js";
import Input from "../../newComponents/Input.js";
import Button from "../../newComponents/Button.js";
import Modal from "../../newComponents/Modal.js";
import { Segment } from "../../components/Segment.js";
import {getProfitStackHierarchy} from "../../templateLayout/api/api";
import Container from "../../components/manageColumns/Container";
import { ReactComponent as SaveFiltersIcon } from '../../styles/images/save-bookmark.svg'
import { ReactComponent as AlertOctagon } from "../../styles/images/alert-octagon.svg";
import CheckBox from "../../newComponents/CheckBox.js";

const $ = require('jquery');
const UIkit = require('uikit');
const MESSAGES = getTranslationFile();

const FILTER_TYPE_VECTOR = FILTER.VALUES.TYPE.VECTOR;
const FILTER_TYPE_PROFITSTACK = FILTER.VALUES.TYPE.PROFIT_STACK;

const _neq = MESSAGES.ui_filter.dropdowns.functions.not_equals.value;
const _ct = MESSAGES.ui_filter.dropdowns.functions.contains.value;
const _nct = MESSAGES.ui_filter.dropdowns.functions.not_contains.value;
const _gt = MESSAGES.ui_filter.dropdowns.functions.greater.value;
const _lt = MESSAGES.ui_filter.dropdowns.functions.less.value;
const _ltoe = MESSAGES.ui_filter.dropdowns.functions.less_or_equalsNUM.value;
const _gtoe = MESSAGES.ui_filter.dropdowns.functions.greater_or_equalsNUM.value;
const _eq = MESSAGES.ui_filter.dropdowns.functions.equals.value;
const _empty = MESSAGES.ui_filter.dropdowns.functions.empty.value;
const _nempty = MESSAGES.ui_filter.dropdowns.functions.not_empty.value;
const _isBetween = MESSAGES.ui_filter.dropdowns.functions.betweenNUM.value;
const _startWith = MESSAGES.ui_filter.dropdowns.functions.start_with.value;
const _endWith = MESSAGES.ui_filter.dropdowns.functions.end_with.value;

const stringFunctionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.functions, "string", "value_type");
const numericFunctionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.functions, "numeric", "value_type");
const stringQuadrantFunctionOptions = removeDuplicateKeyValues(getObjectAsArray(MESSAGES.ui_filter.dropdowns.functions, "true", "quadrant_option"),"value");

const _type = FILTER.KEYS.TYPE;
const _vector = FILTER.KEYS.VECTOR;
const _vectorLabel = FILTER.KEYS.VECTOR_LABEL;
const _field = FILTER.KEYS.FIELD;
const _function = FILTER.KEYS.FUNCTION;
const _entities = FILTER.KEYS.ENTITIES;
const _entitiesOptions = FILTER.KEYS.ENTITIES_OPTIONS;
const _logicalOperator = FILTER.KEYS.LOGICAL_OPERATOR;
const _from = FILTER.KEYS.FROM_QUARTER;
const _to = FILTER.KEYS.TO_QUARTER;
const _toOptions = FILTER.KEYS.TO_QUARTER_OPTIONS;
const _parenthesisBefore = FILTER.KEYS.PARENTHESIS_BEFORE;
const _parenthesisAfter = FILTER.KEYS.PARENTHESIS_AFTER;
const _filterRowType = FILTER.KEYS.FILTER_ROW_TYPE;

const _AND = MESSAGES.ui_filter.dropdowns.buttons.AND;
const _ADD = MESSAGES.ui_filter.dropdowns.buttons.ADD;
const _OR = MESSAGES.ui_filter.dropdowns.buttons.OR;

const _quadrantField = MESSAGES.ui_filter.dropdowns.fields_attributes.quadrant.value;
const _quadrantTierField = MESSAGES.ui_filter.dropdowns.fields_attributes.quadrant_tier.value;

const _profitSegmentField = SEGMENTS_TITLES.PROFIT_SEGMENTS.value;
const _profitTierFiled = SEGMENTS_TITLES.PROFIT_TIERS.value;

const SF_ISPUBLIC = FILTER.SAVED_FILTER.IS_PUBLIC;
const SF_NAME = FILTER.SAVED_FILTER.NAME;
const SF_ID = FILTER.SAVED_FILTER.ID;
const SF_FILTER = FILTER.SAVED_FILTER.FILTER;
const SF_TYPE = FILTER.SAVED_FILTER.TYPE;
const SF_EDITABLE = FILTER.SAVED_FILTER.EDITABLE;
const SF_CREATED_BY = FILTER.SAVED_FILTER.CREATED_BY;
const _name_in_fact = "name_in_fact";
const PROFITSTACK_NAMES_RETURNNAMES = [];
const listsToFetch = [];

//sample filter object with same parameters
//for both vector and profit stack filter types
//-- Do not copy, call getEmptyFilterSample()
const sampleFilterObj = {
    [_type]: "",
    [_name_in_fact]: "",
    [_vector]: "",
    [_vectorLabel]: "",
    [_field]: "",
    [_function]:"",
    [_entities]: [],
    [_entitiesOptions]: [],
    [_from]: "",
    [_to]: "",
    [_logicalOperator]: "",
    [_parenthesisBefore]: "(",
    [_parenthesisAfter]: ")",
    [_filterRowType]: FILTER.VALUES.FILTER_ROW_TYPE.FILTER,  //to set this filter as type "filter" and not quadrant or drill or ps filter
    [FILTER.KEYS.MAX]:"",
    [FILTER.KEYS.MIN]:"",
    ["returnname"]:"",
    ["rank"]: 1
};

const baseUrl = process.env.REACT_APP_BASE_URL;
const visibilityOptions = [{label: FILTER.VISIBILITY_OPTIONS.USER_LABEL, value: FILTER.VISIBILITY_OPTIONS.USER_VALUE, iconClass: "fa-lg fal fa-user"} , {label: FILTER.VISIBILITY_OPTIONS.COMPANY_LABEL, value: FILTER.VISIBILITY_OPTIONS.COMPANY_VALUE, iconClass: "fa-lg fal fa-users"}]
const filterDialog = "- Filter";
class FilterDialog extends Component {
    constructor(props) {
        super(props);
        this.drillingTiers = null;
        this.state = {
            vectorOptions: [],
            filterRefs: this.getNewFilterRefsArray(), //initialized this way to start with at least one row
            selectedOptions: null,
            fromOptions: [],
            toOptions: [],
            initialFilter: this.getEmptyFilterSample(), //this array holds the value of the applied filter (on apply), it's used to revert changes on Cancel
            filterWasEdited: false,

            /* this state starts empty, when filter is changed from outside (from display or clicking on quad count...)
             * we save the new filter inside it because we cannot save it in the reference array and read from it in
             * renderFilterDialog where the state.filterRefs is automatically filled on render
             */
            outsideFilter: [],
            useOutsideFilter: false,
            savedFilterData: props.savedFilterDataState || {},  //this state will hold the data for the previously saved filter, selected by the user from the dropdown
            savedFilterData_temp: {[SF_ISPUBLIC]: false},  //this state will hold the data for the new filter being saved, set private by default
            advancedData: [],
            readOnlyFilter: props.readOnlyFilter ? props.readOnlyFilter : false,
            fromBasicFilter: false,
            OpenSaveFilter: false,
            OpenForgetFilter: false,
            cancelFilterDialog: false,
            visibility: visibilityOptions[0].value,
            filterNameExist: false,
            filterNameEmpty: false,
            savedFilterOpen: false,
            pslArray:[],
            isCheckBoxChecked: tryParse(sessionStorage.getItem(SCOPE_FILTERS))?.[0]?.isDefault

        };

        this.fetchAPI = fetchAPI.bind(this);
        this.fetchDatasets = fetchDatasets.bind(this);
        this.addNewFilter = this.addNewFilter.bind(this);
        this.onChangeFilterRow = this.onChangeFilterRow.bind(this);
        this.startClear = this.startClear.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.dismissModal = this.dismissModal.bind(this);
        this.revertAdvancedFilter = this.revertAdvancedFilter.bind(this);
        this.submitAdvancedFilter = this.submitAdvancedFilter.bind(this);
        this.fetchQuadrantTiers = this.fetchQuadrantTiers.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.markFilterForDeletion = this.markFilterForDeletion.bind(this);
        this.renderFilterDisplay = this.renderFilterDisplay.bind(this);
        this.onChangeSavedFilterId = this.onChangeSavedFilterId.bind(this);
        this.onChangeSavedFilter = this.onChangeSavedFilter.bind(this);
        this.startSaveFilter = this.startSaveFilter.bind(this);
        this.startCancelFilter = this.startCancelFilter.bind(this);
        this.startForgetFilter = this.startForgetFilter.bind(this);
        this.saveFilter = this.saveFilter.bind(this);
        this.forgetFilter = this.forgetFilter.bind(this);
        this.setFilterTo = this.setFilterTo.bind(this);
        this.getSavedFilters = this.getSavedFilters.bind(this);
        this.onSelectSavedFilter = this.onSelectSavedFilter.bind(this);
        this.handleSaveFilterChange = this.handleSaveFilterChange.bind(this);
        this.renderFilterDialog = this.renderFilterDialog.bind(this);
        this.readAndApplyFilterFromCookie = this.readAndApplyFilterFromCookie.bind(this);
        this.renderFilterFormula = this.renderFilterFormula.bind(this);
        this.renderAdvancedFilter = this.renderAdvancedFilter.bind(this);
        this.getTextFilter = this.getTextFilter.bind(this);
        this.getAdvancedFilterData = this.getAdvancedFilterData.bind(this);
        this.reRenderFilter = this.reRenderFilter.bind(this);
        this.renderFilterModal = this.renderFilterModal.bind(this);
        this.updateSavedFilterNameInput = this.updateSavedFilterNameInput.bind(this);
        this.resetTempSavedFilterData = this.resetTempSavedFilterData.bind(this);
        this.resetSavedFilterData = this.resetSavedFilterData.bind(this);
        this.updateSavedDataAttr = this.updateSavedDataAttr.bind(this);
        this.updateSavedFilterData = this.updateSavedFilterData.bind(this);
        this.clearLogicalOperator = this.clearLogicalOperator.bind(this);
        this.updateAdvancedData = this.updateAdvancedData.bind(this);
        this.resetParenthesisInRefs = this.resetParenthesisInRefs.bind(this);
        this.getExportQueryFilter = this.getExportQueryFilter.bind(this);

        this.tempState = {};
        this.lastUpdated = Date.now();
        this.filterDisplayEditable = true;  //used to hide the clear all, (X), and saved filters dropdown when the filter should not be changeable
        this.advancedFilter = "";

        this.cookieActive = props.useCookies !== undefined ? props.useCookies : true;

        this.profitSegmentFields = _profitSegmentField;
        this.tierSegmentFields = _profitTierFiled;
        this.quadrantsFields = _quadrantField;
        this.quadrantTiersFields = _quadrantTierField;

        this.segmentsDictionary = Object.values(copyObjectValues(SEGMENTS)).map(function(item){ item.label = new Segment().getSegmentObject(item.label).label; return item});

    }

    shouldComponentUpdate(nextProps, nextState) {
        return shouldCompUpdate(this, nextProps, nextState);
    }

    startCancelFilter() {
        var comp = this;
        if (comp.state.filterWasEdited) {
            comp.setState({
                cancelFilterDialog: true
            })
        }else{
            comp.dismissModal();
        }
    }

    /**
     * This function runs some checks before actually saving the filter
     */
    startSaveFilter(appliedBasicFilter, fromBasicFilter) {
        this.state.savedFilterData_temp[SF_ISPUBLIC] = $("#by"+(this.props.dashboard ||"")).text().substring($("#by"+(this.props.dashboard ||"")).text().indexOf("(")+1,$("#by"+(this.props.dashboard ||"")).text().indexOf(")")) === "Public"?true:false;
        this.forceUpdate();
        var comp = this;
        var tempFilters = this.getFilterValidationStatus(appliedBasicFilter,fromBasicFilter);
        if(!tempFilters.filter.length) {
            //if no valid filters exist in the modal
            this.openMessageModal(MESSAGES.ui_filter.titles.empty_filter, MESSAGES.ui_filter.messages.empty_filter);
        } else if(tempFilters.invalidFiltersIndices.length){    //check if it has invalid rows, when it is not used in custom views
            this.openMessageModal(MESSAGES.ui_filter.titles.invalid_rows, MESSAGES.ui_filter.messages.invalid_rows,
                function(){
                    comp.cleanUpInvalidFilters(tempFilters.invalidFiltersIndices);
                    //if this is a new filter being saved
                    comp.showModal("filterSaveModal"+(this.props.dashboard||""));
                });
        } else {
            comp.state.savedFilterData_temp[SF_FILTER] = this.extractCleanFilter(appliedBasicFilter, fromBasicFilter); //if saving the new filter, save the filter value in the state
            comp.showModal("filterSaveModal"+(this.props.dashboard||""));
        }
    }

    /**
     * This function sends the save filter request to the api
     * @param {*} saveAsNew 
     */
    saveFilter(saveAsNew = false, isSaveAdvanced) {
        this.state.savedFilterData_temp[SF_NAME] = $('#filterName'+(this.props.dashboard||"")).val() || $('.uk-open #filterName'+(this.props.dashboard||"")).val();
        if(!this.state.savedFilterData_temp[SF_NAME] || this.state.savedFilterData_temp[SF_NAME].trim().length === 0) {
            this.setState({
                filterNameEmpty: true
            })
            return;
        } else if(! /[a-zA-Z]+/.test(this.state.savedFilterData_temp[SF_NAME])){
            this.setInfoDialogOpen(true, "Your filter name should contain at least one character")
            return;
        }

        var comp = this;
        var params = {
            action: "saveFilter",
            saved_filter_id: saveAsNew ? "" : this.state.savedFilterData[SF_ID],
            name: this.state.savedFilterData_temp[SF_NAME],
            filter: JSON.stringify(this.state.savedFilterData_temp[SF_FILTER]),
            // is_public: this.state.savedFilterData_temp[SF_ISPUBLIC],
            filter_visibility: this.state.visibility,
            filter_type: this.state.savedFilterData_temp[SF_TYPE]
        };

        var tempData = {};
        var onThenCallback = function(data) {
            if(data.error){
                return;
            }

            if(data.success){
                tempData = data;
                comp.updateSavedFilterData(data);
                comp.launchToast();
            } else {
                comp.setInfoDialogOpen(true, "Could not save: Unknown Server Error")
            }

            comp.dismissModal();
            
        }

        var onCompleteCallback = ()=>{
            if(tempData.success) {
                deleteItemFromSession("listFilters")
                comp.getSavedFilters(saveAsNew ? comp.state.savedFilterData.saved_filter_id : comp.state.savedFilterData_temp[SF_NAME]);
                comp.onChangeSavedFilter(comp.state.savedFilterData_temp[SF_FILTER], undefined, undefined, comp.state.savedFilterData_temp[SF_NAME], isSaveAdvanced, comp.state.savedFilterData.filter_visibility);  //apply the saved filter
                comp.dismissModal('filterSaveModal'+(this.props.dashboard||""));
            }
        }

        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveFilter",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.FILTER,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: params,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
            [FETCHAPI_PARAMS.machine_name]: this.props.machine_name,
            [FETCHAPI_PARAMS.profitFormat]: this.props.profitFormat,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.save_filter,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + filterDialog,
        }
        this.fetchAPI(fetchOptions);
    }

    startForgetFilter() {
        var comp = this;
        comp.forgetFilter();
        comp.handleCloseForgetFilterDialog()
    }

    forgetFilter() {
        let params = {
            action:"deleteFilter",
            saved_filter_id: this.state.savedFilterData[SF_ID]
        };
        let comp = this;

        let onThenCallback = function(data) {
            if(data.error){
                comp.setInfoDialogOpen(true, data.error)
                return;
            }
            if(data.success){
                deleteItemFromSession("listFilters");   //after deleting a filter, delete it from the session so that the change is reflected
                comp.clearFilter();
                comp.getSavedFilters();
                comp.dismissModal();
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: UI_ACTIONS.FORGET_FILTER,
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.FILTER,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: params,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.machine_name]: this.props.machine_name,
            [FETCHAPI_PARAMS.profitFormat]: this.props.profitFormat,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.delete_filter,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + filterDialog,
        }
        this.fetchAPI(fetchOptions);
    }

    /**This function fetches the data filled in the saved filters dropdown, call it to refresh dropdown values */
    getSavedFilters(newlySavedFilterName) {
        let params = {action: "listFilters"};
        let tempState = {};

        if (this.props.scenario_id) {
            params.scenario_id = this.props.scenario_id
        }

        this.setState({
            isSavedFiltersLoading: true,
            allSavedFiltersOptions: []
        })

        let comp = this;
        const onThenCallback = function (data) {
            if (data.data) {
                let allSavedFilters = data.data;

                // sessionStorage.setItem(JSON.stringify(params), JSON.stringify(data.data));
                tempState = {
                    allSavedFilters: allSavedFilters,
                    isSavedFiltersLoading:false,
                    allSavedFiltersOptions: allSavedFilters.map(f => {
                        let title = f.name + (f.editable ? " *" : " - " + f.created_by_first_name + " " + f.created_by_last_name);
                        let label = f.name + (f.editable ? " *" : " - " + f.created_by_first_name.charAt(0) + f.created_by_last_name.charAt(0));

                        //disabling saved filters that contain unapplicable filters for the current report
                        let disabled = false;
                        let filter = tryParse(f[SF_FILTER], []);
                        filter.map(row => {
                            if (comp.props.psFilterDisabled && row[_type] === FILTER_TYPE_PROFITSTACK) {
                                disabled = true;
                            }
                            if (comp.props.quadrantsDisabled &&
                                [comp.profitSegmentFields, comp.tierSegmentFields, comp.quadrantTiersFields, comp.quadrantsFields].indexOf(row[_field]) > -1) {
                                disabled = true;
                            }
                        });

                        return {
                            label: label,
                            value: f.saved_filter_id,
                            title: title,
                            isDisabled: disabled,
                            isFilterBroken: f.isFilterBroken,
                            rightIcon: f.isFilterBroken ?  <AlertOctagon className="filter-broken-icon" uk-tooltip="This filter cannot be applied on this scenario" /> : "",
                        };
                    })
                };
                if (comp.props.filterInDashBoard || comp.props.isRedirectionFromStacks || newlySavedFilterName) {
                    comp.setState(tempState);
                }
                if (newlySavedFilterName && comp.props.dashboard) {// if we saved a new filter from the widget, update the state of the <filterDialog in the secondaryHeader
                    comp.props.headerRef?.filterDialRef?.setState(tempState);
                }
                if (comp.state.savedFilterData[SF_ID]) {   //if the state is already filled, means the user has previously selected a saved filter
                    let filterSaved = findOptionByKeyValue(allSavedFilters, SF_ID, comp.state.savedFilterData[SF_ID])  //if the state is already filled, means the user has previously selected a saved filter
                    //update savedFilterData state after the user saved it
                    comp.updateSavedFilterData(filterSaved);
                    if (filterSaved?.filter_visibility) {
                        tempState.visibility = filterSaved.filter_visibility;
                        setLocalStorageValueByParameter(CURRENT_FILTER_VISIBILITY_COOKIE, filterSaved.filter_visibility);
                    }
                }

                //if newlySavedFilterName is passed, means the user has just saved a new filter,
                //we should save it in this state after the list of saved filters is fetched
                if (newlySavedFilterName) {
                    if (typeof newlySavedFilterName === 'string') {
                        comp.updateSavedFilterData(findOptionByKeyValue(allSavedFilters, SF_NAME, newlySavedFilterName));
                    } else {
                        comp.updateSavedFilterData(findOptionByKeyValue(allSavedFilters, SF_ID, newlySavedFilterName));
                    }
                }
            }
        }

        const onCompleteCallback = function(){
            comp.awaitOtherLists("getSavedFilters", tempState);
        }
        const fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "listFilters",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.FILTER,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: params,
            [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.useSession]: true,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.get_saved_filters,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + filterDialog,
        }
        this.fetchAPI(fetchOptions);
    }

    /**
     * This function is called when a saved filter id is sent from the outside
     * @param {*} savedFilterId 
     * @param {*} callback 
     */
    onChangeSavedFilterId(savedFilterId, callback, applyFilter=true) {
        var tempSavedFilter = findOptionByKeyValue(this.state.allSavedFilters, SF_ID, savedFilterId);
        this.updateSavedFilterData(tempSavedFilter);

        if(tempSavedFilter) {
            this.onChangeSavedFilter(tempSavedFilter[SF_FILTER], applyFilter);
        } else if(savedFilterId === 0){
            this.onChangeSavedFilter(this.getEmptyFilterSample(), applyFilter);
        }else if(this.state.filterRefs.length > 0){
                let filters = this.state.filterRefs;
                let filtersToAppend = [];
                for(let e in filters){
                    filtersToAppend.push(filters[e].ref.current.filterObj)
                }
                let filterStr = JSON.stringify(filtersToAppend);
                this.onChangeSavedFilter(filterStr, applyFilter);
            } else {
                this.onChangeSavedFilter(this.getEmptyFilterSample(), applyFilter);
            }

        if(typeof callback === "function") {
            callback();
        }
    }

    /**
     * This function is called when the filter is changed from an outside component
     * @param {*} filter 
     * @param {*} applyFilter -- send as false to just update the filter without resending it back to the parent
     */
    onChangeSavedFilter(filter, applyFilter=true, isSwitchBasic, filterDisplayName, isSaveAdvanced, filterVisibility){
        let _this = this
        
        if(typeof filter === "string") {
            try{
                filter = JSON.parse(filter);
            } catch(err) {
                filter = null;
                console.error("Could not parse stringified filter.");
            }
        }
        if(!Array.isArray(filter)) {
            return;
        }
        if(!_this.props.isFromExclusionConfiguration) {
          for(let e in filter){
              filter[e].isBasicFilter = isSaveAdvanced? false : _this.state.savedFilterData && _this.state.savedFilterData.filter_type ? _this.state.savedFilterData.filter_type === FILTER.SAVED_FILTER.BASIC : filter[e].isBasicFilter;
              filter[e].filter_display_name = filterDisplayName || (_this.state.savedFilterData && _this.state.savedFilterData.name ? _this.state.savedFilterData.name : getDataFromSession("listFilters") !== '' && getDataFromSession("listFilters") !== null && getDataFromSession("listFilters") ? JSON.parse(getDataFromSession("listFilters")).data ?.filter(e => e.saved_filter_id === _this.state.savedFilterData?.saved_filter_id)[0]?.name : "");
          }
        }
        var heatmapFilterIndex = filter.findIndex(v => v[_filterRowType] === FILTER.VALUES.FILTER_ROW_TYPE.HEATMAP);
        if(heatmapFilterIndex > 0) {
            filter[0][_parenthesisBefore] += "(";
            filter[heatmapFilterIndex-1][_parenthesisAfter] += ")";
        }

        var comp = this;
        if(filter.length === 0) {
            filter = this.getEmptyFilterSample();
        } else {
            filter[0][_logicalOperator] = "";
        }
        comp.setState({
            visibility: filterVisibility || this.state.savedFilterData.filter_visibility
        })
        this.setFilterTo(filter, ()=>{
            comp.applyFilter(false, applyFilter,false, isSwitchBasic, undefined, filterDisplayName, filterVisibility);
        });
    }

    getExportQueryFilter() {
        return this.state.exportQueryFilter;
    }

    /**
     * DO NOT DELETE COMMENT:
     * update the value by reference, because we're passing "defaultValue" to the input and not "value".
     * - Passing the value prop locks the value of the input and we then have to pass an onChange function
     * and update the state on every keyboard click (performance bottleneck).
     * - Passing the defaultValue prop doesn't lock the value, rather it stores it in the DOM,
     * but then when we update it, it is not updated in the input, so we update it using reference.
     */
    updateSavedFilterNameInput(newValue) {
        if(this.savedFilterNameInput) {
            this.savedFilterNameInput.value = newValue || "";
        }
    }

    /**
     * called when user selects a saved filter from dropdown
     * @param {*} valueObj 
     */
    onSelectSavedFilter(valueObj) {
        //start action here, and it should be closed in fetch data calls in ProfitSummary or ProfitMapList
        window._pi_initialiseTracking(UI_ACTIONS.SELECT_FILTER, this.props.user.email, this.props.machine_name, this.props.profitFormat, false);

        let chosenFilter = findOptionByKeyValue(this.state.allSavedFilters, [SF_ID], valueObj.value);
        let filterDsiplayName = chosenFilter.name
        let isFilterBroken = chosenFilter.isFilterBroken;
        this.updateSavedFilterData(chosenFilter); //set the savedFilterData

        chosenFilter = chosenFilter ? tryParse(chosenFilter[SF_FILTER], this.getEmptyFilterSample()) : this.getEmptyFilterSample();
        chosenFilter.map(m => m.isFilterBroken = isFilterBroken);
        this.state.initialFilter = chosenFilter;    //set the initial filter
        this.onChangeSavedFilter(chosenFilter, undefined, undefined, filterDsiplayName);
    }

    /**
     * Returns a copy of the empty filter sample
     */
    getEmptyFilterSample(sampleType = "array") {
        if(sampleType === "array") {
            return [copyObjectValues(sampleFilterObj)];
        } else {
            return copyObjectValues(sampleFilterObj);
        }
    }

    resetFilterChanges() {
        this.hideAdvancedFilter();
        
        var tempFilters = this.getFilterValidationStatus();
        if (!tempFilters.filter.length) {
            $("#advanced-text").removeClass("uk-hidden");
            $("#advanced-filter").addClass("uk-hidden");
        }

        this.state.filterWasEdited = false; //set as not edited on reset
        this.setFilterTo(this.state.initialFilter, this.revertAdvancedFilter); //reset filter to initial value
    }

    showModal(modalId) {
        // UIkit.modal('#'+ modalId).show();
        this.handleOpenSaveFilterDialog();
    }

    dismissModal(modalId) {
        modalId = modalId || this.componentId;  //if no modal id is sent, dismiss the entire filter modal
        if ($('#filterMessageModal'+(this.props.dashboard ||"")).length > 1) {
            $('#filterMessageModal'+(this.props.dashboard ||"")).remove(); // in some screens we use filter more than once which will cause to interference between each reference so we're deleting the modal if it exits before creating it
        }
        if ($('#filterMessageModal').length >1) {
            $('#filterMessageModal').remove(); // in some screens we use filter more than once which will cause to interference between each reference so we're deleting the modal if it exits before creating it
        }
        if (UIkit.modal('#' + modalId)) {
            UIkit.modal('#' + modalId).hide();
        }
    }

    /**
     * Adds a new empty filter row
     */
    addNewFilter(newFilter, attribute, e) {
        var tempRefArray = this.state.filterRefs;
        var comp = this;
        if (newFilter) {
            tempRefArray.push(this.getNewFilterRefObject());
        }

        this.updateAdvancedData([]);    //reset advanced data
        this.setState({
            filterRefs: tempRefArray,
            filterWasEdited: true,
            nextLogicalOperator: attribute === _logicalOperator ?  e.value : "" //to put operator on the next row and not previous one
        }, function() {
            comp.resetParenthesisInRefs();
            this.hideAdvancedFilter();
        });
    }

    clearLogicalOperator() {
        this.state.nextLogicalOperator = "";
    }

    onChangeFilterRow(rowIndex, loadEntities, render) {
        this.state.filterWasEdited = true;
        let _this = this;
        this.state.filterRefs[rowIndex].lastUpdated = Date.now().valueOf();
        if(this.props.filterInDashBoard){
            var callback = () => {
                if (loadEntities) {
                    _this.state.filterRefs[rowIndex].ref.current.drawFilterEntities();
                    _this.state.filterRefs[rowIndex].ref.current.resetEntities();
                    _this.state.filterRefs[rowIndex].ref.current.loadEntitiesOnInputChange();
                    _this.state.filterRefs[rowIndex].ref.current.setState({
                        updateOptions: true,
                        functionMenuOpen: false 
                    })
                }
            }
            if(render === true){
                this.forceUpdate(callback);
            }
        }
        //if the user had previously selected a saved filter, remove it when changing a row
        // this.resetSavedFilterData();
    }

    /**
     * Resets the temp data of the saved filter, that is, if the user is saving a filter
     * @param {*} silent 
     */
    resetTempSavedFilterData(silent=true) {
        this.updateSavedFilterNameInput(this.state.savedFilterData[SF_NAME]);
        this.handleCloseSaveFilterDialog();
        if(!silent) {
            this.forceUpdate()
        }
    }

    /**
     * Resets the data of the previously saved filter, that is, the saved filter the user selected from the dropdown
     * @param {*} silent 
     */
    resetSavedFilterData(silent=true) {
        this.updateSavedFilterData({}, silent);
    }

    updateSavedDataAttr(key, value, silent=true) {
        this.state.savedFilterData[key] = value;

        if(!silent) {
            this.forceUpdate();
        }
    }
    updateSavedFilterData(newSavedData, silent=true) {
        this.state.savedFilterData = copyObjectValues(newSavedData, "object");
        this.updateSavedFilterNameInput(this.state.savedFilterData[SF_NAME]);

        if(!silent) {
            this.forceUpdate();
        }
    }

    resetInitialFilter() {
        this.state.initialFilter = this.getEmptyFilterSample();
    }

    getNewFilterRefObject(){
        return { ref: React.createRef(), lastUpdated: Date.now().valueOf() };
    }

    getNewFilterRefsArray() {
        return [this.getNewFilterRefObject()];
    }

    setFilterTo(filter, callback) {
        this.setState({
            outsideFilter: filter,
            useOutsideFilter: true
        }, function() {
            this.state.outsideFilter = [];  //reset outsideFilter after rerender
            this.state.useOutsideFilter = false;
            if(callback && typeof callback === "function") {
                callback();
            }
        })
    }


    /**
     * This function returns an object containing:
     * - filter array containing the valid filters
     * - array of integers containing the indices of invalid filters in state.filterRefs
     */
    getFilterValidationStatus(appliedBasicFilter, fromBasicFilter) {
        var filter = [];
        var invalidFiltersIndices = [];
        var notEmptyFilters = [];
        if(appliedBasicFilter && fromBasicFilter){
            appliedBasicFilter.map((ref, index)=>{
                let filterRow = ref ;
                if(validateFilterRow(filterRow)) {
                    filter.push(filterRow);
                } 
                
                else {
                    invalidFiltersIndices.push(index);
                }
    
                if(filterRow['vector'] && filterRow['vector']!=="") {
                    notEmptyFilters.push(filterRow);
                }
            });
            this.state.savedFilterData_temp[SF_TYPE] = FILTER.SAVED_FILTER.BASIC
        }else{
            this.state.filterRefs.map((ref, index)=>{
                let filterRow = ref.ref && ref.ref.current && ref.ref.current.filterObj ? ref.ref.current.filterObj : "";
                if(validateFilterRow(filterRow)) {
                    filter.push(filterRow);
                } 
                
                else {
                    invalidFiltersIndices.push(index);
                }
    
                if(filterRow['vector'] && filterRow['vector']!=="") {
                    notEmptyFilters.push(filterRow);
                }
            });
            this.state.savedFilterData_temp[SF_TYPE] = FILTER.SAVED_FILTER.ADVANCED;
        }
        if(fromBasicFilter !== this.state.fromBasicFilter){
            this.setState({
                fromBasicFilter:fromBasicFilter
            })
        }


        return { filter: filter, invalidFiltersIndices: invalidFiltersIndices,notEmptyFilters:notEmptyFilters};
    }

    /**
     * This function deletes the invalid filters from state.filterRefs
     * @param {*} invalidFiltersIndices 
     */
    cleanUpInvalidFilters(invalidFiltersIndices) {
        if(!Array.isArray(invalidFiltersIndices) && !isNaN(invalidFiltersIndices)) {
            invalidFiltersIndices = [invalidFiltersIndices];    //a single index was received, wrap it in array
        }

        if(this.props.isFromExclusionConfiguration) {
          invalidFiltersIndices = invalidFiltersIndices.sort((a, b) => b - a);
        }
        invalidFiltersIndices.map(index=>{
            this.removeFilter(index);
        });
    }

        /**
     * This function deletes all the filters from state.filterRefs
     * @param {*} invalidFiltersIndices 
     */
        cleanUpAllFilters() {
            this.state.filterRefs = this.getNewFilterRefsArray();
        }

    /**
     * This function checks if the filter contains any invalid rows and alerts the user if so,
     * otherwise applies the filter
     * @param {*} silent 
     */
    startApply = (silent = true, isSaveAndApply, isDeleteScope) => {
        const comp = this;
        let tempFilters = this.getFilterValidationStatus();
        let invalidFilterIndices = tempFilters.invalidFiltersIndices || [];
        let missingFields = [];

        if(this.props.getIndexesOfInvalidExclusions && typeof this.props.getIndexesOfInvalidExclusions === "function") {
          missingFields = this.props.getIndexesOfInvalidExclusions(this.extractCleanFilter());
          invalidFilterIndices = invalidFilterIndices.concat(missingFields);
        } 

        let isFilterValid = invalidFilterIndices.length === 0;

        // if(comp.props?.setIsFilterValid && typeof comp.props.setIsFilterValid === "function") {
        //   comp.props.setIsFilterValid(isFilterValid);
        // }
        
        let filterVisibility = this.state.savedFilterData.filter_visibility ? this.state.savedFilterData.filter_visibility : visibilityOptions[0].value
        if(this.state.filterWasEdited){
            this.resetSavedFilterData();
        }
        if(!isFilterValid) {
            this.openMessageModal(MESSAGES.ui_filter.titles.invalid_rows, MESSAGES.ui_filter.messages.invalid_rows,
                function(){
                    comp.cleanUpInvalidFilters(invalidFilterIndices);
                    comp.applyFilter(false, true, undefined, undefined, undefined, undefined, undefined, missingFields?.length > 0, true, isSaveAndApply, isDeleteScope);
                    comp.handleCloseFilterMessageDialog();
                   
                });
        } else {
            if(isDeleteScope){
                this.cleanUpAllFilters();
            }
            comp.applyFilter(false, true, undefined, undefined,true, undefined, filterVisibility, undefined, true, isSaveAndApply, isDeleteScope);
        }
    }

    extractCleanFilter(appliedBasicFilter, fromBasicFilter) {
        var filter = [];
        if(appliedBasicFilter && fromBasicFilter){
            appliedBasicFilter.map((tempFilter) => {
                if(tempFilter) {
                    tempFilter = copyObjectValues(tempFilter);
                    delete tempFilter[_entitiesOptions];
                    delete tempFilter.initialEntitiesData;
                    filter.push(tempFilter);
                }
            });

        }else{
            this.state.filterRefs.map((tempFilter) => {
                if(tempFilter.ref.current) {
                    tempFilter = copyObjectValues(tempFilter.ref.current.filterObj);
                    delete tempFilter[_entitiesOptions];
                    delete tempFilter.initialEntitiesData;
                    filter.push(tempFilter);
                }
            });
        }

        return filter;
    }

    applyFilter(shouldClear, shouldApplyFilter=false, isClear, isSwitchBasic, fromApplyButton, filterDisplayName, filterVisibility, hasMissingFields, isFromApply, isSaveAndApply, isDeleteScope) {
        var initialFilter = [];
        if (shouldClear) {
            this.state.filterRefs = this.getNewFilterRefsArray();
            this.resetSavedFilterData();
            if(this.props.useCookies !== false) {
                localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
                localStorage.removeItem(CURRENT_FILTER_COOKIE);
                localStorage.removeItem(CURRENT_FILTER_VISIBILITY_COOKIE);
            }
        } else {
            initialFilter = this.extractCleanFilter();
        }
        if(initialFilter.length === 0) {
            initialFilter = this.getEmptyFilterSample();  //if the initialFilter has not been filled from the loop, set it as sample filter
        }
        if(this.filterObj && this.filterObj[FILTER.KEYS.MIN] && this.filterObj[FILTER.KEYS.MAX]){
            initialFilter[FILTER.KEYS.MIN] = this.filterObj[FILTER.KEYS.MIN];
            initialFilter[FILTER.KEYS.MAX] = this.filterObj[FILTER.KEYS.MAX];
        }
        let oldFilter = copyObjectValues(this.state.initialFilter);
        this.state.initialFilter = initialFilter;   //updating the initial value when the user applies the filter
        this.state.filterWasEdited = false;
        if (!shouldClear && (this.props.useCookies !== false) && shouldApplyFilter) {
            if(isSwitchBasic || fromApplyButton) {
                initialFilter.map(function(item){item.isBasicFilter = false});
            }
            setLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE, this.state.savedFilterData[SF_ID]);
            setLocalStorageValueByParameter(CURRENT_FILTER_VISIBILITY_COOKIE, !this.state.savedFilterData.filter_visibility ? filterVisibility : this.state.savedFilterData.filter_visibility);
            setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(initialFilter));
            
        }

        let filterToSend = copyObjectValues(initialFilter);
        if(Array.isArray(filterToSend)) {
            delete filterToSend[0][_entitiesOptions];
            delete filterToSend[0].initialEntitiesData;
        } else if(typeof filterToSend === "object") {
            delete filterToSend[_entitiesOptions];
            delete filterToSend[0].initialEntitiesData;
        }

        let tempEmptyFilter = this.getEmptyFilterSample("object");  //creating an empty filter object
        delete tempEmptyFilter[_entitiesOptions];  
        delete tempEmptyFilter.initialEntitiesData;        //removing its entity options to compare clean data        //removing its entity options to compare clean data
        if(shouldClear || deepCompareObjects(filterToSend, tempEmptyFilter)) {
            filterToSend = [];  //send an empty filter to the api if clearing, or if the filter contains a single empty object
        }

        this.resetTempSavedFilterData(!shouldApplyFilter);
        this.updateAdvancedData([]);
        if(!this.props?.isFromExclusionConfiguration && !isSwitchBasic){
            this.dismissModal();
        }

        if(fromApplyButton && !deepCompareObjects(oldFilter, filterToSend)) {
            filterToSend.map(m => m.filter_row_type = "filter");
        }

        let areOldAndNewFilterDifferent = JSON.stringify(oldFilter) !== JSON.stringify(filterToSend);
        let isOldFilterEmpty = deepCompareObjects(oldFilter, this.getEmptyFilterSample());
        if(this.props.isFromExclusionConfiguration && (!areOldAndNewFilterDifferent || (isOldFilterEmpty && filterToSend?.length === 0))) {
          if(this.props?.unsavedChangesClosingCallback && typeof this.props.unsavedChangesClosingCallback === "function") {
            this.props.unsavedChangesClosingCallback();
          }
        }

        if(this.props.onChangeFilter){
            filterToSend?.map(m => {
                let filterArray = this.props.vectorOptions.filter(f=> f.value === m.vector);
                m.vectorLabel = filterArray[0]?.label;
                return m;
            });

            this.props.onChangeFilter(JSON.stringify(filterToSend), this.props.isDrilling, shouldApplyFilter, isClear, isSwitchBasic || fromApplyButton, filterDisplayName);
        }
        if(this.props.onSaveScopeFilter && isFromApply){
            this.props.onSaveScopeFilter(JSON.stringify(filterToSend), isSaveAndApply, this.state.isCheckBoxChecked, isDeleteScope, this.setIsCheckBoxChecked);
        }
        if(this.props.handleOverlayAndHeights) {
            this.props.handleOverlayAndHeights(!isClear && filterToSend.length > 0, filterToSend.find(f => !f.isBasicFilter));
        }

    }

    setIsCheckBoxChecked=()=>{
        if(this.state.isCheckBoxChecked !== tryParse(sessionStorage.getItem(SCOPE_FILTERS))?.[0]?.isDefault){
            this.setState({
                isCheckBoxChecked : tryParse(sessionStorage.getItem(SCOPE_FILTERS))?.[0]?.isDefault
            })
        }
    }

    startClear() {
        var comp = this;
        var tempFilter = this.getFilterValidationStatus();
        this.hideAdvancedFilter();

        if(!tempFilter.filter.length) {
            //if no valid filters exist in the modal
            this.openMessageModal(MESSAGES.ui_filter.titles.empty_filter, MESSAGES.ui_filter.messages.empty_filter);
        } else {
            this.openMessageModal(MESSAGES.ui_filter.titles.clear_filter,
                MESSAGES.ui_filter.messages.clear_filter,
                function(){
                    comp.clearFilter();
                    comp.handleCloseFilterMessageDialog();
                }
            );
        }
    }

    clearFilter() {
        this.resetSavedFilterData();    //resetting dropdown value
        this.resetTempSavedFilterData(false);
        this.resetInitialFilter();       //resetting initialFilter value
        
        this.applyFilter(true, true, true);
        this.setState({
            fromBasicFilter:false
        })
    }

    openMessageModal(title, message, onSubmitCallback, onCancelCallback=()=>{}){
        let tempState = [];
        tempState.modalMessage = message;
        tempState.openFilterMessageDialog = true;
        tempState.modalMessageTitle = title;
        tempState.onSubmitCallback = onSubmitCallback;
        tempState.onCancelCallback = onCancelCallback;

        this.setState(tempState);
    }

    fetchQuadrantTiers(){
        var comp = this;
        var query = "action=getQuadrantTiers";
        var tempState = {};
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        var onThenCallback = (data) => {
            if (data) {
                var quadrantTiers = [];
                let segmentsLabel = Object.values(SEGMENTS).map(segment => segment.label);
                data = data.filter(e => segmentsLabel.includes(e.quadrant));
                data.map(function (item, key) {
                    var keys = ["label", "value", "name", _type];
                    var values = [new Segment().getSegmentObject(item.quadrant)?.label + (" ") + item.first_tier,
                        new Segment().getSegmentObject(item.quadrant + " " + item.first_tier)?.value,
                        "entity",
                        FILTER_TYPE_VECTOR
                    ];

                    quadrantTiers.push(buildNewObject(keys, values));
                });

                tempState.quadrantTierOptions = quadrantTiers;
                if (comp.props.filterInDashBoard) {
                    comp.setState(tempState);
                }
                if (comp.props.dispatch) {
                    comp.props.dispatch(updateQTs([data]));
                }
                comp.awaitOtherLists("getProfitStackHierarchy", tempState);
            }
        }
        let onCompleteCallback = (data) => {
            comp.awaitOtherLists("getProfitStackHierarchy", tempState);
        }
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getQuadrantTiers",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.GET,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
            [FETCHAPI_PARAMS.useStore]: true,
            [FETCHAPI_PARAMS.dataKey]: "quadrantTierOptions",
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.get_qts,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + filterDialog,
        }
        this.fetchAPI(fetchOptions, comp);
    }

    awaitOtherLists(parentFunction, callerState) {
        this.tempState = Object.assign({}, this.tempState, callerState);
        listsToFetch.splice(listsToFetch.indexOf(parentFunction), 1);
       
        if(!listsToFetch.length) {
            this.setState(this.tempState);
        }
    }

    renderFilterFormula() {
        var filterText = this.getTextFilter();
        if(!this.state.fromBasicFilter){
            var tempFilters = this.getFilterValidationStatus();
        }
        var hideOrNotText = tempFilters?.notEmptyFilters.length > 0 || this.state.fromBasicFilter ? "uk-hidden" : "";
        var hideOrNotFilter = tempFilters?.notEmptyFilters.length > 0 || this.state.fromBasicFilter ? "uk-display-flex" : "uk-hidden" ;
        this.advancedFilterShowHide(hideOrNotText, hideOrNotFilter);

        this.advancedFilter = <div id="filter-table-advanced" className="advanced-filter-parent-rect uk-width-auto uk-display-flex">
            <div id="advanced-text" className={hideOrNotText + " uk-text-medium uk-text-center uk-margin-xsmall"}>{MESSAGES.advanced_filter_text}</div>
            <div id="advanced-filter" className={hideOrNotFilter + " uk-margin-auto"}>
            <div className="uk-text-center uk-margin-xsmall">Filter Formula</div>
            <div className="fs-12 uk-text-center advanced-filter-rect uk-margin-xsmall lightgray-background-color">{filterText}</div>
            <div className="text-link uk-text-center uk-margin-xsmall" onClick={()=>this.reRenderFilter()}>Advanced Filter</div>
        </div>
        {this.renderAdvancedFilter()}
        </div>

        return this.advancedFilter;

    }

    reRenderFilter() {
        // this.renderFilterFormula();
        this.showAdvancedFilter();
    }


    switchOperatorInAdvancedFilter(e) {
        var val = e.currentTarget.innerHTML.replace(/ /g, "") === _AND ? _OR : _AND;
        $("#"+e.currentTarget.id).html(val);
    }

    readAndApplyFilterFromCookie() {
        var tempSavedId = getDefaultLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE, undefined);
        this.updateSavedDataAttr(SF_ID, tempSavedId ? Number(tempSavedId) : undefined);
        var cookieFilter = getDefaultLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, "[]");
        var cookieFilterVisibility = getDefaultLocalStorageValueByParameter(CURRENT_FILTER_VISIBILITY_COOKIE, visibilityOptions[0].value);
        var readFromCookie = false;
        
        if(typeof cookieFilter === "string") {
            try {
                cookieFilter = JSON.parse(cookieFilter);
            } catch (error) {
                cookieFilter = this.getEmptyFilterSample();
                console.error("unable to parse filter from cookie");
            }
        }
        if(!cookieFilter.length) {
            cookieFilter = this.getEmptyFilterSample();
        }
        if(cookieFilter.length > 0 && !deepCompareObjects(cookieFilter, this.getEmptyFilterSample())) {
            readFromCookie = true;
        }

        if(readFromCookie) {
            this.onChangeSavedFilter(cookieFilter, undefined, undefined, cookieFilter[0].filter_display_name, undefined, cookieFilterVisibility);
        }
    }

    renderAdvancedFilter() {
        var advancedData =  this.getTextFilter().replace(/\(/g, " (").replace(/\)/g, " )");
        advancedData = advancedData.trim().split(" ");

        var draggableText = [];
        for (var i = 0; i < advancedData.length; i++) {
            let char = advancedData[i];
            if (char.match(/[A-Z]/i)) {
                draggableText.push(<div className="uk-text-center" key={"operator"+i}>
                    <Button 
                        id={"operator"+i}
                        label={" "+char+" "}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.ICON}
                        type={BUTTON_TYPE.DEFAULT}
                        className="border-button width-fit-cont"
                        onBtnClick={(e)=>this.switchOperatorInAdvancedFilter(e)}
                    />
                    </div>);
            } else {
                draggableText.push(<div id={"char" + i} key={"char" + i} className={(char.match(/[0-9]/g) ? "border-circle uk-padding-xsmall-left-right "
                    : char.match(/[()]/g) ? "remove-cursor " : "")
                    + "uk-margin-xsmall-right uk-margin-xsmall-left uk-display-flex"}
                    onDrop={this.drop} onDragOver={this.allowDrop}> {" "+char+" "}</div>)
            }
        }

        var html = [];
        html.push(<div id='draggable-advanced-filter' key={'draggable-'+Math.random()} className={'uk-hidden uk-margin-auto'}>
                <div id='advanced-text-left' className={'uk-text-small uk-text-center uk-margin-xsmall uk-width-3-7'}>{MESSAGES.advanced_filter_draggable_text}</div>
                <div id='left-bracket' className={'uk-grab uk-margin-small-right highlight'} draggable='true' onDragStart={this.drag}> ( </div>
                <div id='right-bracket' className={'uk-grab highlight'} draggable='true' onDragStart={this.drag}> ) </div>
                <div id="advanced-text-right" className={'uk-text-center advanced-filter-rect uk-margin-xsmall uk-display-flex'}>
                    {draggableText}
                </div>
            <i id='check-advanced-filter' className={'fas fa-check uk-margin-small black uk-cursor-pointer highlight-icon'} onClick={()=>this.submitAdvancedFilter()}></i>
                <i id='revert-advanced-filter' className={'fal fa-times uk-margin-small black uk-cursor-pointer highlight-icon'} onClick={()=>this.revertAdvancedFilter()}></i>
            </div>);

        return html;
    }

    getAdvancedFilterData() {
        var advancedData = [];
        var comp = this;

        if(!this.state.advancedData.length) {
            this.state.filterRefs.map((tempFilter, index) => {
                var operator = "";
                var parBefore = "";
                var parAfter = "";
                if (tempFilter.ref.current) {
                    operator = tempFilter.ref.current.filterObj[_logicalOperator];
                    parBefore = tempFilter.ref.current.filterObj[_parenthesisBefore];
                    parAfter = tempFilter.ref.current.filterObj[_parenthesisAfter];
                } else {
                    operator = comp.state.nextLogicalOperator || "";
                }

                advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index+1, [_logicalOperator]: operator});
            });
            this.updateAdvancedData(advancedData);
        } else {
            return this.state.advancedData;
        }

        return advancedData;
    }

    /**
     * This function is used to update the advanced data in a single place
     * It's used to update the value or reset the state when filter rows are changed
     * @param {*} advancedData 
     * @param {*} silent 
     */
    updateAdvancedData(advancedData, silent=true) {
        if(silent) {
            this.state.advancedData = advancedData;
        } else {
            this.setState({
                advancedData: advancedData
            });
        }
    }

    getTextFilter() {
        var filterText = "";
        this.getAdvancedFilterData().map(e=>{
            filterText += " "+ e[_logicalOperator] +" "+ e[_parenthesisBefore] +" "+ e["index"] +" "+ e[_parenthesisAfter];
        });
        return filterText;
    }

    hideAdvancedFilter() {
        $('[id^="apply-filter"]').removeClass("uk-button-disabled");
        $('[id^="close-filter-dialog"]').removeClass("disabled");
        $('[id^="cancel-filter"]').removeClass("uk-button-disabled");
        $('[id^="save-filter"]').removeClass("uk-button-disabled");
        $('[id^="draggable-advanced-filter"]').addClass("uk-hidden").removeClass("uk-display-flex");
        $('[id^="advanced-filter"]').addClass("uk-display-flex").removeClass("uk-hidden");
    }

    advancedFilterShowHide(hideOrNotText, hideOrNotFilter) {
        $("#advanced-text").addClass(hideOrNotText).removeClass(hideOrNotFilter);
        if(hideOrNotFilter === 'uk-hidden'){
            $('[id^="advanced-filter"]').addClass(hideOrNotFilter).removeClass("uk-display-flex");
        }
        else {
            $('[id^="advanced-filter"]').addClass(hideOrNotFilter).removeClass(hideOrNotText);
        }
        
    }

    showAdvancedFilter() {
        $('[id^="apply-filter"]').addClass("uk-button-disabled");
        $('[id^="close-filter-dialog"]').addClass("disabled");
        $('[id^="cancel-filter"]').addClass("uk-button-disabled");
        $('[id^="save-filter"]').addClass("uk-button-disabled");
        $('[id^="draggable-advanced-filter"]').removeClass("uk-hidden").addClass("uk-display-flex");
        $('[id^="advanced-filter"]').removeClass("uk-display-flex").addClass("uk-hidden");
    }

    submitAdvancedFilter() {
        let ukOpenClass = this.props.isFromExclusionConfiguration ? "" : ".uk-open " 
        let draggedText = $(ukOpenClass + "#advanced-text-right").text();
        // "( (  1 )  ) AND ( ( 2 ) OR ( 3 ) )"
        draggedText = draggedText.toUpperCase().replace(/ /g," ");

        var validation = null;
        try {
            eval ("validation = "+ draggedText.replace(/AND|OR/g, "+") +";");
        } catch(err) {
            validation = null;
        }

        if(validation === null) {
            this.setInfoDialogOpen(true, MESSAGES.advanced_filter_validation_message)
            return;
        }

        draggedText = draggedText.split(" ");
        var advancedData = [];
        let parBefore = "";
        let parAfter = "";
        let index = "";
        let operator = "";
        for(var word in draggedText) {
            word = draggedText[word];
            if(word.match(/[\(]/i)) {
                parBefore += word;
            } else if(word.match(/[\)]/i)) {
                parAfter += word;
            } else if(word.match(/[0-9]/i)) {
                index = word;
            } else if (word.match(/[A-Z]/i)) {
                advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index, [_logicalOperator]: operator});
                operator = word;
                parBefore = parAfter = "";
            }
        }

        //to push the last row that was filled
        advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index, [_logicalOperator]: operator});

        this.state.filterWasEdited = true;
        this.hideAdvancedFilter();
        //update the advanced data state
        this.updateAdvancedData(advancedData);
        this.updateParenthesisInRefs();
        this.setHasUnsavedChanges()
    }

    /**
     * This function updates the saved parenthesis in the refs
     */
    updateParenthesisInRefs() {
        var comp = this;
        this.state.filterRefs.map((ref, i) => {
            if(ref.ref.current) {
                let filterObj = ref.ref.current.filterObj;
                let objAdvancedData = findOptionByKeyValue(comp.state.advancedData, "index", i+1);  //read the advanced data for this index

                //update the advanced data in the refs
                filterObj[_parenthesisBefore] = objAdvancedData[_parenthesisBefore];
                filterObj[_parenthesisAfter] = objAdvancedData[_parenthesisAfter];
                filterObj[_logicalOperator] = objAdvancedData[_logicalOperator];
            }
        });

        //empty advanced data after updating refs
        this.updateAdvancedData([]);
        this.forceUpdate();
    }

    resetParenthesisInRefs(silent=false) {
        var comp = this;
        this.state.filterRefs.map((tempFilter, index) => {
            var operator = "";
            if (tempFilter.ref.current) {
                operator = tempFilter.ref.current.filterObj[_logicalOperator];
            } else {
                operator = comp.state.nextLogicalOperator || "";
            }

            tempFilter.ref.current.filterObj[_parenthesisBefore] = "";  //reset parenthesis
            tempFilter.ref.current.filterObj[_parenthesisAfter] = "";  //reset parenthesis

            comp.state.filterRefs[0].ref.current.filterObj[_parenthesisBefore] = "(";
            comp.state.filterRefs[comp.state.filterRefs.length-1].ref.current.filterObj[_parenthesisAfter] = ")";

            if(operator === _OR && index > 0) {
                comp.state.filterRefs[index-1].ref.current.filterObj[_parenthesisAfter] = ")";
                tempFilter.ref.current.filterObj[_parenthesisBefore] = "(";
            }
        });

        this.updateAdvancedData([], silent);
    }

    revertAdvancedFilter() {
        $("#draggable-advanced-filter .advanced-filter-rect").find('.draggedDiv').each(function (i) {          
            $(this).remove();
        });

        this.hideAdvancedFilter();
        this.updateAdvancedData([], false);
    }

    allowDrop(ev) {
        ev.preventDefault();
    }
      
    drag(ev) {
        ev.dataTransfer.setData("text", ev.target.id);
    }
      
    drop(ev) {
        ev.preventDefault();

        /* If you use DOM manipulation functions, their default behaviour it not to 
        copy but to alter and move elements. By appending a ".cloneNode(true)", 
        you will not move the original element, but create a copy. */
        var data = ev.dataTransfer.getData("text");

        var nodeCopy = document.getElementById(data) ? document.getElementById(data).cloneNode(true) : null;
        if (nodeCopy !== null) {
            var text = nodeCopy ? nodeCopy.innerHTML : "";
            nodeCopy.classList.add("draggedDiv", "remove-cursor");
            nodeCopy.classList.remove("highlight", "uk-grab");
            
            if (text.replace(/ /g,"")  === ")") {
                ev.target.after(nodeCopy);
            } else if (text.replace(/ /g,"")  === "("){
                ev.target.before(nodeCopy);
            }
        }
            
    }

    removeFilter(rowIndex, silent=false, refreshData=false) {
        if(rowIndex === 0 && this.state.filterRefs.length === 1) {
            this.state.filterRefs = this.getNewFilterRefsArray();
        } else {
            if(rowIndex === 0 && this.state.filterRefs[rowIndex+1] && this.state.filterRefs[rowIndex+1].ref.current) {
                this.state.filterRefs[rowIndex + 1].ref.current.filterObj[_logicalOperator] = "";
                this.state.filterRefs[rowIndex+1].ref.current.filterObj[_from] = "";
                this.state.filterRefs[rowIndex+1].ref.current.filterObj[_to] = "";
            }
            this.state.filterRefs.splice(rowIndex, 1); //to remove the row from the list
            this.resetParenthesisInRefs();
        }

        this.state.filterWasEdited = true;

        if(!silent) {
            this.forceUpdate();
        }

        if(refreshData) {
            this.applyFilter(false, true);
        }
    }

    markFilterForDeletion(e) {
        var index = $(e.currentTarget).attr("displayed_filter_index");
        this.removeFilter(Number(index), true, true);
        
        //if the user had previously selected a saved filter, remove it when deleting a row
        this.resetSavedFilterData();
    }

    renderFilterDisplay() {
        var comp = this;
        var allFilters = [];
        var useOutsideFilter = comp.state.useOutsideFilter;
        var loopedOverArr = useOutsideFilter ? comp.state.outsideFilter : comp.state.filterRefs;
        let exportQueryFilter = comp.state.savedFilterData[SF_NAME] ? comp.state.savedFilterData[SF_NAME] + " " : "";

        let i = 0;
        loopedOverArr.map((ref, ind) => {
            //second condition to check if the object is similar to the initial sample object
            if((ref && ref.ref && !ref.ref.current) || (ref && deepCompareObjects(ref, comp.getEmptyFilterSample("object")))
            || (ref && ref.ref && ref.ref.current && ref.ref.current.filterObj && deepCompareObjects(ref.ref.current.filterObj, comp.getEmptyFilterSample("object")))) {
                return;
            }
            let filter = useOutsideFilter ? ref : ref.ref.current && ref.ref.current.filterObj ? ref.ref.current.filterObj : null;
            let filterType = filter[_type];
            let star = [_ct, _nct].indexOf(filter[_function]) > -1 ? "*" : "";
            let filterFunction = ""
            switch(filter[_function]){
                case _gt:
                    filterFunction=">";
                    break;
                case _lt:
                    filterFunction="<";
                    break;
                case _ltoe:
                    filterFunction="<=";
                    break;
                case _gtoe:
                    filterFunction=">=";
                break;
                case _eq:
                    filterFunction="=";
                    break;
                case _isBetween:
                    filterFunction= "is between";
                    break;
                case _startWith:
                    filterFunction= "starts with";
                    break;
                case _endWith:
                    filterFunction= "ends with";
                    break;
            }
            let filterClass = getFilterDisplayClass(filter, comp.props.quadrantsDisabled, comp.props.psFilterDisabled);
            // let filterStyle = !comp.props.quadrantsDisabled ? getFilterQuadrantColorStyle(filter) : "";
            let filterBackgroundColor = getFilterBackgroundColor(filter, false);
            let stylesObj = {};
            if(filterBackgroundColor){
              stylesObj.backgroundColor = filterBackgroundColor.color;
              stylesObj.color = filterBackgroundColor.textColor;
            }
            let filterEntities = "";
            let operator = filter[_logicalOperator];
            if(filter[_entities] && filter[_entities].length) {
                if(typeof filter[_entities] === "object") {   //array of selected values for vector filter
                    filter[_entities].map((ent, i)=>{
                        filterEntities += (i > 0 ? ", " : "") + ent.value.toUpperCase();
                    });
                } else {
                    filterEntities = filter[_entities];     //value written in input for profit stack filter
                }
            }
            if(filterType === FILTER_TYPE_PROFITSTACK && filter[_function] === _isBetween && filter){
                filterEntities = filter[FILTER.KEYS.MIN] + " and "  + filter[FILTER.KEYS.MAX];
            }
            let displayVector = findOptionByKey(comp.state.vectorOptions, filter[_vector]).label;

            if(operator) {
                allFilters.push(<span className={"pi-chip filtered-item-title"} key={"filter-display-key-v-"+i++} title={operator}>{operator}</span>);
            }

            filter[_parenthesisBefore].split("").forEach(entity => {
                allFilters.push(<span className={"filtered-item-title parenthesis"} key={"filter-display-key-v-"+i++} title={entity}>{entity}</span>);
            });

            let text = "";
            if(filterType === FILTER_TYPE_VECTOR) {
                let extraDisplay = [];
                if([this.profitSegmentFields, this.tierSegmentFields, this.quadrantTiersFields, this.quadrantsFields].indexOf(filter[_field]) !== -1) {
                    if(filter[_from] !== "" && filter[_from] !== null && filter[_from] !== undefined) {
                        extraDisplay.push(filter[_from]);
                    }
                    if(filter[_to] !== "" && filter[_to] !== null && filter[_from] !== undefined) {
                        extraDisplay.push(filter[_to] === MESSAGES.ui_filter.dropdowns.months.mo3.value ? filter[_from] : this.getLastDataset(filter[_from] ));
                    }                
                }
                let quarterDisplay = extraDisplay.length ? " [" + extraDisplay.join(" - ") + "]" : "";    
                if ([_empty, _nempty].indexOf(filter[_function]) > -1) {
                    var condition = getObjectAsArray(MESSAGES.ui_filter.dropdowns.functions, filter[_function], "value")[0].label;
                    filterEntities = condition;
                }
                let label;
                if ([SEGMENTS_TITLES.PROFIT_SEGMENTS.value, SEGMENTS_TITLES.PROFIT_TIERS.value].includes(filter[_field])) {
                    let segment = new Segment();
                    let filterEntitiesArr = filterEntities.split(",");
                    let final = [];
                    for (var e in filterEntitiesArr) {
                      final.push(segment.getSegmentObject(filterEntitiesArr[e].replace(/ /g,''))?.label)
                    }
                    label =  final.join(",");  
                }
                text = star + displayVector + ":" + filter[_field] +" "+ filterFunction +" "+ (label || filterEntities) + quarterDisplay + star;
                allFilters.push(
                    <span style={stylesObj} className={filterClass + "filtered-item-title uk-text-truncate uk-text-wrap filter-header-el-"+filter[_field]} operator={operator} key={"filter-display-key-v-"+i++} title={text}>{text}
                        {this.filterDisplayEditable ?
                            <i className={"fal fa-times closed-filter"} displayed_filter_index={ind} displayed_filter_type={filterType} onClick={this.markFilterForDeletion}/>
                        :""}
                    </span>
                );
            } else if(filterType === FILTER_TYPE_PROFITSTACK){  //if not empty
                text = star+ displayVector +":"+ PROFITSTACK_NAMES_RETURNNAMES[filter[_field]] +" "+ filterFunction +" "+ filterEntities+star;
                allFilters.push(
                    <span style={stylesObj} className={filterClass + "filtered-item-title filter-header-el-"+ filterType} operator={operator} key={"filter-display-key-ps-"+i++} title={text}>{text}
                        {this.filterDisplayEditable ?
                            <i className={"fa fa-times closed-filter"} displayed_filter_index={ind} displayed_filter_type={filterType} onClick={this.markFilterForDeletion}/>
                        :""}
                    </span>
                );
            }

            filter[_parenthesisAfter].split("").forEach(entity => {
                allFilters.push(<span className={"filtered-item-title parenthesis"} key={"filter-display-key-v-"+i++} title={entity}>{entity}</span>);
            });

            let neg = ([_neq, _nct].indexOf(filter[_function]) > -1) ? "!" : "";

            //to remove the disabled filters from the excel filter
            var quadrantsDisabled = comp.props.quadrantsDisabled
              && [comp.profitSegmentFields, comp.tierSegmentFields, comp.quadrantTiersFields, comp.quadrantsFields].indexOf(filter[_field]) > -1;
            exportQueryFilter += " ";
            //this check is for not adding the operator at first when the previous filter is unapplied
            if (!quadrantsDisabled && exportQueryFilter.trim().replace(/\(/g, "").replace(/\)/g, "") !== "") {
                exportQueryFilter += (operator ? operator + " " : "")
            } 

            exportQueryFilter += " " + filter[_parenthesisBefore]

            //this to add the filter object only for applied filters
            if (!quadrantsDisabled) {
                exportQueryFilter += neg + text + neg;
            } 

            exportQueryFilter += filter[_parenthesisAfter];
        });

        //replacing the () with empty when we have unapplied filters
        if(this.state.exportQueryFilter !== exportQueryFilter.trim().replace("()","")) {
            this.state.exportQueryFilter = exportQueryFilter.trim().replace("()","");
            if(typeof this.props.forceUpdateParent === "function") {
                this.props.forceUpdateParent();
            }
        }
        return allFilters;
    }

    setPSLData = (formattedPslArray, originalPslData) =>{
        let _this = this;
        _this.setState({
            psLinesOptions: formattedPslArray
        },()=>{
            if (originalPslData) {
                let linearizedData = linearizeHierarchy(originalPslData, SECTION.CHILDREN)
                linearizedData?.map(function (item, key) {
                    //fill names and return names to display the names in the filter header
                    PROFITSTACK_NAMES_RETURNNAMES[item[PSS.RETURN_NAME]] = window._format.replaceCurrency(item[PSS.NAME]);
                });
            }
        });
    }

    componentDidMount() {
        let _this = this;
        this.componentId = this.props.componentName ? "filterModal_" + this.props.componentName : this.props.compNumber ? "filterModal_" + this.props.compNumber : "filterModal";
        $("#"+ this.componentId).remove();  //remove old component with the same id
        this.filterDisplayEditable = this.props.filterDisplayEditable !== undefined ? this.props.filterDisplayEditable : this.filterDisplayEditable;
        
        if (this.props.mustFetchDatasets) {
            this.fetchDatasets();       //fetch the datasets if not sent from parent
        }

        listsToFetch.push.apply(listsToFetch, ["getSavedFilters","getProfitStackHierarchy","fetchQuadrantTiers"]); //pushing multiple values together
        let scenario_id = this.props.scenario_id && isNaN(this.props.scenario_id) ? this.props.scenario_id.value : this.props.scenario_id
        setTimeout(() => {
            if(!_this.props.fromManageAccess){
                this.getSavedFilters();
            }
            getProfitStackHierarchy(0, scenario_id, this, this.setPSLData);
            this.fetchQuadrantTiers();
        }, 200)
        
        if(this.props.useCookies !== false) {
            this.readAndApplyFilterFromCookie();
        }

        if(!!this.props.fromOptions) {
            let newOptions = this.getUpdatedDatasetsFromProps(this.props);

            this.setState({
                toOptions: newOptions.to,
                fromOptions: newOptions.from,
            });
          }
          
        $(document).on("click", ".remove-cursor", function () {
            $(this).remove(); 
        })

        this.closeSavedFilterDropdown();
        
    }

    getLastDataset(dataset) {
        dataset = dataset.value ? dataset.value : dataset;
        let datasets = returnLastDatasets(this.state.fromOptions, dataset);
            if (datasets.length === 4) {
               return datasets[datasets.length-1].value; //sent first element
        }

        return null;
    }

    getUpdatedDatasetsFromProps(props) {
        //append default to quarter options
        var fromOptions = [{label: MESSAGES.default_label, value: ""}];
        fromOptions = fromOptions.concat(props.fromOptions);

        //append dash to quarter options
        var toOptions = [{label: MESSAGES.default_label, value: ""}];
        toOptions = toOptions.concat(props.fromOptions);

        return {from: fromOptions, to: toOptions};
    }

    static getDerivedStateFromProps(props, state) {
        if (state.fromOptions && state.datasetOptions_id !== props.datasetOptions_id) {
            var fromOptions = [{label: MESSAGES.default_label, value: ""}];
            fromOptions = fromOptions.concat(props.fromOptions);

            var toOptions = [{label: MESSAGES.default_label, value: ""}];
            toOptions = toOptions.concat(props.fromOptions);

            return {
                toOptions: toOptions,
                fromOptions: fromOptions.from || fromOptions,
                dataSet: state.dataSet
            }
        }

        if(props.vectorOptions !== state.vectorOptions){
            return state.vectorOptions;
        }
        return null;
	}

    setHasUnsavedChanges = () => {
        let comp = this;
        if (comp.props.setHasUnsavedChanges && typeof comp.props.setHasUnsavedChanges === "function") {
            comp.props.setHasUnsavedChanges(true);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        var comp = this;

        if (!this.props.isNotModal ) {
            const modalElement = $("#" + this.componentId);
            modalElement.attr("uk-modal", 'stack:' + (this.props.dashboard || "") + "; bgClose: false");
            
            // Check if modal container is not already set to root
            // This is needed for React 17 since event listeners are bound to root element
            // Get current container ID from UIkit modal instance
            const currentContainer = UIkit.modal(modalElement[0])?.container?.id;

            // If container is not already set to root, update it
            if (currentContainer !== 'root') {
                UIkit.modal(modalElement[0], {
                    container: "#root" // Set container to root element
                });
            }
          }

        if (this.props.vectorOptions && (Array.isArray(this.props.vectorOptions) && (JSON.stringify(this.props.vectorOptions) !== JSON.stringify(prevProps.vectorOptions)) ||  prevState.vectorOptions.length <= 1 || JSON.stringify(this?.props?.vectorOptions) !== JSON.stringify(this.state.vectorOptions))) {
            var vectors = [];
            this?.props?.vectorOptions.map(function (item) {
                var keys = [VECTOR_STAGING_ATTRIBUTES.ID,"label", "value", "name", [_type], "isDisabled", "vector_group", "isGroupTitle", VECTOR_STAGING_ATTRIBUTES.MACHINE_ID];
                var values = [item[VECTOR_STAGING_ATTRIBUTES.ID],item.label, item.value, FILTER_TYPE_VECTOR, FILTER_TYPE_VECTOR, item.isDisabled ? true : false, item[VECTOR_STAGING_ATTRIBUTES.GROUP], item.isGroupTitle,item.machine_id];
                vectors.push(buildNewObject(keys, values));
            });
            prevState.vectorOptions = this.state.vectorOptions = vectors;
        }

        $('#rows-parent').sortable({
            handle: ".uk-grab",
            start: () => {
                var emptyRows = prevState.filterRefs.filter(elem => elem.ref.current.filterObj.vector === "");
                if (emptyRows.length === prevState.filterRefs.length){
                    $("#advanced-filter").addClass("uk-hidden").removeClass("uk-display-flex");
                } else {
                    this.hideAdvancedFilter();
                    prevState.filterWasEdited = true;
                }
                
            },
            stop: function (e, ui) {
                var operators = [];
                var orders = [];

                var children = $(this).children();
                children.map((index) => {
                    var child = children[index];
                    if (child.id.split('-').pop() !== "")
                        operators.push(child.id.split('-').pop());
                   if ($(child).attr("datarowindex"))
                        orders.push($(child).attr("datarowindex").split('-').pop());
                });

                let ar = [];
                while (orders.length) {
                    let cur_order = orders.shift();
                    var element = prevState.filterRefs[cur_order - 1];
                    if (element.ref.current.filterObj.logical_operator === "")
                        element.ref.current.filterObj.logical_operator = _AND;
                    ar.push(element)
                }
                
                ar[0].ref.current.filterObj.logical_operator = "";
                comp.setState({
                    filterRefs: ar
                }, () => {
                    comp.resetParenthesisInRefs(false);
                    comp.setHasUnsavedChanges(true);
                })
            }
        });
        if (this.props.setFilterTo && typeof this.props.setFilterTo === 'function') {
            this.props.setFilterTo();
        }
        if((prevProps.scenario_id !== this.props.scenario_id) || 
            (prevProps.comparisonScenarioId !== this.props.comparisonScenarioId) || this.props.reportTitle !== prevProps.reportTitle) {
            let scenario_id = this.props.scenario_id && isNaN(this.props.scenario_id) ? this.props.scenario_id.value : this.props.scenario_id;
            getProfitStackHierarchy(0, scenario_id, this, this.setPSLData);
            this.getSavedFilters();
        }

        if(this.props.quadrantsDisabled !== prevProps.quadrantsDisabled) {
          this.getSavedFilters();
        }
    }

   

    handleSaveFilterChange(value, attr) {
        let obj = copyObjectValues(this.state.savedFilterData_temp, "object");
        obj[attr] = typeof value === "boolean" ? value : $(value.currentTarget).val();
        obj[SF_CREATED_BY] = this.props.user ? this.props.user.email : "";
        this.setState({
            savedFilterData_temp: obj   //this is a temp state, when the modal data is submitted, it's copied to the actual state
        })
        this.checkFilterExists(this.state.visibility,value.target.value)

    }

    /**
     * Renders all the filter rows in the filter modal
     */
    renderFilterDialog() {
        var filterRows = [];
        var useOutsideFilter = this.state.useOutsideFilter;
        var loopedOverArr = useOutsideFilter ? this.state.outsideFilter : this.state.filterRefs;
        
        //if state.filterRefs contains less rows than outsideFilter, fill it the same amount
        if(useOutsideFilter) {
            if(this.state.filterRefs.length < loopedOverArr.length) {
                for(var i = this.state.filterRefs.length; i < loopedOverArr.length; i++){
                    this.state.filterRefs.push(this.getNewFilterRefObject());
                }
            } else {
                this.state.filterRefs.length = loopedOverArr.length;    //if useOutsideFilter, remove all the extra rows in filterRefs
            }
        }
        for (var i in loopedOverArr) {
            i = Number(i);
            var filterRowObj = useOutsideFilter ? this.state.outsideFilter[i] : this.getEmptyFilterSample("object");    //current filterObj
            var tempRef = this.state.filterRefs[i] ? this.state.filterRefs[i].ref : React.createRef();
            if(useOutsideFilter && this.state.filterRefs[i] && tempRef.current) {
                //if useOutsideFilter, update the filterObj in the ref if the row has been previously rendered
                //(it could be not previously rendered if outsideFilter.length > filterRefs.length)
                this.state.filterRefs[i].ref.current.filterObj = filterRowObj;
            } else if (this.state.filterRefs[i] && tempRef.current) {
                filterRowObj = tempRef.current.filterObj;
            }
            if (this.props?.isFromExclusionConfiguration) {
                filterRowObj["rank"] = i + 1;
            }

            filterRows.push(
                <FilterDialogRow key={"filterRow_" + Math.random()} ref={tempRef} user={this.props.user}
                    onChangeFilterRow={this.onChangeFilterRow} checkIfMonthsValid={this.props.checkIfMonthsValid}
                    vectorOptions={this.state.vectorOptions} fromOptions={this.state.fromOptions} toOptions={this.state.toOptions}
                    psFilterDisabled={this.props.psFilterDisabled} addNewFilter={this.addNewFilter} totalRows={loopedOverArr.length}
                    psLinesOptions={this.state.psLinesOptions} rowIndex={i} removeFilter={this.removeFilter} componentId={this.componentId}
                    filterRowObj={filterRowObj} quadrantTierOptions={this.state.quadrantTierOptions} getEmptyFilterSample={this.getEmptyFilterSample}
                    renderFilterModal={this.renderFilterModal} quadrantsDisabled={this.props.quadrantsDisabled} scenario_id={this.props.scenario_id}
                    nextLogicalOperator={!tempRef.current ? this.state.nextLogicalOperator : tempRef.current.filterObj[_logicalOperator]} segmentsDictionary={this.segmentsDictionary}
                    clearLogicalOperator={this.clearLogicalOperator} dataSet={this.state.dataSet} filterRefs= {this.state.filterRefs} hideFilterDisplay={this.props.hideFilterDisplay}
                    hideClear={this.props.hideClear} hideOr={this.props.hideOr} hideAdvancedFilter={this.hideAdvancedFilter} 
                    fromProfile={this.props.fromProfile} isManageAccessScope={this.props.isManageAccessScope}
                    setHasUnsavedChanges={this.props.setHasUnsavedChanges} isFromExclusionConfiguration={this.props.isFromExclusionConfiguration}
                />
            );
        }

        filterRows.push(
            <div key={"op-"+Math.random()} className="uk-display-flex uk-flex-left uk-margin-top mrgl70">
                  <Button 
                        label={_ADD}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className="uk-margin-default-right"
                        value={_AND}
                        leftIcon={<i className="far fa-plus-circle fa-lg uk-padding-xsmall-right" />}
                        onBtnClick={() => this.state.filterRefs[0].ref.current.handleChange({ value: _AND }, _logicalOperator, true)}                        
                    />
              
            </div>
        );
        
        return filterRows;
    }

    dragRow(ev) {
        ev.dataTransfer.setData("text/html", ev.target.id);
    }

    allowDropRow(ev) {
        ev.preventDefault();
    }
      
    dropRow(ev) {
        ev.preventDefault();

        /* If you use DOM manipulation functions, their default behaviour it not to 
        copy but to alter and move elements. By appending a ".cloneNode(true)", 
        you will not move the original element, but create a copy. */
        var data = ev.dataTransfer.getData("text/html");

        var nodeCopy = document.getElementById(data) ? document.getElementById(data) : null;
        if (nodeCopy !== null) {
            // nodeCopy.id = "draggedDiv"; /* We cannot use the same ID */
            nodeCopy.classList.add("draggedDiv");
            ev.target.append(nodeCopy);
        }

    }

    getInputsLabels() {
      let labelsObj = {};
      if(this.props.isFromExclusionConfiguration) {
        labelsObj.filter = lang.supply_chain_set_up.exclusions.input_labels.select_filter;
        labelsObj.vector = lang.supply_chain_set_up.exclusions.input_labels.select_vector;
        labelsObj.field = lang.supply_chain_set_up.exclusions.input_labels.select_field;
        labelsObj.function = lang.supply_chain_set_up.exclusions.input_labels.select_function;
        labelsObj.value = lang.supply_chain_set_up.exclusions.input_labels.select_value;
      } else {
        labelsObj.filter = COST_FUNCTIONS_TITLES.FILTER;
        labelsObj.vector = MESSAGES.ui_filter.col_names.vector;
        labelsObj.field = lang.ui_filter.col_names.typeManage;
        labelsObj.function = MESSAGES.ui_filter.col_names.function;
        labelsObj.value = MESSAGES.ui_filter.col_names.value;
      }

      return labelsObj;
    }

    onCheckBoxChecked=()=> {
        let _this = this;
        _this.setState({
            isCheckBoxChecked: !_this.state.isCheckBoxChecked,
            filterWasEdited: true
        })
    }



    renderFilterModal() {
        let filterDialog = this.renderFilterDialog();     //displays selected filters
        this.advancedFilter = this.renderFilterFormula();
        let fromCount = 0;
        let inputLabels = this.getInputsLabels();

        if (this.state.filterRefs && this.state.filterRefs.length > 0) {
            this.state.filterRefs.map((filter) => {
                var obj = filter.ref.current ? filter.ref.current.filterObj : "";
                var isQuadrant = [this.profitSegmentFields, this.tierSegmentFields, this.quadrantTiersFields, this.quadrantsFields].indexOf(obj[_field]) > -1;
                if (isQuadrant) {
                    fromCount++;
                }
            });
        }

        let rowClassNames = this.props.isFromExclusionConfiguration ? "max_width" : "width-225 uk-margin-small";
        let filtersLabel = this.props.fromProfile || this.props.isManageAccessScope? tryParse(this.props.filterFinal)?.length? lang.edit_scope : lang.add_scope : this.props.hideFilterDisplay ? MESSAGES.limit_access : MESSAGES.define_filter;
        let renderSaveAs = (!this.state.savedFilterData) || (this.state.savedFilterData[SF_EDITABLE] !== true);
        return (
            <div id={this.componentId}>
                <div className={!!this.props.isNotModal ? "" : "uk-modal-dialog width-fit-content filter-dialog"} id="filterDialog">
                  {this.props.isNotModal ? "" : 
                    <div className={"uk-modal-header uk-padding-remove"}>  
                        <div className={"panel-heading"} >
                           <div className={"uk-grid uk-grid-small uk-flex uk-flex-middle"} uk-grid="" >
                                <div className={"uk-width-expand"}>       
                                    <div id="filtersLabel" >
                                        <span className={"uk-text-lead uk-text-bold " + (!this.props.hideFilterDisplay ? "uk-margin-medium-right" : "")}>
                                            {filtersLabel}
                                        </span>
                                        {this.props.hideFilterDisplay && !this.props.fromProfile && !this.props.isManageAccessScope? <i className="fal fa-info-circle uk-margin-small-left" uk-tooltip={MESSAGES.manage_access.limit_access_tooltip} /> : ""}
                                        {this.state.savedFilterData[SF_NAME] ?
                                            <div className="filter-secondary-text">
                                                {this.state.savedFilterData[SF_NAME] + " "}

                                                <span id={"by"+(this.props.dashboard || "")}>&nbsp; by {this.state.savedFilterData[SF_EDITABLE] ? " You (" + (this.state.savedFilterData[SF_ISPUBLIC] ? "Public" : "Private") + ")"
                                                    :
                                                    this.state.savedFilterData[SF_CREATED_BY].substring(0, this.state.savedFilterData[SF_CREATED_BY].lastIndexOf('@')
                                                    )}</span>

                                                {this.state.savedFilterData[SF_EDITABLE] !== true ? "" :
                                                    // <button type="button"
                                                    //     className="forget-btn uk-margin-small-left"
                                                    //     onClick={this.startForgetFilter}>{MESSAGES.modal.buttons.forget}
                                                    // </button>
                                                    <Button 
                                                        variant={BUTTON_VARIANT.TERTIARY}
                                                        size={SIZES.ICON}
                                                        type={BUTTON_TYPE.DEFAULT}
                                                        className={"forget-filter-button-icon"}
                                                        leftIcon={<i class="fal fa-trash" />}
                                                        onBtnClick={this.handleOpenForgetFilterDialog}
                                                    />                                                
                                                }
                                            </div> : ""
           
                                        }
                                    <Button 
                                        variant={BUTTON_VARIANT.TERTIARY}
                                        size={SIZES.ICON}
                                        type={BUTTON_TYPE.DEFAULT}
                                        id="close-filter-dialog"
                                        className="close-button uk-margin-small top-right" 
                                        aria-label="Close"
                                        onBtnClick={this.startCancelFilter}
                                        leftIcon={<i className="fal fa-times"/>}
                                    />
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                  }
                    <div id="filter-table" className="advanced-filter-inner-container uk-padding-xmedium-top">
                        <div className="uk-display-inline-flex max_width">
                            <div className={(this.props.isFromExclusionConfiguration ? "" : "width-40 uk-margin-left")} style={this.props.isFromExclusionConfiguration ? {width: "23%"} : {}}></div>    {/**To reserve space over index icon */}
                            <div className={"uk-text-left " + rowClassNames}>
                                <span className={"fs-14"} style={{fontWeight: this.props.isFromExclusionConfiguration ? 700 : 400}}>{inputLabels.filter}</span>
                            </div>
                            <div className={"uk-text-left " + rowClassNames}>
                                <span className={"fs-14"} style={{fontWeight: this.props.isFromExclusionConfiguration ? 700 : 400}}>{inputLabels.vector}</span>
                            </div>
                            <div className={"uk-text-left " + rowClassNames}>
                                <span className={"fs-14"} style={{fontWeight: this.props.isFromExclusionConfiguration ? 700 : 400}}>{inputLabels.field}</span>
                            </div>
                            <div className={"uk-text-left " + rowClassNames}>
                                <span className={"fs-14"} style={{fontWeight: this.props.isFromExclusionConfiguration ? 700 : 400}}>{inputLabels.function}</span>
                            </div>
                            <div className={(this.props.isFromExclusionConfiguration ? "" : "uk-margin-small width-350 uk-display-inline-flex uk-height-fit mrgr40 ")} style={this.props.isFromExclusionConfiguration ? {width: "130%"} : {}}>
                                <div className="uk-text-left uk-width-1-1">
                                    <span className={"fs-14"} style={{fontWeight: this.props.isFromExclusionConfiguration ? 700 : 400}}>{inputLabels.value}</span>
                                </div>
                            </div>
                            <div className={"uk-display-flex align-items-center " + (this.props.isFromExclusionConfiguration ? "" : "width-20")} style={this.props.isFromExclusionConfiguration ? {width: "15%"} : {}}></div>    {/**To reserve space over delete icon */}
                        </div>
                        <div id="rows-parent">
                            {filterDialog}
                        </div>
                        {this.advancedFilter}
                        {this.props.isNotModal ? "" :
                            <div>
                                {this.props.fromProfile && 
                                    <div className="uk-margin-small-bottom font-size-x-small uk-margin-medium-left">  
                                        <CheckBox onChange={this.onCheckBoxChecked} checked={this.state.isCheckBoxChecked} labelText={lang.make_this_my_default}
                                        />
                                    </div>
                                }
                                <div className="uk-display-flex justify-content-between uk-margin-medium-right-left">
                                    <div className="uk-display-flex uk-margin-default-bottom" >
                                        <Button 
                                            id="apply-filter" 
                                            label={this.props.fromProfile? MESSAGES.modal.buttons.save_and_apply : MESSAGES.modal.buttons.apply}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            className="uk-margin-default-right"
                                            onBtnClick={()=>this.startApply(undefined, true)}
                                        />
                                        {this.props.fromProfile &&
                                            <Button 
                                                id="apply-scope-filter" 
                                                label={MESSAGES.modal.buttons.save}
                                                variant={BUTTON_VARIANT.PRIMARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}
                                                className="uk-margin-default-right"
                                                onBtnClick={this.startApply}
                                        />}
                                        <Button 
                                            id="save-filter"
                                            disabled={!renderSaveAs && (this.state.savedFilterData.filter_visibility === FILTER.VISIBILITY_OPTIONS.COMPANY_VALUE) && !this.props.canSaveCompanyFilters}
                                            label={renderSaveAs? "Save As" : "Save"}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            className={"uk-margin-default-right" + (this.props.hideFilterDisplay ? " uk-hidden" : "")}
                                            onBtnClick={this.startSaveFilter}
                                        />
                                        <Button 
                                            id="cancel-filter"
                                            label={MESSAGES.modal.buttons.cancel}
                                            variant={BUTTON_VARIANT.SECONDARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            onBtnClick={this.startCancelFilter}
                                        />
                                    {/* <button id=className="red-btn btn_primary" onClick={() => this.()}>
                                        
                                    </button> */}
                                    </div>
                                    {this.props.fromProfile?
                                    <a className={"uk-text-decoration-underline uk-margin-default-left uk-text-xmedium uk-cursor-pointer"}
                                        onClick={()=>this.startApply(undefined, undefined, true)}>{MESSAGES.remove_scope}</a>
                                    :
                                    <a className={"uk-text-decoration-underline uk-margin-default-left uk-text-xmedium uk-cursor-pointer" + (this.props.hideClear ? " uk-invisible" : "")}
                                       onClick={this.startClear}>{this.props.isManageAccessScope ? MESSAGES.remove_scope : MESSAGES.modal.buttons.clear}</a>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
    closeSavedFilterDropdown = () => {
        let _this = this;
        $(document).on("mouseover click", function (event) {
            if (($('*[id^="select_saved_filter"]:hover').length === 0) && (_this.state.savedFilterOpen)) {
                if (event.type === "click") {
                    _this.setState({
                        savedFilterOpen: false
                    })
                }
            }
        });
    }

    showSavedFilterDropdown = () => {
        let _this = this;
        _this.setState({
            savedFilterOpen: !_this.state.savedFilterOpen
        })
    }
    handleVisibilityChange = (visibility) => {
        let _this = this
        let filterInputName = $("#filterName").val()
        if (visibility === visibilityOptions[0].value) {
            _this.setState({
                visibility: visibilityOptions[0].value
            }, () => {
                this.checkFilterExists(this.state.visibility,filterInputName);
            })
        } else {
            _this.setState({
                visibility: visibilityOptions[1].value
            }, () => {
                this.checkFilterExists(this.state.visibility,filterInputName);
            })
        }

    }
    checkFilterExists = (visibility, name) =>{
        let nameExist = this.state.allSavedFilters.filter(f=> (f.name === name) && (visibility === f.filter_visibility));

        if(nameExist.length>0){
            this.setState({
                filterNameExist:true,
                filterNameEmpty:false
            })
        }else{
            this.setState({
                filterNameExist:false
            })
        }
        if (name !== ""){
            this.setState({
                filterNameEmpty:false
            })
        }
    }

     saveFilterContent = () => {
        let _this = this;
        let options = visibilityOptions;
        if(!_this.props.canSaveCompanyFilters){
            options.find(e=>e.value === FILTER.VISIBILITY_OPTIONS.COMPANY_VALUE).isDisabled = true;
            options.find(e=>e.value === FILTER.VISIBILITY_OPTIONS.COMPANY_VALUE).tooltip = lang.manage_stacks.no_permission; 
        }
        return (
            <div className="fs-12">
                <h5 className="uk-margin-xsmall-bottom">{FILTER.FILTER_NAME}</h5>
                <Input id={"filterName" + (_this.props.dashboard || "")} className={"uk-input uk-form-small"} placeholder={lang.input_unique_filter_name} ref={rf => _this.savedFilterNameInput = rf}
                    defaultValue={_this.state.savedFilterData[SF_NAME]}
                    onBlur={(e) => _this.handleSaveFilterChange(e, SF_NAME)}
                />
                <span className={"red italic uk-margin-default-top uk-text-medium" + (_this.state.filterNameExist || _this.state.filterNameEmpty ? "" : " name-not-exist")}>{_this.state.filterNameEmpty ? lang.input_name_for_filter : lang.manage_reports.name_used}</span>
                <div className="uk-margin-xsmall-bottom">
                    <h5>{lang.manage_columns.titles.visibility}</h5>
                </div>
                <ToggleTab options={options}
                    onSelectTab={(visibility) => this.handleVisibilityChange(visibility)}
                    defaultValue={this.state.visibility}
                />
            </div>

        );
      }
     forgetFilterContent = () => {
        return (
            <h4>
                <span>{lang.ui_filter.messages.forget_filter+" "}{" "+this.state.savedFilterData.name}?</span>
            </h4>
        );
      }
     cancelFilterContent = () => {
        return (
            <h4>
                <span dangerouslySetInnerHTML={{__html:MESSAGES.ui_filter.messages.discard_changes}} />
            </h4>
        );
      }

     filterMessageContent = () => {
        return (
            <h4>
                <span dangerouslySetInnerHTML={{__html:this.state.modalMessage}} />
            </h4>
        );
      }

    saveFilterDialogActions = () => {
        let comp = this;
        var saveFilterDisabled = !this.state.savedFilterData || this.state.savedFilterData[SF_EDITABLE] !== true;
        return (
            <>         
                <Button  
                    id={"save-as-new-filter-dialog"}   
                    label={MESSAGES.modal.buttons.save_as_new}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    disabled={this.state.savedFilterData[SF_NAME] === "" || this.state.filterNameExist}
                    onBtnClick={() => { comp.saveFilter(true, !comp.state.fromBasicFilter) }}
                />
                <Button  
                    id={"save-filter-dialog"}
                    label={MESSAGES.modal.buttons.save}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    disabled={saveFilterDisabled}
                    onBtnClick={() => { comp.saveFilter(false,!comp.state.fromBasicFilter) }}
                />
                <Button  
                    id={"cancel-save-filter-dialog"}
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    className="uk-modal-close"
                    onBtnClick={() => this.resetTempSavedFilterData(false)}
                />
            </>
        );
    }
    forgetFilterDialogActions = () => {
        return (
            <>
                <Button 
                    label={MESSAGES.modal.buttons.confirm}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}  
                    className="uk-modal-close"
                    onBtnClick={() => this.startForgetFilter()}
                />
                <Button  
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={this.handleCloseForgetFilterDialog}
                />
            </>
        );
    }
    cancelFilterDialogActions = () => {
        return (
            <>
                <Button 
                    label={MESSAGES.modal.buttons.yes}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}  
                    className="uk-modal-close"
                    onBtnClick={() => this.handleCloseCancelFilterDialog(true)}
                />
                <Button  
                    label={MESSAGES.modal.buttons.no}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => this.handleCloseCancelFilterDialog()}
                />
            </>
        );
    }
    filterMessageDialogActions = () => {
        return (
            <>
                {this.state.onSubmitCallback &&
                <Button 
                    label={MESSAGES.modal.buttons.yes}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}  
                    className="uk-modal-close"
                    onBtnClick={this.state.onSubmitCallback}
                />}
                <Button  
                    label={this.state.onSubmitCallback ? MESSAGES.modal.buttons.no : MESSAGES.modal.buttons.ok}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => {this.resetTempSavedFilterData();this.handleCloseFilterMessageDialog();}}
                />
            </>
        );
    }
    
     handleOpenSaveFilterDialog = () => {
        let _this = this;
        let visibility = (getLocalStorageValueByParameter(CURRENT_FILTER_VISIBILITY_COOKIE) || this.state.visibility) || visibilityOptions[0].value;
        if(!_this.props.canSaveCompanyFilters && visibility === FILTER.VISIBILITY_OPTIONS.COMPANY_VALUE){
            visibility = FILTER.VISIBILITY_OPTIONS.USER_VALUE;
        }
        _this.setState({
            OpenSaveFilter:true,
            visibility: visibility,
            filterNameEmpty: $("#filterName").val() !== '' ? false : true
        },()=>{
            this.checkFilterExists(this.state.visibility,this.state.savedFilterData.name)
        })
	}
     handleCloseSaveFilterDialog = () => {
        let _this = this
        _this.setState({
            OpenSaveFilter:false,
            visibility:getLocalStorageValueByParameter(CURRENT_FILTER_VISIBILITY_COOKIE),
            isCheckBoxChecked: tryParse(sessionStorage.getItem(SCOPE_FILTERS))?.[0]?.isDefault
        })
	}

     handleOpenForgetFilterDialog = () => {
        let _this = this
        _this.setState({
            OpenForgetFilter:true
        })
	}
    
     handleCloseForgetFilterDialog = () => {
        let _this = this
        _this.setState({
            OpenForgetFilter:false,
        })
	}
     handleCloseFilterMessageDialog = () => {
        let _this = this;

        _this.setState({
            openFilterMessageDialog:false,
        })
	}

     handleCloseCancelFilterDialog = (fromYes) => {
        let _this = this
        if(fromYes === true){
            _this.setState({
                cancelFilterDialog: false,
                isCheckBoxChecked: tryParse(sessionStorage.getItem(SCOPE_FILTERS))?.[0]?.isDefault
            },()=>{
                _this.resetFilterChanges();
                _this.dismissModal();
            })
        } else {
            _this.setState({
                cancelFilterDialog: false,
            })
        }
 
	}

    launchToast=()=> {
        $("#toastLogin").addClass("show");
        setTimeout(function(){
            $("#toastLogin").removeClass("show");
        }, 4000);
      }

      setInfoDialogOpen = (isOpen, infoMsg) => {
        let _this = this;
        _this.setState({
          openInfoDialog: isOpen,
          infoMsg: infoMsg || this.state.infoMsg
        })
      }
  
      openInfoDialogActions = () => {
        return (
          <Button
            label={lang.modal.buttons.ok}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => this.setInfoDialogOpen(false)}
          />
        )
      }

    /**
     * displays the saved filter dropdown
     * @returns 
     */
    getSavedFilterDropdownContent() {
        // if (!this.state.allSavedFiltersOptions) {
        //     return null;
        // }

        let filter = this.props.filterFinal || getDefaultLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, [])
        filter = typeof filter === "string" ? tryParse(filter) : filter;

        let savedFilterVal = this.state.savedFilterData ? findOptionByKeyValue(this.state.allSavedFiltersOptions, "value", this.state.savedFilterData[SF_ID]) : "";
        savedFilterVal = savedFilterVal || "";  //if undefined set it as empty string, otherwise the dropdown is not emptying

        if(filter?.length === 0 && savedFilterVal !== "") { // when redirected from dashboard, filter is empty but savedFilter is not
            filter = typeof this.state.savedFilterData?.filter === "string" ? tryParse(this.state.savedFilterData?.filter) : this.state.savedFilterData?.filter;
        }
        
        let filterHasSegments = typeof filter === "string" ? false : filter?.filter(f => [SEGMENTS_TITLES.PROFIT_SEGMENTS.value, SEGMENTS_TITLES.PROFIT_TIERS.value].includes(f.field)).length > 0
        const segmentsWarningMsg = this.props.quadrantsDisabled && filterHasSegments ? lang.header.tooltips.segments_not_applicable : undefined;

        // if we redirect from a widget having a non saved filter
        let widgetHasNoSavedFilter = filter?.length === 0 && this.state.filterRefs.length > 0 ? this.state.filterRefs[0].ref.current?.filterObj?.vector !== "" : false;

        if (this.props?.useNewQuickFilters) {
            let isFilterNotEmpty = !this.props?.isWidgetFilter && ((filter?.length > 0 && filter !== "[]") || widgetHasNoSavedFilter);
            let isSavedFilterNotBroken = savedFilterVal?.isFilterBroken ===  false;
            let isAppliedFilterNotBroken = filter.flatMap(m => m.entities || []).filter(entity => entity?.scenarioIds?.includes(this.props.scenario_id)).length > 0;
            let showSaveBtn = isFilterNotEmpty && (isAppliedFilterNotBroken || isSavedFilterNotBroken);
            return (
                <div style={{display: "flex", alignItems: "center"}}>
                    <div id="saved-filters-container" className={showSaveBtn ? "saved-filters-container" : ""}>
                        {showSaveBtn && <Button
                            id="header-save-filter-btn"
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            aria-hidden="true"
                            className={`${this.state.savedFilterOpen ? "active-dropdown" : ""}`}
                            onBtnClick={() => this.startSaveFilter(filter, filter?.filter(f => !f.isBasicFilter).length === 0 && !widgetHasNoSavedFilter)}
                            leftIcon={<SaveFiltersIcon style={{ width: "100%", height: "100%" }} />}
                        />}
                        <Dropdown
                            id={"select_saved_filter" + (this.props.dashboard || "")}
                            className={`button__dropdown max_width ${showSaveBtn ? "select_saved_filter" : ""}`}
                            placeholder={lang.header.placeholders.my_filters}
                            value={savedFilterVal}
                            onChange={this.onSelectSavedFilter}
                            options={this.state.allSavedFiltersOptions}
                            dropDownWidth={120}
                            type={DROPDOWN_TYPE.BUTTON}
                            disabled={this.props.savedFilterDisabled}
                            isOpen={this.state.savedFilterOpen}
                            menuWidth={this.props?.isWidgetFilter ? 307 : 380}
                            dropdownRight={this.props?.isWidgetFilter}
                            isLoading={this.state.isSavedFiltersLoading}
                        />
                    </div>
                    {segmentsWarningMsg && <i className="fas fa-exclamation pi-text-yellow fa-lg uk-margin-small-right" title={segmentsWarningMsg}/>}
                    {(isFilterNotEmpty && !(isAppliedFilterNotBroken || isSavedFilterNotBroken)) && (<AlertOctagon className="filter-broken-icon" uk-tooltip="This filter cannot be applied on this scenario" />)}
                </div>
            );
        }

        return (
            <Dropdown
                id={"select_saved_filter" + (this.props.dashboard || "")}
                className="button__dropdown max_width"
                placeholder="Saved filters"
                value={savedFilterVal}
                onChange={this.onSelectSavedFilter}
                options={this.state.allSavedFiltersOptions}
                dropDownWidth={155}
                type={DROPDOWN_TYPE.BUTTON}
                disabled={this.props.savedFilterDisabled}
                isOpen={this.state.savedFilterOpen}
                icon={<Icon className="fal fa-bookmark" sx={{ fontWeight: "500", overflow: "initial", fontSize: convertPxToViewport(16) }} />}
            />
        );
    }

    render() { 
        var comp = this;
        var clearAll = MESSAGES.ui_filter.clear_all;
        var filterModal = this.renderFilterModal();
        var filterDisplay = this.props.hideFilterDisplay || this.props.fromSecondaryHeader || this.props.isWidgetFilter ? "" : this.renderFilterDisplay();
        let profitSegmentField = SEGMENTS_TITLES.PROFIT_SEGMENTS.label;
        let profitTierSegmentField = SEGMENTS_TITLES.PROFIT_TIERS.label;
        var quadTierNotWorkingMsgAddition = this.props.quadrantsDisabled ? ", "+profitSegmentField+" and "+profitTierSegmentField+" filters" : "";
        var not = quadTierNotWorkingMsgAddition !== "" ? " aren't" : " isn't";
        var psNotWorkingMsg = "Note that the profit stack filter" + quadTierNotWorkingMsgAddition + not +" applied in this report.";
        var quadTierNotWorkingMsg = "Note that "+profitSegmentField+" and "+profitTierSegmentField+" filters are not applied in this report.";
        var message = this.props.quadrantsDisabled ?  this.props.psFilterDisabled ? "- " + psNotWorkingMsg : "-" + quadTierNotWorkingMsg :  this.props.psFilterDisabled ? "- " + psNotWorkingMsg : "";
        message = message === "" && this.props.parentMessage ? this.props.parentMessage : message;
        
        var isPublic = this.state.savedFilterData_temp[SF_ISPUBLIC];
        var saveFilterDisabled = !this.state.savedFilterData || this.state.savedFilterData[SF_EDITABLE] !== true;
        const Option = (props) => {
            return (
                <div title={props.data.title} className="Select-option">
                    <components.Option {...props} />
                </div>
            );
        };
        const ValueContainer = (props) => {
            return (
                <components.ValueContainer {...props}>
                    <div className="saved-filter-text-container">
                        <Icon className={"saved-filter-icon fal fa-bookmark uk-margin-small-right"} sx={{ fontSize: "1.3rem" }} />
                        <span className="saved-filter-text">{lang.header.placeholders.saved_filters}</span>
                    </div>
                </components.ValueContainer>
            );
          };
        const ArrowDownIcon = () => {
            return (
                <div onClick={this.showSavedFilterDropdown}>
                    <Icon className={"saved-filter-arrow-icon far fa-chevron-down uk-margin-small-left"} />
                </div>
            );
        };
          
          const DropdownIndicator = props => {
            return (
              <components.DropdownIndicator {...props}>
                <ArrowDownIcon />
              </components.DropdownIndicator>
            );
          };
        let filterHasQuadrant = this.state.exportQueryFilter !== undefined && this.state.exportQueryFilter.includes("quadrant");
        let messageWarning = "";
        if(this.props.quadrantDefault && filterDisplay.length > 0 && filterHasQuadrant) {
            messageWarning = MESSAGES.ui_filter.quadrant_timeline_warning;
            messageWarning = messageWarning.replace("{x}", this.props.dataSet);
        }
        /* split dropdown button custom style */
        const customStyles = {
            control: (styles) => ({
                ...styles,
                cursor: 'pointer',
                border: 'unset',
                background: '#d9d9d9',
                borderRadius: convertPxToViewport(4),
                borderWidth: convertPxToViewport(1),
            }),
            placeholder: (styles) => ({
                ...styles,
                color: '#FFF',
                fontSize: convertPxToViewport(12),
                padding: convertPxToViewport(8) + ' ' + convertPxToViewport(12) 
            }),
            option: (styles) => ({
                ...styles,
                cursor: 'pointer',
                padding: convertPxToViewport(8)+ ' ' + convertPxToViewport(12),
            }),
            menu: styles => ({
                ...styles,
                width: convertPxToViewport(200)
            })
        };
        return (
            <div id={"filter_container"} className={"filter-container " + (this.props.isDrilling ? "disabled" : "")}>
                {this.props.hideFilterDisplay ? "" :
                    <div id={"filter-display"} className={"filter-input uk-flex uk-flex-middle " + (filterDisplay.length > 0 && this.filterDisplayEditable === false ? "disabled" : "")}>
                    {this.filterDisplayEditable?
                        <div onClick={this.showSavedFilterDropdown} className={"split__button--dropdown"+(this.props.savedFilter && Object.keys(this.state.savedFilterData).length > 1 ? " saved_filters_selected" : this.props.savedFilter ? " saved_filters" : "")}>
                            {this.getSavedFilterDropdownContent()}
                        </div>
                        :""
                    } 
                        {filterDisplay && <div className={"filter-display-filters"}>
                            {filterDisplay}
                        </div>
                        }
                    <div className={"filter-display-filters_full_filter_query uk-hidden"}>
                        {this.state.exportQueryFilter}
                    </div>
                    {filterDisplay.length > 0 && this.filterDisplayEditable ?
                        <div className="close-filter pull-left" alt="Close"
                            onClick={this.clearFilter}>{clearAll}</div>
                        :""}
                    {filterDisplay.length > 0 && message.length > 0 ?
                        <div className="close-filter-note pull-left"><i className="fas fa-exclamation pi-text-yellow fa-lg uk-margin-small-right" title={message}/></div>
                        : ""
                    }
                    {messageWarning.length > 0 ?
                        <div className="uk-margin-small-left">
                            <i className="fas fa-exclamation pi-text-yellow fa-lg uk-cursor-pointer" uk-toggle="target: #full-year-warning-tooltip; mode: hover;" />
                            <div id="full-year-warning-tooltip" hidden>
                                <div className="toggleTooltipDiv">{messageWarning}</div>
                            </div>
                        </div>
                        : ""
                    }
                </div>
                }
                {filterModal}
                <Modal 
                    id={"save-filter-dialog"}
                    title={lang.save_filter} 
                    openDialog={this.state.OpenSaveFilter}  
                    closeClick={this.handleCloseSaveFilterDialog}
                    bodyContent={this.saveFilterContent} 
                    dialogActions={this.saveFilterDialogActions}
                    size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                    id={"forget-filter-dialog"}
                    openDialog={this.state.OpenForgetFilter}
                    closeClick={this.handleCloseForgetFilterDialog}
                    bodyContent={this.forgetFilterContent}         
                    dialogActions={this.forgetFilterDialogActions}
                    size={DIALOG_SIZE.MEDIUM}
                />
                <Modal  
                    id={"advanced-filter-cancel-dialog"}
                    openDialog={this.state.cancelFilterDialog}
                    closeClick={this.handleCloseCancelFilterDialog}
                    bodyContent={this.cancelFilterContent}
                    dialogActions={this.cancelFilterDialogActions}
                    size={DIALOG_SIZE.SMALL}
                />
                <Modal  
                    id={"filter-message-dialog"}
                    openDialog={this.state.openFilterMessageDialog}
                    closeClick={this.handleCloseFilterMessageDialog}
                    bodyContent={this.filterMessageContent}
                    dialogActions={this.filterMessageDialogActions}
                    size={DIALOG_SIZE.MEDIUM}
                />
                  <Modal
                    id={"info-dialog"}
                    openDialog={this.state.openInfoDialog}
                    bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                    dialogActions={this.openInfoDialogActions}
                    closeClick={() => this.setInfoDialogOpen(false)}
                    size={DIALOG_SIZE.MEDIUM}
                  />
                <div id="toastLogin" className="toast toast-success">
                    <div id="desc"><i className="fa-lg fas fa-check-circle uk-margin-small-right greenText" aria-hidden="true"></i>{lang.filter_saved}</div>
                </div>         
            </div>
        )
    }
}

class FilterDialogRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeOptions: [
                { label: MESSAGES.ui_filter.dropdowns.type.vector, value: FILTER_TYPE_VECTOR },
                { label: MESSAGES.ui_filter.dropdowns.type.profit_stack, value: FILTER_TYPE_PROFITSTACK, isDisabled: this.props.psFilterDisabled},
            ],
            toOptions: [
                {
                    label: MESSAGES.default_label, value: ""
                }
            ],
            fromOptions: [
                {
                    label: MESSAGES.default_label, value: ""
                }
            ],
            vectorOptions: [],
            functionOptions: [],
            entitiesDropDownText: MESSAGES.filter_text.no_results_found,
            updateOptions: false,
            entitiesRndId: Math.floor(Math.random()*100),
            isLoading: false,
            menuIsOpen: false,
            functionMenuOpen: false,
            pslArray: props.psLinesOptions

        }

        this.fetchAPI = fetchAPI.bind(this);
        this.drawFilterEntities = this.drawFilterEntities.bind(this);
        this.switchOperator = this.switchOperator.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.loadEntitiesOnInputChange = this.loadEntitiesOnInputChange.bind(this);
        this.fetchFilterEntities = this.fetchFilterEntities.bind(this);
        this.toggleEntitiesMenu = this.toggleEntitiesMenu.bind(this);
        this.noOptionsMessage = this.noOptionsMessage.bind(this);
        this.showHideFromToTitles = this.showHideFromToTitles.bind(this);
        this.getFieldOptions = this.getFieldOptions.bind(this);

        this.filterObj = {
            [_type]: "vector"
        }
        this.filterObj = copyObjectValues(this.props.filterRowObj);
        if (this.props.nextLogicalOperator) {
            this.filterObj[_logicalOperator] = this.props.nextLogicalOperator;
            this.props.clearLogicalOperator();
        }
        
        this.profitSegmentFields = _profitSegmentField;
        this.tierSegmentFields = _profitTierFiled;
        this.quadrantsFields = _quadrantField;
        this.quadrantTiersFields = _quadrantTierField;

    }
    
    toggleEntitiesMenu(toggle, value) {
        var comp = this;
        if (toggle === undefined) {
            toggle = !comp.state.menuIsOpen;    //if toggle not sent, revert menuIsOpen
        }

        if (toggle !== comp.state.menuIsOpen) {  //if true while already opened, or false while already closed, do not rerender
            comp.setState({
                menuIsOpen: toggle
            }, function () {
                
                if(value === 0 || value === undefined){
                    if(comp.filterObj[_field] === comp.profitSegmentFields || comp.filterObj[_field] === comp.quadrantsFields){
                        comp.drawFilterEntities(copyObjectValues(comp.props.segmentsDictionary), undefined, undefined, toggle)
                    }else if(this.filterObj[_field] === comp.tierSegmentFields || this.filterObj[_field] === comp.quadrantTiersFields){
                        comp.drawFilterEntities(copyObjectValues(comp.props.quadrantTierOptions), undefined, undefined, toggle)
                    }else{
                        comp.drawFilterEntities(comp.filterObj.initialEntitiesData, undefined, undefined, toggle);
                    }
                }
            });
        }
    }

    loadEntitiesOnInputChange(value, callback){
        const comp = this;

        if (this.filterObj[_type] === FILTER_TYPE_VECTOR && this.filterObj[_vector] && this.filterObj[_field]) {
            switch (this.filterObj[_field]) {
                case this.profitSegmentFields: //if segment
                    this.drawFilterEntities(copyObjectValues(comp.props.segmentsDictionary), "", callback);
                    break;
                case this.quadrantsFields: //if quadrant
                    this.drawFilterEntities(Object.values(QUADRANTS), "", callback);
                    break;
                case this.tierSegmentFields: //if segment tier
                case this.quadrantTiersFields: //if quadrant tier
                    this.drawFilterEntities(copyObjectValues(this.props.quadrantTierOptions), "", callback);
                    break;
                default:
                    clearTimeout(this.entitiesTimeout);
                    this.entitiesTimeout = setTimeout(function(){
                        comp.fetchFilterEntities(value, function(data){
                            if(callback && typeof callback === "function") {
                                callback(data);
                            }
                        });
                    }, 300);
                    break;
            }
        }
    }

    showHideFromToTitles() {
        var fromCount = 0;

        if (this.props.filterRefs && this.props.filterRefs.length > 0) { 
            this.props.filterRefs.map((filter) => {
                var obj = filter.ref.current ? filter.ref.current.filterObj : "";
                var isQuadrant = [this.profitSegmentFields, this.tierSegmentFields, this.quadrantTiersFields, this.quadrantsFields].indexOf(obj[_field]) > -1;
                if (isQuadrant) {
                    fromCount++;
                }
            })
        };
        if( fromCount > 0){
            $("#from-options-title").removeClass("uk-hidden");
            $("#from-options-title").addClass("uk-text-left");
            $("#from-options-title").addClass("width-75");

            $("#to-options-title").removeClass("uk-hidden");
            $("#to-options-title").addClass("uk-text-left");   
            $("#to-options-title").addClass("width-75");
        }
        if (fromCount === 0) {
            $("#from-options-title").addClass("uk-hidden");
            $("#from-options-title").removeClass("uk-text-left");
            $("#from-options-title").removeClass("width-75");

            $("#to-options-title").addClass("uk-hidden");
            $("#to-options-title").removeClass("width-75");
            $("#to-options-title").removeClass("uk-text-left");
        } 
    }

    drawFilterEntities(data, inputValue, callback, toggle) {
        data = data || [];
        var tempFunction = this.filterObj[_function];
        var keys = ["label", "value", "name", _type, "action"];
        var inputValObj = [inputValue, inputValue, "entity", FILTER_TYPE_VECTOR, "set-value"];
        inputValue = inputValue === undefined ? "" : inputValue;
        var optionsDisabled = false;

        var options = [];
        //to put entity into dropdown when data is received and disable others, if function is "contains"
        if ([_ct, _nct].includes(tempFunction)) {
            keys.push("isDisabled");  //the new key should be added AFTER adding the typed word to the options
            optionsDisabled = true;
        }

        //filter the quadrant values regarding the typed text
        if(inputValue !== "") {
            data = data.filter(v => v.label.toLowerCase().includes(inputValue.toLowerCase()));
        }

        data.map(function (item, key) {
            //if item has a label use it, else use the value
            var values = [item.label || item.value, item.abv || item.value, "entity", FILTER_TYPE_VECTOR, "input-value", optionsDisabled];
            options.push(buildNewObject(keys, values));
        });

        let optionsSet = new Set(options.map(e => JSON.stringify(e)));
        options = Array.from(optionsSet).map(e => JSON.parse(e));

        let inputOption = buildNewObject(keys, inputValObj);
        if (inputValue !== "") {
            let optionsExistsIndex = findIndexOfValue(options, "value", inputOption.value);
            if(optionsExistsIndex === -1 || [_ct, _nct].includes(tempFunction) || inputOption.value.includes(",")) {
                // if (this.filterObj.function !== FILTER_FUNCTIONS.FILTER_FUNCTION_EQ && this.filterObj.function !== FILTER_FUNCTIONS.FILTER_FUNCTION_NEQ){
                    options.unshift(inputOption);   //if option doesn't exist, add it in the beginning
                // }
            } else {
                options.splice(optionsExistsIndex, 1, inputOption); //if option exists, replace it
            }
            if (options?.filter(e=>e.value === inputValue)?.length === 0) {
                logUIError("input value "+ inputValue +" wasn't added to the options");
            }
        }

        this.filterObj[_entitiesOptions] = options;
        if(callback && typeof callback === "function") {
            callback(options);
        } else {
            this.setState(this.state,()=>{
                if(this.selectRef && toggle) {
                    this.selectRef.focus();
                }
            });
        }
    }

    fetchFilterEntities(inputValue, callback) {
        var _this = this;
        var tier = this.filterObj[_vector];
        var field = this.filterObj[_field];
        inputValue = inputValue === undefined ? "" : inputValue;

        var tierOption = _this.props.vectorOptions.filter(e => e.value === tier);
        let vectorId = tierOption.length > 0 ? tierOption[0][VECTOR_STAGING_ATTRIBUTES.MACHINE_ID]: undefined;
        var isDefault = tierOption.length > 0 ? tierOption[0][VECTOR_STAGING_ATTRIBUTES.IS_DEFAULT] : false;

        var query = "action=getTierFilter"
            + "&tier=" + tier
            + "&scenario_id="+ this.props.scenario_id
            + "&filter_value=" + encodeURIComponent(inputValue.toUpperCase())
            + "&filter_attribute=" + field
            + "&is_default=" + isDefault
            + "&vector_id=" + vectorId;

        var onThenCallback = (data) => {
            data = data?.tierFilters?.length ? data.tierFilters : [];
            data = data.map(item => {
                var tempVal = item[field];
                return { value: tempVal, label: tempVal }
            });

            // this condition is to instantly set the state to isLoading false when we change the _function value
            if(!_this.state.menuIsOpen) {
                _this.setState({isLoading: false})
            }
            _this.state.isLoading = false; // using this.state instead of setState to stop the options dropdown from rerendering when we input a suggestion
            _this.state.inputValue = "";
            if(_this.filterObj[_field] === field) {    //check if field has been changed before request came back
                if(_this.state.updateOptions) {
                    _this.state.updateOptions = false; 
                    var isToggled = _this.state.menuIsOpen;
                    if(_this.filterObj[_function] !== "") { // when field is selected for the first time, user need to select the function before the value gets focused
                        _this.toggleEntitiesMenu(false);
                    }
                    _this.drawFilterEntities(data, inputValue, callback);
                    if(isToggled) {
                        _this.toggleEntitiesMenu(isToggled);    //retoggle entities menu dropdown
                    }
                } else {
                    _this.drawFilterEntities(data, inputValue, callback);
                }
            }
            if (inputValue === "" || inputValue === undefined) {
                _this.filterObj.initialEntitiesData = data;
                _this.forceUpdate();
            }
        };

        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: UI_ACTIONS.FILTER_AUTOCOMPLETE,
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.GET,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.machine_name]: this.props.machine_name,
            [FETCHAPI_PARAMS.profitFormat]: this.props.profitFormat,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.fetch_filter_entities,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + filterDialog,
        }
        //using a UI action and not function name because this action starts here and ends here, unlike change_format and select_filter
        if (vectorId && vectorId !== null) {
            if(inputValue){
                _this.state.inputValue = inputValue;// do not force rerender in order not to loose the input value in the search field
                _this.state.isLoading = this.filterObj[_field] !== "" && this.filterObj[_function] !== "" && this.filterObj[_vector] !== "";
            }else{
                _this.setState({// force rerender to disable dropdown while loading
                    isLoading: this.filterObj[_field] !== "" && this.filterObj[_function] !== "" && this.filterObj[_vector] !== ""
                })
            }
            this.fetchAPI(fetchOptions);
        }
    }

    getFirstAndLastDatasets(dataset) {
        dataset = dataset.value ? dataset.value : dataset;
        let datasets = returnLastDatasets(this.props.fromOptions, dataset);
        var array = [];
        if (datasets.length) {
            array.push(datasets[0].value);  //if only one dataset is available, add it in the "to"
        }
        if (datasets.length === 4) {    //only push another dataset if it's the fourth quarter in the from quarter's FY
            array.push(datasets[datasets.length -1].value);
        }
        
        return array;
    }
    
    adjustToOptions(e) {
        this.filterObj[_from] = e.value;
        var options = copyObjectValues(this.props.toOptions);
        if (e.label === MESSAGES.default_label) {
            options = [{ label: MESSAGES.default_label, value: ""}]
            this.filterObj[_to] = "";
        } else {
            var emptyOptions = [];
            if (this.props.checkIfMonthsValid(e.label)) {
                emptyOptions = [];
                var arrayOptions = this.getFirstAndLastDatasets(e.label);

                arrayOptions.forEach((obj) => {
                    emptyOptions.push({ label: obj, value: obj === e.label ? MESSAGES.ui_filter.dropdowns.months.mo3.value : MESSAGES.ui_filter.dropdowns.months.mo12.value });
                });
            } else {
                emptyOptions = [];
                emptyOptions.push({ label: e.label, value: MESSAGES.ui_filter.dropdowns.months.mo3.value });  
            }
        
            options = emptyOptions;
        }

        this.filterObj[_toOptions] = options;
        this.filterObj[_to] = options[0].value; // as default
        this.setState({
            toOptions: options,
            filterObj: this.filterObj
        });
    }

    handleMultiChange = selectedOptions=> {
        var arrayOfValues = [];
        if(selectedOptions && typeof selectedOptions === "object" && !Array.isArray(selectedOptions)) {
            selectedOptions = [selectedOptions];
        }

        if(selectedOptions) {    
            selectedOptions.map(labelValObj=>{
                if(labelValObj.action === "set-value" && labelValObj.value.includes(",")) {
                    let tempValues = labelValObj.value.split(",");
                    tempValues.map(stringVal=>{
                        if(stringVal === "") {
                            return;
                        }
                        arrayOfValues.push({label: stringVal.trim(), value: stringVal.trim(), name:labelValObj.name, type:labelValObj.type});
                    });
                } else {
                    if ([SEGMENTS_TITLES.PROFIT_SEGMENTS.value, SEGMENTS_TITLES.PROFIT_TIERS.value, SEGMENTS_TITLES.PROFIT_TIERS.variableName].includes(this.filterObj[_field])) {
                        labelValObj.label = new Segment().getSegmentObject(labelValObj.value)?.originalLabel;
                    }
                    arrayOfValues.push(labelValObj);
                }
            });
        } else {
            this.state.isLoading = this.filterObj[_field] !== "" && this.filterObj[_function] !== "" && this.filterObj[_vector] !== "";
            this.state.updateOptions = true;
            this.drawFilterEntities();
            this.loadEntitiesOnInputChange();
        }
        var uniq = new Set(arrayOfValues.map(e => JSON.stringify(e)));
        arrayOfValues = Array.from(uniq).map(e => JSON.parse(e));
        this.state.menuIsOpen = true;
        this.filterObj[_entities] = arrayOfValues || [];  //when deleting all options, they're received as null
    };

    resetEntities(oldType) {
        if(this.filterObj[_type] === FILTER_TYPE_VECTOR) {
            this.filterObj[_entities] = [];
            this.filterObj[_entitiesOptions] = [];
        } else {
            this.filterObj[_entities] = "0";
        }

        if(oldType && oldType !== this.filterObj[_type]) {
            this.filterObj[_field] = "";
            this.filterObj[_function] = "";
        }
    }
        
    setHasUnsavedChanges = () => {
      if(this.props.setHasUnsavedChanges && typeof this.props.setHasUnsavedChanges === "function") {
        this.props.setHasUnsavedChanges(true);
      }
    }

    handleChange(e, attribute, newFilter) {
        let tempState = {};
        let oldVal = this.filterObj[attribute];
        this.setHasUnsavedChanges();

        if(e && oldVal === e.value) {    //if choosing same old value, do nothing
            return;
        }
        if (attribute === _field && e[_type] === "profit_stack") {
            this.filterObj[_name_in_fact] = e[_name_in_fact];
        }
        if (attribute !== _entities || this.filterObj[_type] === FILTER_TYPE_PROFITSTACK) {
            if(attribute !== _logicalOperator && !newFilter){
                this.filterObj[attribute] = e.value;    //replace the object attribute value by the new one
            }
        }
        
        if (attribute !== _type && this.filterObj[_type] === "") {
            this.filterObj[_type] = this.state.typeOptions[0].value
        }

        let isQuadrant = [this.profitSegmentFields, this.tierSegmentFields, this.quadrantTiersFields, this.quadrantsFields].indexOf(this.filterObj[_field]) > -1;
        if(attribute !== FILTER.KEYS.MIN && attribute !== FILTER.KEYS.MAX){
            tempState.isInBetween = false;
        }
        //to hide
        $("#advanced-text").addClass("uk-hidden");
        $("#draggable-advanced-filter").addClass("uk-hidden");
    
        //to show inline
        $("#advanced-filter").removeClass("uk-hidden");
        $("#advanced-filter").addClass("uk-display-flex");

        //removing the highlight on the type dropdownlist
        $("#filterVector").removeClass("highlight-select");
        let loadEntities = false;
        let render = true
        //run specific behaviors according to some conditions
        switch (attribute) {
            case _type:

                this.resetEntities(oldVal);
            break;
            case _vector:
                this.filterObj[_vectorLabel] = e.label;
                tempState.isLoading = this.filterObj[_type] === FILTER_TYPE_VECTOR && this.filterObj[_field] !== "" && this.filterObj[_function] !== "" && this.filterObj[_vector] !== "";
                this.resetEntities();
                if (this.filterObj[_type] === FILTER_TYPE_VECTOR && this.filterObj[_field] && !isQuadrant) {
                    this.fetchFilterEntities(); //sets the default options for the dropdown
                }
                break;
            case _field:
                // if(!isQuadrant) {
                //     tempState.isLoading = true;
                // }
                if(isQuadrant){
                    tempState.isLoading = false;
                }
                tempState.updateOptions = true;
                this.showHideFromToTitles();
                this.drawFilterEntities();

                this.resetEntities();
                if(this.filterObj[_field] !== "" && this.filterObj[_function] !== "" && this.filterObj[_vector] !== ""){
                    this.loadEntitiesOnInputChange();
                    loadEntities = true;
                }else{
                    loadEntities = false;
                }
                // loadEntities = true;
                break;
            case _function:
                if ([_empty, _nempty].indexOf(e.value) > -1) {
                    this.filterObj[_entities] = [];
                } else if(e.value === _isBetween){
                    tempState.isInBetween = true;
                } else {
                    tempState.updateOptions = true;

                    tempState.functionMenuOpen = false // functionMenuOpen is used to prevent function dropdown from closing
                    this.drawFilterEntities();
    
                    this.resetEntities();
                    this.loadEntitiesOnInputChange();
                    loadEntities = true;
                }
                break;
            case _entities:
                if (this.filterObj[_type] === FILTER_TYPE_VECTOR) {
                    this.handleMultiChange(e);
                }
                render = false;
                break;
            case _logicalOperator:
                if(!newFilter) {
                    this.filterObj[attribute] = e.value;
                }
                this.props.addNewFilter(newFilter, attribute, e);
                break;
            case _from:
                this.adjustToOptions(e);
                break;
            case _to:
            if (this.filterObj[_from] === "") {
                this.setInfoDialogOpen(true, "Please insert the from quarter first.")
                }
                break;
            case FILTER.KEYS.MIN:
                this.filterObj[FILTER.KEYS.MIN] = e.value;
                break;
            case FILTER.KEYS.MAX:
                this.filterObj[FILTER.KEYS.MAX] = e.value;
                break;
        }
        this.setState(tempState)
            this.forceUpdate(); //to update the dropdowns
            this.props.onChangeFilterRow(this.props.rowIndex, loadEntities, render);
    }

    switchOperator() {
        var newValue = this.filterObj[_logicalOperator] === _AND ? _OR : _AND;
        this.handleChange({ value: newValue }, _logicalOperator, false);
        this.props.hideAdvancedFilter();
        this.forceUpdate();
    }

    handleInputChange(e) {
        let floatValue = getNumericValue($(e.currentTarget).val());    //remove all non numeric characters
        $(e.currentTarget).val(floatValue);
    }

    removeFilter() {
        this.props.removeFilter(this.props.rowIndex, false, false);
        this.showHideFromToTitles();
        this.props.hideAdvancedFilter();
        this.setHasUnsavedChanges();
    }

    loadingMessage(obj) {
        return MESSAGES.filter_text.fetching_results;
    }
    noOptionsMessage(obj) {
        return this.state.isLoading ? MESSAGES.filter_text.fetching_results : MESSAGES.filter_text.no_results_found;
    }

    componentDidMount() {
        var comp = this;
        $("#"+this.props.componentId).on("mousedown", function (e) {
            if(comp.state.menuIsOpen && (!$(e.target).parents("[id^=entities_quadrants_"+comp.state.entitiesRndId+"]").length || $(e.target).parents("[class$=IndicatorsContainer]").length )) {
                if (["[]", "\"\""].includes(JSON.stringify(comp.filterObj[_entities])) && [_ct, _nct].indexOf(comp.filterObj[_function]) > -1 && comp.filterObj[_entitiesOptions].length && !comp.filterObj[_entitiesOptions][0]["isDisabled"]) {
                    // comp.handleChange(comp.filterObj[_entities].concat(comp.filterObj[_entitiesOptions][0]), _entities);
                }
                comp.toggleEntitiesMenu(false, $(e.target).parents("[id^=entities_quadrants_"+comp.state.entitiesRndId+"]").length);
            }
        });

        if(!comp.props.filterRowObj.isBasicFilter && !deepCompareObjects(comp.filterObj, comp.props.getEmptyFilterSample("object")) && (!comp.filterObj[_entitiesOptions] || !comp.filterObj[_entitiesOptions].length)) {
            this.loadEntitiesOnInputChange();
        }

        $(document).on("click", (e) => {
            let _this = this;
            if (this.state.functionMenuOpen && !$($(e.target)[0]).hasClass('function-dropdown')) {
                _this.setState({functionMenuOpen: false});
                return;
            }
        });
    }

    componentWillUnmount() {
        $("#"+this.props.componentId).unbind("mousedown");
    }

    handleSelectRef(select) {
        this.selectRef = select;
        if (select) {
            select.setState({
                isOpen: this.state.menuIsOpen
            })
        }
    }

    getFieldOptions() {
        let _this = this;
        let isVectorFilter = this.filterObj[_type] !== FILTER_TYPE_PROFITSTACK;
        let fieldOptions = isVectorFilter ? [] : this.props.psLinesOptions;
        if(isVectorFilter) {
            let vectorObj = findOptionByKeyValue(this.props.vectorOptions, "value", this.filterObj[_vector]);
            if(!!vectorObj) {
                let allowedAttrs = getVectorAllowedAttributes(this.filterObj[_vector], vectorObj[VECTOR_STAGING_ATTRIBUTES.GROUP], this.props.user);
                if(allowedAttrs.includes(IDENTIFIERS.NAME) || this.props.hideFilterDisplay) {
                    fieldOptions.push(MESSAGES.ui_filter.dropdowns.fields_attributes.name);
                }
                if(allowedAttrs.includes(IDENTIFIERS.NUMBER) || this.props.hideFilterDisplay) {
                    fieldOptions.push(MESSAGES.ui_filter.dropdowns.fields_attributes.number);
                }
                if(!_this.props.quadrantsDisabled) {    //add quadrant and quadrant/tier options if quadrant filter is not disabled
                    fieldOptions.push(SEGMENTS_TITLES.PROFIT_SEGMENTS);
                    fieldOptions.push(SEGMENTS_TITLES.PROFIT_TIERS);
                }
            }
        }

        return fieldOptions;
    }

    setFieldDropdownValue = (pslArray) => {
        let _this = this;
        if (_this.props.isFromExclusionConfiguration) {
            pslArray = pslArray.filter(e=>e.costtype !== costtype.attribute);
        }
        if (pslArray.length > 0) {
            if (this.filterObj.field) {
                return pslArray?.map(psl => {
                    if (psl.value === _this.filterObj.field) {
                        updateData(pslArray, psl);
                    } else {
                        delete psl.checked;
                    }
                    if (psl.children) {
                        _this.setFieldDropdownValue(psl.children)
                    }
                    return psl;
                })
            } else {
                _this.filterObj.field = pslArray[0].value;
                _this.filterObj["returnname"] = pslArray[0].value;
                return pslArray
            }
        }
    }

    handlePslineChange = (node, attribute) => {
        let _this = this
        _this.setHasUnsavedChanges();
        let profitStackLines = copyObjectValues(this.props.psLinesOptions);
        let oldVal = _this.filterObj.attribute;
        if(node && oldVal === node.value) {    //if choosing same old value, do nothing
            return;
        }
        updateAllData(profitStackLines, false); // uncheck all checked lines
        updateData(profitStackLines, node);
        _this.filterObj.field = node.value;    //replace the object attribute value by the new one
        _this.filterObj[_name_in_fact] = node.nameInFact;
        _this.filterObj[attribute] = node.value;    //replace the object attribute value by the new one
        if(_this.props.isFromExclusionConfiguration) {
          _this.filterObj["returnname"] = node.value;
        }

        if (this.filterObj[_type] === "") {
            _this.filterObj[_type] = this.state.typeOptions[0].value
        }
    };

    render() {
        let filterTypesOptions = this.props.fromProfile || this.props.isManageAccessScope? this.state.typeOptions.filter(e=>e.value === FILTER_TYPE_VECTOR) : this.state.typeOptions;
        let optionsMissing = this.filterObj[_field] === "" || this.filterObj[_function] === "" || this.filterObj[_vector] === "";
        const optionLabel = ({ label, isDisabled, vector_description, isprofessional, is_default, isGroupTitle, tooltipText }) => (
            <div className={(isGroupTitle ? "option-group-header" : "") + " options-padding"} uk-tooltip={!!tooltipText ? tooltipText : ""}>
                {label}
            </div>
        );
        
        const customStyles = {
            menu: styles => ({
                ...styles,
                width: convertPxToViewport(220)
            }),
            option: (styles) => ({
                ...styles,
                cursor: 'pointer',
                fontSize: convertPxToViewport(12),
                padding: convertPxToViewport(6) + ' 0',
            }),
            control: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                background: '#fff',
                borderColor: '#dcdcdc',
                minHeight: convertPxToViewport(33),
                height: convertPxToViewport(33),
                fontSize: convertPxToViewport(12),
                borderRadius: convertPxToViewport(4),
                borderWidth: convertPxToViewport(1),
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: convertPxToViewport(33),
                padding: '0 '
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: convertPxToViewport(33),
            }),
            dropdownIndicator: (styles) => ({
                ...styles,
                paddingTop: convertPxToViewport(6),
                paddingBottom: convertPxToViewport(6),
            }),
            clearIndicator: (styles) => ({
                ...styles,
                paddingTop: convertPxToViewport(6),
                paddingBottom: convertPxToViewport(6),
            }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    color: "#C6CCD7",
                    lineHeight: convertPxToViewport(12)
                };
            }
        };

        const customStylesMultiSelect = {
            option: (styles) => ({
                ...styles,
                cursor: 'pointer',
                fontSize: convertPxToViewport(12),
                padding: convertPxToViewport(8)+' 0 '+' '+convertPxToViewport(8)+' '+convertPxToViewport(9)
            }),
            control: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                borderColor: '#dcdcdc',
                minHeight: convertPxToViewport(33),
                height: convertPxToViewport(33),
                fontSize: convertPxToViewport(12),
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: convertPxToViewport(33),
                padding:  '0 '+convertPxToViewport(6),
                multiValue: (styles) => ({
                    marginRight: convertPxToViewport(15),
                })
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: convertPxToViewport(33),
            }),
            dropdownIndicator: (styles) => ({
                ...styles,
                paddingTop: convertPxToViewport(6),
                paddingBottom: convertPxToViewport(6),
            }),
            clearIndicator: (styles) => ({
                ...styles,
                paddingTop: convertPxToViewport(6),
                paddingBottom: convertPxToViewport(6),
            }),
            menu: base => ({
                ...base,
                zIndex: 100
              }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    color: "#C6CCD7",
                    lineHeight: convertPxToViewport(12),
                };
            },
          
        };

        var isVectorFilter = this.filterObj[_type] !== FILTER_TYPE_PROFITSTACK;  //not to repeat the condition
        var vectorOptions = this.props.vectorOptions;
        var functionOptions = [];
        let fieldOptions = this.getFieldOptions();
        var entitiesOptions = this.filterObj[_entitiesOptions] || [];
        var fromOptions = this.props.fromOptions || [];
        var toOptions = this.filterObj[_toOptions] ? this.filterObj[_toOptions] : this.state.toOptions;
            
        if (isVectorFilter) {
            if ([this.profitSegmentFields, this.tierSegmentFields, this.quadrantTiersFields, this.quadrantsFields].indexOf(this.filterObj[_field]) > -1) {
                functionOptions = stringQuadrantFunctionOptions
            } else {
                functionOptions = stringFunctionOptions;
            }
        } else {
            functionOptions = numericFunctionOptions;
        }
        var isRowDisabled = (this.props.psFilterDisabled && this.filterObj[_type] === FILTER_TYPE_PROFITSTACK) || this.filterObj.isDisabled;
        if(!isRowDisabled) {
            //to split the condition into 2 for the sake of shortness, if not yet disabled from PS condition, check quad condition
            isRowDisabled = this.props.quadrantsDisabled && [this.profitSegmentFields, this.tierSegmentFields, this.quadrantTiersFields, this.quadrantsFields].indexOf(this.filterObj[_field]) > -1;
        }

        var filterRow = [];
        if(isRowDisabled) {
            let disabledEntities = this.filterObj[_entities];
            let disabledEntitiesArray;
            let filterFunction = functionOptions.filter(f => f.value === this.filterObj.function)[0].label
            if(this.filterObj[_type] !== FILTER_TYPE_PROFITSTACK) {
                disabledEntities = this.filterObj[_entities]?.map(en=>{return en["label"]});
                disabledEntitiesArray = disabledEntities?.map(m => {
                    if (this.filterObj[_field] === this.profitSegmentFields || this.filterObj[_field] === this.quadrantsFields) {
                        return new Segment().getSegmentObject(m).label
                    } else {
                        return m;
                    }
                });
            }
            filterRow = 
            <React.Fragment>
            <div datarowindex={"filter-row-" + (this.props.rowIndex + 1)} className="uk-display-inline-flex uk-margin-small-bottom max_width" key={"vectorFilterRow" + Math.random()}>
                <div className="filter-item-disabled width-40 uk-margin-medium-left"><p className="uk-grab">{this.props.rowIndex + 1}</p></div>
                <div className="filter-item-disabled input-width-250 uk-margin-small-right-left">{this.filterObj[_type]}</div>
                <div className="filter-item-disabled input-width-200 uk-margin-small-right-left">{this.filterObj[_vectorLabel]}</div>
                <div className="filter-item-disabled input-width-250 uk-margin-small-right-left">{capitaliseFirstLetterAfterChar(this.filterObj[_field].replace("_"," ").replace("/", " "), " ")}</div>
                <div className="filter-item-disabled input-width-200 uk-margin-small-right-left">{filterFunction}</div>
                <div className="uk-display-inline-flex width-350 uk-margin-small-right-left">
                    <div className="filter-item-disabled input-width-125">{disabledEntitiesArray}</div>
                    <div className="filter-item-disabled input-width-125">{this.filterObj[_from] && this.filterObj[_from].indexOf("_")> -1 ? findOptionByKey(this.state.fromOptions, this.filterObj[_from]).label : this.filterObj[_from]}</div>
                    <div className="filter-item-disabled input-width-125">{this.state.toOptions && this.filterObj[_to] !== "" ? findOptionByKey(this.state.toOptions, this.filterObj[_to]).label : this.filterObj[_to]}</div>
                </div>
            {this.filterObj.isDisabled === undefined || this.filterObj.isDisabled === false?
                <div className="fa-div">
                    <i className="fal fa-trash fa-lg uk-button-icon transparent-bg" onClick={this.removeFilter}></i>
                </div> : ""}
            </div> 
            </React.Fragment>
        } else {
            filterRow = <React.Fragment>
                {this.props.rowIndex  !== 0 ? 
                    <div id={"operator-"+this.filterObj[_logicalOperator]} className="uk-margin-left">
                        <button id={"filter-operator-" + (this.props.rowIndex + 1)} className="border-button width-fit-cont" onClick={this.switchOperator}>
                            {this.filterObj[_logicalOperator]}<Icon className={"operator-arrow-icon far fa-chevron-down"} /></button>
                        {this.filterObj && this.filterObj[_logicalOperator] === _OR ?
                            <div className="filter-separator"></div> //remove the after parenthesis condition
                        :""}   
                    </div>
                : ""}
            <div datarowindex={"filter-row-" + (this.props.rowIndex + 1)} key={"filterRow_" + Math.random()} className="uk-display-inline-flex max_width">
                <div className="uk-display-flex align-items-center width-40 uk-margin-left uk-grab">
                    {this.props.totalRows > 0 ?
                        <div className="border-circle uk-margin-xsmall-right-left">
                            <span>{this.props.rowIndex + 1}</span>
                        </div>
                    :""}
                </div>
                <div id={`vector-dropdown-select-${this.props.rowIndex}`} className="max_width uk-margin-small-right-left uk-height-fit">
                    <Dropdown //to remove comment and add comment <CustomSelect
                        className={(this.filterObj[_type] ? "" : "input-warning highlight-select") + " input__dropdown"}
                        id="filterVector"
                        name="filterVector"
                        value={this.filterObj[_type] !== "" ? findOptionByKey(this.state.typeOptions, this.filterObj[_type]) : this.state.typeOptions[0]}     
                        options={filterTypesOptions}
                        onChange={(e)=>this.handleChange(e, _type)}
                        tabSelectsValue={true}
                        placeholder={"Choose a " + MESSAGES.ui_filter.col_names.type.toLowerCase()}
                        type={DROPDOWN_TYPE.INPUT}
                        defaultVal={this.filterObj[_type] !== "" ? findOptionByKey(this.state.typeOptions, this.filterObj[_type]) : this.state.typeOptions[0]}     
                        firstAttr={`#vector-dropdown-select-${this.props.rowIndex}`}
                    />
                </div>
                <div id={`vector-dropdown-${this.props.rowIndex}`} className={"max_width uk-margin-small-right-left uk-height-fit " + (this.state.isLoading ? "disabled" : "")}>
                    <Dropdown //to remove comment and add comment <CustomSelect
                        id={`filter_vector_dropdown_${this.props.rowIndex}`}
                        className={"input__dropdown " + (this.filterObj[_vector] ? "" : "input-warning")}
                        // classNamePrefix={"filter-dropdown"}
                        filterOption={(option, searchText)=>{return option.label.toLowerCase().includes(searchText.toLowerCase())}}
                        name="filterType"
                        value={findOptionByKey(vectorOptions, this.filterObj[_vector])}
                        options={vectorOptions}
                        onChange={(e)=>this.handleChange(e, _vector)}
                        tabSelectsValue={true}
                        placeholder={"Choose a " + MESSAGES.ui_filter.col_names.vector.toLowerCase()}
                        formatOptionLabel={optionLabel}
                        menuPlacement="auto"
                        maxMenuHeight={this.props?.isFromExclusionConfiguration ? 190 : undefined}
                        type={DROPDOWN_TYPE.INPUT}
                        // menuWidth={220}
                        // dropDownWidth={150}
                        defaultStyles={customStyles}
                        firstAttr={`#vector-dropdown-${this.props.rowIndex}`}
                        secondAttr={`#filter_vector_dropdown_${this.props.rowIndex}`}
                        disabled={this.state.isLoading}
                    />
                </div>
                <div id={`column-dropdown-${this.props.rowIndex}`}
                     className={"max_width uk-margin-small-right-left uk-height-fit " + (this.state.isLoading && this.filterObj[_field] !== "" ? "disabled" : "")}>
                    {this.filterObj.type === "profit_stack" ?
                        <Container
                            data={this.setFieldDropdownValue(copyObjectValues(this.state.pslArray))}
                            onChange={(e) => this.handlePslineChange(e, _field)}
                            mode={"radioSelect"}
                            className={
                                "x-axis landscape-configure heatmap-configure dropdown-tree filter_dialog_container"
                            }
                        />
                        :
                        <Dropdown
                            id={`filter_column_dropdown_${this.props.rowIndex}`}
                            className={"input__dropdown " + (this.filterObj[_field] ? "" : "input-warning")}
                            name="filterField"
                            value={findOptionByKey(fieldOptions, this.filterObj[_field])}
                            options={fieldOptions}
                            onChange={(e) => this.handleChange(e, _field)}
                            tabSelectsValue={true}
                            placeholder={"Choose a " + MESSAGES.ui_filter.col_names.field.toLowerCase()}
                            type={DROPDOWN_TYPE.INPUT}
                            // formatOptionLabel={optionLabel}
                            firstAttr={`#column-dropdown-${this.props.rowIndex}`}
                            secondAttr={`#filter_column_dropdown_${this.props.rowIndex}`}
                            disabled={this.state.isLoading && this.filterObj[_field] !== ""}
                        />
                    }
                </div>
                <div id={`function-dropdown-${this.props.rowIndex}`}
                     className={"max_width uk-margin-small-right-left uk-height-fit " + (this.state.isLoading ? "disabled" : "")}>
                     <Dropdown //to remove comment and add comment <CustomSelect
                        id={`filter_function_dropdown_${this.props.rowIndex}`}
                        className={"function-dropdown input__dropdown " + (this.filterObj[_function] ? "" : "input-warning")}
                        name="filterFunction"
                        value={findOptionByKey(functionOptions, this.filterObj[_function])}
                        options={functionOptions}
                        onChange={(e)=>this.handleChange(e, _function)}
                        tabSelectsValue={true}
                        placeholder={"Choose a " + MESSAGES.ui_filter.col_names.function.toLowerCase()}
                        menuIsOpen={this.state.functionMenuOpen}
                        type={DROPDOWN_TYPE.INPUT}
                        firstAttr={`#function-dropdown-${this.props.rowIndex}`}
                        secondAttr={`#filter_function_dropdown_${this.props.rowIndex}`}
                        disabled={this.state.isLoading }
                    />
                </div>
                    <div className={(this.props.isFromExclusionConfiguration ? "" : "width-350") + " uk-display-inline-flex uk-margin-small-right-left uk-height-fit"} style={this.props.isFromExclusionConfiguration ? {width: "130%"} : {}}>
                    {this.filterObj[_type] !== FILTER_TYPE_PROFITSTACK ?
                        <AsyncSelect
                            id={"entities_quadrants_" + this.state.entitiesRndId}
                            ref={(r) => {this.handleSelectRef(r)}}
                                className={(this.props.isFromExclusionConfiguration ? "max_width " : "width-350 ")  + (this.filterObj[_entities] ? "" : "input-warning") + ((this.state.isLoading && !this.state.inputValue) || optionsMissing ? " disabled" : "")}
                            classNamePrefix="multiSelectNoWrap"
                            formatOptionLabel={optionLabel}
                            name="filterEntities"
                            loadOptions={this.loadEntitiesOnInputChange}
                            onChange={(e) => this.handleChange(e, _entities)}
                            onMenuOpen={() => this.toggleEntitiesMenu(true)}
                            onFocus={() => this.toggleEntitiesMenu(this.state.menuIsOpen)}
                            tabSelectsValue={true}
                            isLoading={this.state.isLoading}
                            loadingMessage={this.loadingMessage}
                            noOptionsMessage={this.noOptionsMessage}
                            placeholder={[_empty, _nempty].indexOf(this.filterObj[_function]) > -1 ? "" : "Choose a " + MESSAGES.ui_filter.col_names.value.toLowerCase()} //no placeholder when the function chsen is empty or not empty
                            isMulti   //the 'isMulti' prop allows it to be multi-select
                            isSearchable
                            closeMenuOnSelect={false}
                            defaultOptions={entitiesOptions}
                            options={entitiesOptions}
                            value={[SEGMENTS_TITLES.PROFIT_SEGMENTS.value, SEGMENTS_TITLES.PROFIT_TIERS.value, SEGMENTS_TITLES.PROFIT_TIERS.variableName].includes(this.filterObj[_field]) ? copyObjectValues(this.filterObj[_entities]).map(function(item){item.label = new Segment().getSegmentObject(item.label)?.label; return item}) : this.filterObj[_entities]}
                            menuIsOpen={this.state.menuIsOpen}
                            isDisabled={(this.state.isLoading && !this.state.inputValue) || ([_empty, _nempty].indexOf(this.filterObj[_function]) > -1) || (optionsMissing) ? true : false}
                            styles={customStylesMultiSelect}
                            menuPlacement="auto"
                            maxMenuHeight={convertPxToViewport(190)}
                            // onKeyDown={this.handleAsyncBackspace}
                        />
                    :
                        (this.state.isInBetween || this.filterObj[_function] === _isBetween?
                            <div className = "uk-display-flex">
                                <Input className={"form-control width-170 " + (!isNaN(this.filterObj[_entities]) ? "" : "input-warning")}
                                        onChange={(e)=>this.handleInputChange(e)}
                                        onBlur={(e)=>{this.handleChange(e.currentTarget, FILTER.KEYS.MIN)}}
                                        defaultValue={this.filterObj[FILTER.KEYS.MIN]? this.filterObj[FILTER.KEYS.MIN]:0}/>
                                <span className="uk-margin-small-left uk-margin-small-top fs-14">{lang.ui_filter.dropdowns.and}</span>
                                <Input className={"form-control width-170 uk-margin-small-left" + (!isNaN(this.filterObj[_entities]) ? "" : "input-warning")}
                                        onChange={(e)=>this.handleInputChange(e)}
                                        onBlur={(e)=>{this.handleChange(e.currentTarget, FILTER.KEYS.MAX)}}
                                        defaultValue={this.filterObj[FILTER.KEYS.MAX]? this.filterObj[FILTER.KEYS.MAX] : 0}/>
                            </div>
                            :
                            <Input className={(this.props.isFromExclusionConfiguration ? "" : "width-350 ") + "form-control" + (!isNaN(this.filterObj[_entities]) ? "" : "input-warning")}
                                onChange={(e)=>this.handleInputChange(e)}
                                onBlur={(e)=>{this.handleChange(e.currentTarget, _entities)}}
                                defaultValue={this.filterObj[_entities]}
                                />
                        )
                    }
                    {""}
                </div>
                <div className={"uk-display-flex align-items-center uk-margin-default-right "+ (this.state.isInBetween || this.filterObj[_function] === _isBetween? "uk-margin-large-left" :"uk-margin-xsmall-left")}>
                    {/* <i className="fal fa-trash-alt fa-lg uk-button-icon transparent-bg uk-display-flex align-items-center" onClick={this.removeFilter}></i> */}
                    <Button 
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.ICON}
                        type={BUTTON_TYPE.DEFAULT}
                        className={"forget-filter-button-icon"}
                        leftIcon={<i className="fal fa-trash" />}
                        onBtnClick={this.removeFilter}
                    /> 
                </div>
            </div>
            </React.Fragment>
        }

      return (filterRow);
    }
}

  export default FilterDialog;