import React from "react";

const TableFromString = (props) => {
   //inputStringExample "| Customer Segmentation | Profit | Revenue |\n|:---|---:|---:|\n| TOP MANAGED PRIMARY | $14,601,913 | $180,799,487 |\n| TOP REV NOT MANAGED (T1) | $9,532,336 | $59,947,847 |";

  const parseTable = (tableString) => {
    const rows = tableString.split("\n"); 
    const headers = rows[0].split("|").filter((col) => col.trim() !== ""); 
    const dataRows = rows.slice(2); 

    const data = dataRows.map((row) =>
      row.split("|").filter((col) => col.trim() !== "").map((col) => col.trim()) 
    );

    return { headers, data };
  };

  const { headers, data } = parseTable(props.inputString);

  return (
    <table id="table" style={{ borderCollapse: "collapse", width: "100%", border: "1px solid black" }}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th
              key={index}
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td
                key={cellIndex}
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: cellIndex === 0 ? "left" : "right", 
                }}
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableFromString;