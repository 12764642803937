import { useState } from "react";
import { ReactComponent as ExpandArrow } from "../../styles/images/solid-arrow-up.svg";



export const RecentChats = ({recentChats, resumeChat})=>{
    const [expanded, setExpanded] = useState(false);
    
    return (
        <div className="recent-chats-header-container">
            <span className="recent-chats-text">Recent Chats</span> 
            <button className="recent-chats-header" onClick={()=>setExpanded(!expanded)} aria-expanded={expanded}>
                <ExpandArrow className={expanded ? "expand-arrow" : "collapse-arrow"} />
            </button>  
            {
                <div className={"recent-chat-container" + (expanded?  " expanded" : "")}>
                    {recentChats?.map((item, index)=>{
                        return <div className="chat-history" onClick={()=>resumeChat(index)}>{item.title}</div>
                    })}
                </div>      
            }    
        </div>
    );
}