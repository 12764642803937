import { AuthErrorCodes, signInWithEmailAndPassword } from "firebase/auth";
import React, { Component } from "react";
import Popup from "react-popup";
import { connect } from "react-redux";

import {
  checkIfSectionIdExists,
  formatAdvancedFilter,
  formatBasicFilter,
  getOutputScreenName,
  getQuadrantName,
  prepareFilter,
  setLocalStorageValueByParameter,
  toggleLoader,
  triggerExportData,
} from "../../class/common";
import {
  ALL_REPORTS,
  ALL_WIDGETS,
  API_URL,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  COLORS_PALETTE,
  COLUMN_PROFILE,
  CONFIG_SETTINGS,
  DROPDOWN_TYPE,
  FILTER,
  FILTER_FUNCTIONS,
  FY_VALUES,
  HEADER_ELEMENT,
  HEATMAP,
  IDENTIFIERS,
  MENU_ITEM,
  PSL_RETURN_NAMES,
  PSS,
  ROLLING_SEGMENTS,
  SEGMENTS_TITLES,
  SIZES,
  UI_ACTIONS,
  VECTOR_STAGING_ATTRIBUTES,
  VECTOR_TABS_OPTIONS,
  VECTOR_SEGMENTS_TABS_OPTIONS,
  session_storage_keys, BUTTON_DROPDOWN_VARIANT,
  MENU_ITEM_URLS,
  SAVE_COMPANY_FILTERS,
} from "../../class/constants";
import {
  extractFromFullQuarter,
  generatePeriods,
  getFullQuarterFromStartEndQuarters,
  getGeneratedQuarterRange,
  getLastBuiltPeriodForSegments,
  getPeriodQuarter,
  getQuarterFromDate,
  monthDiff,
} from "../../class/date";
import { convertPxToViewport, convertViewportToPx } from "../../class/formatting";
import {
  getLimit,
  getPSTier,
  hideProfitStackLink,
  replaceSpecialCharacters,
  setDrillRowsLimit,
  setLimit,
  showProfitStackLink,
} from "../../class/jqueries";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils";
import { getItemFromStore } from "../../class/reduxStoreUtils";
import {
  copyObjectValues,
  deepCompareObjects,
  findOptionByKey,
  getSectionExists,
  getTranslationFile,
  isValidDate, numberToWords,
  parseBoolean,
  removeDuplicateObject,
  tryParse,
} from "../../class/utils";
import ConfirmPasswordModal from "../../components/ConfirmPasswordModal";
import ManageColumns from "../../components/manageColumns/ManageColumns";
import ScrollList from "../../components/ScrollList";
import { Segment } from "../../components/Segment";
import { auth } from "../../firebase/firebase";
import Button from "../../newComponents/Button";
import Dropdown from "../../newComponents/DropDown";
import { getValidPeriods } from "../../templateLayout/functions/periodFunctions";
import { updateHeatmapListData, updateHeatmapObjBeforeBack } from "../../actions/heatMapActions";
import { lang } from "../../language/messages_en";
import FilterDialog from "../filter/FilterDialog";
import { getNewEntityFilter } from "../filter/FilterHelperFunctions";
import { getVectorAllowedAttributes } from "../manageAccess/ManageAccessUtils";
import ProfitMapList from "../ProfitMapList";
import HeatMapTable from "./HeatMapTable";
import ButtonDropdown from "../../newComponents/ButtonDropdown";
import DrillButtonDropdown from "../../components/drill/DrillButtonDropdown";
import { atLeastOneVectorHasNAInData } from "../../templateLayout/functions/componentFunctions";

const defaultPath = API_URL.PROFIT_LANDSCAPE;

const USER_ACTIVITY_USER_NOT_AUTHENTICATED = "User was not authenticated - client side.";
const $ = require("jquery");
const MESSAGES = getTranslationFile();

const LIST = ALL_REPORTS.LIST_TOP;
const HEATMAP_SCREEN = ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.HEATMAP;

const ENTITY = "Entity";
var isPlaying = false;
var rowsTotal = [];
var colsTotal = [];
var firstDimOrder = [];
var secondDimOrder = [];

const _entityGroups = MESSAGES.heatmap_configure.view_groups;
const _groupedEntities = MESSAGES.heatmap_configure.entities.grouped_entities;
let profitStackSelectedEntities = [];

let initialHeatMapConfObj = {
  selectedVectorEntity: VECTOR_TABS_OPTIONS[0].value,
  selectedVectorSegment: VECTOR_SEGMENTS_TABS_OPTIONS[1].value,
  threshold: "0",
  stack: 0,
};
class Heatmap extends Component {
  constructor(props) {
    super(props);
    let initialEntitiesObject = {
      selectedColumnEntities: [],
      selectedRowEntities: [],
      groupByColumn: false,
      groupByRow: false,
    };
    this.initialState = {
      dimension_0:
        this.props.history.location?.state?.vectors && this.props.history.location?.state?.vectors[0]
          ? this.props.history.location.state.vectors[0]
          : this.props.vectorState?.nextVectors[0],
      dimension_1:
        this.props.history.location.state?.vectors && this.props.history.location.state?.vectors[1]
          ? this.props.history.location.state.vectors[1]
          : this.props.vectorState?.nextVectors[1],
      fromBack: this.props.history.location.state && this.props.history.location.state.isRedirectionFromStacks,
      // showPrint: true,
      // displayFilter: true,
      // profitFormat: this.props.report,
      filterFinal: props?.filterFinal,
      filterFinalBasic: props?.filterFinalBasic,
      vectorObjects: props.vectorState?.vectorObjects,
      vectorOptions: props.vectorState?.vectorOptions,
      showList: false,
      hide: " uk-hidden",
      order: "DESC",
      showMainComponent: true,
      displayTraditionalProfitStack: false,
      shouldFetchData: true,
      dimensionListFilter: this.props.history.location.state ? this.props.history.location.state.filter : this.state?.dimensionListFilter,
      FY: FY_VALUES.M3,
      filterDisplayEditable: false,
      threshold: 0,
      isDrilling: false,
      psFilterDisabled: false,
      useFilterCookies: true,
      hasDefaultHeaderValues: true,
      selectedPeriod: this.props.history.location.state ? this.props.history.location.state.dataset : this.state?.selectedPeriod,
      ps_default_line: tryParse(sessionStorage.getItem(session_storage_keys.heatmap_ps_line)) || undefined,
      rowSelectedEntities: [],
      columnSelectedEntities: [],
      showHeatmapConfigureDialog: false,
      tempHeatmapConfObj: initialHeatMapConfObj,
      heatmapConfObj: initialHeatMapConfObj,
      colColumns: [],
      rowColumns: [],
      columnEntities: [],
      rowEntities: [],
      limitEntities: 0,
      tempSelectedEntitiesObj: initialEntitiesObject,
      selectedEntitiesObj: undefined,
      showRowEntitiesDropDown: false,
      showColumnEntitiesDropDown: false,
      isEntityConfChanged: false,
      fromReset: false,
      revenueCostKey: undefined,
      isListFullScreen: false,
      selectedEntities:0
    };
    this.state = Object.assign({}, this.state, this.initialState);
    this.configDropdownExpandingRef = React.createRef();
    this.getHeatmapData = this.getHeatmapData.bind(this);
    this.onChangeSelectedPeriod = this.onChangeSelectedPeriod.bind(this);
    this.hideComponent = this.hideComponent.bind(this);
    this.showComponent = this.showComponent.bind(this);
    this.toggleLoader = toggleLoader.bind(this);
    this.onChangeDimensionFilter = this.onChangeDimensionFilter.bind(this);
    this.setDimensionFilter = this.setDimensionFilter.bind(this);
    this.onTooltipBtnClick = this.onTooltipBtnClick.bind(this);
    this.setClickedCell = this.setClickedCell.bind(this);
    this.setThreshold = this.setThreshold.bind(this);
    this.setTableColumns = this.setTableColumns.bind(this);
    this.runProfitStack = this.runProfitStack.bind(this);
    this.getPeriodsHtml = this.getPeriodsHtml.bind(this);
    this.setSliderLimit = this.setSliderLimit.bind(this);
    this.changeData = this.changeData.bind(this);
    this.updateDataOrder = this.updateDataOrder.bind(this);
    this.getProfitStackLines = this.getProfitStackLines.bind(this);
    this.showEntitiesOptions = this.showEntitiesOptions.bind(this);
    this.hideEntitiesOptions = this.hideEntitiesOptions.bind(this);
    this.applyChanges = this.applyChanges.bind(this);
    this.loadDimensionEntities = this.loadDimensionEntities.bind(this);
    this.onToggleManageColsBoard = this.onToggleManageColsBoard.bind(this);
    this.saveChosenEntities = this.saveChosenEntities.bind(this);
    this.approveBulkChange = this.approveBulkChange.bind(this)
    this.updateManageColumnsProfile = this.updateManageColumnsProfile.bind(this)
    this.onSelectDefaultProfile = this.onSelectDefaultProfile.bind(this)
    this.onChangeProfileData = this.onChangeProfileData.bind(this)
    

    this.pageComponent = React.createRef();
    this.lastEntitiesChanged = null;
    // this.isMainReport = {isSecondDimension: true};
    this.prevState = this.state; //object to hold the value of previous props, updated in didUpdate()
    // this.configDropdown = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    // if we change scenario, reset configureObj to initale
    if (this.props?.scenarioState?.scenarioObjects[0] !== prevProps?.scenarioState?.scenarioObjects[0]) {
      this.setState({
        tempHeatmapConfObj: initialHeatMapConfObj,
        heatmapConfObj: initialHeatMapConfObj,
      });
    }

    if (this.props.filterFinal !== prevProps.filterFinal) {
      this.setState({
        filterFinal: this.props.filterFinal,
      });
    }

    if (this.props.filterFinalBasic !== prevProps.filterFinalBasic) {
      this.setState({
        filterFinalBasic: this.props.filterFinalBasic,
        selectedEntities: 0
      });
    }

    if (JSON.stringify(this.props.vectorState.nextVectors) !== JSON.stringify(prevProps.vectorState.nextVectors)) {
      this.setState({
        dimension_0: this.props.vectorState.nextVectors[0],
        dimension_1: this.props.vectorState.nextVectors[1],
      });
    }
 
    if(this.props.isDrilling !== prevProps.isDrilling) {
      this.setState({
        isDrilling: this.props.isDrilling,
        selectedEntities: 0
      });

      // this is used to indicate that the list is in drill mode. It is used to exit drill if we change scope
      let state = this.props.history?.location?.state || {};
      state.isListDrilling = this.props.isDrilling;
      this.props.history.push({
        state: state
      });
    }

    if (this.state.isDrilling) {
      if (this.state.selectedEntities > 0) {
        this.setState({
          selectedEntities: 0
        })
      }
    }

  }

  componentDidMount() {
    let _this = this;

    if (!this.props.history?.location?.state?.isRedirectionFromStacks) {
      // clear store when comming from menu and not from back in entity stacks
      _this.props.dispatch(updateHeatmapObjBeforeBack([], "heatmapObjBeforeBack", true));
      _this.props.dispatch(updateHeatmapListData([], "heatmapListData", true));
    } else {
      this.props.setShowGreyOverLay(false);
    }

    this.setState({
      readFromStore: _this.props.history?.location?.state?.isRedirectionFromStacks
    },()=>{
      _this.go(HEATMAP_SCREEN, _this.state?.fromBack || false);
      
      let state = _this.props.history?.location?.state || {};
      state.isRedirectionFromStacks = false;
      _this.props.history.push({
        state: state
      });
    })
    
    _this.closeConfigureDropdown();
    // $(".detailsTooltip").remove();
    // $(".totalsTooltip").remove();
  }

  closeConfigureDropdown = () => {
    let _this = this;
    $(document).on("mouseover click", function (event) {
      if ($("#heatmap_configure_dialog:hover").length === 0 && $("#heatmap-configure-btn:hover").length === 0) {
        if (event.type === "click") {
          _this.setState({
            showHeatmapConfigureDialog: false,
          });
        }
      }
    });
  };

  onChangeSelectedPeriod(value) {
    const _this = this;
    // if (value !== -1) {
    // var period = this.props.periodsStatusState?.periods.filter(e => e.index === value.toString())[0].value;

    var data = _this.state.alldata.data.data;
    this.updateDataOrder(data);

    let tempState = {
      // selectedPeriod: period,
      selectedIndex: Number(value),
      heatMapData: data,
      contourMapData: data,
      stateChanged: true,
    };

    _this.setState(tempState, function () {
      $(".totalsTooltip").hide();
      $(".detailsTooltip").hide();
      $("[id$='_period']").css("font-weight", "normal");
    });
  }

  onApplyManageColumnsProfileChanges(profile) {
    var _this = this;
    profitStackSelectedEntities = [];
    hideProfitStackLink();
    let pageComponent = _this.pageComponent;
    _this.pageComponent.setState(
      {
        sorter: undefined,
        order: "desc",
      },
      () => {
        let obj = _this.pageComponent ? _this.pageComponent : pageComponent;
        obj.tabulatorList.current.tabulator.setPageSize(100);
      }
    );
    profitStackSelectedEntities = [];
    // });
  }

  onToggleManageColsBoard() {
    this.props.manageColsOverlay?.current?.classList.toggle("uk-hidden");
  }

  onToggleManageColsDrillBoard = () => {
    this.props.manageColsDrillOverlay?.current?.classList.toggle("uk-hidden");
  }

  applySelectedEntities = (isRow) => {
    let _this = this;
    let selectedEntitiesObj = _this.state.selectedEntitiesObj;
    if (!selectedEntitiesObj) {
      selectedEntitiesObj = {};
    }
    if (isRow) {
      selectedEntitiesObj.selectedRowEntities = _this.state.tempSelectedEntitiesObj.selectedRowEntities;
      selectedEntitiesObj.groupByRow = _this.state.tempSelectedEntitiesObj.groupByRow;
    } else {
      selectedEntitiesObj.selectedColumnEntities = _this.state.tempSelectedEntitiesObj.selectedColumnEntities;
      selectedEntitiesObj.groupByColumn = _this.state.tempSelectedEntitiesObj.groupByColumn;
    }
    _this.setstate({
      selectedEntitiesObj: selectedEntitiesObj,
    });
  };

  getDefaultColumnEntities = (tempObject, isReadOnly) => {
    let _this = this;
    let counter;
    let columnEntities;
    let rowEntities;
    tempObject.selectedColumnEntities = [];
    counter =
      _this.state.columnEntities && this.state.columnEntities.length < _this.state.limitEntities
        ? _this.state.columnEntities.length
        : Number(_this.state.limitEntities) - 1;
    columnEntities = _this.state.columnEntities;

    for (let i = 0; i < counter; i++) {
      if (_this.state.columnEntities) {
        if (!isReadOnly) {
          columnEntities[i].check = true;
        }
        tempObject.selectedColumnEntities.push(columnEntities[i].entities);
        tempObject.groupByColumn = _this.state.columnEntities.length >= _this.state.limitEntities;
      }
    }
    if (columnEntities && columnEntities.length <= _this.state.limitEntities - 1) {
      if (tempObject.selectedColumnEntities.length < columnEntities.length) {
        tempObject.groupByColumn = true;
        tempObject.toggleColumn = false;
      } else {
        tempObject.groupByColumn = false;
        tempObject.toggleColumn = true;
        tempObject.tooltipMessageColumn = lang.no_entities_to_group;
      }
    } else {
      if (tempObject.selectedColumnEntities.length > _this.state.limitEntities - 1) {
        tempObject.groupByColumn = false;
        tempObject.toggleColumn = true;
        tempObject.tooltipMessageColumn = lang.entity_limit_reached;
      } else {
        tempObject.groupByColumn = true;
        tempObject.toggleColumn = false;
      }
    }
  };
  getDefaultRowEntities = (tempObject, isReadOnly) => {
    let _this = this;
    tempObject.selectedRowEntities = [];
    let counter;
    let columnEntities;
    let rowEntities;
    counter =
      _this.state.rowEntities && _this.state.rowEntities.length < _this.state.limitEntities
        ? _this.state.rowEntities.length
        : Number(_this.state.limitEntities) - 1;
    rowEntities = _this.state.rowEntities;
    for (let j = 0; j < counter; j++) {
      if (rowEntities) {
        if (!isReadOnly) {
          rowEntities[j].check = true;
        }
        tempObject.selectedRowEntities.push(rowEntities[j].entities);
        tempObject.groupByRow = _this.state.rowEntities.length >= _this.state.limitEntities;
      }
    }
    if (rowEntities && rowEntities.length <= _this.state.limitEntities - 1) {
      if (tempObject.selectedRowEntities.length < rowEntities.length) {
        tempObject.groupByRow = true;
        tempObject.toggleRow = false;
      } else {
        tempObject.groupByRow = false;
        tempObject.toggleRow = true;
        tempObject.tooltipMessageRow = lang.no_entities_to_group;
      }
    } else {
      if (tempObject.selectedRowEntities.length > _this.state.limitEntities - 1) {
        tempObject.groupByRow = false;
        tempObject.toggleRow = true;
        tempObject.tooltipMessageRow = lang.entity_limit_reached;
      } else {
        tempObject.groupByRow = true;
        tempObject.toggleRow = false;
      }
    }
  };

  //add documentation
  getDefaultEntitiesTempObject = (tempState, fromReset, isRow, isReadOnly) => {
    let _this = this;
    let tempObject = copyObjectValues(_this.state.tempSelectedEntitiesObj);
    if (!fromReset) {
      _this.getDefaultColumnEntities(tempObject, isReadOnly);
      _this.getDefaultRowEntities(tempObject, isReadOnly);
      tempObject.isRowApplyDisabled = false;
      tempObject.isColumnApplyDisabled = false;
      tempObject.isChangedRow = false;
      tempObject.isChangedColumn = false;
    } else {
      if (isRow) {
        _this.getDefaultRowEntities(tempObject, isReadOnly);
        tempObject.isChangedRow = false;
        tempObject.isRowApplyDisabled = false;
      } else {
        _this.getDefaultColumnEntities(tempObject, isReadOnly);
        tempObject.isChangedColumn = false;
        tempObject.isColumnApplyDisabled = false;
      }
    }
    tempState.columnEntities = _this.state.columnEntities;
    tempState.rowEntities = _this.state.rowEntities;
    return tempObject;
  };

  //add documentation
  getSelectedEntitiesObject = (tempState, isRow) => {
    let _this = this;
    let defaultObj = this.getDefaultEntitiesTempObject({}, undefined, undefined, true);
    let object = copyObjectValues(_this.state.selectedEntitiesObj);
    let columnEntities = _this.state.columnEntities;
    let counter = columnEntities ? columnEntities.length : 0;
    for (let i = 0; i < counter; i++) {
      if (columnEntities && object.selectedColumnEntities.includes(columnEntities[i].entities)) {
        columnEntities[i].check = true;
      }
    }
    let rowEntities = _this.state.rowEntities;
    counter = rowEntities ? rowEntities.length : 0;
    for (let j = 0; j < counter; j++) {
      if (rowEntities && object.selectedRowEntities.includes(rowEntities[j].entities)) {
        rowEntities[j].check = true;
      }
    }
    if (JSON.stringify(_this.state.selectedEntitiesObj.selectedRowEntities) === JSON.stringify(defaultObj.selectedRowEntities)) {
      object.isChangedRow = object.isChangedRow;
    } else {
      object.isChangedRow = true;
    }
    if (JSON.stringify(_this.state.selectedEntitiesObj.selectedColumnEntities) === JSON.stringify(defaultObj.selectedColumnEntities)) {
      object.isChangedColumn = object.isChangedColumn;
    } else {
      object.isChangedColumn = true;
    }
    tempState.columnEntities = columnEntities;
    tempState.rowEntities = rowEntities;
    return object;
  };

  /**
   * function generates list of periods based on the profit stack type
   * selected along with the periods of the last year if yoy option is selected
   */
  generatePeriods() {
    const _this = this;
    let customStartDate = getValidPeriods(_this.props.periodsStatusState, _this.props.clientPeriodsState).startDate;
    let customEndDate = getValidPeriods(_this.props.periodsStatusState, _this.props.clientPeriodsState).endDate;
    let periodsCount = monthDiff(customStartDate, customEndDate) + 1;
    let periods = generatePeriods(customStartDate, periodsCount);
    return periods.join("','");
  }

  getHeatmapData(defaultLine, axisViews, selectedEntities = {}, fromGo, isRow) {
    const _this = this;
    if (!this.state.dimension_0 || !this.state.dimension_1 || !this.props.scenarioState?.scenario) {
      this.emptyAllData();
      return;
    }
    // close selected list when we fetch new heatmap data
    _this.closeList();

    let tempHistState = this.props.history?.state;
    tempHistState = {
      dimension_0: this.state.dimension_0,
      dimension_1: this.state.dimension_1,
      FY: this.props.userSettingsState.FY,
      scenarioState: this.props.history?.location?.state?.scenarioState,
    };
    this.props.history.push({
      pathname: window.location.pathname,
      search: "?",
      hash: "",
      state: tempHistState,
    });

    let viewX =
      this.state?.vectorOptions.filter((e) => e.value === this.state.dimension_0)[0].generated_quarters === "ALL_NOT_GENERATED"
        ? _entityGroups.entity.value
        : axisViews
        ? axisViews.rowView
        : sessionStorage.getItem(session_storage_keys.heatmap_row_view) || _entityGroups.default.value;
    let viewY =
      this.state?.vectorOptions.filter((e) => e.value === this.state.dimension_1)[0].generated_quarters === "ALL_NOT_GENERATED"
        ? _entityGroups.entity.value
        : axisViews
        ? axisViews.columnView
        : sessionStorage.getItem(session_storage_keys.heatmap_column_view) || _entityGroups.default.value;

    let entitiesConfiguration = fromGo ? _this.state.initialEntitiesObject : _this.state.selectedEntitiesObj;
    let periods = this.generatePeriods();
    let periodsObject = this.getPeriodsObjectMonth();
    let months = periodsObject.months;
    // let entitiesToCompare = _this.state.selectedEntitiesObj;
    let entitiesChangedToCompare = _this.state.tempSelectedEntitiesObj;
    if (this.lastEntitiesChanged) {
      delete this.lastEntitiesChanged.id;
    }
    if (entitiesChangedToCompare) {
      delete entitiesChangedToCompare.id;
    }
    if (!fromGo && (JSON.stringify(this.lastEntitiesChanged) === JSON.stringify(entitiesChangedToCompare) || !this.state.isEntityConfChanged)) {
      return;
    }
    var query = {
      action: "getHeatmapData",
      firstDimension: _this.state.dimension_0,
      secondDimension: _this.state.dimension_1,
      filter: "{'filter':" + encodeURIComponent(prepareFilter(_this.state.filterFinal, false, _this.state.psFilterDisabled)) + "}",
      FY: months,
      dataTier: _this.state.listDimension,
      scenario_id: _this.props.scenarioState?.scenario,
      quarter: periodsObject.quarter,
      defaultLine: defaultLine[PSS.DUPLICATE_KEYS.NAME_IN_FACT],
      rowView: viewX,
      periods: periods,
      columnView: viewY,
      entitiesConfiguration: entitiesConfiguration,
      heatmapConfObj: _this.state.heatmapConfObj,
      rollingPeriod: periodsObject.segmentPeriod,
      rollingSegment: ROLLING_SEGMENTS.R_12,
    };

    this.lastEntitiesChanged = fromGo ? null : copyObjectValues(_this.state.selectedEntitiesObj);

    _this.sentRequests = _this.sentRequests || 0;
    _this.sentRequests += 1;
    var onThenCallback = (data) => {
      this.setState({
        colColumns: data.colColumns,
        rowColumns: data.rowColumns,
        columnEntities: data.entities.columnEntities,
        rowEntities: data.entities.rowEntities,
        limitEntities: data.queryLimitDim,
        tempHeatmapConfObj: _this.state.heatmapConfObj,
        isEntityConfChanged: false,
      });
      _this.sentRequests -= 1;
      let hasInvalidAccess = _this.props.checkForLimitAccessMessage(data, _this.sentRequests === 0);
      if (hasInvalidAccess) {
        _this.emptyAllData();
        return;
      }
      let tempState = {};

      if (data.colorCodes) {
        // colors of the cell in the heatmap
        tempState.colorCodes = data.colorCodes;
      }
      tempState.fromReset = false;
      tempState.tempSelectedEntitiesObj =
        _this.state.selectedEntitiesObj && !fromGo
          ? _this.getSelectedEntitiesObject(tempState, isRow)
          : _this.getDefaultEntitiesTempObject(tempState);
      tempState.tempSelectedEntitiesObj.id = _this.state.selectedEntitiesObj ? _this.state.selectedEntitiesObj.id + 1 : 0; // to force rendering the entitieNewTabulator component
      tempState.selectedEntitiesObj = copyObjectValues(tempState.tempSelectedEntitiesObj);
      if (Object.keys(selectedEntities).length > 0) {
        tempState.rowSelectedEntities = selectedEntities.row || _this.state.rowSelectedEntities; //setting the selected values received from the popup in a state
        tempState.columnSelectedEntities = selectedEntities.column || _this.state.columnSelectedEntities;
      }
      if (!deepCompareObjects(defaultLine, _this.state.ps_default_line)) {
        tempState.dim1DefaultEntities = []; //resetting default when changing ps line from configure
        tempState.dim2DefaultEntities = [];
      }
      if (viewX !== _this.state.dim1Type && viewX !== _entityGroups.default.value) {
        tempState.dim1DefaultEntities = []; //resetting default when entity view changes from configure
      }
      if (viewY !== _this.state.dim2Type && viewY !== _entityGroups.default.value) {
        tempState.dim2DefaultEntities = [];
      }
      if (data) {
        _this.generateData(data, defaultLine, tempState);
      }
    };

    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getHeatmapData",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: defaultPath,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.email]: this.props.userSettingsState?.user.email,
      [FETCHAPI_PARAMS.machine_name]: this.props.userSettingsState.machine_name,
      [FETCHAPI_PARAMS.profitFormat]: this.props.profitFormat,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.heatmap.screen_name,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.heatmap.requests_description.apply,
      [FETCHAPI_PARAMS.vector]: "First Dimension Vector: "+ _this.state.dimension_0 + ", Second Dimension Vector: " + _this.state.dimension_1,
      [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
      [FETCHAPI_PARAMS.periods]: "'" + periods + "'",
    };
    //if there is a tracking obj in the session, action is two_dimension_heatmap, else generate_heatmap
    fetchAPI(fetchOptions);
  }

  /**
   * this function is used to fetch vector entities when the user is typing in
   * the search box of the ScrollList component. It uses the same API as the filter
   * for fetching entities since the required functionality is the same
   * @param {*} inputValue
   * @param {*} callback
   */
  loadDimensionEntities(inputValue, callback) {
    let _nameField = MESSAGES.ui_filter.dropdowns.fields_attributes.name.value;
    let query =
      "action=getTierFilter" +
      "&tier=" +
      this.state.chosenEntitiesDimension +
      "&scenario_id=" +
      this.props.scenarioState?.scenario +
      "&filter_value=" +
      encodeURIComponent(inputValue.toUpperCase()) +
      "&filter_attribute=" +
      _nameField + //name field is used because this is the field used in the getHeatmapData query
      "&is_default=" +
      false;

    var onThenCallback = (data) => {
      if (data?.tierFilters && typeof callback === "function") {
        if (!Array.isArray(data?.tierFilters)) {
          data = [];
        } else {
          data = data?.tierFilters?.map((item) => {
            return { label: item[_nameField], value: item[_nameField] };
          });
        }
        callback(data);
      }
    };

    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "loadDimensionEntities",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.GET,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.email]: this.props.userSettingsState?.user.email,
      [FETCHAPI_PARAMS.machine_name]: this.props.userSettingsState.machine_name,
      [FETCHAPI_PARAMS.profitFormat]: this.props.profitFormat,
    };
    fetchAPI(fetchOptions);
  }

  getListData() {
    var obj = this;
    obj.setDimensionFilter(obj.state.listFilter, function () {
      if (obj.state.isComponentRendered) {
        //if (obj.pageComponent) {
        obj.pageComponent?.tabulatorList?.current?.tabulator?.setPageSize(100);
        // obj.pageComponent?.fetchProfitInfo();   //if the list is alrady mounted, call its fetchProfitInfo
      } else {
        obj.showComponent(LIST); //it mounts the list component which automatically fetches the data on mount
        // obj.pageComponent?.fetchProfitInfo();
        obj.pageComponent?.tabulatorList?.current?.tabulator?.setPageSize(100); // setSize will trigger ajaxRequestFunc, which will call fetchProfitInfo()
      }
    });
      if (obj.state.selectedEntities > 0) {
          obj.setState({
              selectedEntities: 0
          })
      }
  }

  getProfitStackLines(fetchHMData = true, stackId = this.state.nextProfitStackViewId, isFromStackChange = false) {
    const _this = this;
    if (!this.state.dimension_0 || !this.state.dimension_1 || !this.props.scenarioState?.scenario) {
      this.emptyAllData();
      return;
    }

    var query = {
      action: "getCostHierarchyByStack",
      scenario_id: this.props.scenarioState?.scenario,
      view_id: stackId,
    };

    _this.sentRequests = _this.sentRequests || 0;
    _this.sentRequests += 1;
    var onThenCallback = (data) => {
      _this.sentRequests -= 1;
      let hasInvalidAccess = _this.props.checkForLimitAccessMessage(data, _this.sentRequests === 0);
      if (hasInvalidAccess) {
        _this.emptyAllData();
        return;
      }
      if (data.firstLevelPsl) {
        let isScenarioChanged = false;
        let isDefaultStack = stackId === 0;
        let defaultRevenue = isDefaultStack
          ? data.firstLevelPsl.find((f) => f.returnname === PSL_RETURN_NAMES.NET_REVENUE)
          : _this.state.defaultRevenue;
        let revenueCostKey = isDefaultStack ? defaultRevenue.id : undefined;
        let pslQuadrant = data.pslQuadrant;
        let psLines = _this.formatPslLines(data.firstLevelPsl, isDefaultStack ? pslQuadrant : undefined); // if isNotDefaultStack send pslQuadrant as undefined so that psls used for quadrant generation wont be displayed in bold
        let psl = data.defaultPSL;
        psl = { label: psl[PSS.NAME], value: psl.returnName, path: psl.path, format: psl.format, nameInFact: psl.nameInFact, costtype: psl.costType };
        let defaultPsl = isDefaultStack ? psl : psLines[0];

        let machine_value = psl;
        let confObj = _this.state.tempHeatmapConfObj;
        if (_this.props.scenarioState?.scenarioObjects[0] !== confObj.scenario) {
          confObj.scenario = _this.props.scenarioState?.scenarioObjects[0];
          isScenarioChanged = true;
        }

        defaultPsl =
          confObj[HEATMAP.CONFIGURE_OBJECT.selected_psl] && !isFromStackChange && !isScenarioChanged
            ? confObj[HEATMAP.CONFIGURE_OBJECT.selected_psl]
            : defaultPsl;
        _this.checkSelectedPsl(psLines, defaultPsl);
        confObj[HEATMAP.CONFIGURE_OBJECT.stack] = stackId;
        confObj[HEATMAP.CONFIGURE_OBJECT.selected_psl] = defaultPsl;

        this.props.setProfitStackLines(psLines);
        _this.setState(
          {
            tempHeatmapConfObj: confObj,
            profitStackLines: psLines,
            ps_default_line: defaultPsl,
            machine_value: machine_value,
            viewOptions: Object.values(_entityGroups),
            firstDimMaxEntities: data.maxEntitiesCount,
            secondDimMaxEntities: data.maxEntitiesCount,
            revenueCostKey: revenueCostKey,
            fromBack: false,
            defaultRevenue: defaultRevenue,
          },
          function () {
            if (fetchHMData) {
              // dont fetch until pressing on go
              _this.getHeatmapData(defaultPsl, undefined, undefined, true);
            }
          }
        );
      }
    };

    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getCostHierarchyByStack",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: defaultPath,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.email]: this.props.userSettingsState?.user.email,
      [FETCHAPI_PARAMS.machine_name]: this.props.userSettingsState.machine_name,
      [FETCHAPI_PARAMS.profitFormat]: this.props.profitFormat,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.get_cost_hierarchy,
      [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href),
    };
    //if there is a tracking obj in the session, action is two_dimension_heatmap, else generate_heatmap
    fetchAPI(fetchOptions);
  }

  formatPslLines = (pslLines, pslQuadrants) => {
    let _this = this;
    let className = "heatmap-configure";
    return pslLines.map((psl) => {
      className = pslQuadrants?.includes(psl[PSS.RETURN_NAME]) ? "psl-bold-text heatmap-configure " : "heatmap-configure ";
      if (psl.children) {
        let pslObj = _this.formatPslObject(psl, className);
        pslObj.children = _this.formatPslLines(psl.children, pslQuadrants);
        return pslObj;
      } else {
        return _this.formatPslObject(psl, className);
      }
    });
  };

  formatPslObject = (psl, className) => {
    return {
      label: psl[PSS.NAME],
      value: psl[PSS.RETURN_NAME],
      path: psl.path,
      format: psl.format,
      nameInFact: psl.name_in_fact,
      costtype: psl.costtype,
      className: className,
    };
  };

  /**
   * loops over the lines and their children to find the matching node and updates it
   * @param {} arr
   * @param {*} node
   * @returns
   */
  checkSelectedPsl = (arr, node) => {
    arr.forEach((row) => {
      if (row.value === node.value || !node) {
        row.checked = true;
        return;
      }
      if (!row.children) {
        return;
      }
      this.checkSelectedPsl(row.children, node);
    });

    return arr;
  };

  runProfitStack(customViewId, fromTooltipButton = false, dropdownName) {
    var obj = this;
    this.setState({
      custom_stack_id: customViewId,
      nextProfitStackViewId: customViewId,
      profitStackViewId: customViewId,
    });
    profitStackSelectedEntities = fromTooltipButton ? [] : profitStackSelectedEntities;
    this.setDimensionFilter(fromTooltipButton ? this.state.clickedCellFilter : this.state.listFilter, function () {
      obj.runExtendedProfitStackFunc(fromTooltipButton, dropdownName);
      $(".totalsTooltip").hide();
      $(".detailsTooltip").hide();
    });
  }

  // @override
  go(element, fetchHMData) {
    let _this = this;
    profitStackSelectedEntities = [];
    switch (element) {
      case LIST:
        this.getListData();
        break;
      case HEATMAP_SCREEN:
      default:
        let dataFromStore = _this.state.readFromStore ? getItemFromStore("heatmapObjBeforeBack", this, "heatmapObjBeforeBack") : undefined;
        if (dataFromStore) {
          let tempState = _this.getHeatMapBeforeBackObj(dataFromStore);
          secondDimOrder = dataFromStore.heatmapData.secondDimOrder;
          firstDimOrder = dataFromStore.heatmapData.firstDimOrder;
          colsTotal = dataFromStore.heatmapData.colsTotal;
          rowsTotal = dataFromStore.heatmapData.rowsTotal;
          // let hasBanner = $(".banner-component").height();
          // let hasFilter = $(".border-third-header").height() !== undefined;
          // let halfScreenClassName = hasBanner && hasFilter ? "half-screen-list-with-filter-banner" : (hasFilter ? "half-screen-list-with-filter" : hasBanner ? "half-screen-list-with-banner" : "half-screen-list");

          this.props.setProfitStackLines(tempState.profitStackLines);
          this.props.setIsListFullScreen(tempState.isListFullScreen);
          this.setState(tempState, () => {
            if (tempState.showList) {
              this.go(LIST);
              $(".heatMap-container").addClass("half-screen-heatmap");
              $("#heatMap-list").addClass("half-screen-list-container");
              $("#heatMap-list #table-list").addClass("half-screen-list");
              this.maximizeMinimizeTable(tempState.isListFullScreen);
            }
            this.props.dispatch(updateHeatmapObjBeforeBack([], "heatmapObjBeforeBack", true)); // remove heatmapBeforeBackObj from store after redirection from entity stacks to heatmap
          });
        } else {
          let isFetchingData = !this.props.showGreyOverLay;
          let confObj = isFetchingData ? _this.state.tempHeatmapConfObj : _this.state.heatmapConfObj;
          _this.setState(
            {
              tempHeatmapConfObj: confObj, // set heatmapConfObj to the applied heatmapConfObj
              showHeatmapConfigureDialog: false, // close configure dropdown on go
              showMainComponent: true,
              rowSelectedEntities: [], //resetting the selected entities when clicking on Go
              columnSelectedEntities: [],
              dim1DefaultEntities: [], //resetting default when clicking on GO
              dim2DefaultEntities: [],
            },
            () => _this.getProfitStackLines(fetchHMData, confObj[HEATMAP.CONFIGURE_OBJECT.stack])
          );
        }

        break;
    }
  }

  /** set check to true if entity is selected */
  checkSelectedEntities = (entities, selectedEntities) => {
    const updatedEntities = entities?.map((entity) => ({
      ...entity,
      check: selectedEntities?.includes(entity.entities),
    }));

    return updatedEntities;
  };

  hideComponent(comp) {
    var tempState = {};
    switch (comp) {
      case HEATMAP_SCREEN:
        // case CONTOURMAP:
        tempState.showMainComponent = false;
        break;
      case LIST:
        tempState.showList = false;
        tempState.hide = " uk-hidden";
        break;
    }

    this.setState(tempState);
  }

  showComponent(comp) {
    var tempState = {};
    switch (comp) {
      case HEATMAP_SCREEN:
        // case CONTOURMAP:
        tempState.showMainComponent = true;
        break;
      case LIST:
        tempState.showList = true;
        tempState.hide = "";
        break;
    }
    tempState.isComponentRendered = true;
    this.setState(tempState);
  }

  getFilterAttribute(attr, vectorObj) {
    var returnAttribute = "";
    if (replaceSpecialCharacters(attr).toLowerCase() === replaceSpecialCharacters(FILTER.VALUES.FIELD.QUADRANT).toLowerCase()) {
      returnAttribute = FILTER.VALUES.FIELD.QUADRANT;
    } else if (replaceSpecialCharacters(attr).toLowerCase() === replaceSpecialCharacters(FILTER.VALUES.FIELD.QUADRANT_TIER, "_").toLowerCase()) {
      returnAttribute = FILTER.VALUES.FIELD.QUADRANT_TIER;
    } else if (attr === SEGMENTS_TITLES.PROFIT_TIERS.variableName) {
      returnAttribute = SEGMENTS_TITLES.PROFIT_TIERS.value;
    } else if(attr === SEGMENTS_TITLES.PROFIT_SEGMENTS.value){
      returnAttribute = SEGMENTS_TITLES.PROFIT_SEGMENTS.value;
    }else {
      let allowedAttrs = getVectorAllowedAttributes(
        vectorObj["value"],
        vectorObj[VECTOR_STAGING_ATTRIBUTES.GROUP],
        this.props?.userSettingsState.user
      );
      if (allowedAttrs.includes(IDENTIFIERS.NAME)) {
        returnAttribute = this.state.tempHeatmapConfObj.selectedVectorEntity;
      } else {
        returnAttribute = FILTER.VALUES.FIELD.NUMBER;
      }
    }

    return returnAttribute;
  }

  /**
   * This function sets the filter of the clicked cell
   * @param {*} firstDimensionEntity
   * @param {*} secondDimensionEntity
   */
  setClickedCell(firstDimensionEntity, secondDimensionEntity, cell) {
    var dim1Attribute = this.getFilterAttribute(this.state.dim1Type, this.props.vectorState?.vectorObjects[0]);
    var dim2Attribute = this.getFilterAttribute(this.state.dim2Type, this.props.vectorState?.vectorObjects[1]);

    //if filtering on 'Grouped Entities', the filter should be (!= all other selected entities)
    let firstDimensionFunction;
    let secondDimensionFunction;
    if (firstDimensionEntity === _groupedEntities) {
      let firstDimensionEntityStrArray = [];
      this.state.selectedEntitiesObj.selectedRowEntities.forEach((e) => {
        firstDimensionEntityStrArray.push({ value: e.toString(), label: e.toString() });
      });

      firstDimensionEntity = firstDimensionEntityStrArray; // this.dim1ScrollListRef.getSelectedOptions();
      firstDimensionFunction = MESSAGES.ui_filter.dropdowns.functions.not_equals.value;
    }
    if (secondDimensionEntity === _groupedEntities) {
      let secondDimensionEntityStrArray = [];
      this.state.selectedEntitiesObj.selectedColumnEntities.forEach((e) => {
        secondDimensionEntityStrArray.push({ value: e.toString(), label: e.toString() });
      });

      secondDimensionEntity = secondDimensionEntityStrArray; //this.dim2ScrollListRef.getSelectedOptions();
      secondDimensionFunction = MESSAGES.ui_filter.dropdowns.functions.not_equals.value;
    }
    //when clicking on 'Grouped Entities', filter becomes of the form (vector not in (all selected entities))

    let logOperator = FILTER.VALUES.LOGICAL_OPERATOR.AND;
    var dim1Filter = getNewEntityFilter(
      this.state.dimension_0,
      dim1Attribute,
      firstDimensionEntity,
      FILTER.VALUES.FILTER_ROW_TYPE.HEATMAP,
      logOperator,
      firstDimensionFunction
    );
    if (cell.key) {
      // if dim1 is by key
      dim1Filter.key = cell.key;
    }
    var dim2Filter = getNewEntityFilter(
      this.state.dimension_1,
      dim2Attribute,
      secondDimensionEntity,
      FILTER.VALUES.FILTER_ROW_TYPE.HEATMAP,
      logOperator,
      secondDimensionFunction
    );

    var arr = [dim1Filter, dim2Filter];
    this.state.clickedCellFilter = JSON.stringify(arr); //save filter in state to be used when fetching list data
  }

  setThreshold(threshold) {
    this.setState({
      threshold: threshold,
    });
  }
  setDimensionFilter(filterString, callback) {
    var filter = filterString ? JSON.parse(filterString) : [];
    filter = filter.filter((e) => !(e.function === "NEQ" && e.entities.length === 0)); // if we removed all the entities and we still have the grouped entities toggle turned on
    var filterFinal = this.state.filterFinal?.length > 0 ? JSON.parse(this.state.filterFinal) : [];
    filter.forEach((e) => filterFinal.push(e));
    this.setState(
      {
        dimensionListFilter: JSON.stringify(filterFinal),
        dimensionFilterLastUpdate: Date.now(),
      },
      function () {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );

    //update the filter so that the new value is displayed over the list
    if (this.dimensionFilterDialRef) {
      this.dimensionFilterDialRef.onChangeSavedFilter(filterFinal, false);
    }
  }

  //this function is called when the list's filter is changed
  onChangeDimensionFilter(filterString, fromDidMount, fetchData) {
    if (fetchData) {
      const _this = this;
      this.setDimensionFilter(filterString, function () {
        if (!fromDidMount) {
          _this.go(LIST);
        }
      });
    }
  }

  onTooltipBtnClick(clickedDimension) {
    setLimit(100);
    var obj = this;
    profitStackSelectedEntities = [];
    this.setState(
      {
        showList: true,
        hide: "",
        listDimension: clickedDimension,
        listFilter: obj.state.clickedCellFilter,
      },
      function () {
        $(".totalsTooltip").hide();
        $(".detailsTooltip").hide();
        obj.go(LIST);
        $(".heatMap-container").addClass("half-screen-heatmap");
        $("#heatMap-list").addClass("half-screen-list-container");
        $("#heatMap-list #table-list").addClass("half-screen-list");
      }
    );
  }

  setTableColumns(tableColumns) {
    this.setState({
      tableColumns: tableColumns,
    });
  }

  //@override
  onBackNavigation() {
    this.saveChosenEntities(true); // clear profitStackSelectedEntities
    this.props.setIsDrilling(false);
    setDrillRowsLimit(this.state.drillRows);
    $("#To_Scroll_Top").hide();
    $("#To_Scroll_List").show();
    $("#To_Scroll_List_Drill").hide();
    $("#DrillDown").hide();
    $(".expand-close-btns").show();
    // sessionStorage.removeItem("selectedProfile_list_drill"); //Remove when changing profile
    this.pageComponent.setState({
      cardsData: [],
    });
    this.go(false, true);
  }

  runExtendedProfitStackFunc = (fromTooltipButton, dropdownName) => {
    var comp = this;
    var profitStackItems = [];
    var isDrilling = this.state.isDrilling; //$("#isDrilling").length > 0 && $("#isDrilling").val() !== "0";
    const profitStackTier = this.state.listDimension;
    const profitStackDataSet = this.state.selectedPeriod;
    const profitFormat = this.props.profitFormat;
    var drilledPath = [];
    var entities = profitStackSelectedEntities;
    let nameIndex = 0;
    let numberIndex = 0;
    let quadrantIndex = 0;
    let quadrantTierIndex = 0;
    if (entities && entities.length > 0) {
      nameIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.NAME);
      numberIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.NUMBER);
      quadrantIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.QUADRANT);
      quadrantTierIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.QUADRANT_TIER);
    }

    for (let i = 0; i < entities.length; i++) {
      let entity = entities[i];
      var loopedItem = {};

      loopedItem.key = entity.key;
      loopedItem.number = entity.number;
      loopedItem.name = entity.name;
      loopedItem.lines = entity.lines;
      loopedItem.segment =
        nameIndex === -1 && numberIndex === -1 && quadrantTierIndex === -1 && quadrantIndex > -1
          ? entity.quadrant
          : nameIndex === -1 && numberIndex === -1 && quadrantTierIndex > -1
          ? entity.quadranttier
          : undefined;
      loopedItem.profittier = nameIndex === -1 && numberIndex === -1 && quadrantTierIndex > -1
          ? entity.quadranttier
          : undefined;
      loopedItem.tier = getPSTier(profitStackTier, isDrilling);
      loopedItem.dataset = profitStackDataSet;
      loopedItem.profitFormat = profitFormat;
      loopedItem.color = COLORS_PALETTE[i]; // get the correspondant color based on entities order
      profitStackItems.push(loopedItem);
    }
    if (fromTooltipButton) {
      let entitiesObj = JSON.parse(comp.state.clickedCellFilter).filter((e) => e.vector === comp.state.dimension_0);
      let item = {};
      item.tier = comp.state.dimension_0;
      if (entitiesObj[0].field === FILTER.VALUES.FIELD.QUADRANT_TIER.toLowerCase() || entitiesObj[0].field === SEGMENTS_TITLES.PROFIT_TIERS.value) {
        // if dim1 is by qt
        item.segment = new Segment().getSegmentObject(entitiesObj[0].entities[0].value)?.value;
        item.profittier = new Segment().getSegmentObject(entitiesObj[0].entities[0].value)?.value;
      } else if(entitiesObj[0].field === SEGMENTS_TITLES.PROFIT_SEGMENTS.value){
        item.segment = entitiesObj[0].entities[0].value;
      } else if ([FILTER.VALUES.FIELD.NAME.toLowerCase(), FILTER.VALUES.FIELD.NUMBER.toLowerCase()].includes(entitiesObj[0].field)) {
        // if dim1 by key
        if (entitiesObj[0].function === FILTER_FUNCTIONS.FILTER_FUNCTION_NEQ) {
          // if type is NEQ, the selected entity is "Grouped entities"
          item[entitiesObj[0].field] = _groupedEntities;
          item.key = _groupedEntities;
        } else {
          item[entitiesObj[0].field] = entitiesObj[0].entities[0].label;
        }
      }
      if (entitiesObj[0].key) {
        // if dim1 by key
        item.key = entitiesObj[0].key;
      }
      profitStackItems.push(item);
    }

    let isExtendedStack = dropdownName === ALL_WIDGETS.FIELDS.SELECT_STACK;
    let selectedStackVisibility = this.props.PSViewsState.profitStackViews.find((e) => e.value === this.state.nextProfitStackViewId)?.visibility;
    let psAllowed = checkIfSectionIdExists(
      this.props.userAllowedMenuLinks,
      isExtendedStack
        ? ALL_WIDGETS.FIELDS.PROFIT_STACK // extended
        : !selectedStackVisibility
        ? MENU_ITEM.FIELDS.MANAGE_STACKS // for default profit stack
        : selectedStackVisibility === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER
        ? MENU_ITEM.FIELDS.MANAGE_STACKS
        : MENU_ITEM.FIELDS.VIEW_COMPANY_STACKS
    ); // user or company stacks
    if (!psAllowed) {
      alert("No access");
      return;
    }

    if (!fromTooltipButton && (profitStackSelectedEntities.length === 0 || profitStackSelectedEntities.length > 10)) {
      Popup.alert("Make sure to select at least one item but not more than 10!");
    } else {
      // put all heatmap data in an obj and save it in redux store
      let heatmapObj = this.fillHeatmapBeforeBackObj(this.state);

      if (this.pageComponent?.state) {
        sessionStorage.setItem("selectedProfile_list", JSON.stringify(this.state.manageColumnsProfile)); // save the selected list for back navigation
        let listData = this.pageComponent?.state?.dataFinal;
        listData?.chunkData?.map((m) => (m.checked = false)); // uncheck selected entites
        listData.orderer = this.pageComponent?.state?.sorter;
        listData.order = this.pageComponent?.state?.order.toLowerCase();
        listData.columnsWidths = this.pageComponent?.state?.columnsWidths;
        this.props.dispatch(updateHeatmapListData([listData], "heatmapListData", false));
      }

      this.props.dispatch(updateHeatmapObjBeforeBack([heatmapObj], "heatmapObjBeforeBack", false));

      // var pathName = isExtendedStack
      //   ? "/ProfitLandscape/profitMap/profitStack/" + access[SECTION.ID] + "/" + this.state.custom_stack_id
      //   : "/reporting/financial_analytics/entity_stacks";
      var pathName = MENU_ITEM_URLS.PROFIT_PATTERNS_ENTITY_STACKS;
      let startDate = this.props.periodsStatusState.customStartDate;
      let endDate = this.props.periodsStatusState.customEndDate;
      let dimensionListFilter = [];
      let toBeAddedFilter = [];
      if (fromTooltipButton) {
        toBeAddedFilter = JSON.parse(comp.state.clickedCellFilter).filter((e) => e.vector === comp.state.dimension_0);
        // remove the dim1 selected entity from the filter
        // when clicking on view stack of an intersection, the selected row should be the selected entity and the selected column should only be added to the filter
        dimensionListFilter = removeDuplicateObject(
          JSON.parse(comp.state.dimensionListFilter),
          JSON.parse(comp.state.clickedCellFilter).filter((e) => e.vector === comp.state.dimension_0)
        );
      } else {
        dimensionListFilter = JSON.parse(comp.state.dimensionListFilter);
      }

      let savedFilter = dimensionListFilter.find(f => f.filter_display_name);
      
      if(savedFilter) {
        savedFilter.filter_display_name = undefined
      }

      this.props.history.push({
        pathname: pathName,
        search: "?",
        hash: "",
        state: {
          dataset: this.props.datasetState.dataSet,
          datasetOptions: this.props.datasetState?.datasetOptions,
          drilledPath: drilledPath,
          drilling: isDrilling,
          endDate: endDate,
          dimensionListFilter: dimensionListFilter,
          filter: JSON.stringify(this.state.filterFinal),
          FY: FY_VALUES.OFF,
          headerOptionChanged: false,
          isRedirectionFromStacks: true,
          mainFilter: this.state.filterFinal,
          mainFilterFinalBasic: this.state.filterFinalBasic,
          mainVector: this.pageComponent ? this.pageComponent?.state?.drillTier : undefined,
          order: this.props.history.location.state ? this.props.history.location.state.order : undefined,
          origin_name: this.props.profitFormat,
          origin: window.location.href,
          originalScenarios: this.props.scenarioState?.scenarios,
          outsideFilter: JSON.stringify({ filter: tryParse(this.state.filterFinal) }),
          profitFormat: this.props.profitFormat,
          profitStackItems: profitStackItems,
          profitStackViewId: this.state.nextProfitStackViewId,
          ps_default_line: this.state.ps_default_line,
          scenarios: this.props.scenarioState?.scenarios,
          scenarioState: this.props.history.location.state.scenarioState,
          secondDimVectors: this.props.vectorState.vectors,
          startDate: startDate,
          toBeAddedFilter: toBeAddedFilter[0]?.entities?.length ? toBeAddedFilter : undefined,
          useFilterCookies: false,
          vectorName: findOptionByKey(comp.props.vectorState.vectorObjects, !fromTooltipButton ? comp.state.listDimension : comp.state.dimension_0).label,
        },
      });
    }
  };

  fillHeatmapBeforeBackObj = (obj) => {
    let heatmapObj = {};

    heatmapObj.heatmapConfObj = obj.heatmapConfObj;
    heatmapObj.exportOptions = obj.exportOptions;
    heatmapObj.selectedEntitiesObj = copyObjectValues(obj.selectedEntitiesObj);
    heatmapObj.heatmapData = {
      colColumns: obj.colColumns,
      rowColumns: obj.rowColumns,
      columnEntities: obj.columnEntities,
      rowEntities: obj.rowEntities,
      limitEntities: obj.limitEntities,
      isEntityConfChanged: obj.isEntityConfChanged,
      colorCodes: obj.colorCodes,
      dim1DefaultEntities: obj.dim1DefaultEntities,
      dim2DefaultEntities: obj.dim2DefaultEntities,
      dim1SelectedEntities: obj.dim1SelectedEntities,
      dim2SelectedEntities: obj.dim2SelectedEntities,
      totalFirstDimension: obj.totalFirstDimension,
      totalSecondDimension: obj.totalSecondDimension,
      dim1Type: obj.dim1Type,
      dim2Type: obj.dim2Type,
      firstDimensionEntities: obj.firstDimensionEntities,
      secondDimensionEntities: obj.secondDimensionEntities,
      dim1EntityOptions: obj.dim1EntityOptions,
      dim2EntityOptions: obj.dim2EntityOptions,
      addEntitiesOptions_row: obj.addEntitiesOptions_row,
      addEntitiesOptions_column: obj.addEntitiesOptions_column,
      heatMapData: obj.heatMapData,
      secondDimOrder: secondDimOrder,
      firstDimOrder: firstDimOrder,
      rowsTotal: rowsTotal,
      colsTotal: colsTotal,
    };
    heatmapObj.profitStackLinesData = {
      profitStackLines: obj.profitStackLines,
      ps_default_line: obj.ps_default_line,
      machine_value: obj.machine_value,
      firstDimMaxEntities: obj.firstDimMaxEntities,
      secondDimMaxEntities: obj.secondDimMaxEntities,
      revenueCostKey: obj.revenueCostKey,
      defaultRevenue: obj.defaultRevenue,
    };
    heatmapObj.listData = {
      isListFullScreen: obj.isListFullScreen,
      showList: obj.showList,
      hide: obj.hide,
      listDimension: obj.listDimension,
      manageColumnsProfile: obj.manageColumnsProfile,
      tableColumns: obj.tableColumns,
      vectorObjects: obj.vectorObjects,
      vectorOptions: obj.vectorOptions,
      drillVectorsOptions: obj.drillVectorsOptions,
      clickedCellFilter: obj.clickedCellFilter,
      filterFinal: obj.filterFinal,
      listFilter: obj.listFilter,
    };

    return heatmapObj;
  };

  getHeatMapBeforeBackObj = (data) => {
    let _this = this;
    let tempState = {};

    tempState.heatmapConfObj = data.heatmapConfObj;
    tempState.tempHeatmapConfObj = data.heatmapConfObj;
    tempState.selectedEntitiesObj = data.selectedEntitiesObj;
    tempState.tempSelectedEntitiesObj = copyObjectValues(data.selectedEntitiesObj);
    tempState.colColumns = data.heatmapData.colColumns;
    tempState.rowColumns = data.heatmapData.rowColumns;
    tempState.columnEntities = _this.checkSelectedEntities(data.heatmapData.columnEntities, data.selectedEntitiesObj.selectedColumnEntities);
    tempState.rowEntities = _this.checkSelectedEntities(data.heatmapData.rowEntities, data.selectedEntitiesObj.selectedRowEntities);
    tempState.limitEntities = data.heatmapData.limitEntities;
    tempState.isEntityConfChanged = data.heatmapData.isEntityConfChanged;
    tempState.colorCodes = data.heatmapData.colorCodes;
    tempState.dim1DefaultEntities = data.heatmapData.dim1DefaultEntities;
    tempState.dim2DefaultEntities = data.heatmapData.dim2DefaultEntities;
    tempState.dim1SelectedEntities = data.heatmapData.dim1SelectedEntities;
    tempState.dim2SelectedEntities = data.heatmapData.dim2SelectedEntities;
    tempState.totalFirstDimension = data.heatmapData.totalFirstDimension;
    tempState.totalSecondDimension = data.heatmapData.totalSecondDimension;
    tempState.dim1Type = data.heatmapData.dim1Type;
    tempState.dim2Type = data.heatmapData.dim2Type;
    tempState.firstDimensionEntities = data.heatmapData.firstDimensionEntities;
    tempState.secondDimensionEntities = data.heatmapData.secondDimensionEntities;
    tempState.dim1EntityOptions = data.heatmapData.dim1EntityOptions;
    tempState.dim2EntityOptions = data.heatmapData.dim2EntityOptions;
    tempState.addEntitiesOptions_row = data.heatmapData.addEntitiesOptions_row;
    tempState.addEntitiesOptions_column = data.heatmapData.addEntitiesOptions_column;
    tempState.heatMapData = data.heatmapData.heatMapData;
    tempState.profitStackLines = data.profitStackLinesData.profitStackLines;
    tempState.ps_default_line = data.profitStackLinesData.ps_default_line;
    tempState.machine_value = data.profitStackLinesData.machine_value;
    tempState.firstDimMaxEntities = data.profitStackLinesData.firstDimMaxEntities;
    tempState.secondDimMaxEntities = data.profitStackLinesData.secondDimMaxEntities;
    tempState.revenueCostKey = data.profitStackLinesData.revenueCostKey;
    tempState.defaultRevenue = data.profitStackLinesData.defaultRevenue;
    tempState.isListFullScreen = data.listData.isListFullScreen;
    tempState.showList = data.listData.showList;
    tempState.hide = data.listData.hide;
    tempState.listDimension = data.listData.listDimension;
    tempState.manageColumnsProfile = data.listData.manageColumnsProfile;
    tempState.tableColumns = data.listData.tableColumns;
    tempState.vectorObjects = data.listData.vectorObjects;
    tempState.vectorOptions = data.listData.vectorOptions;
    tempState.drillVectorsOptions = data.listData.drillVectorsOptions;
    tempState.clickedCellFilter = data.listData.clickedCellFilter;
    tempState.filterFinal = data.listData.filterFinal;
    tempState.order = data.listData.order;
    tempState.listFilter = data.listData.listFilter;
    tempState.exportOptions = data.exportOptions;
    tempState.readFromStore = tempState.showList;
    return tempState;
  };

  generateData(alldata, defaultLine, tempState = {}) {
    var selectedQuarter = this.state.selectedPeriod;
    $(".contourDetailsTooltip").hide();
    $(".contourDetailsTooltip").removeClass("uk-display-inline-block");
    const _this = this;
    if (alldata.heatmap_timeperiod_shifting_delay) {
      tempState.timeperiodDelay = alldata.heatmap_timeperiod_shifting_delay;
    }

    var data = alldata.data;
    // var quarters = Object.keys(data).sort();
    var firstDimensionEntities = [];
    var secondDimensionEntities = [];
    var dim1EntityOptions = [];
    var dim2EntityOptions = [];

    // for (var elt in quarters) {
    data.data.map(function (e) {
      Object.keys(e).forEach(function (key) {
        if (key && key !== _this.state.dimension_0 + "Entity" && !secondDimensionEntities.includes(key)) {
          secondDimensionEntities.push(key);

          let isGrouped = parseBoolean(e[key][_this.state.dimension_1 + "_is_grouped"] || false);
          dim2EntityOptions.push({
            label: isGrouped ? key + MESSAGES.heatmap_configure.entities.grouped_entities_desc : key,
            value: key,
            isGrouped: isGrouped,
          });
        }
      });
      let value = e[_this.state.dimension_0 + "Entity"].value;
      if (!firstDimensionEntities.includes(value) && value !== "") {
        firstDimensionEntities.push(value);

        let isGrouped = parseBoolean(e[_this.state.dimension_0 + "Entity"][_this.state.dimension_0 + "_is_grouped"] || false);
        dim1EntityOptions.push({
          label: isGrouped ? value + MESSAGES.heatmap_configure.entities.grouped_entities_desc : value,
          value: value,
          isGrouped: isGrouped,
        });
      }
    });
    // }

    tempState.dim1SelectedEntities = copyObjectValues(dim1EntityOptions); //set all present options as selected before adding 'Grouped Entities'
    tempState.dim2SelectedEntities = copyObjectValues(dim2EntityOptions);
    if (dim1EntityOptions.filter((ent) => ent.isGrouped).length === 0) {
      //number of entities is less than total, manually add "Grouped Entities"
      dim1EntityOptions.push({
        label: _groupedEntities + MESSAGES.heatmap_configure.entities.grouped_entities_desc,
        value: _groupedEntities,
        isGrouped: true,
      });
    }
    if (dim2EntityOptions.filter((ent) => ent.isGrouped).length === 0) {
      //number of entities is less than total, manually add "Grouped Entities"
      dim2EntityOptions.push({
        label: _groupedEntities + MESSAGES.heatmap_configure.entities.grouped_entities_desc,
        value: _groupedEntities,
        isGrouped: true,
      });
    }

    tempState.alldata = alldata;
    tempState.totalFirstDimension = alldata["countFirstDimension"];
    tempState.totalSecondDimension = alldata["countSecondDimension"];
    tempState.dim1Type = alldata["Dim1Type"];
    tempState.dim2Type = alldata["Dim2Type"];
    tempState.showList = false; //unmount list component when the data changes
    tempState.hide = " uk-hidden";
    tempState.firstDimensionEntities = firstDimensionEntities;
    tempState.secondDimensionEntities = secondDimensionEntities;
    //setting selected options (if all entities are returned - less than max - Grouped Entities should be unchecked)
    tempState.dim1EntityOptions = dim1EntityOptions;
    tempState.dim2EntityOptions = dim2EntityOptions;

    let dim1TempDefaultEntities = tempState.dim1DefaultEntities || this.state.dim1DefaultEntities; //if emptied in getHeatmapData, check the tempState and not the state
    let dim2TempDefaultEntities = tempState.dim2DefaultEntities || this.state.dim2DefaultEntities; //if emptied in getHeatmapData, check the tempState and not the state
    tempState.dim1DefaultEntities = dim1TempDefaultEntities && dim1TempDefaultEntities.length ? dim1TempDefaultEntities : dim1EntityOptions;
    tempState.dim2DefaultEntities = dim2TempDefaultEntities && dim2TempDefaultEntities.length ? dim2TempDefaultEntities : dim2EntityOptions;
    tempState.addEntitiesOptions_row = tempState.dim1Type === _entityGroups.entity.value;
    tempState.addEntitiesOptions_column = tempState.dim2Type === _entityGroups.entity.value;
    tempState.ps_default_line = defaultLine;
    tempState.tempSelectedEntitiesObj.id = tempState.tempSelectedEntitiesObj.id + 1;
    var timelineQuarters = [];
    _this.setState(tempState, function () {
      _this.onChangeSelectedPeriod(tempState.selectedIndex);
    });
  }

  emptyAllData() {
    this.setState({
      alldata: [],
      profitStackLines: [],
      heatMapData: [],
      contourMapData: [],
      showList: false,
      hide: " uk-hidden",
      fromBack: false,
    });
  }

  sortEntityOptions(dim1EntityOptions, dim2EntityOptions) {
    const _this = this;
    let rowEntities = firstDimOrder.map((el) => el.rowVec);
    let columnEntites = secondDimOrder.map((el) => el.col);
    dim1EntityOptions.sort((a, b) => {
      return rowEntities.indexOf(a.value) - rowEntities.indexOf(b.value);
    });
    dim2EntityOptions.sort((a, b) => {
      return columnEntites.indexOf(b.value) - columnEntites.indexOf(a.value); //flipping b and a to flip the sorting order (both should be DESC)
    });

    this.setState(
      {
        dim1EntityOptions: dim1EntityOptions,
        dim2EntityOptions: dim2EntityOptions,
      },
      () => {
        if (_this.dim1ScrollListRef) {
          _this.dim1ScrollListRef.init();
        }
        if (_this.dim2ScrollListRef) {
          _this.dim2ScrollListRef.init();
        }
        _this.heatMap.stateChanged = true;
      }
    );
  }

  /**
   * this function is called after drilling, to refresh the datalenght in the confirmPasswordModel componenet
   */
  refreshDataLenght = (length) => {
    let _this = this;
    if (_this.confirmPassModalRef) {
      _this.confirmPassModalRef.setState({
        dataLength: length,
      });
    }
  };

  updateDataOrder(data) {
    const _this = this;
    if (data && data.length > 0) {
      // rowsTotal returns each firstDimension rowEntity with its sum
      rowsTotal = [];
      let firstDimEntities = this.state.firstDimensionEntities;
      let secondDimEntities = this.state.secondDimensionEntities;
      for (let firstKey in firstDimEntities) {
        let sum = 0;
        let row = data.filter((e) => e[_this.state.dimension_0 + ENTITY].value === firstDimEntities[firstKey])[0];
        for (let col in secondDimEntities) {
          sum +=
            row && row[secondDimEntities[col]]
              ? // this.state.ps_default_line && this.state.ps_default_line[PSS.DUPLICATE_KEYS.RETURNNAME] && this.state.ps_default_line[PSS.DUPLICATE_KEYS.RETURNNAME] !== this.state.machine_value[PSS.DUPLICATE_KEYS.RETURNNAME] ?
                row[secondDimEntities[col]].defaultLinePerc
              : //  : row[secondDimEntities[col]].machineValuePerc
                0;
        }
        rowsTotal.push({ total: sum, rowVec: firstDimEntities[firstKey] });
      }

      // colsTotal returns each secondDimension entity with its total
      colsTotal = [];
      for (let secondKey in secondDimEntities) {
        let sum = 0;
        data.forEach(function (e) {
          sum +=
            e[secondDimEntities[secondKey]] && e[_this.state.dimension_0 + ENTITY].value !== ""
              ? // _this.state.ps_default_line[PSS.DUPLICATE_KEYS.RETURNNAME] && _this.state.ps_default_line[PSS.DUPLICATE_KEYS.RETURNNAME] !== _this.state.machine_value[PSS.DUPLICATE_KEYS.RETURNNAME] ?
                e[secondDimEntities[secondKey]].defaultLinePerc
              : // : e[secondDimEntities[secondKey]].machineValuePerc
                0;
        });
        colsTotal.push({ col: secondDimEntities[secondKey], total: sum });
      }

      if (!isPlaying || firstDimOrder.length === 0) {
        firstDimOrder = rowsTotal; // same total order globaly for animation
        secondDimOrder = colsTotal; // same total order globaly for animation
        this.sortByCol(firstDimOrder, "total", true); // returns sorted totals values along with rowVec for final column
        this.sortByCol(secondDimOrder, "total", false); // returns sorted totals values along with their entity for final row
      }

      this.sortEntityOptions(this.state.dim1EntityOptions, this.state.dim2EntityOptions);
    }
  }

  setSliderLimitOnClick(e) {
    this.setSliderLimit(Number($(e.currentTarget).attr("value")));
  }

  setSliderLimit(value) {
    $(".totalsTooltip").hide();
    $(".detailsTooltip").hide();
    $(".contourDetailsTooltip").removeClass("uk-display-inline-block");
    $(".contourDetailsTooltip").hide();
    this.onChangeSelectedPeriod(value);
  }

  changeData() {
    var obj = this;
    var data = this.state.alldata.data;
    obj.setState({
      playingPeriod: obj.state.selectedPeriod,
    });

    if (Object.keys(data).length > 0) {
      var interval = setInterval(function () {
        isPlaying = true;
        var variable = obj.state.selectedIndex + 1 >= obj.props.periodsStatusState?.periods.length ? 0 : obj.state.selectedIndex + 1;
        var period = obj.props.periodsStatusState?.periods.filter((e) => e.index === variable.toString())[0].value;
        if (period === obj.state.playingPeriod) {
          clearInterval(interval);
          isPlaying = false;
        }
        obj.onChangeSelectedPeriod(variable);
      }, this.state.timeperiodDelay * 1000);
    }
  }

  sortByCol(arr, colIndex, desc) {
    arr.sort(sortFunction);

    function sortFunction(a, b) {
      a = a[colIndex];
      b = b[colIndex];
      if (desc) {
        return a === b ? 0 : a < b ? 1 : -1;
      } else {
        return a === b ? 0 : a < b ? -1 : 1;
      }
    }
  }

  getPeriodsHtml(periods) {
    if (periods !== undefined) {
      var obj = this;
      var divs = [];
      var width = Math.floor(100 / periods.length, 2);
      for (var i = 0; i < periods.length; i++) {
        divs.push(
          <div
            className="uk-display-inline-block"
            key={i}
            style={{ width: width.toString() + "%", textAlign: "center" }}
            id={periods[i].index.toString() + "_period"}
            value={i}
          >
            <p style={{ cursor: "pointer" }} value={i} onClick={(e) => obj.setSliderLimitOnClick(e)}>
              {periods[i].label}
            </p>
          </div>
        );
      }
      return divs;
    }
  }

  showEntitiesOptions(e) {
    const axis = $(e.target).attr("axis");
    let tempState = {};
    let listToShow = null;
    if (axis === "y") {
      tempState.chosenEntitiesDimension = this.state.dimension_0;
      listToShow = this.dim1ScrollListRef;
      $("#x-axis-entity").addClass("background-dark-grey");
    } else {
      tempState.chosenEntitiesDimension = this.state.dimension_1;
      listToShow = this.dim2ScrollListRef;
      $("#y-axis-entity").addClass("background-dark-grey");
    }
    if (listToShow && listToShow.state.visible) {
      listToShow.hide();
      $("#" + e.currentTarget.id).removeClass("background-dark-grey");
      return;
    }

    let $btn = $(e.currentTarget);
    let top = $btn.offset().top + $btn.height() + convertViewportToPx(20);
    let left = $btn.offset().left;
    tempState.scrollListPosition = { top: top, left: left };

    this.setState(tempState, () => {
      if (listToShow) {
        listToShow.show();
      }
    });
  }

  onBackClick = () => {
    let _this = this;
    $(".view-profit-stack-filter-container").removeClass("disabled-button uk-disabled");
    // this.setState({
    //     secondaryReport: undefined      //resetting secondaryReport state since the back button reverts to the main report
    // }, ()=>{
    _this.onBackNavigation();
    // });
    return;
  };

  hideEntitiesOptions(e) {
    if (
      $(".scroll-list-container").is(":visible") &&
      !$(".scroll-list-container").is(e.target) &&
      $(".scroll-list-container").has(e.target).length === 0
    ) {
      if (this.dim1ScrollListRef && e.currentTarget.activeElement.id !== "x-axis-entity") {
        this.dim1ScrollListRef.hide();
        $("#x-axis-entity").removeClass("background-dark-grey");
      }
      if (this.dim2ScrollListRef && e.currentTarget.activeElement.id !== "y-axis-entity") {
        this.dim2ScrollListRef.hide();
        $("#y-axis-entity").removeClass("background-dark-grey");
      }
    }
  }

  saveChosenEntities(clearFlag, cell, isChecked) {
    let _this = this;
    if (clearFlag) {
      profitStackSelectedEntities = [];
      return;
    }

    var entities = profitStackSelectedEntities;
    var currEntity = cell.getRow().getData();
    let ents = copyObjectValues(entities);
    if (
      ents.filter(
        (e) =>
          (e.key && e.key === currEntity.key) ||
          (e.quadranttier && e.quadranttier === currEntity.quadranttier) ||
          (e.quadrant && e.quadrant === currEntity.quadrant)
      )?.length > 0
    ) {
      entities = entities.filter(
        (e) =>
          (e.key && e.key !== currEntity.key) ||
          (e.quadranttier && e.quadranttier !== currEntity.quadranttier) ||
          (e.quadrant && e.quadrant !== currEntity.quadrant)
      );
    }

    if (isChecked && !this.state.isDrilling) {
      showProfitStackLink();
      entities.push(currEntity);
    } else {
      if (entities.length === 0) {
        hideProfitStackLink();
      }
    }
    let tempState = {};
    tempState.selectedEntities = entities?.length;
    let isDrillingFromHeatmap = this.state.isDrilling && this.props.profitFormat === ALL_REPORTS.HEATMAP;
    let rowSelectedInDrillHeatmap = copyObjectValues(this.state.rowSelectedInDrillHeatmap);
    if(isDrillingFromHeatmap){
      if(isChecked){
        rowSelectedInDrillHeatmap.push(currEntity);
        tempState.rowSelectedInDrillHeatmap = rowSelectedInDrillHeatmap;
      }else{
        tempState.rowSelectedInDrillHeatmap = this.state.rowSelectedInDrillHeatmap.filter(
          (e) =>
            (e.key && e.key !== currEntity.key) ||
            (e.quadranttier && e.quadranttier !== currEntity.quadranttier) ||
            (e.quadrant && e.quadrant !== currEntity.quadrant)
        );
      }
      entities = tempState.rowSelectedInDrillHeatmap;
    }    
    _this.setState(tempState);

    //disable view stack when drilling in heatmap/contourmap
    if (this.state.isDrilling) {
      $(".view-profit-stack-filter-container").addClass("disabled-button uk-disabled");
      $(".disabled-view-profit-stack").addClass("cursorNotAllowed").attr("uk-tooltip", MESSAGES.select_entities_to_view_PS);
    }

    profitStackSelectedEntities = entities;
    if (_this?.pageComponent?.tabulatorList !== null)
      _this?.pageComponent?.tabulatorList?.current?.addFooterText(
        _this?.pageComponent?.tabulatorList?.current?.tabulator?.getData(),
        undefined,
        entities
      );
    if (_this?.pageComponent?.tabulatorDrill !== null)
      _this?.pageComponent?.tabulatorDrill?.current?.addFooterText(
        _this?.pageComponent?.tabulatorDrill?.current?.tabulator?.getData(),
        undefined,
        entities
      );
  }

  /**
   * this function is used for apply buttons in "configure dialog" (selectedEntities is empty) and "select entities" dialog
   * @param {*} selectedEntities
   */
  applyChanges(selectedEntities = []) {
    let selectedEntitiesObj = {};
    if (this.state.chosenEntitiesDimension === this.state.dimension_0) {
      selectedEntitiesObj.row = selectedEntities.map((ent) => ent.value.replaceAll("'", "\\'"));
    } else {
      selectedEntitiesObj.column = selectedEntities.map((ent) => ent.value.replaceAll("'", "\\'"));
    }

    let configureData = this.heatMap.getConfigureData(); // : this.contourMap.getConfigureData();
    this.getHeatmapData(configureData.psLine, { rowView: configureData.rowView, columnView: configureData.columnView }, selectedEntitiesObj);
  }

  updateStateFromParent = (value) => {
    this.setState({
      dataLen: value,
    });
  };

  getPeriodsObjectMonth = () => {
    let _this = this;
    let periods = [];
    let periods_yoy = [];
    let periods_pa = [];
    let quarter = "";
    let quarterPre = "";
    let months = FY_VALUES.M3;
    let segmentPeriod = "";
    let startDate = getValidPeriods(this.props.periodsStatusState, this.props.clientPeriodsState).startDate;
    let endDate = getValidPeriods(this.props.periodsStatusState, this.props.clientPeriodsState).endDate;

    if (isValidDate(startDate) && isValidDate(endDate) && startDate && endDate) {
      let periodsCount = monthDiff(startDate, endDate) + 1;
      periods = generatePeriods(startDate, periodsCount);
      periods_yoy = generatePeriods(startDate, periodsCount, false);
      periods_pa = generatePeriods(startDate, periodsCount, true);
    }
    if (isValidDate(startDate) && isValidDate(endDate)) {
      let firstQuarter = getQuarterFromDate(startDate);
      let endQuarter = getQuarterFromDate(endDate);

      let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
      let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length - 1]);

      let generatedRange = getGeneratedQuarterRange(this.props.datasetState?.datasetOptions, firstQuarter, endQuarter);
      let generatedRangePre = getGeneratedQuarterRange(this.props.datasetState?.datasetOptions, firstPreQuarter, endPreQuarter);

      let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
      let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));

      quarter = fullQuarter.quarter;
      quarterPre = fullQuarterPre.quarter;

      months = fullQuarter.months;

      let lastSelectedPeriod = periods[periods.length - 1];
      let builtPeriods = _this.props.periodsStatusState?.actuallyBuiltPeriods?.map((m) => m.label);
      segmentPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
    }
    return {
      periods: periods,
      segmentPeriod: segmentPeriod,
      quarter: quarter,
      months: months,
      periods_yoy: periods_yoy,
      periods_pa: periods_pa,
      preQuarter: quarterPre,
    };
  };

  getPeriodsObject = () => {
    const _this = this;
    let periods = [];
    let quarter = "";
    let startDate = "";
    let endDate = "";
    if (_this.props.datasetState?.datasetOptions) {
      let date = _this.props.datasetState?.datasetOptions.filter((e) => e.label === _this.state.selectedPeriod);
      if (date && date[0]) {
        startDate = new Date(date[0].start_date);
        endDate = new Date(date[0].end_date);
      }
      if (startDate && endDate && isValidDate(startDate) && isValidDate(endDate)) {
        let periodsCount = monthDiff(startDate, endDate) + 1;
        periods = generatePeriods(startDate, periodsCount);
      }
      quarter = _this.state.selectedPeriod;
    }
    return { periods: periods, quarter: quarter };
  };

  startExport = () => {
    let _this = this;
    _this.setState({
      userAuthenticated_exportAll: true,
    });
    _this.confirmPassModalRef.setState({
      userAuthenticated_exportAll: true,
    });
  };

  getExportFilter = () => {
    let _this = this;
    let dimensionListFilter = _this.state.dimensionListFilter
      ? typeof _this.state.dimensionListFilter === "string"
        ? _this.state.dimensionListFilter === ""
          ? []
          : JSON.parse(_this.state.dimensionListFilter)
        : _this.state.dimensionListFilter
      : "";
    let exportQueryFilter = _this.props?.exportQueryFilter(dimensionListFilter);
    let filter =
      typeof _this.state.filterFinal === "string"
        ? _this.state.filterFinal === ""
          ? []
          : JSON.parse(_this.state.filterFinal)
        : _this.state.filterFinal;
    if ((!exportQueryFilter || exportQueryFilter === "None") && filter?.length > 0) {
      exportQueryFilter = filter.find((f) => !f.isBasicFilter)
        ? formatAdvancedFilter(
            filter,
            this.props?.userSettingsState?.user?.user_allowed_vectors,
            this.props.datasetState?.datasetOptions,
            this.props.vectorState?.vectorOptions,
            this.props.filterDialRef?.state?.psLinesOptions
          )
        : formatBasicFilter(filter, this.props.userSettingsState?.user?.user_allowed_vectors);
    }

    return exportQueryFilter;
  };

  /**
   * copied from header.js
   */
  reauthenticateWithCredential = () => {
    var email = this.props?.userSettingsState?.user.email;
    var password = $("#passwordConfirm").val();
    var obj = this;

    if (this.props?.userSettingsState.isClientSSO && !parseBoolean(this.props?.userSettingsState.user.is_system)) {
      obj.startExport();
    } else {
      // Sign in with the newly linked credential
      signInWithEmailAndPassword(auth, email, password)
        .then(function () {
          obj.startExport();
        })
        .catch(function (error) {
          if (error.code === AuthErrorCodes.MFA_REQUIRED) {
            obj.startExport();
            return;
          }
          obj.logExportData(USER_ACTIVITY_USER_NOT_AUTHENTICATED);
          Popup.alert("The password you entered is incorrect.");
          return false;
        });
    }
  };

  /**
   * copied from header.js
   * @param {*} activityStatus
   */
  logExportData = (activityStatus) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const path = API_URL.PROFIT_LANDSCAPE_EXPORT_ALL;
    var filter = this.state.filterFinal;

    var myUrl = baseUrl + path + "?";
    var query =
      "action=logExportData" +
      // + "&ip_address=" +ipAddress
      "&tier=" +
      this.props.vectorState.vectors[0] +
      "&quarter=" +
      this.props.datasetOptions?.dataset +
      "&filter={'filter':" +
      encodeURIComponent(filter) +
      "}" +
      "&activity_status=" +
      activityStatus;
    setLocalStorageValueByParameter(window.location.host + "_" + "lastRequestSentTime", new Date());
    $.ajax({
      url: myUrl + query,
      async: true,
      crossDomain: true,
      xhrFields: { withCredentials: true },
      dataType: "json",
    });
  };

  launchExportToast = () => {
    $("#toastExport").addClass("show");
    setTimeout(function () {
      $("#toastExport").removeClass("show");
    }, 4000);
  };

  setExportAll = (value, isExportExcel) => {
    let _this = this;
    _this.setState(
      {
        export_all: value,
      },
      function () {
        if (!isExportExcel) {
          _this.exportAll();
        }
      }
    );
    this?.configDropdownExpandingRef?.setConfigDropdownOpen(false);
  };

  showError = () =>{
    let _this = this;
    _this.setState({
      error: lang.header.custom_text.error,
      message: lang.header.custom_text.error,
      isExporting: false,
    },()=>{
        $("#toastExport").removeClass("toast-success");
        $("#toastExport").addClass("toast-fail");
        $("#toastExport div i").removeClass("fa-check-circle");
        $("#toastExport div i").removeClass("uk-margin-small-right greenText");
        $("#toastExport div i").addClass("fa-minus-circle uk-text-primary");
        $("#toastExport div i").addClass("uk-margin-small-right");
        $("#toastExport div span").text(_this.state.message);
        _this.launchExportToast();
    })
  }

  /**
   * copied from header.js
   * #region export all
   */
  exportAll = () => {
    let _this = this;
    let useOutputService = _this.props?.userSettingsState.useOutputService;
    window._pi_initialiseTracking(
      UI_ACTIONS.EXPORT_ALL,
      _this.props?.userSettingsState?.user.email,
      _this.props.userSettingsState?.machine_name,
      _this.props.profitFormat,
      false
    );
    this.confirmPassModalRef.setState({
      userAuthenticated_exportAll: false,
    });
    _this.setState(
      {
        userAuthenticated_exportAll: false,
        message: lang.header.custom_text.download_start,
        error: undefined,
      },
      function () {

        try { //export
          _this.launchExportToast();
          let query = $("#export-all-form").serializeArray().reduce(function(obj, item) {
              obj[item.name] = item.value;
              return obj;
          });
          if(_this.state.isDrilling && _this.state.drillFilter && query.filter && typeof query.filter === "string" && typeof _this.state.drillFilter === "object"){
            query.filter = encodeURIComponent(query.filter);
          }
          query.numericFormatName = window._format?.numeric_format_name
          let onThenCallback = function (blob) {
            if (blob?.size === 0) {
              showError();
              return;
            }
            
            if(useOutputService) { // this means we are using output-service export logic
                $("#frame_export").attr('src', blob.signedUrl);
            } else {
              triggerExportData(blob, query, _this.props.userSettingsState);
            }

            _this.setState({
              isExporting: false
            })
          }
  
          let onErrorCallback = function () {
            _this.showError();
          }
  
          let options = {
              [FETCHAPI_PARAMS.funcName]: "exportAll",
              [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
              [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE_EXPORT_ALL,
              [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
              [FETCHAPI_PARAMS.query]: query,
              [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
              [FETCHAPI_PARAMS.onErrorCallback]: onErrorCallback,
              [FETCHAPI_PARAMS.useBlob]: !useOutputService,
              [FETCHAPI_PARAMS.screenName]: lang.observability.output.heatmap.heatmap_list.screen_name,
              [FETCHAPI_PARAMS.requestDescription]:( _this.state.isDrilling ? lang.observability.output.heatmap.heatmap_list.requests_description.export_drill: lang.observability.output.heatmap.heatmap_list.requests_description.export) + (this.state.export_all? lang.observability.output.list.requests_description.with_all_psls:""),
              [FETCHAPI_PARAMS.vector]: query.tier,
              [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
              [FETCHAPI_PARAMS.periods]: "'"+query.selectedPeriods+"'",
          }
          fetchAPI(options);
        } catch (err) {
          _this.showError();
        }
        //removed because there is nothing else to do for this action, tracking continued on the API
        window._pi_removeTrackingData();
      }
    );
  };

  /**
   * copied from header.js
   */
  cancel = () => {
    this.confirmPassModalRef.setState({ userAuthenticated_exportAll: false });
  };

  /**
   * On apply button in configure dropdown in HeatMap.
   * @param {*} obj
   */
  onHeatmapApplyConfigure = (obj) => {
    let _this = this;
    // removing id from psl object to compare them if they are identical
    delete obj.psl._id;
    delete _this.state.heatmapConfObj.psl._id;

    let isThresholdChanged = obj.threshold !== _this.state.heatmapConfObj.threshold;
    let isHeatmapObjNotChanged =
      JSON.stringify(obj.psl) === JSON.stringify(_this.state.heatmapConfObj.psl) &&
      obj.stack === _this.state.heatmapConfObj.stack &&
      obj.selectedVectorSegment === _this.state.heatmapConfObj.selectedVectorSegment &&
      obj.selectedVectorEntity === _this.state.heatmapConfObj.selectedVectorEntity;

    _this.setState(
      {
        tempHeatmapConfObj: obj,
        heatmapConfObj: obj,
        showHeatmapConfigureDialog: false,
      },
      () => {
        /* If anything was changed (other than the threshold) in configure dialog -> fetch new data, 
                else -> close configure dialog and dont send request.
                Dont send request if only the threshold was changed because data is already filtered on the ui
                and does not need to be refetched
            */
        if (!isHeatmapObjNotChanged) {
          if (!this.props.showGreyOverLay) {
            _this.getHeatmapData(_this.state.ps_default_line, undefined, undefined, true);
          }
        } else if (isThresholdChanged) {
          // if only the threshlold is changed, we don't reload the data but we should close the selected list
          _this.closeList();
        }
      }
    );
  };


  /**
   * Save changes in a temp obj so that when we make a change outside configure dropdown without pressing on go/apply
   * the changes done in configure will remain
   * @param {*} obj
   */
  setTempHeatmapConfObj = (obj) => {
    let _this = this;
    _this.setState({
      tempHeatmapConfObj: obj,
    });
  };

  setSelectedEntities = (isRow, entity, checked) => {
    let _this = this;
    let tempState = {};
    let obj = copyObjectValues(_this.state.tempSelectedEntitiesObj);
    let entities = [];
    if (isRow) {
      entities = _this.state.rowEntities;
      if (checked) {
        obj.selectedRowEntities.push(entity);
      } else {
        obj.selectedRowEntities = obj.selectedRowEntities.filter((e) => e !== entity);
      }
      entities.find((f) => f.entities === entity).check = checked;
      tempState.rowEntities = entities;
      if (tempState.rowEntities.length <= _this.state.limitEntities - 1) {
        if (obj.selectedRowEntities.length < tempState.rowEntities.length) {
          // obj.groupByRow = ;
          obj.toggleRow = false;
        } else {
          obj.groupByRow = false;
          obj.toggleRow = true; // no entities to group
          obj.tooltipMessageRow = lang.no_entities_to_group;
        }
      } else {
        if (tempState.rowEntities.length === _this.state.limitEntities) {
          if (obj.selectedRowEntities.length > _this.state.limitEntities - 1) {
            obj.groupByRow = false;
            obj.toggleRow = true; // entity limit reached
            obj.tooltipMessageRow = lang.no_entities_to_group;
          } else {
            // obj.groupByRow = true;
            obj.toggleRow = false;
          }
        } else {
          if (obj.selectedRowEntities.length > _this.state.limitEntities - 1) {
            obj.groupByRow = false;
            obj.toggleRow = true; // entity limit reached
            obj.tooltipMessageRow = lang.entity_limit_reached;
          } else {
            // obj.groupByRow = true;
            obj.toggleRow = false;
          }
        }
      }
      obj.isChangedRow = true;
    } else {
      entities = _this.state.columnEntities;
      if (checked) {
        obj.selectedColumnEntities.push(entity);
      } else {
        obj.selectedColumnEntities = obj.selectedColumnEntities.filter((e) => e !== entity);
      }
      entities.find((f) => f.entities === entity).check = checked;
      tempState.columnEntities = entities;
      if (tempState.columnEntities.length <= _this.state.limitEntities - 1) {
        if (obj.selectedColumnEntities.length < tempState.columnEntities.length) {
          // obj.groupByColumn = true;
          obj.toggleColumn = false;
        } else {
          obj.groupByColumn = false;
          obj.toggleColumn = true; //no entities to group
          obj.tooltipMessageColumn = lang.no_entities_to_group;
        }
      } else {
        if (tempState.columnEntities.length === _this.state.limitEntities) {
          if (obj.selectedColumnEntities.length > _this.state.limitEntities - 1) {
            obj.groupByColumn = false;
            obj.toggleColumn = true; // entity limit reached
            obj.tooltipMessageColumn = lang.no_entities_to_group;
          } else {
            // obj.groupByRow = true;
            obj.toggleColumn = false;
          }
        } else {
          if (obj.selectedColumnEntities.length > _this.state.limitEntities - 1) {
            obj.groupByColumn = false;
            obj.toggleColumn = true; //entity limit reached
            obj.tooltipMessageColumn = lang.entity_limit_reached;
          } else {
            // obj.groupByColumn = true;
            obj.toggleColumn = false;
          }
        }
      }

      obj.isChangedColumn = true;
    }
    if (isRow) {
      obj.isRowApplyDisabled = !obj.groupByRow && obj.selectedRowEntities.length === 0;
    } else {
      obj.isColumnApplyDisabled = !obj.groupByColumn && obj.selectedColumnEntities.length === 0;
    }
    tempState.tempSelectedEntitiesObj = obj;
    tempState.isEntityConfChanged = true;
    _this.setState(tempState);
  };

  applyThenfetchData = (isRow) => {
    let _this = this;
    let selectedEntitiesObj = _this.state.selectedEntitiesObj;
    let tempSelectedEntitiesObj = _this.state.tempSelectedEntitiesObj;
    if (isRow) {
      //apply from row selected Entities dialog
      selectedEntitiesObj.selectedRowEntities = tempSelectedEntitiesObj.selectedRowEntities;
      selectedEntitiesObj.groupByRow = tempSelectedEntitiesObj.groupByRow;
      selectedEntitiesObj.tooltipMessageRow = tempSelectedEntitiesObj.tooltipMessageRow;
      selectedEntitiesObj.toggleRow = tempSelectedEntitiesObj.toggleRow;
      selectedEntitiesObj.isChangedRow = tempSelectedEntitiesObj.isChangedRow;
    } else {
      //apply from column selected entities dialog
      selectedEntitiesObj.selectedColumnEntities = tempSelectedEntitiesObj.selectedColumnEntities;
      selectedEntitiesObj.groupByColumn = tempSelectedEntitiesObj.groupByColumn;
      selectedEntitiesObj.tooltipMessageColumn = tempSelectedEntitiesObj.tooltipMessageColumn;
      selectedEntitiesObj.toggleColumn = tempSelectedEntitiesObj.toggleColumn;
      selectedEntitiesObj.isChangedColumn = tempSelectedEntitiesObj.isChangedColumn;
    }
    _this.setState(
      {
        selectedEntitiesObj: selectedEntitiesObj,
      },
      function () {
        _this.getHeatmapData(_this.state.ps_default_line, undefined, undefined, false, isRow);
      }
    );
  };

  resetToDefault = (fromReset, isRow) => {
    let _this = this;
    _this.setState({
      tempSelectedEntitiesObj: _this.getDefaultEntitiesTempObject({}, fromReset, isRow),
      fromReset: fromReset,
      isEntityConfChanged: true,
    });
    if (isRow) {
      _this.state.tempSelectedEntitiesObj.isChangedRow = false;
    } else {
      _this.state.tempSelectedEntitiesObj.isChangedColumn = false;
    }
  };

  setGroupByValue = (value, isRow) => {
    let _this = this;
    let obj = _this.state.tempSelectedEntitiesObj;
    if (isRow) {
      obj.groupByRow = value;
      obj.isChangedRow = true;
      obj.isRowApplyDisabled = !obj.groupByRow && obj.selectedRowEntities.length === 0;
    } else {
      obj.groupByColumn = value;
      obj.isChangedColumn = true;
      obj.isColumnApplyDisabled = !obj.groupByColumn && obj.selectedColumnEntities.length === 0;
    }
    _this.setState(
      {
        isEntityConfChanged: true,
        tempSelectedEntitiesObj: obj,
      },
      () => {
        _this.heatMap.forceUpdate();
      }
    );
  };

  closeList = () => {
    this.hideComponent(LIST); //if it is a red x, hide the list
    this.showComponent(HEATMAP_SCREEN);
    $("#filter-control-0").show();
    $(".heatMap-container").show();
    $("#hm-third-header").show();
    $(".heatMap-container").removeClass("half-screen-heatmap");
    sessionStorage.removeItem("selectedProfile_list");
    this.props.setIsListFullScreen(false);
    this.setState({
      isListFullScreen: false,
    });
  };

  /**
   * toggle full screen view on full screen button click
   */
  maximizeTable = () => {
    let _this = this;
    this.props.setIsListFullScreen(!this.state.isListFullScreen);
    this.setState(
      {
        isListFullScreen: !this.state.isListFullScreen,
      },
      function () {
        _this.maximizeMinimizeTable(_this.state.isListFullScreen);
      }
    );
  };

  maximizeMinimizeTable = (isListFullScreen) => {
    let _this = this;
    if (isListFullScreen) {
      _this.makeListFullScreen();
    } else {
      _this.makeListHalfScreen();
    }
  };

  makeListFullScreen = () => {
    $("#heatMap-list").removeClass("half-screen-list-container");
    $("#heatMap-list").addClass("full-screen-list-container");
    $("#heatMap-list #table-list").addClass("full-screen-list");
    $("#heatMap-list #table-list").removeClass("half-screen-list");
    $(".heatMap-container").hide();
    $("#hm-third-header").hide();
  };

  makeListHalfScreen = () => {
    $("#heatMap-list").addClass("half-screen-list-container");
    $("#heatMap-list").removeClass("full-screen-list-container");
    $("#heatMap-list #table-list").addClass("half-screen-list");
    $("#heatMap-list #table-list").removeClass("full-screen-list");
    $(".heatMap-container").show();
    $("#hm-third-header").show();
  };

  /**Export Related functionalities */
  setExportOptionsForUser = (hideExportOnList) => {
    let _this = this;
    if (!_this.state.readFromStore) {
      let exportOptions = hideExportOnList ? [] : [{
        value: "export_excel",
        label: lang.header.titles.export_to_excel,
        tooltip: lang.header.tooltips.export_all_excel,
        description:lang.header.titles.export_selected_visible_rows,
        click: () => {this.props?.tablesToExcelWrapper(); this.setExportAll(false, true);}
      }];
      var hasExportCSVAccess = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.EXPORT_DATA_TO_CSV);
      if (hasExportCSVAccess) {
        exportOptions.push({
          value: ALL_WIDGETS.EXPORT_ALL,
          label: lang.header.titles.export_to_csv,
          tooltip: lang.header.tooltips.csv,
          description:lang.header.titles.export_selected_rows_csv,
          click: () => {this.setExportAll(false)}
        });
        exportOptions.push({
          value: ALL_WIDGETS.EXPORT_CSV_PSL,
          label: lang.header.titles.csv_all_psl,
          tooltip: lang.header.tooltips.csv_all,
          description:lang.header.titles.export_all_csv,
          click: () => {this.setExportAll(true)}
        });
      }
      _this.setState({
        exportOptions: exportOptions
      })
    } else {
        let exportOptions = _this.state.exportOptions;
        exportOptions.map(function(item){
          item.click = item.value === ALL_WIDGETS.EXPORT_ALL ? () =>{_this.setExportAll(false)}: item.value ===  ALL_WIDGETS.EXPORT_CSV_PSL ? ()=>{_this.setExportAll(true)} :()=>{ _this.props?.tablesToExcelWrapper(); _this.setExportAll(false, true)};
        });
        _this.setState({
          readFromStore: false,
          exportOptions: exportOptions
        })
    }
  }
  

   /**Manage columns Related functionalities */
  updateManageColumnsProfile = (profile, callback) => {
    let manageColumnsProfile = copyObjectValues(profile);
    manageColumnsProfile.is_modified = profile.is_modified;
    manageColumnsProfile.is_applied = profile.is_applied;
    manageColumnsProfile.name = profile.name;
    manageColumnsProfile.label = profile.name;
    manageColumnsProfile.simplified_columns = profile.simplified_columns || profile.columns;
    this.setState({
      manageColumnsProfile: manageColumnsProfile,
      selectedEntities: 0
    }, () => {
      if (callback) callback();
    });
  };

  approveBulkChange = (key, element) => {
    if (key === PROFILE_COLUMN.IS_CHECKED) {
      let elementCosttype = element[PSS.DUPLICATE_KEYS.COSTTYPE];
      if (elementCosttype === costtype.calculated) {
        //if calculated, allow only if not percentage
        return ALL_FORMATS[element[PSS.FORMAT_TYPE_ID]] !== FormatTypes.PERCENTAGE;
      } else {
        return ![costtype.attribute, costtype.medians, costtype.ratios, costtype.totalratios, costtype.count].includes(elementCosttype);
      }
    }
  };

  onSelectDefaultProfile = (profile, callback) => {
    profile.is_modified = false;
    this.updateManageColumnsProfile(profile, callback);
  };

  onChangeProfileData = (profile, callback) => {
    let _this = this;
    let callbackFunc = () => { 
      if(callback) callback()
      _this.pageComponent?.tabulatorList?.current?.tabulator?.setPageSize(100)
    };

    _this.updateManageColumnsProfile(profile, callbackFunc);
    
  };

  saveDrillFilter = (filter) => {
    this.setState({
        drillFilter: filter
    });
  }

  renderExportBody = () => {
    let configureClassName = "table-configure-dialog";
    return (
        <div id="configure-dropdown"
             style={{position: "absolute", right: this.props.isDrilling ? "8.5vw" : "9vw", top: "0.5vw"}}>
          <div id="stack_table_export_dialog" className={configureClassName + " table-configure-dialog configure-container configure_body"}>
            {this.state?.exportOptions.map((option, index) => (
                <div className={"export_options_" + option.value} key={index}>
                  <div className="stacks_configure_title" onClick={()=>option?.click()}>{option.label}</div>
                  <div className="export_description">{option.description}</div>
                  <div className="export_container_divider"></div>
                </div>
            ))}
          </div>
        </div>
    )
  }

  onDrillOptionClick = (option, type) =>{
    let cardsDataLength = this.pageComponent?.state?.cardsData?.length;
    if(cardsDataLength>0){
      this.pageComponent.tabulatorDrill.current.drill(option.value, type);
    }else{
      this.pageComponent.tabulatorList.current.drill(option.value, type);
    }
  }

  resetSelectedEntities=()=>{
    this.setState({
      selectedEntities: 0,
      rowSelectedInDrillHeatmap: []
    })
  }

  /**
   * This is used for list drill because when calling this.props.manageColumnsDrillHeatmapRef
   * in ProfitMapList.js in submitDrill(), we will get null since props not yet updated.
   * @returns manage columns drill ref
   */
  getManageColumnsDrillRef = () => {
    return this.manageColumnsDrillHeatmapRef;
  }

  render() {
    let periodsObj = this.getPeriodsObjectMonth();
    let selectedProfile = ALL_WIDGETS.TITLES.COMPANY_DEFAULT;
    let addModified = false;
    let remaining = numberToWords(3 - this.state.selectedEntities);
    let rowText = this.state.selectedEntities < 2 ? "rows" : "row";
    if (this.state.manageColumnsProfile) {
      selectedProfile = this.state.manageColumnsProfile.label;
      if (this.state.manageColumnsProfile.is_applied || this.state.manageColumnsProfile.is_modified) {
        addModified = true;
      }
    }
    var filterHeaderClassName = "second-dimension-header-container";
    // if(this.state._ready){
    var filterIconClass = this.state.dimensionListFilter !== "[]" ? "filter-icon-filtering" : "filter-icon";
    var periodWidth = 15;
    var sliderWidth = "96%";
    var sliderMarginLeft = "0%";
    var playWidth = 3;
    if (this.props.periodsStatusState?.periods) {
      periodWidth = Math.floor(100 / this.props.periodsStatusState?.periods.length, 2);
      sliderWidth = (this.props.periodsStatusState?.periods.length - 1) * periodWidth + "%";
      sliderMarginLeft = Math.floor(100 / this.props.periodsStatusState?.periods.length, 2) / 2 - playWidth + "%";
    }
    var selectedDataset = this.state.selectedPeriod;
    if (selectedDataset) {
      selectedDataset = selectedDataset.replace("FY", MESSAGES.heatmap_12month_abbrv);
    }
    var comp = [];
    var comp2 = [];
    /* Extended profit stack dropdown options tooltip (it shows the custom views description) */
    const optionLabel = ({ label, description }) => (
      <div title={description} className="Select-option">
        {label}
      </div>
    );
    /* dropdown button custom style */

    comp = (
        <HeatMapTable
          ref={(el) => (this.heatMap = el)}
          firstDimension={this.state.dimension_0}
          secondDimension={this.state.dimension_1}
          firstDimensionLabel={findOptionByKey(this.props.vectorState?.vectorObjects, this.state.dimension_0).label}
          secondDimensionLabel={findOptionByKey(this.props.vectorState?.vectorObjects, this.state.dimension_1).label}
          data={this.state.heatMapData}
          setClickedCell={this.setClickedCell}
          onTooltipBtnClick={this.onTooltipBtnClick}
          dim1Type={this.state.dim1Type}
          dim2Type={this.state.dim2Type}
          runExtendedProfitStack={this.runProfitStack}
          rowsTotal={rowsTotal}
          colsTotal={colsTotal}
          firstDimOrder={firstDimOrder}
          secondDimOrder={secondDimOrder}
          firstDimensionEntities={this.state.firstDimensionEntities}
          secondDimensionEntities={this.state.secondDimensionEntities}
          threshold={this.state.tempHeatmapConfObj[HEATMAP.CONFIGURE_OBJECT.threshold]}
          profitStackLines={this.state.profitStackLines}
          viewOptions={this.state.viewOptions}
          fetchData={this.getHeatmapData}
          ps_default_line={this.state.heatmapConfObj[HEATMAP.CONFIGURE_OBJECT.selected_psl]}
          machineValue={this.state.machine_value}
          addEntitiesDialog_row={this.state.addEntitiesOptions_row}
          addEntitiesDialog_column={this.state.addEntitiesOptions_column}
          showEntitiesOptions={this.showEntitiesOptions}
          profitFormat={this.props.profitFormat}
          vectorOptions={this.state?.vectorOptions}
          colColumns={this.state.colColumns}
          rowColumns={this.state.rowColumns}
          columnEntities={this.state.columnEntities}
          rowEntities={this.state.rowEntities}
          limitEntities={this.state.limitEntities}
          tempSelectedEntitiesObj={this.state.tempSelectedEntitiesObj}
          showRowEntitiesDropDown={this.state.showRowEntitiesDropDown}
          showColumnEntitiesDropDown={this.state.showColumnEntitiesDropDown}
          setSelectedEntities={this.setSelectedEntities}
          colorCodes={this.state.colorCodes}
          setGroupByValue={this.setGroupByValue}
          applyThenfetchData={this.applyThenfetchData}
          profitStackViewId={this.state.heatmapConfObj.stack}
          resetToDefault={this.resetToDefault}
          userAllowedMenuLinks={this.props.userAllowedMenuLinks}
          revenueCostKey={this.state.revenueCostKey}
          defaultRevenue={this.state.defaultRevenue}
        />
    );

    let renderDrillButton = (this.state.selectedEntities >0 && this.state.selectedEntities <= Number(this.props.userSettingsState?.drillEntitySelectionLimit)) || (this.state.rowSelectedInDrillHeatmap?.length>0 && this.state.rowSelectedInDrillHeatmap?.length <= Number(this.props.userSettingsState?.drillEntitySelectionLimit));
    let cardsDataLength = this.pageComponent?.state?.cardsData?.length;

    let isViewStackDisabled = this.pageComponent?.state?.isCheckboxDisabled || this.state.isDrilling;    
    let viewStackTooltip = isViewStackDisabled? this.state.isDrilling? lang.COMMON.profit_stack_not_supported_when_drilling : lang.COMMON.profit_stack_not_supported_three_vectors: "";
    
    let rowData = this.pageComponent?.tabulatorList?.current?.state?.cell?.getRow().getData() || this.pageComponent?.tabulatorDrill?.current?.state?.cell?.getRow().getData();        
    let selectedVectorWithNoKeyHasNA = rowData && (!rowData[PSL_RETURN_NAMES.NAME] && !rowData[PSL_RETURN_NAMES.NUMBER] && (rowData[PSL_RETURN_NAMES.QUADRANT] === "N/A" || rowData[PSL_RETURN_NAMES.QUADRANT_TIER] === "N/A"));
    let atLeastOneVectorOtherThanSelectedHasNA = rowData && atLeastOneVectorHasNAInData(rowData, this.props.vectorState?.vectorOptions);
    
    let drillLimitExceeded = cardsDataLength && (cardsDataLength >= Number(this.props.userSettingsState?.drillSelectionLimit));
    let isDrillDisabled = atLeastOneVectorOtherThanSelectedHasNA || selectedVectorWithNoKeyHasNA || drillLimitExceeded;
    let drillTooltip = isDrillDisabled? drillLimitExceeded? lang.drill_limit_exceeded : lang.COMMON.drill_not_supported_list_q :"";
    let canSaveCompanyFilters = getSectionExists(this.props.userAllowedMenuLinks, SAVE_COMPANY_FILTERS);

    return (
      <div style={{height: "100%"}}>
         <div id="toastExport" className={!!this.state.error ? "toast toast-fail" : "toast toast-success"}>
          <div id="desc">
            <i
              className={"fa-lg fas uk-margin-small-right " + (!!this.state.error ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")}
              aria-hidden="true"
            />
            {this.state.message}
          </div>
        </div>
        <div className={"onlyprint uk-width-1-1"}>
           <img src={"/images/Profit-Isle-Logo.png"} height={convertPxToViewport(56)} style={{ verticalAlign: "middle" }} />  {/*the logo should be changed when re adding print*/}
          <span style={{ marginLeft: convertPxToViewport(20) }} className="uk-text-lead">
            Profit {this.props.profitFormat}
          </span>
          <span style={{ marginLeft: convertPxToViewport(20), fontWeight: "bold" }}>(Dataset: {selectedDataset})</span>
        </div>
        {this.state.showMainComponent ? (
          <div className="heatMap-container" >
            {comp}
            {/* {comp2} */}
          </div>
        ) : (
          ""
        )}
        <div id="heatMap-list" className={"noprint " + this.state.hide}>
          <div className={filterHeaderClassName} style={{position: "relative"}}>
            <div
              id="filter-control-1"
              className=" uk-border noprint uk-flex uk-flex-middle uk-width-1-1 uk-margin-remove filter-control"
              style={{ padding: convertPxToViewport(5) + " " + convertPxToViewport(10) }}
            >
              <div className="uk-flex uk-flex-middle">
                <div className="expand-close-btns uk-flex uk-flex-middle uk-padding-xxsmall-top uk-margin-small-right">
                  <Button
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.ICON}
                    type={BUTTON_TYPE.DEFAULT}
                    leftIcon={<i className="fal fa-times" style={{display: "flex"}} />}
                    className="close-button uk-float-unset uk-margin-remove"
                    data-dismiss="modal"
                    aria-label="Close"
                    onBtnClick={this.closeList}
                  />
                  <Button
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.ICON}
                    type={BUTTON_TYPE.DEFAULT}
                    className={"uk-margin-xsmall-left"}
                    leftIcon={<i className={"fa-lg far fa-" + (!this.state.isListFullScreen ? "expand" : "compress")} style={{display: "flex"}} />}
                    onBtnClick={this.maximizeTable}
                  />
                </div>
                <div className="uk-flex-inline">
                  <span className="filter-control-titles">
                    {findOptionByKey(this.props.vectorState?.vectorObjects, this.state.listDimension).label + " - " + selectedProfile}
                  </span>
                  {addModified ? (
                    <div className="uk-flex-inline uk-margin-left-negative">
                      <span className="modified">{lang.manage_columns.titles.modified}</span>
                      <i
                        className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-margin-xsmall-top uk-cursor-pointer"
                        uk-tooltip={lang.manage_columns.text.modified_stmt}
                      ></i>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {renderDrillButton &&
                    <DrillButtonDropdown vectors={this.props.vectorState?.vectorOptions?.filter(e=>!e.isGroupTitle)} onOptionClick={this.onDrillOptionClick} disabled={isDrillDisabled} tooltipMessage={drillTooltip}/>
                }
                {getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.SELECT_STACK) ? (
                  <div className="disabled-profit-stack cursorNotAllowed" uk-tooltip={MESSAGES.select_entities_to_view_PS}>
                    <div className={"profit-stack-filter-container disabled-button uk-disabled"}></div>
                  </div>
                ) : (
                  ""
                )}
                {(this.state.selectedEntities>0 || (this.state.rowSelectedInDrillHeatmap?.length>0)) && (getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.MANAGE_STACKS) ||
                getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_STACK)) ? ( // to remove disabled-view-profit-stack when view-stack will no longer be disabled when drilling
                    <div uk-tooltip={viewStackTooltip || "title:"}>
                      <div className="">
                        <div className={"profit-stack-filter-container view-profit-stack-filter-container " +(renderDrillButton? "uk-margin-small-left " :"")}>
                          <Button
                              id="view_stack"
                              label={lang.header.placeholders.view_stack}
                              variant={BUTTON_VARIANT.PRIMARY}
                              size={SIZES.DEFAULT}
                              type={BUTTON_TYPE.DEFAULT}
                              disabled={isViewStackDisabled}
                              onBtnClick={() => this.runProfitStack(0, false, HEADER_ELEMENT.VIEW_STACK)}
                          />
                        </div>
                      </div>
                    </div>
                ) : (
                  ""
                )}

                {this.state.filterDisplayEditable ? (
                  <div>
                    <span style={{ marginRight: convertPxToViewport(10) }}>{MESSAGES.COMMON.CREATE_FILTER}</span>
                  </div>
                ) : (
                  ""
                )}
                {this.state.filterDisplayEditable && (
                  <div className={filterIconClass} uk-toggle="target: #filterModal_1">
                    {/* uk-toggle target id changed to avoid conflict between two different filter components */}
                    <i className="far fa-filter"></i>
                  </div>
                )}
                {this.state.showList && (
                  <FilterDialog
                    ref={(el) => (this.dimensionFilterDialRef = el)}
                    // BASE PARAMS
                    section_id={this.props.match.params.sectionId}
                    tier={this.state.listDimension}
                    vectorOptions={this.props.vectorState?.vectorOptions}
                    filter={this.state.dimensionListFilter}
                    REPORT_FORMAT_LIST_TOP={ALL_REPORTS.LIST_TOP}
                    REPORT_FORMAT_LIST_QUADRANT_TIER={ALL_REPORTS.LIST_QUADRANT_TIER}
                    psFilterDisabled={false}
                    quadrantsDisabled={false}
                    // requestFormatChange={this.changeFormat}
                    onChangeFilter={this.onChangeDimensionFilter}
                    refresh={this.refreshFilter}
                    useCookies={false}
                    scenario_id={this.props.scenarioState?.scenario}
                    compNumber={1} //changes the id of the filter comp because in heatmap we have two filters
                    filterLastUpdate={this.state.dimensionFilterLastUpdate}
                    filterDisplayEditable={this.state.filterDisplayEditable}
                    quarterOptions={this.state.optionsListDataSet}
                    profitFormat={this.props.profitFormat}
                    fromOptions={this.state.optionsListDataSet}
                    dataSet={this.props.datasetOptions?.dataset}
                    checkIfMonthsValid={this.checkIfMonthsValid}
                    user={this.props?.userSettingsState.user}
                    dispatch={this.props.dispatch}
                    canSaveCompanyFilters={canSaveCompanyFilters}
                  />
                )}
              </div>
              <div className="add-columns-export-container gap_between_buttons">
                <ButtonDropdown
                    id={"stacks-table-export-btn"}
                    ref={(r) => (this.configDropdownExpandingRef = r)}
                    placeholder={lang.header.titles.xls_short}
                    className="export-button-table uk-margin-small-left"
                    // onBtnClick={this.showConfigureDialog}
                    dropId={"stack_table_export_dialog"}
                    variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
                    size={SIZES.SMALL}
                    firstAttr={"#button-drop-stacks-table-export-btn"}
                    secondAttr={"#stack_table_export_dialog"}
                    renderContainerBody={this.renderExportBody}
                    tooltip={lang.header.tooltips.export_format}
                    // disabled={isExportExcelDisabled}
                />
                {!this.state.isDrilling && this.props.manageColsAccess && this.props.manageColsAccess[ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS] && (
                  <ManageColumns
                    ref={(r) => (this.manageColumnsRef = r)}
                    key={HEADER_ELEMENT.ADD_COLUMNS}
                    scenarioId={this.props.scenarioState?.scenario}
                    profitFormat={ALL_WIDGETS.FIELDS.LIST}
                    approveBulkChange={this.approveBulkChange}
                    user={this.props?.userSettingsState.user}
                    onSelectProfile={this.updateManageColumnsProfile}
                    onSelectDefaultProfile={this.onSelectDefaultProfile}
                    onChangeProfileData={this.onChangeProfileData}
                    manageColsAccess={this.props.manageColsAccess}
                    vectorObjects={this.props.vectorState?.vectorObjects}
                    vector={this.state.listDimension}
                    sectionsProfilesAreValidFor={this.props.manageColumnsProps.manageColumnsUpdateSections}
                    onToggleBoard={this.onToggleManageColsBoard}
                    hideDropdown={this.state.isDrilling}
                    vectorOptions={this.props.vectorState.vectorOptions}
                    updateManageColumnsProfile={this.updateManageColumnsProfile}
                    dispatch={this.props.dispatch}
                    profileColumns={this.props.profileColumns}
                    customGroups={this.props.customGroups}
                    profiles={this.props.profiles}
                    stateProfiles={this.props.stateProfiles}
                    columnProfiles={this.props.columnProfiles}
                    manageColumnsSelectionLimit={this.props.userSettingsState.manageColumnsSelectionLimit}
                    isFromExpandingList={true}
                    constraint={"8"}
                    characterSizeLimit={this.props.userSettingsState?.characterSizeLimit}
                    useAppearanceList={this.props.userSettingsState?.useAppearanceList}
                    setColumnWidthState={this.pageComponent?.setColumnWidthState}
                    setDrillListWidthState={this.pageComponent?.setDrillListWidthState}
                    useNewReorderList={this.props.useNewReorderList}
                    scenarioState={this.props.scenarioState}
                    useNewAddColumn={this.props?.userSettingsState.useNewAddColumn}
                  />
                )}
                {this.state.isDrilling ? (
                  <div className="noprint" key={"noprint"}>
                    {(this.props.manageColsAccess && !Object.keys(this.props.manageColsAccess).every((k) => !this.props.manageColsAccess[k])) && this.state.isDrilling ?
                        <ManageColumns ref={(r) => (this.manageColumnsDrillHeatmapRef = r)}
                                       key={HEADER_ELEMENT.ADD_COLUMNS + "_"}
                                       resetDrillVectorType = {this.pageComponent.resetDrillVectorType}
                                       scenarioId={this.props.scenarioState?.scenario}
                                       profitFormat={ALL_WIDGETS.FIELDS.LIST}
                                       user={this.props?.userSettingsState.user}
                                       onToggleBoard={this.onToggleManageColsDrillBoard}
                                       onSelectDefaultProfile={this.pageComponent.selectProfile}
                                       onSelectProfile={this.pageComponent.selectProfile}
                                       onChangeProfileData={this.pageComponent.onApplyProfile}
                                       manageColsAccess={this.props.manageColsAccess}
                                       vectorObjects={this.props.vectorState?.vectorObjects}
                                       vectorOptions={this.props.vectorState.vectorOptions}
                                       updateDrillProfile={this.pageComponent.updateDrillProfile}
                                       checkForLimitAccessMessage={this.props.checkForLimitAccessMessage}
                                       vector={this.state.isDrilling && this.props?.history?.location?.state?.drillTier ? this.props.history.location.state.drillTier : this.props.vectorState.vectors[0]}
                                       hideArrow={false}
                                       id={"overlay-manage-columns-drill"}
                                       isDrilling={this.state.isDrilling}
                                       manageColumnsSelectionLimit={this.props?.userSettingsState?.manageColumnsSelectionLimit}
                                       setDrillTabulatorPageSize={this.pageComponent.setDrillTabulatorPageSize}
                                       constraint={"15"}
                                       updateManageColumnsProfile={this.updateManageColumnsProfile}
                                       dispatch={this.props.dispatch}
                                       characterSizeLimit={this.props?.userSettingsState?.characterSizeLimit}
                                       useAppearanceList={this.props?.userSettingsState.useAppearanceList}
                                       setColumnWidthState={this.pageComponent?.setColumnWidthState}
                                       setDrillListWidthState={this.pageComponent?.setDrillListWidthState}
                                       useNewReorderList={this.props.useNewReorderList}
                                       scenarioState={this.props.scenarioState}
                                       useNewAddColumn={this.props?.userSettingsState.useNewAddColumn}
                        /> : ""}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {this.state.showList && (
            <ProfitMapList
              ref={(r) => (this.pageComponent = r)}
              setSelectedEntities={this.resetSelectedEntities}
              key={"heatmap-list"}
              manageColsRef={this.manageColumnsRef}
              manageColumnsRef={this.manageColumnsRef}
              manageColumnsDrillRef={this.manageColumnsDrillHeatmapRef}
              filter={this.state.dimensionListFilter}
              saveDrillFilter={this.saveDrillFilter}
              saveChosenEntities={this.saveChosenEntities}
              refresh={true}
              profitStackSelectedEntities={profitStackSelectedEntities}
              user={this.props?.userSettingsState.user}
              sectionId={this.props.match.params.sectionId}
              drillVectorsOptions={this.state.drillVectorsOptions}
              options={this.props.vectorState?.vectorOptions}
              isQuadrant={[ALL_REPORTS.LIST_QUADRANT, ALL_REPORTS.LIST_QUADRANT_TIER].indexOf(this.props.profitFormat) > -1 ? true : false}
              dataset={periodsObj.quarter}
              FY={this.props.userSettingsState.FY}
              dataTier={this.state.listDimension}
              order={this.state.order}
              limit={getLimit()}
              idToken={this.state.idToken}
              report={ALL_REPORTS.LIST_TOP}
              machine_name={this.props.userSettingsState.machine_name}
              manageColumnsProfile={this.state.manageColumnsProfile}
              setIsDrilling={this.props.setIsDrilling}
              isHeatmap={true}
              fetchDataOnMount={false}
              scenarioNumber={this.props.scenarioState?.scenarioObjects[0].scenarioNumber}
              refreshDataLenght={this.refreshDataLenght}
              scenarioObject={this.props.scenarioState?.scenarioObjects[0]}
              toggleLoader={toggleLoader}
              setTableColumns={this.setTableColumns}
              filterHeaderCSSSelector={"." + filterHeaderClassName}
              isDrilling={this.state.isDrilling}
              profitFormat={this.props.profitFormat}
              psFilterDisabled={this.state.psFilterDisabled}
              scenario_id={this.props.scenarioState?.scenario}
              setDataLength={this.props.setDataLength}
              drillFilter={this.state.drillFilter}
              checkIfMonthsValid={this.checkIfMonthsValid}
              setLimit={this.setLimit}
              getExportQueryFilter={this.getExportFilter()}
              checkForLimitAccessMessage={this.props.checkForLimitAccessMessage}
              updateStateFromParent={this.updateStateFromParent}
              records_limit={this.props.userSettingsState.records_limit}
              manageColsAccess={this.props.manageColsAccess}
              vectorObjects={this.props.vectorState?.vectorObjects}
              updateManageColumnsProfile={this.updateManageColumnsProfile}
              selectedPeriods={periodsObj.periods}
              months={periodsObj.months}
              manageColumnsSelectionLimit={this.props.userSettingsState.manageColumnsSelectionLimit}
              dispatch={this.props.dispatch}
              heatmapListData={this.props.heatmapListData}
              dataChunkLimit={this.props.userSettingsState.dataChunkLimit}
              rollingPeriod={periodsObj?.segmentPeriod}
              parentComponent={MENU_ITEM.FIELDS.HEATMAP}
              setExportOptionsForUser={this.setExportOptionsForUser}
              updateColumnsOrder={this.manageColsRef?.current?.updateColumnsOrder}
              exitDrill={this.onBackClick}
              drillSelectionLimit={Number(this.props.userSettingsState?.drillSelectionLimit)}
              hasDrillOption={true}
              useAppearanceList={this.props.userSettingsState.useAppearanceList}
              getManageColumnsDrillRef={this.getManageColumnsDrillRef}
              exportScopeFilter={this.props.exportScopeFilter}
            />
          )}
        </div>

        {this.state.dim1Type === _entityGroups.entity.value && this.state.dim1EntityOptions && this.state.dim1EntityOptions.length > 0 ? ( //only mount component when options are ready
          <ScrollList
            ref={(r) => (this.dim1ScrollListRef = r)}
            options={this.state.dim1EntityOptions}
            selectedOptions={this.state.dim1SelectedEntities}
            position={this.state.scrollListPosition}
            disabledOptions={this.state.dim1EntityOptions.filter((ent) => ent.isGrouped).map((ent) => ent.value)}
            loadOptions={this.loadDimensionEntities}
            onApply={this.applyChanges}
            defaultOptions={this.state.dim1DefaultEntities}
            maxCheckableOptions={this.state.firstDimMaxEntities}
          />
        ) : (
          ""
        )}
        {this.state.dim2Type === _entityGroups.entity.value && this.state.dim2EntityOptions && this.state.dim2EntityOptions.length > 0 ? (
          <ScrollList
            ref={(r) => (this.dim2ScrollListRef = r)}
            options={this.state.dim2EntityOptions}
            selectedOptions={this.state.dim2SelectedEntities}
            position={this.state.scrollListPosition}
            disabledOptions={this.state.dim2EntityOptions.filter((ent) => ent.isGrouped).map((ent) => ent.value)}
            loadOptions={this.loadDimensionEntities}
            onApply={this.applyChanges}
            defaultOptions={this.state.dim2DefaultEntities}
            maxCheckableOptions={this.state.secondDimMaxEntities}
          />
        ) : (
          ""
        )}
        <ConfirmPasswordModal
          ref={(r) => (this.confirmPassModalRef = r)}
          user={this.props?.userSettingsState.user}
          manageColumnsProfile={
            this.state.isDrilling ? (this.manageColumnsDrillHeatmapRef?.state?.appliedProfile || this.manageColumnsDrillHeatmapRef?.state?.selectedProfile) : this.state.manageColumnsProfile
          }
          isDrilling={this.state.isDrilling}
          trackingData={this.props.trackingData}
          dataLength={this.props.dataLength}
          pageComponent={this.pageComponent}
          getPeriodsObject={this.getPeriodsObjectMonth}
          isTempScenario={false}
          match={this.props.match}
          drillFilter={this.state.drillFilter}
          vectors={this.props.vectorState.vectors}
          vectorOptions={this.props.vectorState.vectorOptions}
          profitFormat={this.props.profitFormat}
          scenarios={this.props.scenarioState.scenarios}
          filterDialRef={this.props.filterDialRef}
          cancel={this.cancel}
          reauthenticateWithCredential={this.reauthenticateWithCredential}
          exportRef={this.exportRef}
          exportAll={this.exportAll}
          filterFinal={this.state.dimensionListFilter}
          // secondaryHeader={this.headerRef.secondaryHeader}
          manageColumnsRef={this.manageColumnsRef}
          pssLines={this.manageColumnsRef ? this.manageColumnsRef.state.profitStackLineColumns : ""}
          dataTier={this.state.listDimension}
          export_all={this.state.export_all}
          isClientSSO={this.props.userSettingsState.isClientSSO}
          exportQueryFilter={this.getExportFilter()}
          rollingSegment={ROLLING_SEGMENTS.R_12}
          exportScopeFilter={this.props.exportScopeFilter}
          // dimensionFilterDialRef={this.dimensionFilterDialRef}
        />
      </div>
    );
    // } else {
    //     return <div></div>
    // }
  }
}

function mapStateToProps(state) {
  return {
      customViewData: state.customViewData,
      profiles: state.profiles,
      heatmapObjBeforeBack: state.heatmapObjBeforeBack, // the object that contains heatmapData, profitStackLines data, configure data & selected entities data
      heatmapListData: state.heatmapListData,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(Heatmap);
