import React from "react"
import {ReactComponent as PofitAssistImg} from "../../styles/images/chatbot/profit_assist.svg";
import {lang} from "../../language/messages_en";

const ProfitAssistButton = (props) => {
    return (
        <div className="profit-assist-container" onClick={props.onClick}>
            <span className="ask-text">{lang.profit_assist.ask}</span>
            <div className="profit-assist-img-container">
                <div className="profit-assist-img"><PofitAssistImg/></div>
            </div>
        </div>
    )
}
export default ProfitAssistButton;